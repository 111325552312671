import React, { useCallback, useEffect, useState } from "react";
import MainTable from "../../insideComponents/mainTable";
import PopUp from "../../insideComponents/addressPopup";
import "../../assets/css/login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserTable from "../../sampleData/sampleDataViewUser.json";
import { useNavigate } from "react-router-dom";
import { Paper, Link, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import {
  listCompany,
  listFullCompany,
} from "../../redux/reducer/UserMaster/userMasterApiService";
import { edit, CompanyData } from "../../redux/reducer/UserMaster/editCompany";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Autocomplete } from "@mui/material";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "100",
    },
  },
});

function ViewCompany(props) {
  const [tabledata, setTableData] = useState(UserTable);
  const [popup, setPopUp] = useState(false);
  const [address, setAdress] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [company_name_filter, setCompany_filter] = useState(null);
  const [group_id, setGroup_id] = useState(null);
  const fetchedUserData = useSelector((state) => state.listCompanyApi);
  const listFullCompanyapi = useSelector((state) => state.listFullCompany);
  const [tabledata1, setTableData1] = useState(
    fetchedUserData.responce.data ? fetchedUserData.responce.data : []
  );
  const classes = useStyles();
  //api call
  const apiCall = () => {
    dispatch(listCompany(""));
    dispatch(listFullCompany(""));
  };
  useEffect(() => {
    apiCall();
  }, []);
  const getData = (val) => {
    const data = fetchedUserData.responce.data.filter((a) => {
      return a._id === val.data._id;
    });
    // console.log(data);
    // console.log(val.data);
    if (val.action === "edit") {
      console.log(val);
      dispatch(edit(true));
      dispatch(CompanyData(data[0]));
      navigate("/usermaster/addcompany");
    }
    if (val.action === "view") {
      // console.log(val);
      dispatch(edit(true));
      dispatch(CompanyData(data[0]));
      navigate("/usermaster/viewcompany/fullviewcompany");
    }
    if (val.action === "machine") {
      dispatch(edit(true));
      dispatch(CompanyData(data[0]));
      navigate("/usermaster/viewcompany/company_mapped_machine");
    }
    // console.log(val);
  };

  //filter apis
  const groupId = (e) => {
    console.log(e, company_name_filter);
    if (e) {
      dispatch(
        listCompany(
          "?group_id=" +
            e.value +
            (company_name_filter
              ? "&company_name=" + company_name_filter.value
              : "")
        )
      );
    }
    if (!e) {
      dispatch(
        listCompany(
          company_name_filter
            ? "?company_name=" + company_name_filter.value
            : ""
        )
      );
    }
  };
  const companyName = (e) => {
    // console.log(group_id, e);
    if (!e) {
      dispatch(listCompany(group_id ? "?group_id=" + group_id.value : ""));
    }
    if (e) {
      dispatch(
        listCompany(
          "?company_name=" +
            e.value +
            (group_id ? "&group_id=" + group_id.value : "")
        )
      );
    }
  };
  //popup address fieald
  const popUpWindow = (e) => {
    // console.log(e.target.id);
    const id = e.target.farthestViewportElement
      ? e.target.farthestViewportElement.id
      : e.target.id;
    const data = fetchedUserData.responce.data.filter((a) => {
      return a._id === id;
    });

    // const address1 = ;
    setAdress([...data]);
    // if (popup) {
    //   return (
    //     <>
    //       <div className="postion-absolute mt-5 bg-danger">hello</div>
    //     </>
    //   );
    // }
    // console.log(address[0]);
    // return <PopUp />;
  };
  const Action = {
    Action: [
      {
        icon: (
          <FontAwesomeIcon
            title="view machines"
            type="button"
            className="p-2"
            icon="fa-solid fa-dharmachakra"
          />
        ),
        name: "machine",
      },
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="view company"
            className="p-2"
            icon="fa-solid fa-eye"
          />
        ),
        name: "view",
      },
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="edit"
            className="p-2"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
    ],
  };
  //filter
  const filter1 = [
    {
      name: "Customer",
      value: "CUSTOMER",
    },
    {
      name: "Sales",
      value: "SALES",
    },
    {
      name: "Service",
      value: "SEARVICE ",
    },
  ];
  //filter table data
  const filter = () => {
    console.log(tabledata1);

    if (fetchedUserData.responce.data) {
      const filterarr = [];
      for (let i = 0; i < fetchedUserData.responce.data.length; i++) {
        let data = { ...fetchedUserData.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const date = new Date(data.create_at);
        const create_date =
          date.getDate() +
          "-" +
          monthNames[date.getMonth()] +
          "-" +
          date.getFullYear();

        data.create_at ? (data.create_at = create_date) : console.log("sdfdsf");
        data.Address = (
          <FontAwesomeIcon
            onClick={(e) => {
              setPopUp(true);
              popUpWindow(e);
            }}
            title="view address"
            type="button"
            // fontSize={20}
            id={data._id}
            name={data.company_name}
            className="p-2"
            icon="fa-solid fa-eye"
          />
        );
        // console.log(data._id);
        // data.is_ads_allowed = "jsd";
        filterarr.push(data);
        // console.log(data);
      }
      filterarr.filter((a) => delete a.updated_at);
      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.status);
      filterarr.filter((a) => delete a.billing_address_1);
      filterarr.filter((a) => delete a.billing_address_2);
      filterarr.filter((a) => delete a.billing_city);
      filterarr.filter((a) => delete a.billing_pincode);
      filterarr.filter((a) => delete a.billing_state);
      filterarr.filter((a) => delete a.delivery_address_1);
      filterarr.filter((a) => delete a.delivery_address_2);
      filterarr.filter((a) => delete a.delivery_city);
      filterarr.filter((a) => delete a.delivery_pincode);
      filterarr.filter((a) => delete a.delivery_state);
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={group_id ? group_id.value : ""}
                    className=""
                    options={
                      listFullCompanyapi.responce
                        ? listFullCompanyapi.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.group_id,
                              label: e.group_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setGroup_id(value);
                      groupId(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Group Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const groupdrop2 = () => {
        return (
          <>
            <div className="search-heder-table " id="dropdown1">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group "
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setCompany_filter(value);
                      companyName(value);
                      // console.log(value);
                    }}
                    value={company_name_filter ? company_name_filter.value : ""}
                    options={
                      listFullCompanyapi.responce
                        ? listFullCompanyapi.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.company_name,
                              label: e.company_name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        placeholder="Company Name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const tablefilterDropDown = [groupdrop(), groupdrop2()];
      return (
        <MainTable
          tabledata={filterarr}
          tablefilterDropDown={tablefilterDropDown}
          // filter={filter1}
          listOfMachine={" COMPANY LIST "}
          getData={getData}
          Action={Action}
        />
      );
    } else {
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={group_id ? group_id.value : ""}
                    className=""
                    options={
                      listFullCompanyapi.responce
                        ? listFullCompanyapi.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.group_id,
                              label: e.group_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setGroup_id(value);
                      groupId(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Group Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const groupdrop2 = () => {
        return (
          <>
            <div className="search-heder-table " id="dropdown1">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group "
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setCompany_filter(value);
                      companyName(value);
                      // console.log(value);
                    }}
                    value={company_name_filter ? company_name_filter.value : ""}
                    options={
                      listFullCompanyapi.responce
                        ? listFullCompanyapi.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.company_name,
                              label: e.company_name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        placeholder="Company Name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const tablefilterDropDown = [groupdrop(), groupdrop2()];
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tabledata={filterarr}
          tablefilterDropDown={tablefilterDropDown}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };
  //brudcrumps
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      CRM
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      VIEW COMPANY
    </Link>,
  ];
  return (
    <div className="comp-change">
      {fetchedUserData.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <div className="bg-primary">
        {popup && (
          <PopUp
            popup={popup}
            address={address}
            closePopUp={() => {
              setPopUp(false);
            }}
          />
        )}
      </div>

      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      {fetchedUserData.isLoading ? console.log("detehjdk") : filter()}
    </div>
  );
}

export default ViewCompany;
