import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "../../assets/css/addUser.css";
import TextInput from "../forms/textInput";
import DropdownSelecter from "../forms/dropdownSelecter";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import EmailInput from "../forms/emailInput";
import CompanyDetails from "../forms/companyDetails";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Link, CircularProgress } from "@material-ui/core";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckboxComp from "../forms/checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import {
  addUser,
  editUser,
  listCompany,
  listProfile,
} from "../../redux/reducer/UserMaster/userMasterApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useNavigate } from "react-router-dom";
import { isSuccess } from "../../redux/reducer/UserMaster/editUserApi";
import { isSuccessuser } from "../../redux/reducer/UserMaster/AddUserApi";
import ChangePassword from "../../insideComponents/changePassword";

function AddUser(props) {
  const navigate = useNavigate();
  const [edtuser, setEditUser] = useState(false);
  const [edtCompany, setedtCompany] = useState(false);
  const [iscompany, setIsCompany] = useState(false);
  const userDetails = useSelector((state) => state.viewProfile);
  const companyList = useSelector((state) => state.listCompanyApi);
  const is_edit = useSelector((state) => state.editCompany);
  const editUserApi = useSelector((state) => state.editUserApi);
  const addUserApi = useSelector((state) => state.addUser);
  const [sameBillingAddress, setSameAddress] = useState(false);
  const [edit, setEdit] = useState(is_edit.edit ? true : false);
  const [Role, setRole] = useState(edit ? is_edit.data.user[0].role : "");
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({});

  // console.log(is_edit.data.company.length);
  //   console.log(userDetails.responce.data[0].companyDetails[0]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: userDetails.responce ? userDetails.responce.data[0].name : "",

      role: userDetails.responce ? userDetails.responce.data[0].role : "",
      company_id: userDetails.responce
        ? userDetails.responce.data[0].company_id
        : "",
      email: userDetails.responce ? userDetails.responce.data[0].email_id : "",
      stay_here: false,
      companyName: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].company_name
          : ""
        : "",
      group_id: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].group_id
          : ""
        : "",
      type: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].type
          : ""
        : "",
      gst_no: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].gst_no
          : ""
        : "",
      companyemail: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].email_id
          : ""
        : "",
      primary_contact_no: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].primary_contact_no
          : ""
        : "",
      secondary_contact_no: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].secondary_contact_no
          : ""
        : "",
      pan_no: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].pan_no
          : ""
        : "",
      billing_address_1: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].billing_address_1
          : ""
        : "",
      billing_address_2: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].billing_address_2
          : ""
        : "",
      billing_city: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].billing_city
          : ""
        : "",
      billing_state: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].billing_state
          : ""
        : "",
      billing_pincode: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].billing_pincode
          : ""
        : "",
      delivery_address_1: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].delivery_address_1
          : ""
        : "",
      delivery_address_2: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].delivery_address_2
          : ""
        : "",
      delivery_city: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].delivery_city
          : ""
        : "",
      delivery_state: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].delivery_state
          : ""
        : "",
      delivery_pincode: userDetails.responce
        ? userDetails.responce.data[0].companyDetails.length != 0
          ? userDetails.responce.data[0].companyDetails[0].delivery_pincode
          : ""
        : "",
    },
    validationSchema,

    onSubmit: (data) => {
      submit(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;
  console.log(values);
  //submit data
  const submit = (data) => {
    const user_role = localStorage.getItem("Role");

    if (!iscompany) {
      if (user_role === "CUSTOMER ADMIN" || user_role === "DISTRIBUTOR") {
        const {
          email,
          role,
          username,

          company_id,
        } = data;

        const submit = {
          _id: userDetails.responce.data[0]._id,
          email_id: email,
          role: role,
          name: username,
          iscompany: false,
          company_id: company_id,
        };
        dispatch(editUser(submit));
      } else {
        const { email, role, username } = data;
        const submit = {
          _id: userDetails.responce.data[0]._id,
          email_id: email,
          iscompany: false,
          role: role,
          name: username,
        };
        dispatch(editUser(submit));
      }
    }
    if (iscompany) {
      const {
        companyName,
        group_id,
        type,
        gst_no,
        companyemail,
        primary_contact_no,
        secondary_contact_no,
        pan_no,
        billing_address_1,
        billing_address_2,
        billing_city,
        billing_state,
        billing_pincode,
        delivery_address_1,
        delivery_address_2,
        delivery_city,
        delivery_state,
        delivery_pincode,
      } = data;
      const submitdata = {
        _id: userDetails.responce.data[0].company_id,
        iscompany: true,
        company_detail: {
          group_id: group_id,
          type: type,
          company_name: companyName,
          email_id: companyemail,
          primary_contact_no: primary_contact_no,
          secondary_contact_no: secondary_contact_no,
          gst_no: gst_no,
          pan_no: pan_no,
          billing_address_1: billing_address_1,
          billing_address_2: billing_address_2,
          billing_city: billing_city,
          billing_state: billing_state,
          billing_pincode: billing_pincode,
          delivery_address_1: delivery_address_1,
          delivery_address_2: delivery_address_2,
          delivery_city: delivery_city,
          delivery_state: delivery_state,
          delivery_pincode: delivery_pincode,
        },
      };
      dispatch(editUser(submitdata));
    }
  };
  //get company details
  useEffect(() => {
    dispatch(listProfile(""));

    if (editUserApi.isSuccess) {
      //   resetForm();
      setedtCompany(false);
      setEditUser(false);
      dispatch(isSuccess(false));
      //   if (!values.stay_here) {
      //     navigate("/usermaster/viewUser");
      //   }
    }
  }, [editUserApi]);

  //navigate view user
  const type1 = [
    { value: "SALES", label: "SALES" },
    { value: "CUSTOMER", label: "CUSTOMER" },
    { value: " SEARVICE ", label: " SEARVICE " },
  ];

  const role = [
    { value: "ADMIN", label: "ADMIN" },
    { value: "CUSTOMER SUPPORT MANAGER", label: "CUSTOMER SUPPORT MANAGER" },
    { value: "CUSTOMER SUPPORT TEAM", label: "CUSTOMER SUPPORT TEAM" },
    { value: "CUSTOMER ADMIN", label: "CUSTOMER ADMIN" },
    { value: "DISTRIBUTOR", label: "DISTRIBUTOR" },
  ];
  const type = !companyList.isSuccess
    ? [{ value: "", label: "No Company Found" }]
    : companyList.responce.data.map((data, key) => {
        return {
          key: key,
          value: data.company_name,
          label: data.company_name,
        };
      });

  // [
  //   { value: "SALES", label: "SALES" },
  //   { value: "CUSTOMER", label: "CUSTOMER" },
  //   { value: " SEARVICE ", label: " SEARVICE " },

  // ];
  const city = [
    { value: "Namakkal", label: "Namakkal" },
    { value: "Chennai", label: "Chennai" },
    { value: " Coimbatore ", label: " Coimbatore " },
    { value: "Pondicherry", label: "Pondicherry" },
    { value: "Tirichy", label: "Tirichy" },
    { value: " Selam ", label: " Selam " },
    { value: "Nagarkovil", label: "Nagarkovil" },
    { value: "Thanjavur", label: "Thanjavur" },
    { value: "Madurai", label: "Madurai" },
  ];
  const state = [
    { value: "Tamil Nadu", label: "Tamil Nadu " },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: " Maharashtra ", label: " Maharashtra " },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: " Arunachal Pradesh ", label: " Arunachal Pradesh " },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Kerala", label: "Kerala" },
    { value: " Tripura ", label: " Tripura " },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  ];
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      USER PROFILE
    </Link>,
  ];
  console.log(errors);

  const clearForm = () => {
    resetForm();
  };

  //company details
  const companyDetail = () => {
    const user_role = localStorage.getItem("Role");
    const isLoadingCompanydetails = userDetails.responce
      ? userDetails.responce.data[0].companyDetails.length != 0
        ? true
        : false
      : false;
    if (isLoadingCompanydetails) {
      console.log("type");
      // return <CompanyDetails companyDetails={companyDetails} />;
      return (
        <div>
          <div className="Form p-4 ">
            <Paper
              className="bg-white machineForm"
              style={{ marginBottom: "100px" }}
            >
              <div className="row  px-1 d-flex mx-1 ">
                <div className="font-weight-bold w-75 px-0 p-2">
                  COMPANY DEATAILS
                </div>
                <div
                  className="font-weight-bold d-flex justify-content-end w-25 px-0 p-4"
                  onClick={() => {
                    setedtCompany(!edtCompany);
                    // console.log(edtCompany);
                  }}
                >
                  Edit
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      className="px-2 pb-1"
                      icon="fa-solid fa-user-pen"
                    />
                  </div>
                </div>
              </div>
              <div className="row pt-1 px-4">
                <div className="col-4 ">
                  <TextInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .company_name
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    handleChange={handleChange}
                    placeholder="Company Name"
                    readonly={!edtCompany}
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="companyName"
                  />
                </div>
                <div className="col-4 ">
                  <EmailInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .email_id
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    readonly={!edtCompany}
                    handleChange={handleChange}
                    placeholder="Company email id"
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="companyemail"
                  />
                </div>
                <div className="col-4 ">
                  <TextInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .group_id
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    handleChange={handleChange}
                    readonly={!edtCompany}
                    placeholder="Group id"
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="group_id"
                  />
                </div>
                <div className="col-4 ">
                  <DropdownSelecter
                    style={{ width: "90%" }}
                    placeholder="User type"
                    readOnly={!edtCompany}
                    onBlur={() => {
                      handleBlur({ target: { name: "type" } });
                    }}
                    setFieldValue={setFieldValue}
                    vari={() => {
                      console.log();
                    }}
                    options={type}
                    errors={errors}
                    values={values}
                    touched={touched}
                    name="type"
                  />
                </div>
                <div className="col-4 ">
                  <TextInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .primary_contact_no
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    handleChange={handleChange}
                    placeholder="primary contact no"
                    readonly={!edtCompany}
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="primary_contact_no"
                  />
                </div>
                <div className="col-4 ">
                  <TextInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .secondary_contact_no
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    handleChange={handleChange}
                    placeholder="secondary contact no"
                    errors={errors}
                    values={values}
                    readonly={!edtCompany}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="secondary_contact_no"
                  />
                </div>
                <div className="col-4 ">
                  <TextInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .gst_no
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    handleChange={handleChange}
                    placeholder="Gst No"
                    readonly={!edtCompany}
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="gst_no"
                  />
                </div>
                <div className="col-4 ">
                  <TextInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .pan_no
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    handleChange={handleChange}
                    placeholder="Pan No"
                    readonly={!edtCompany}
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="pan_no"
                  />
                </div>
              </div>
              <div className="row  px-1 d-flex mx-1 ">
                <div className="font-weight-bold w-75 px-0 p-2">
                  BILLING ADDRESS
                </div>
              </div>
              <div className="row pt-1 px-4">
                <div className="col-4 ">
                  <TextInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .billing_address_1
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    handleChange={handleChange}
                    placeholder="Billing Address 1"
                    readonly={!edtCompany}
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleBlur={handleBlur}
                    name="billing_address_1"
                  />
                </div>
                <div className="col-4 ">
                  <TextInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .billing_address_2
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    handleChange={handleChange}
                    placeholder="Billing Address 2"
                    errors={errors}
                    values={values}
                    touched={touched}
                    readonly={!edtCompany}
                    handleBlur={handleBlur}
                    name="billing_address_2"
                  />
                </div>
                <div className="col-4 ">
                  <DropdownSelecter
                    style={{ width: "90%" }}
                    placeholder="Biling City"
                    onBlur={() => {
                      handleBlur({ target: { name: "billing_city" } });
                    }}
                    setFieldValue={setFieldValue}
                    readOnly={!edtCompany}
                    options={city}
                    errors={errors}
                    values={values}
                    touched={touched}
                    vari={() => {
                      console.log();
                    }}
                    name="billing_city"
                  />
                </div>
                <div className="col-4 ">
                  <DropdownSelecter
                    style={{ width: "90%" }}
                    placeholder="Biling State"
                    onBlur={() => {
                      handleBlur({ target: { name: "billing_state" } });
                    }}
                    setFieldValue={setFieldValue}
                    options={state}
                    errors={errors}
                    readOnly={!edtCompany}
                    vari={() => {
                      console.log();
                    }}
                    values={values}
                    touched={touched}
                    name="billing_state"
                  />
                </div>
                <div className="col-4 ">
                  <TextInput
                    defaultvalue={
                      userDetails.responce
                        ? userDetails.responce.data[0].length != 0
                          ? userDetails.responce.data[0].companyDetails[0]
                              .billing_pincode
                          : ""
                        : ""
                    }
                    style={{ width: "90%" }}
                    handleChange={handleChange}
                    placeholder="Billing Pincode"
                    errors={errors}
                    values={values}
                    touched={touched}
                    readonly={!edtCompany}
                    handleBlur={handleBlur}
                    name="billing_pincode"
                  />
                </div>
              </div>
              <div className="d-flex w-100">
                <div className="row  px-1 w-100 d-flex mx-1 ">
                  <div className="font-weight-bold w-75 px-0 p-2">
                    DELIVER ADDRESS
                  </div>
                </div>
              </div>

              {sameBillingAddress ? (
                ChangeDeliveryAddress()
              ) : (
                <div className="row pt-1 px-4">
                  <div className="col-4 ">
                    <TextInput
                      defaultvalue={
                        userDetails.responce
                          ? userDetails.responce.data[0].length != 0
                            ? userDetails.responce.data[0].companyDetails[0]
                                .delivery_address_1
                            : ""
                          : ""
                      }
                      style={{ width: "90%" }}
                      handleChange={handleChange}
                      placeholder="Delivery Address"
                      errors={errors}
                      values={values}
                      readonly={!edtCompany}
                      touched={touched}
                      handleBlur={handleBlur}
                      name="delivery_address_1"
                    />
                  </div>
                  <div className="col-4 ">
                    <TextInput
                      defaultvalue={
                        userDetails.responce
                          ? userDetails.responce.data[0].length != 0
                            ? userDetails.responce.data[0].companyDetails[0]
                                .delivery_address_2
                            : ""
                          : ""
                      }
                      style={{ width: "90%" }}
                      handleChange={handleChange}
                      placeholder="Delivery Address 2"
                      errors={errors}
                      values={values}
                      readonly={!edtCompany}
                      touched={touched}
                      handleBlur={handleBlur}
                      name="delivery_address_2"
                    />
                  </div>
                  <div className="col-4 ">
                    <DropdownSelecter
                      style={{ width: "90%" }}
                      placeholder="Delivery City"
                      onBlur={() => {
                        handleBlur({ target: { name: "delivery_city" } });
                      }}
                      setFieldValue={setFieldValue}
                      options={city}
                      errors={errors}
                      values={values}
                      touched={touched}
                      readOnly={!edtCompany}
                      vari={() => {
                        console.log();
                      }}
                      name="delivery_city"
                    />
                  </div>
                  <div className="col-4 ">
                    <DropdownSelecter
                      style={{ width: "90%" }}
                      placeholder="Delivery State"
                      onBlur={() => {
                        handleBlur({ target: { name: "delivery_state" } });
                      }}
                      setFieldValue={setFieldValue}
                      options={state}
                      errors={errors}
                      readOnly={!edtCompany}
                      vari={() => {
                        console.log();
                      }}
                      values={values}
                      touched={touched}
                      name="delivery_state"
                    />
                  </div>
                  <div className="col-4 ">
                    <TextInput
                      defaultvalue={
                        userDetails.responce
                          ? userDetails.responce.data[0].length != 0
                            ? userDetails.responce.data[0].companyDetails[0]
                                .delivery_pincode
                            : ""
                          : ""
                      }
                      style={{ width: "90%" }}
                      handleChange={handleChange}
                      placeholder="Delivery Pincode"
                      errors={errors}
                      readonly={!edtCompany}
                      values={values}
                      touched={touched}
                      handleBlur={handleBlur}
                      name="delivery_pincode"
                    />
                  </div>
                </div>
              )}
              {edtCompany ? (
                <div className="w-100 d-flex justify-content-end p-4 px-5 ">
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setedtCompany(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="outlined"
                      loading={editUserApi.isLoading ? true : false}
                      onClick={() => {
                        setIsCompany(true);
                        handleSubmit();
                      }}
                    >
                      {edit ? "Submit" : "Save"}
                    </LoadingButton>
                  </Stack>
                </div>
              ) : (
                ""
              )}
            </Paper>
          </div>
        </div>
      );
    }
  };

  const ChangeDeliveryAddress = (e) => {
    return (
      <div className="row pt-1 px-4">
        <div className="col-4 py-3">
          <div className="d-flex flex-column">
            <TextField
              style={{ width: "90%" }}
              className="bg-white w-100 grey formTextInput p-2"
              defaultValue={values.billing_address_1}
              type="text"
              values={values.billing_address_1}
              inputProps={{ readOnly: true }}
              onBlur={handleBlur}
              onChange={handleChange}
              label={"Delivery Address 1"}
              variant="standard"
              name="delivery_address_1"
            />
          </div>
        </div>
        <div className="col-4 py-3 ">
          <div className="d-flex flex-column">
            <TextField
              style={{ width: "90%" }}
              className="bg-white w-100 grey formTextInput p-2"
              defaultValue={values.billing_address_2}
              inputProps={{ readOnly: true }}
              type="text"
              values={values.billing_address_2}
              onBlur={handleBlur}
              onChange={handleChange}
              label={"Delivery Address 2"}
              variant="standard"
              name="delivery_address_2"
            />
          </div>
        </div>
        <div className="col-4 py-3">
          <div className="" style={{ width: "90%" }}>
            <Autocomplete
              // value={values.billing_city}
              options={city}
              defaultValue={{
                value: values.billing_city,
                label: values.billing_city,
              }}
              readOnly
              renderInput={(params) => (
                <TextField
                  className="bg-white w-100 grey formTextInput p-2"
                  name={"delivery_city"}
                  {...params}
                  label={"Delivery City"}
                  variant="standard"
                />
              )}
              onChange={(e, value) => {
                const a = value ? value.value : "";
                // vari(a);

                setFieldValue("delivery_city", a);
              }}
            />
          </div>
        </div>
        <div className="col-4 py-3">
          <div className="" style={{ width: "90%" }}>
            <Autocomplete
              // value={values.billing_city}
              options={city}
              defaultValue={{
                value: values.billing_state,
                label: values.billing_state,
              }}
              readOnly
              renderInput={(params) => (
                <TextField
                  className="bg-white w-100 grey formTextInput p-2"
                  name={"delivery_state"}
                  {...params}
                  label={"Delivery State"}
                  variant="standard"
                />
              )}
              onChange={(e, value) => {
                const a = value ? value.value : "";
                // vari(a);

                setFieldValue("delivery_state", a);
              }}
            />
          </div>
        </div>
        <div className="col-4 py-3">
          <div className="d-flex flex-column">
            <TextField
              style={{ width: "90%" }}
              className="bg-white w-100 grey formTextInput p-2"
              defaultValue={values.billing_pincode}
              inputProps={{ readOnly: true }}
              type="text"
              values={values.billing_pincode}
              onBlur={handleBlur}
              onChange={handleChange}
              label={"Billing Pincode"}
              variant="standard"
              name="billing_pincode"
            />
          </div>
        </div>
      </div>
    );
  };
  const UserDetails = (val) => {
    console.log(val.data[0].email_id);
    if (val) {
      return (
        <div>
          <div className="Form  d-flex">
            <div className="col-6 p-4">
              <Paper className="bg-white machineForm">
                <div className="row  px-1 d-flex mx-1 ">
                  <div className="font-weight-bold w-75 ">
                    PERSIONAL DEATAILS
                  </div>
                  <div
                    className="font-weight-bold d-flex justify-content-end w-25 "
                    onClick={() => {
                      setEditUser(!edtuser);
                      console.log(edtuser);
                    }}
                  >
                    Edit
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon
                        className="px-2 pb-1"
                        icon="fa-solid fa-user-pen"
                      />
                    </div>
                  </div>
                </div>
                <div className="row pt-3 px-4">
                  <div className="col-12 ">
                    <TextInput
                      defaultvalue={val.data[0].name}
                      style={{ width: "90%" }}
                      handleChange={handleChange}
                      placeholder="User Name"
                      readonly={!edtuser}
                      errors={errors}
                      values={values}
                      touched={touched}
                      handleBlur={handleBlur}
                      name="username"
                    />
                  </div>

                  <div className="col-12 ">
                    <TextInput
                      defaultvalue={val.data[0].email_id}
                      style={{ width: "90%" }}
                      handleChange={handleChange}
                      placeholder="User email id"
                      readonly={true}
                      errors={errors}
                      values={values}
                      touched={touched}
                      handleBlur={handleBlur}
                      name="email"
                    />
                  </div>
                </div>
                {edtuser ? (
                  <div className="w-100 d-flex justify-content-end p-4 px-5 ">
                    <Stack spacing={2} direction="row">
                      <Button
                        color="error"
                        variant="outlined"
                        onClick={() => {
                          setEditUser(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        variant="outlined"
                        loadingPosition="center"
                        loading={editUserApi.isLoading ? true : false}
                        onClick={() => {
                          setIsCompany(false);
                          handleSubmit();
                        }}
                      >
                        {edit ? "Submit" : "Save"}
                      </LoadingButton>
                    </Stack>
                  </div>
                ) : (
                  ""
                )}
              </Paper>
            </div>
            <ChangePassword />
          </div>
          {companyDetail()}
        </div>
      );
    }
  };
  console.log(userDetails.responce);
  return (
    <div className="comp-change ">
      {userDetails.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      {!userDetails.responce ? "" : UserDetails(userDetails.responce)}
    </div>
  );
}

export default AddUser;
