import React from "react";
import "../assets/css/mainTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Pagination({
  lastPageNumber,
  firstPage,
  curentPage,
  totalPage,
  totalData,
  nextPage,
  prevPage,
  pagePerData,
}) {
  const Pagenumber = [];

  //get total page number

  for (let i = 1; i <= Math.ceil(totalData / totalPage); i++) {
    Pagenumber.push(i);
  }
  //   console.log(Pagenumber);
  return (
    <>
      <div className="d-flex">
        <div className="pagination py-3 d-flex align-items-center justify-content-center w-100 px-5">
          <a
            href="#"
            onClick={() => {
              firstPage(1);
            }}
            style={{ paddingLeft: "10%" }}
          >
            <FontAwesomeIcon
              className={
                curentPage === 1
                  ? "grey opacity-50  bg-white pag-icon-next"
                  : "grey  bg-white pag-icon-next"
              }
              icon="fa-solid fa-arrow-left"
            />
          </a>
          <a
            href="#"
            onClick={
              curentPage === 1
                ? null
                : () => {
                    prevPage(1);
                  }
            }
          >
            <FontAwesomeIcon
              className={
                curentPage === 1
                  ? "grey opacity-50  bg-white pag-icon"
                  : "grey  bg-white pag-icon"
              }
              icon="fa-solid fa-arrow-left"
            />
          </a>
          <p className="grey d-flex pb-1 align-items-center export-excel m-0">
            Page {curentPage} of {Pagenumber.length}
          </p>
          <a
            href="#"
            onClick={
              curentPage === Pagenumber.length
                ? null
                : () => {
                    nextPage(1);
                  }
            }
          >
            <FontAwesomeIcon
              className={
                curentPage === Pagenumber.length
                  ? "grey opacity-50  bg-white pag-icon"
                  : "grey  bg-white pag-icon"
              }
              icon="fa-solid fa-arrow-right"
            />
          </a>
          <a
            href="#"
            onClick={() => {
              lastPageNumber(Pagenumber.length);
            }}
          >
            <FontAwesomeIcon
              className={
                curentPage === Pagenumber.length
                  ? "grey opacity-50  bg-white pag-icon-next"
                  : "grey  bg-white pag-icon-next"
              }
              icon="fa-solid fa-arrow-right"
            />
          </a>
        </div>
        <div className="d-flex number-page align-items-center mt-2 export-excel justify-content-around">
          <label className="w-100 d-flex font-12 justify-content-end">
            item per page:
          </label>
          <select
            style={{ backgroundColor: "#efefef" }}
            className="form-select w-50 font-12 oopt py-0"
            aria-label="Default select example"
            onChange={(e) => {
              pagePerData(e.target.value);
            }}
          >
            <option value={10} selected>
              10
            </option>
            <option className="oopt" value={15}>
              15
            </option>
            <option className="oopt" value={20}>
              20
            </option>
          </select>
        </div>
      </div>
    </>
  );
}

export default Pagination;
