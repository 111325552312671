import React, { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { Paper, Link } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropdownSelecter from "../forms/dropdownSelecter";
import { Autocomplete } from "@mui/material";
import TextInput from "../forms/textInput";
import EmailInput from "../forms/emailInput";
import { useSelector, useDispatch } from "react-redux";

import Button from "@mui/material/Button";

import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckboxComp from "../forms/checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import {
  addLeads,
  editLeadsapi,
} from "../../redux/reducer/crm/leadsApiService";
import { isSuccessAddLeads } from "../../redux/reducer/crm/addleadsapi";
import { clearLeadsData, isEditLeads } from "../../redux/reducer/crm/editLeads";
import { isSuccessEditLeads } from "../../redux/reducer/crm/editLeadsApi";

function AddLeads(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addleadsApi = useSelector((state) => state.addLeads);
  const editLeads = useSelector((state) => state.editLeads);
  const editApiLeads = useSelector((state) => state.editLeadsApi);

  // console.log(addleadsApi);
  //form validationos
  const validationSchema = Yup.object().shape({
    customer_name: Yup.string().required("Coustomer name is required"),
    email_id: Yup.string().email().required("email is id required"),
    mobile_no: Yup.string()
      .matches(new RegExp("[0-9]{10}"), "Mobile number is not valid")
      .max(10),
    contact_person: Yup.string().required("Contect is id required"),
    location: Yup.string().required("Location is id required"),
    source: Yup.string().required("Location is id required"),
    status: Yup.string().required("Status is id required"),
  });
  const formik = useFormik({
    initialValues: {
      customer_name: editLeads.edit ? editLeads.data[0].customer_name : "",
      email_id: editLeads.edit ? editLeads.data[0].email_id : "",
      mobile_no: editLeads.edit ? editLeads.data[0].mobile_no : "",
      contact_person: editLeads.edit ? editLeads.data[0].contact_person : "",
      location: editLeads.edit ? editLeads.data[0].location : "",
      source: editLeads.edit ? editLeads.data[0].source : "",
      status: editLeads.edit ? editLeads.data[0].status : "",
      remarks: editLeads.edit ? editLeads.data[0].remarks : "",
      customer_feedback: editLeads.edit
        ? editLeads.data[0].customer_feedback
        : "",
      stay_here: false,
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
    onReset: (data) => {
      console.log(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleReset,
    setTouched,
  } = formik;
  console.log(errors);
  //submit data
  const submit = (val) => {
    if (!editLeads.edit) {
      dispatch(addLeads(val));
    }
    if (editLeads.edit) {
      val._id = editLeads.data[0]._id;
      dispatch(editLeadsapi(val));
      console.log(val);
    }
  };
  //useEffect
  useEffect(() => {
    if (addleadsApi.isSuccess) {
      resetForm();
      dispatch(isSuccessAddLeads());
      if (!values.stay_here) {
        navigate("/crm/viewleads");
      }
    }
    if (editApiLeads.isSuccess) {
      resetForm();

      dispatch(isSuccessEditLeads());

      navigate("/crm/viewleads");
    }
  }, [addleadsApi, editApiLeads]);

  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      CRM
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      {editLeads.edit ? "EDIT" : "ADD"} LEADS
    </Link>,
  ];
  //locations
  const location = [
    { value: "Namakkal", label: "Namakkal" },
    { value: "Chennai", label: "Chennai" },
    { value: "Coimbatore ", label: "Coimbatore " },
    { value: "Pondicherry", label: "Pondicherry" },
    { value: "Tirichy", label: "Tirichy" },
    { value: "Selam ", label: "Selam " },
    { value: "Nagarkovil", label: "Nagarkovil" },
    { value: "Thanjavur", label: "Thanjavur" },
    { value: "Madurai", label: "Madurai" },
  ];
  const Status = [
    { value: "New", label: "New" },
    { value: "Requirement discussed", label: "Requirement discussed" },
    { value: "Demo Scheduled ", label: "Demo Scheduled " },
    { value: "Demo Completed", label: "Demo Completed" },
    { value: "Won,", label: "Won," },
    { value: "Lost, ", label: "Lost " },
    { value: "Decision Pending", label: "Decision Pending" },
  ];
  return (
    <div className="comp-change">
      <Stack className="mx-4 pt-4 ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form p-4 " style={{ marginBottom: "100px" }}>
        <Paper className="bg-white machineForm">
          <div className="row pt-1 px-4">
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  editLeads.edit ? editLeads.data[0].customer_name : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Coustomer Name"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="customer_name"
              />
            </div>
            <div className="col-4 ">
              <EmailInput
                defaultvalue={editLeads.edit ? editLeads.data[0].email_id : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Email id"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="email_id"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={editLeads.edit ? editLeads.data[0].mobile_no : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Mobile  no"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="mobile_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  editLeads.edit ? editLeads.data[0].contact_person : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Contact Persion Name"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="contact_person"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Location"
                onBlur={() => {
                  handleBlur({ target: { name: "location" } });
                }}
                setFieldValue={setFieldValue}
                options={location}
                errors={errors}
                values={values}
                touched={touched}
                vari={() => {
                  console.log();
                }}
                name="location"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={editLeads.edit ? editLeads.data[0].source : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Source"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="source"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Status"
                onBlur={() => {
                  handleBlur({ target: { name: "status" } });
                }}
                setFieldValue={setFieldValue}
                options={Status}
                errors={errors}
                values={values}
                touched={touched}
                vari={() => {
                  console.log();
                }}
                name="status"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={editLeads.edit ? editLeads.data[0].remarks : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="remarks"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="remarks"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  editLeads.edit ? editLeads.data[0].customer_feedback : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="customer_feedback"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="customer_feedback"
              />
            </div>
            <div className="col-4 ">
              <CheckboxComp
                style={{ width: "90%" }}
                label={"Stay here"}
                defaultChecked={false}
                // handleChange={(e)=>{
                //   handleChange({target:{name:"is_ads_allowed",}})
                // }}
                // onBlur={() => {
                //   handleBlur({ target: { name: "is_ads_allowed" } });
                // }}

                setFieldValue={setFieldValue}
                name="stay_here"
              />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end p-4 px-5 ">
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  resetForm();
                  dispatch(isEditLeads(false));
                  dispatch(clearLeadsData());
                  editLeads.edit ? navigate("/crm/viewleads") : resetForm();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="outlined"
                // loading={
                //   editUserApi.isLoading || addUserApi.isLoading ? true : false
                // }
                onClick={handleSubmit}
              >
                save
                {/* {edit ? "Submit" : "Save"} */}
              </LoadingButton>
            </Stack>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default AddLeads;
