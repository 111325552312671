import DashboardIcon from "@mui/icons-material/Dashboard";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import Icon from "@mui/material/Icon";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import BuildIcon from "@mui/icons-material/Build";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import 'font-awesome/css/font-awesome.min.css';

export const adminnavigations = [
  {
    name: "sidenav.sidenavDashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    name: "sidenav.sideNavServiceRequest",
    path: "/servicerequest",
    icon: <BuildIcon />,
    subNav: [
      {
        name: "Rise Request",
        path: "/servicerequest/raiseRequest",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "View Request",
        path: "/servicerequest/viewRequest",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
    ],
  },
  {
    name: "CRM",
    path: "/crm",
    icon: <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-dots" />,
    subNav: [
      {
        name: "Add Company",
        path: "/usermaster/addcompany",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "View Company",
        path: "/usermaster/viewcompany",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "Add Leads",
        path: "/crm/addleads",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "View Leads",
        path: "/crm/viewleads",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
    ],
  },
  {
    name: "sidenav.sidnavMachineMaster",
    path: "/machinemaster",
    icon: <FontAwesomeIcon icon="fa-solid fa-blender-phone" />,
    subNav: [
      {
        name: "Add/Edit Machine",
        path: "/machinemaster/addmachine",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      // {
      //   name: "View Machine",
      //   path: "/machinemaster/viewmachinedetails",
      //   icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      // },
      {
        name: "View Machine",
        path: "/machinemaster/viewmachine",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "Map Company & Machine",
        path: "/usermaster/Mapmachine",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "Machine Setting",
        path: "/machinemaster/machinesetting",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
    ],
  },
  {
    name: "sidenav.sidenavDrinkMaster",
    icon: <EmojiFoodBeverageIcon />,
    path: "/drinkmaster",
    subNav: [
      {
        name: "Add Drink",
        path: "/drinkmaster/adddrink",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "View Drink",
        path: "/drinkmaster/viewdrink",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
    ],
  },

  {
    name: "INVENTORY",
    icon: <FontAwesomeIcon icon="fa-solid fa-cart-flatbed" />,
    path: "/inventory",
    subNav: [
      {
        name: "Add Product",
        path: "/inventory/addproduct",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "View Product",
        path: "/inventory/viewproduct",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "Product Order ",
        path: "/inventory/product_order",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "Inventory",
        path: "/inventory/view_inventory",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
    ],
  },
  {
    name: "sidenav.userMaster",
    icon: <FontAwesomeIcon icon="fa-solid fa-user" />,
    path: "/usermaster",
    subNav: [
      {
        name: "Add User",
        path: "/usermaster/adduser",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      {
        name: "View User",
        path: "/usermaster/viewUser",
        icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      },
      // {
      //   name: "Inventory",
      //   path: "/usermaster/inventory",
      //   icon: <FontAwesomeIcon icon="fa-solid fa-angle-right" />,
      // },
    ],
  },
];
export const parentAdminNavigations = [
  {
    name: "sidenav.sidenavDashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    name: "sidenav.sidenavDrinkMaster",
    path: "/drinkmaster",
    icon: <EmojiFoodBeverageIcon />,
  },
  {
    name: "sidenav.sidnavMachineMaster",
    path: "/machinemaster",
    icon: <FontAwesomeIcon icon="fa-solid fa-blender-phone" />,
  },
  {
    name: "sidenav.sidnavUsage",
    path: "/usage",
    icon: <FontAwesomeIcon icon="fa-solid fa-chart-line" />,
  },
];
