import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainTable from "../../insideComponents/mainTable";
import { Card, Grid, Badge, TextField, Autocomplete } from "@mui/material";
import tabledatas from "../../sampleData/sampleTableData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
import {
  listMapedMachine,
  updatemachineStatus,
  listMapedMachinefilterApi,
  changeaddStatus,
} from "../../redux/reducer/machineMaster/addMachineApiService";
import MachineCart from "../../insideComponents/machinemastercards";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  data,
  edit,
  vieaData,
  settingData,
} from "../../redux/reducer/viewMachineEditData";
import { Link, Paper, Box, LinearProgress } from "@material-ui/core";
import {
  card,
  Issues,
  tabledata,
} from "../../insideComponents/dashbord/dashbordComponents";
import {
  gensettingdata,
  paths,
} from "../../redux/reducer/machineMaster/machineSetting";
import {
  pagePurpose,
  viewmachineUsageData,
} from "../../redux/reducer/machine_usage/machine_usage_page_data";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "2",
    },
  },
});
function ViewCompanyMachine(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const is_edit = useSelector((state) => state.editCompany);
  const fetchedTableData = useSelector((state) => state.mapedMachines);
  const filterapi = useSelector((state) => state.listMapedMachineFilterApi);
  const [tabledata, setTableData] = useState(
    fetchedTableData.responce.data ? fetchedTableData.responce.data : []
  );
  const changeMachineStatus = useSelector((state) => state.changeMachineStatus);
  const [curentStatus, setCurentStatus] = useState(null);
  const ChangeaddStatus = useSelector((state) => state.addStatus);
  const [activefilter, setActiveFilter] = useState(null);
  const [deviceIdfilter, setDeviceIdFilter] = useState(null);
  const [addsFilter, setAddsFilter] = useState(null);
  //api call functions
  const apiCall = async () => {
    await dispatch(listMapedMachine("?company_id=" + is_edit.data._id));
    await dispatch(
      listMapedMachinefilterApi("?company_id=" + is_edit.data._id)
    );
  };

  useEffect(() => {
    apiCall();
  }, [ChangeaddStatus, changeMachineStatus]);
  //lagnvage translation
  const { t } = useTranslation();
  console.log(fetchedTableData.responce.data);
  //set data to state
  // const settingData = () => {
  //   setTableData(fetchedTableData.responce.data);
  // console.log(fetchedTableData.responce.data);
  //   // setTableData(fetchedTableData.data);
  // };
  const filter1 = [
    {
      name: "Adds Allowed",
      value: "Allowed",
    },
    {
      name: "Not Allowed",
      value: "notAllowed",
    },
  ];
  const Action = {
    Action: [
      {
        icon: (
          <FontAwesomeIcon
            title="view"
            type="button"
            className="p-2"
            icon="fa-solid fa-eye"
          />
        ),
        name: "view",
      },
      {
        icon: (
          <FontAwesomeIcon
            title="edit"
            type="button"
            className="p-2"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
      {
        icon: (
          <FontAwesomeIcon
            title="setting"
            type="button"
            className="p-2"
            icon="fa-solid fa-gear"
          />
        ),
        name: "setting",
      },
      {
        icon: (
          <FontAwesomeIcon
            title="machine usage"
            type="button"
            className="p-2"
            icon="fa-chart-line"
          />
        ),
        name: "usage",
      },
    ],
  };
  //brudcrumbs
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      CRM
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        navigate("/usermaster/viewcompany");
      }}
    >
      VIEW COMPANY
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      {is_edit.data.company_name}
    </Link>,
  ];
  //callback functions for action buttons
  const getData = (value) => {
    console.log(value);
    value.data.companyName = is_edit.data.company_name;

    //edit functions
    if (value.action == "edit") {
      // console.log("hello");
      dispatch(edit(true));
      dispatch(data(value));
      navigate(
        "/usermaster/viewcompany/company_mapped_machine/fullview/editmachine"
      );
    }
    if (value.action == "view") {
      console.log(value);
      dispatch(vieaData(value));
      navigate("/usermaster/viewcompany/company_mapped_machine/fullview");
    }
    if (value.action == "setting") {
      const data = fetchedTableData.responce.data.filter((a) => {
        return a.machine._id === value.data._id;
      });
      const path = {
        path: "View_company",
        second: "VIEW MACHINE",
        third: "MACHINE NAME",
      };
      // data=value.data
      console.log(data);
      dispatch(gensettingdata(data));
      dispatch(paths(path));

      navigate("/machinemaster/machinesetting/individual");
    }
    if (value.action == "usage") {
      const data = fetchedTableData.responce.data.filter((a) => {
        return a.machine._id === value.data._id;
      });
      dispatch(pagePurpose("machine_view"));
      dispatch(viewmachineUsageData(data));

      navigate("/usermaster/viewcompany/company_mapped_machine/machineUsage");
    }
  };
  const viewCompanyUsage = (e) => {
    dispatch(
      pagePurpose({
        purpose: "companyView",
        company_name: is_edit.data.company_name,
        _id: is_edit.data._id,
      })
    );
    navigate("/usermaster/viewcompany/company_mapped_machine/machineUsage");
  };
  //table filter data
  const filtervalue = (e) => {
    if (e != "all") {
      setAddsFilter(e);
      dispatch(
        listMapedMachine(
          "?company_id=" +
            is_edit.data._id +
            "&is_ads_allowed=" +
            (e === "Allowed" ? true : false) +
            (activefilter ? "&is_active=" + activefilter : "") +
            (deviceIdfilter ? "&machine_id=" + deviceIdfilter._id : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    } else {
      setAddsFilter(null);
      dispatch(
        listMapedMachine(
          "?company_id=" +
            is_edit.data._id +
            (activefilter ? "&is_active=" + activefilter : "") +
            (deviceIdfilter ? "&machine_id=" + deviceIdfilter._id : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
  };
  //status filter
  const activeFilter = (e) => {
    if (e != "all") {
      dispatch(
        listMapedMachine(
          "?company_id=" +
            is_edit.data._id +
            "&is_active=" +
            e +
            (addsFilter
              ? "&is_ads_allowed=" + (e === "Allowed" ? true : false)
              : "") +
            (deviceIdfilter ? "&machine_id=" + deviceIdfilter._id : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    } else {
      dispatch(
        listMapedMachine(
          "?company_id=" +
            is_edit.data._id +
            (addsFilter
              ? "&is_ads_allowed=" + (e === "Allowed" ? true : false)
              : "") +
            (deviceIdfilter ? "&machine_id=" + deviceIdfilter._id : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
  };
  const DeviceIdFilter = (e) => {
    if (e) {
      dispatch(
        listMapedMachine(
          "?company_id=" +
            is_edit.data._id +
            "&machine_id=" +
            e._id +
            (addsFilter
              ? "&is_ads_allowed=" + (e === "Allowed" ? true : false)
              : "") +
            (activefilter ? "&is_active=" + activefilter : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    } else {
      dispatch(
        listMapedMachine(
          "?company_id=" +
            is_edit.data._id +
            (addsFilter
              ? "&is_ads_allowed=" + (e === "Allowed" ? true : false)
              : "") +
            (activefilter ? "&is_active=" + activefilter : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
  };
  const filterCurentValue = (e) => {
    if (e != "all") {
      setCurentStatus(e);
      dispatch(
        listMapedMachine(
          "?company_id=" +
            is_edit.data._id +
            (addsFilter
              ? "&is_ads_allowed=" + (e === "Allowed" ? true : false)
              : "") +
            (activefilter ? "&is_active=" + activefilter : "") +
            "&current_status=" +
            e +
            (deviceIdfilter ? "&machine_id=" + deviceIdfilter._id : "")
        )
      );
    } else {
      setCurentStatus(null);
      dispatch(
        listMapedMachine(
          "?company_id=" +
            is_edit.data._id +
            (addsFilter
              ? "&is_ads_allowed=" + (e === "Allowed" ? true : false)
              : "") +
            (activefilter ? "&is_active=" + activefilter : "") +
            (deviceIdfilter ? "&machine_id=" + deviceIdfilter._id : "")
        )
      );
    }
  };
  // const allacated = [];
  // tabledata.map((a) => {
  //   allacated.push(a.deviceId, a.Allacated);
  // });
  console.log(fetchedTableData.responce.data);
  //get active user
  const getAciveMachine = fetchedTableData.responce.data
    ? fetchedTableData.responce.data.filter((data) => {
        console.log(data.is_active);
        return data.is_active === true;
      })
    : 0;
  const getDefectMachine = fetchedTableData.responce.data
    ? fetchedTableData.responce.data.filter((data) => {
        console.log(data.is_active);
        return data.is_active === false;
      })
    : 0;

  //change status
  const changeStatus = (val) => {
    const submit = {
      _id: val.id,
      is_active: val.checked,
    };
    dispatch(updatemachineStatus(submit));
    // console.log(val.checked);
  };
  const changeaddStatuss = (val) => {
    const submit = {
      _id: val.id,
      is_ads_allowed: val.checked,
    };
    dispatch(changeaddStatus(submit));
    // console.log(val.checked);
  };
  //tables listing
  const fiter = () => {
    if (fetchedTableData.responce) {
      const filterarr = [];
      // console.log(fetchedTableData.responce);
      for (let i = 0; i < fetchedTableData.responce.data.length; i++) {
        let data = { ...fetchedTableData.responce.data[i].machine };
        // console.log(data);

        const adds = data.is_ads_allowed;
        delete data.is_ads_allowed;
        console.log(adds);
        data.is_ads_allowed = (
          <FormGroup
            id={data._id}
            name={data.name}
            onChange={(e) => {
              changeaddStatuss(e.target);
            }}
          >
            <FormControlLabel
              id={data._id}
              name={data.name}
              className="d-flex justify-content-center"
              control={
                <Checkbox
                  id={data._id}
                  name={data.name}
                  defaultChecked={adds ? true : false}
                />
              }
            />
          </FormGroup>
        );

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const createdate = new Date(data.created_at);
        const firmware = new Date(data.firmware_updated_at);
        data.is_active = (
          <FormGroup
            id={data._id}
            name={data.name}
            onChange={(e) => {
              changeStatus(e.target);
            }}
          >
            <FormControlLabel
              id={data._id}
              name={data.name}
              className="d-flex justify-content-center"
              control={
                <Checkbox
                  id={data._id}
                  name={data.name}
                  defaultChecked={
                    fetchedTableData.responce.data[i].is_active ? true : false
                  }
                />
              }
            />
          </FormGroup>
        );
        const create_date =
          createdate.getDate() +
          "-" +
          monthNames[createdate.getMonth()] +
          "-" +
          createdate.getFullYear();
        const firmware_updated =
          firmware.getDate() +
          "-" +
          monthNames[firmware.getMonth()] +
          "-" +
          firmware.getFullYear();
        data.created_at
          ? (data.created_at = create_date)
          : console.log("sdfdsf");
        data.firmware_updated_at
          ? (data.firmware_updated_at = firmware_updated)
          : console.log("sdfdsf");
        data.current_status =
          fetchedTableData.responce.data[i].current_status === "Connected" ? (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p
                className="px-2"
                style={{ margin: 0, padding: 0, color: "#1B8000" }}
              >
                {fetchedTableData.responce.data[i].current_status}
              </p>
            </div>
          ) : fetchedTableData.responce.data[i].current_status ===
            "Reconnected" ? (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p
                className="px-2"
                style={{ margin: 0, padding: 0, color: "#1B8000" }}
              >
                {fetchedTableData.responce.data[i].current_status}
              </p>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p className="px-2 text-danger" style={{ margin: 0, padding: 0 }}>
                {fetchedTableData.responce.data[i].current_status
                  ? fetchedTableData.responce.data[i].current_status
                  : " Not Connected"}
              </p>
            </div>
          );
        filterarr.push(data);
      }

      console.log(filterarr);
      filterarr.filter((a) => delete a.updated_at);
      filterarr.filter((a) => delete a.entity_id);
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={deviceIdfilter ? deviceIdfilter.value : ""}
                    className=""
                    options={
                      filterapi.responce
                        ? filterapi.responce.data.map((e) => {
                            return {
                              _id: e.machine._id,
                              value: e.machine.device_id,
                              label: e.machine.device_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setDeviceIdFilter(value);
                      DeviceIdFilter(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Device Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const statusFilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={activefilter ? activefilter : ""}
                    className=""
                    options={[
                      { value: true, label: "Active" },
                      { value: false, label: "Not Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setActiveFilter(value ? value.value : null);
                      activeFilter(value.value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const curentStatusFilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={curentStatus ? curentStatus : ""}
                    className=""
                    options={[
                      { value: "Connected", label: "Connected" },
                      { value: "Disconnected", label: "Disconnected" },
                      { value: "Reconnected", label: "Reconnected" },
                      { value: "Not Connected", label: "Not Connected" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      filterCurentValue(a);
                    }}
                    sx={{ width: 170, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Curent Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const addsFilters = () => {
        return (
          <>
            <div
              className="search-heder-table "
              style={{
                marginRight: "55px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={addsFilter ? addsFilter : ""}
                    className=""
                    options={[
                      { value: "Allowed", label: "Allowed" },
                      { value: "notAllowed", label: "Not Allowed" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : null;

                      filtervalue(a);
                      // console.log(value);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Adds"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      const tablefilterDropDown = [
        groupdrop(),
        statusFilters(),
        curentStatusFilters(),
        addsFilters(),
      ];
      // console.log(arr);
      if (filterarr.length != 0) {
        return (
          <MainTable
            // allacated={allacated}

            tablefilterDropDown={tablefilterDropDown}
            filtervalue={filtervalue}
            // filter={filter1}
            tabledata={filterarr}
            listOfMachine={" LIST OF MACHINE"}
            getData={getData}
            Action={Action}
          />
        );
      }
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={deviceIdfilter ? deviceIdfilter.value : ""}
                    className=""
                    options={
                      filterapi.responce
                        ? filterapi.responce.data.map((e) => {
                            return {
                              _id: e.machine._id,
                              value: e.machine.device_id,
                              label: e.machine.device_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setDeviceIdFilter(value);
                      DeviceIdFilter(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Device Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const statusFilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={activefilter ? activefilter : ""}
                    className=""
                    options={[
                      { value: true, label: "Active" },
                      { value: false, label: "Not Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setActiveFilter(value ? value.value : null);
                      activeFilter(value.value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const curentStatusFilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={curentStatus ? curentStatus : ""}
                    className=""
                    options={[
                      { value: "Connected", label: "Connected" },
                      { value: "Disconnected", label: "Disconnected" },
                      { value: "Reconnected", label: "Reconnected" },
                      { value: "Not Connected", label: "Not Connected" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      filterCurentValue(a);
                    }}
                    sx={{ width: 170, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Curent Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const addsFilters = () => {
        return (
          <>
            <div
              className="search-heder-table "
              style={{
                marginRight: "55px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={addsFilter ? addsFilter : ""}
                    className=""
                    options={[
                      { value: "Allowed", label: "Allowed" },
                      { value: "notAllowed", label: "Not Allowed" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : null;

                      filtervalue(a);
                      // console.log(value);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Adds"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      const tablefilterDropDown = [
        groupdrop(),
        statusFilters(),
        curentStatusFilters(),
        addsFilters(),
      ];
      return (
        <MainTable
          tablefilterDropDown={tablefilterDropDown}
          tabledata={filterarr}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };

  // console.log(tabledata);

  return (
    <>
      <div className="comp-change">
        {fetchedTableData.isLoading ? (
          <div className="border">
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </div>
        ) : (
          ""
        )}
        <div>
          <div className=" pt-3 px-2  row w-100">
            <div className="d-flex w-100">
              <Stack className="mx-3 py-2 w-100 ">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                  {breadcrumbs}
                </Breadcrumbs>
                {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
              </Stack>

              <div className="px-4 d-flex w-100 justify-content-end align-items-center">
                <div
                  className="d-flex  justify-content-end align-items-center"
                  onClick={() => {
                    // navigate("/drinkmaster/adddrink");
                    viewCompanyUsage();
                  }}
                  type="button"
                >
                  <div className="pb-1 font-13 ">View Usage</div>
                  <div className="px-2">
                    <FontAwesomeIcon
                      className="p-2 font-18"
                      icon="fa-solid fa-eye"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-12 col-lg-6 ">
              <Grid spacing={2} container className="align-items-center   px-3">
                <Grid
                  item
                  className="align-items-center py-3 col-sm-12 col-md-12 col-lg-6 grid h-100"
                >
                  <Card
                    style={{ borderRadius: "15px", height: "100%" }}
                    className="px-10"
                  >
                    <div className="darkgrey fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                      {t("dashboard.activeMachine")}
                    </div>
                    <div className="d-flex align-items-center">
                      <CheckCircleIcon
                        className="text-succe cancel_ico"
                        htmlColor="#a1c335"
                      />
                      <img
                        src={require("../../assets/image/coffee-machine.png")}
                        className="icon-border-cofee grey"
                      />
                      <div className="d-flex mx-2 flex-column">
                        <div className="d-flex px-3">
                          <h1 className="fw-bolder textlightblue">12,600</h1>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-up "
                            className="grey up-arrow"
                          />
                        </div>
                        <p className="grey company-text">
                          *{t("dashboard.newlyAddedMachine", { count: "10" })}{" "}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid
                  item
                  className="align-items-center py-3 col-sm-12 col-md-12 col-lg-6 grid h-100"
                >
                  <Card style={{ borderRadius: "15px", height: "100%" }}>
                    <div className="darkgrey fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                      {t("dashboard.defectMachine")}
                    </div>
                    <div className="d-flex align-items-center">
                      <CancelIcon className="text-danger cancel_ico" />
                      <img
                        src={require("../../assets/image/coffee-machine.png")}
                        className="icon-border-cofee grey"
                      />
                      <div className="d-flex mx-2 flex-column">
                        <div className="d-flex px-3">
                          <h1 className="fw-bolder text-danger">120</h1>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-down "
                            className="grey up-arrow"
                          />
                        </div>
                        <p className="grey company-text md">
                          *{" "}
                          {t("dashboard.newlyDefectedMachine", { count: "10" })}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div className=" col-sm-12 col-md-12 col-lg-6 ">
              <Grid spacing={2} container className="align-items-center px-3">
                {Issues.map((data, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      className="align-items-center col-sm-6 col-md-3 col-lg-3 py-3 grid h-100"
                    >
                      <Card
                        // className="p-2"
                        style={{ borderRadius: "15px", height: "100%" }}
                      >
                        <div className="d-flex p-3  flex-column justify-content-center align-items-center">
                          {data.icon}
                          <p className="fw-bolder grey m-0">{t(data.issue)}</p>
                          <h1 className="fw-bolder grey pt-2 ">{data.value}</h1>
                        </div>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </div> */}
          </div>
          <MachineCart
            getAciveMachine={getAciveMachine}
            getDefectMachine={getDefectMachine}
            machineData={
              fetchedTableData.responce ? fetchedTableData.responce.data : 0
            }
          />
        </div>
        {fetchedTableData.isLoading ? null : fiter()}
      </div>
    </>
  );
}

export default ViewCompanyMachine;
