import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const machineUsageDetails = createSlice({
  name: "MapedDrinks",
  initialState: {
    edit: false,
    data: {},
    pagePurpose: "",
  },
  reducers: {
    viewmachineUsageData: (state, payload) => {
      state.data = payload.payload;
    },
    pagePurpose: (state, payload) => {
      state.pagePurpose = payload.payload;
    },
    clearUsagePageData: (state, payload) => {
      state.data = {};
      state.pagePurpose = {
        purpose: "",
        company_name: "",
        _id: "",
      };
    },
  },
});

export const { viewmachineUsageData, clearUsagePageData, pagePurpose } =
  machineUsageDetails.actions;
// export const selectCompany = (state) => statetCompany.login;
export default machineUsageDetails.reducer;
