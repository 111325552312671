import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "../forms/textInput";
import PasswordInput from "../forms/PasswordInput";
import "../../assets/css/form.scss";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import DatePicker from "../forms/datepicker";
import DateRangePicker from "../forms/dateRangePicker";
import RadioButton from "../forms/radioButton";
import FileUpload from "../forms/fileUpload";
import Slider from "../forms/slider";
import DiscrideSlider from "../forms/DiscrideSlider";
import Groupcheckbox from "../forms/groupcheckbox";
import CheckboxComp from "../forms/checkbox";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useFormik, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import DropdownSelecter from "../forms/dropdownSelecter";
import MultiDropDown from "../forms/multiDropDown";
import { Link, Paper } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSuccess } from "../../redux/reducer/machineMaster/addmachineApi";
import { isSuccess1 } from "../../redux/reducer/machineMaster/editmachineapi";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  addMachine,
  editMachine,
} from "../../redux/reducer/machineMaster/addMachineApiService";

function AddMachine(props) {
  const is_edit = useSelector((state) => state.viewMachineEditData);
  const company_name = useSelector((state) => state.editCompany);

  const addapi = useSelector((state) => state.Addmachine);
  const editApi = useSelector((state) => state.EditMachine);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const edit_data = useSelector((state) => state);
  const [edit, setEdit] = useState(is_edit.edit);

  const [files, setFiles] = useState();
  const validationSchema = Yup.object().shape({
    machinename: Yup.string().required("machineName is required"),
    device_id: Yup.string().required("machineId is required"),
    Model: Yup.string().required("machineModel Number is required"),
    // CompanyName: Yup.string().required("CompanyName is required"),
    is_ads_allowed: Yup.string().required("this  is required"),
    // CompanyLocation: Yup.array()
    //   .of(Yup.object().required("company location is required").nullable())
    //   .nullable(),

    // date: Yup.string().required("date is required").nullable(),
    // file: Yup.mixed().required("file is required").nullable(),
    // DateRange: Yup.array()
    //   .of(Yup.object().required("date range is required").nullable())
    //   .nullable(),
  });
  const formik = useFormik({
    initialValues: {
      machinename: edit ? is_edit.data["name"] : "",
      device_id: edit ? is_edit.data["device_id"] : "",
      Model: edit ? is_edit.data["model"] : "",
      // CompanyName: "",
      is_ads_allowed: false,

      // CompanyLocation: [],
      // date: "",
      // DateRange: [null, null],
      // file: null,
    },
    validationSchema,
    onSubmit: (values) => {
      // console.log(values);

      submit(values);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    // handleSubmit = (values) => {
    //   console.log(values);
    // },
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;

  // console.log(values);
  // console.log(errors);
  const locationset = (e) => {
    console.log(e);
    // setLocation(e);
  };

  //onsubmit values
  // console.log(is_edit);

  //getRadio Value
  // const getRadioValue = (e) => {
  //   console.log(e.target.value);
  // };
  // const radioButtonvalue = [
  //   {
  //     option: "Option1",
  //   },
  //   {
  //     option: "Option2",
  //   },
  // ];
  //getting file
  // const file = (e) => {
  //   const data = [];
  //   for (let i = 0; i < e.target.files.length; i++) {
  //     data.push(e.target.files[i]);
  //   }
  //   setFiles(data);
  // };

  //dropdown option
  const option = [
    { value: true, label: "Adds allowed" },
    { value: false, label: "Adds not allowed" },
  ];
  //form submitions
  const submit = (val) => {
    if (edit) {
      const { machinename, Model, device_id, is_ads_allowed } = val;
      const value = {
        _id: is_edit.data["_id"],
        name: machinename,
        model: Model,
        device_id: device_id,
        is_ads_allowed: is_ads_allowed,
        firmware_version: "v8iue",
        firmware_updated_at: new Date(),
      };
      dispatch(editMachine(value));
    }
    if (!edit) {
      const { machinename, Model, device_id, is_ads_allowed } = val;
      const value = {
        name: machinename,
        model: Model,
        device_id: device_id,
        is_ads_allowed: is_ads_allowed,
        firmware_version: "v8iue",
        firmware_updated_at: new Date(),
      };
      console.log(val);
      dispatch(addMachine(value));
    }
  };
  //submit after clear forms
  // console.log(values);
  // console.log(addapi);
  useEffect(() => {
    if (addapi.isSuccess) {
      resetForm();
      dispatch(isSuccess());
    }
    if (editApi.isSuccess) {
      resetForm();
      dispatch(isSuccess1());
      navigate("/usermaster/viewcompany/company_mapped_machine");
    }
  }, [addapi, editApi]);

  //brudcrumbs
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      type="button"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      USER MASTER
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        navigate("/usermaster/viewcompany");
      }}
    >
      VIEW COMPANY
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        navigate("/usermaster/viewcompany/company_mapped_machine");
      }}
    >
      {company_name.data.company_name}
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey brud font-weight-bol font-weight-bold"
      key="2"
      color="inherit"
    >
      {edit ? "Edit Machine" : "ADD MACHINE"}
    </Link>,
  ];
  return (
    <div className="comp-change p-3">
      <Stack className="mx-2 py-2  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div>
        <div className="grey">
          <div className="px-1   py-1 font-weight-bold"></div>
        </div>
        <div className="Form p-2  h-100 ">
          <Paper className="bg-white addmachineform  machineForm1">
            <div className="row  ">
              <div className="col-12 ">
                <TextInput
                  defaultvalue={
                    edit ? is_edit.data["device_id"] : values.device_id
                  }
                  style={{ width: "90%" }}
                  handleChange={handleChange}
                  placeholder="Device Id"
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="device_id"
                />
              </div>
            </div>
            <div className="row  ">
              <div className="col-12">
                <TextInput
                  defaultvalue={edit ? is_edit.data["name"] : ""}
                  style={{ width: "90%" }}
                  handleChange={handleChange}
                  placeholder="Machine name"
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="machinename"
                />
              </div>
            </div>
            <div className="row  ">
              <div className="col-12 ">
                <TextInput
                  defaultvalue={edit ? is_edit.data.model : ""}
                  style={{ width: "90%" }}
                  handleChange={handleChange}
                  placeholder="Machine Model "
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="Model"
                />
              </div>
            </div>
            {/* <div className="row  px-4">
              <div className="col-4 ">
                <DropdownSelecter
                  style={{ width: "90%" }}
                  placeholder="adds allowed"
                  onBlur={() => {
                    handleBlur({ target: { name: "is_ads_allowed" } });
                  }}
                  setFieldValue={setFieldValue}
                  options={option}
                  errors={errors}
                  values={values}
                  touched={touched}
                  name="is_ads_allowed"
                />
              </div>
            </div> */}
            <div className="row mt-2 ">
              <div className="col-12 ">
                <div>
                  <CheckboxComp
                    style={{ width: "90%" }}
                    label={"Ads Allowed"}
                    defaultChecked={false}
                    // handleChange={(e)=>{
                    //   handleChange({target:{name:"is_ads_allowed",}})
                    // }}
                    // onBlur={() => {
                    //   handleBlur({ target: { name: "is_ads_allowed" } });
                    // }}
                    setFieldValue={setFieldValue}
                    name="is_ads_allowed"
                  />
                </div>
              </div>

              <div className="col-12 ">
                <div>
                  <CheckboxComp
                    style={{ width: "90%" }}
                    label={"Stay Here"}
                    defaultChecked={false}
                    // handleChange={(e)=>{
                    //   handleChange({target:{name:"is_ads_allowed",}})
                    // }}
                    // onBlur={() => {
                    //   handleBlur({ target: { name: "is_ads_allowed" } });
                    // }}
                    setFieldValue={setFieldValue}
                    name="stay_here"
                  />
                </div>
              </div>

              {/* <div className="col py-3 pt-1">
                <TextInput
                  style={{ width: "400px" }}
                  handleChange={handleChange}
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  placeholder="Company Name"
                  name="CompanyName"
                />
              </div>
              <div className="col py-3">
                <MultiDropDown
                  style={{ width: "400px" }}
                  placeholder="Company Location"
                  onBlur={() => {
                    handleBlur({ target: { name: "CompanyLocation" } });
                  }}
                  setFieldValue={setFieldValue}
                  options={option}
                  errors={errors}
                  values={values}
                  touched={touched}
                  name="CompanyLocation"
                />
              </div>

              <div className="col py-3">
                <DatePicker
                  style={{ width: "400px" }}
                  setFieldValue={setFieldValue}
                  placeholder=" Date"
                  options={option}
                  errors={errors}
                  touched={touched}
                  handchange={handleChange}
                  handleBlur={handleBlur}
                  name="date"
                  values={values}
                />
              </div>
              <div className="col mt-3 ">
                <FileUpload
                  style={{ width: "400px" }}
                  file={file}
                  files={files}
                  radioButtonvalue={radioButtonvalue}
                  placeholder=" Date Range Picker"
                  options={option}
                  errors={errors}
                  touched={touched}
                  onchange={(e) => {
                    console.log(e.target.files);
                    setFiles(e.target.files);
                    let event = {
                      target: {
                        name: "file",
                        value: e.target.files,
                      },
                    };
                    handleChange(event);
                  }}
                  handleBlur={handleBlur}
                  name="file"
                  values={values}
                />
              </div>

              <div className="col py-2 ">
                <p className="m-0 px-3 grey">Select Option</p>
                <RadioButton
                  style={{ width: "400px" }}
                  radioButtonvalue={radioButtonvalue}
                  placeholder=" Date Range Picker"
                  options={option}
                  errors={errors}
                  touched={touched}
                  handchange={handleChange}
                  handleBlur={handleBlur}
                  name="option"
                  values={values}
                />
              </div>

              <div className=" mt-3 ">
                <p className="m-0 grey">Select Range</p>
                <Slider
                  style={{ width: "200px" }}
                  min={10}
                  defaultValue={50}
                  maxRangeValue={100}
                  errors={errors}
                  touched={touched}
                  handchange={handleChange}
                  handleBlur={handleBlur}
                  name="range"
                  values={values}
                />
              </div> */}
              {/* <div className="mt-3">
                <DateRangePicker
                  setFieldValue={setFieldValue}
                  name="DateRange"
                  errors={errors}
                  touched={touched}
                  style={{ width: "400px" }}
                  handleBlur={handleBlur}
                  values={values}
                />
              </div> */}
              {/* <div className=" mt-3 ">
                <Example />
              </div> */}
              {/* <div className=" mt-3 ">
                      <DiscrideSlider
                        style={{ width: "200px" }}
                        min={10}
                        defaultValue={50}
                        maxRangeValue={100}
                      />
                    </div> */}
              <div className="w-100 d-flex justify-content-end p-4 px-1 ">
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      if (edit) {
                        navigate(
                          "/usermaster/viewcompany/company_mapped_machine"
                        );
                      }
                      if (!edit) {
                        resetForm();
                        navigate(
                          "/usermaster/viewcompany/company_mapped_machine"
                        );
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={
                      addapi.isLoading || editApi.isLoading ? true : false
                    }
                    variant="outlined"
                    onClick={handleSubmit}
                  >
                    {edit ? "Submit" : "Save"}
                  </LoadingButton>
                </Stack>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default AddMachine;
