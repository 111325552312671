import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://dtv29cy9i3.execute-api.ap-south-1.amazonaws.com/dev";
// const API_URL = "http://localhost:8080";
const entity_id = 1;
const token = localStorage.getItem("Token");

export const loginUser = createAsyncThunk(
  "Login/loginUser",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/user/login", value, {
        headers: { entity_id: entity_id },
      });
      return data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

//forget password
export const forgetPassword = createAsyncThunk(
  "ForgetUser/forgetPassword",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/user/forget_password", value, {
        headers: { entity_id: entity_id },
      });
      return data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
//create password
export const createPassword = createAsyncThunk(
  "CreatePassword/createPassword",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        API_URL + "/user/createpassword/" + value._id,
        value,
        {
          headers: { entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
