import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const editUser = createSlice({
  name: "editUser",
  initialState: {
    edit: false,
    data: {},
  },
  reducers: {
    edit: (state, payload) => {
      state.edit = payload.payload;
    },
    editUsers: (state, payload) => {
      state.edit = payload.payload;
      // state.edit = false;
    },
    userData: (state, payload) => {
      //   console.log(payload.payload.action);
      state.data = payload.payload;
    },
    clearUserData: (state, payload) => {
      state.edit = false;
      state.data = "";
    },
  },
});

export const { edit, editUsers, userData, clearUserData } = editUser.actions;
// export const selectUser = (state) => state.user.login;
export default editUser.reducer;
