import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import Stack from "@mui/material/Stack";
import { Paper, Link } from "@material-ui/core";
import {
  Box,
  Grid,
  LinearProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import "../../assets/css/service_request.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DropdownSelecter from "../forms/dropdownSelecter";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  listRequest,
  listAssignedUser,
  assigneRequest,
  changeRequestStatusApi,
} from "../../redux/reducer/serviceRequest/servise_requestApiService";
import { useDispatch, useSelector } from "react-redux";
import MainTable from "../../insideComponents/mainTable";
import { useNavigate } from "react-router-dom";
import { editRequestData } from "../../redux/reducer/serviceRequest/editRequest";
import { isSuccessAssignRequest } from "../../redux/reducer/serviceRequest/assignRequest";
import TextInput from "../forms/textInput";
import { isSuccesseditRequestStatusApi } from "../../redux/reducer/serviceRequest/updateStatusApi";
import { viewRequestDetailData } from "../../redux/reducer/serviceRequest/getDetailData";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "2",
    },
  },
});

function ViewRequest(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assignedUser = useSelector((state) => state.AssignedUsers);
  const assignRequestApi = useSelector((state) => state.assignRequest);
  const editStatus = useSelector((state) => state.changeRequestStatus);
  const requests = useSelector((state) => state.listRequest);
  const [open, setOpen] = React.useState(false);
  const [assignUserData, setassignUserData] = useState([]);
  const [status, setStatus] = useState(false);
  const [status_filter, setStatus_filter] = useState(null);
  const [requestTypeFilter, setRequestTypeFilter] = useState(null);
  const classes = useStyles();
  const role = localStorage.getItem("Role");
  let oneMonthPreviosDate = new Date();
  oneMonthPreviosDate.setMonth(oneMonthPreviosDate.getMonth() - 1);

  const [DateRangeStartDate, setDateRangeStartDate] =
    useState(oneMonthPreviosDate);
  const [DateRangeEndDate, setDateRangeEndDate] = useState(new Date());

  //form validations
  const validationSchema = !status
    ? Yup.object().shape({
        // request_type: Yup.string().required("Request Type is required"),
        // description: Yup.string().required("description is id required"),
        assigned_to: Yup.object()
          .shape({
            label: Yup.string().required("assignet to is required"),
            value: Yup.string().required("assignet to is required"),
          })
          .required("assignet to is required"),
      })
    : Yup.object().shape({
        status: Yup.string().required("status Type is required"),
        remarks: Yup.string().required("remarks is id required"),
      });
  const formik = useFormik({
    initialValues: {
      status: status ? assignUserData.status : "",
      remarks: "",
      assigned_to: {
        id: "",
        value: "",
        label: "",
      },

      // stay_here: false,
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
    onReset: (data) => {
      console.log(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleReset,
    setTouched,
  } = formik;
  console.log(errors);
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      SERVICE REQUEST
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      VIEW REQUEST
    </Link>,
  ];

  //open dialough for assigned user
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setassignUserData([]);
    resetForm();
    setOpen(false);
  };
  const handleClickOpenStatus = () => {
    setStatus(true);
  };

  const handleCloseStatus = () => {
    setassignUserData([]);
    resetForm();
    setStatus(false);
  };

  //useEffect
  useEffect(() => {
    dispatch(listAssignedUser(""));
    dispatch(
      listRequest(
        "?from_date=" + DateRangeStartDate + "&toDate=" + DateRangeEndDate
      )
    );
    if (assignRequestApi.isSuccess) {
      dispatch(isSuccessAssignRequest());
      handleClose();
    }
    if (editStatus.isSuccess) {
      dispatch(isSuccesseditRequestStatusApi());
      handleCloseStatus();
    }
  }, [assignRequestApi, editStatus]);

  //submit data
  const submit = (val) => {
    if (!status) {
      console.log(val);
      const submit = {
        request_id: assignUserData._id,
        assigned_to: val.assigned_to.id,
      };
      // console.log(submit);
      dispatch(assigneRequest(submit));
    }
    if (status) {
      const submit = {
        request_id: assignUserData._id,
        status: val.status,
        remarks: val.remarks,
      };
      // console.log(submit);
      dispatch(changeRequestStatusApi(submit));
    }
  };

  //const getData
  const getData = (val) => {
    const ListRequest = requests.responce.data.filter((a) => {
      return val.data._id === a._id;
    });
    if (val.action === "edit") {
      dispatch(editRequestData(ListRequest[0]));
      navigate("/servicerequest/raiseRequest");
    }
    if (val.action === "assign") {
      setassignUserData(val.data);
      handleClickOpen();
    }
    if (val.action === "status") {
      setassignUserData(val.data);
      handleClickOpenStatus();
    }
    if (val.action === "view") {
      const filterData = requests.responce.data.filter(
        (e) => e._id === val.data._id
      );
      let values = {
        detailData: val.data,
        fullData: filterData[0],
      };
      console.log(filterData);
      dispatch(viewRequestDetailData(values));
      navigate("/servicerequest/detail_view_request");
    }
  };

  //table filter
  const tablefilter = [
    { value: "Complaint", label: "Complaint" },
    { value: "Feedback", label: "Feedback" },
    { value: "Machine Related", label: "Machine Related" },
  ];

  //assigned user options
  const AssignUseroption = assignedUser.responce
    ? assignedUser.responce.data.map((e) => {
        const option = { id: e._id, value: e.name, label: e.name };
        return option;
      })
    : [{ id: "0", value: "No user found", label: "No user found" }];

  //request status
  const request_status = [
    { value: "Open", label: "Open" },
    { value: "Service Engineer Assigned", label: "Service Engineer Assigned" },
    { value: "Issue fixed", label: "Issue fixed" },
    { value: "Closed", label: "Closed" },
  ];
  //actions
  // let Action=[]
  const ActionAdmin = {
    Action: [
      {
        icon: (
          <FontAwesomeIcon
            title="view"
            type="button"
            className="p-2"
            icon="fa-solid fa-eye"
          />
        ),
        name: "view",
      },

      {
        icon: (
          <FontAwesomeIcon
            title="status"
            type="button"
            className="p-2"
            icon="fa-solid fa-arrows-spin"
          />
        ),
        name: "status",
      },
      {
        icon: (
          <FontAwesomeIcon
            title="assing user"
            type="button"
            className="p-2"
            icon="fa-solid fa-user-astronaut"
          />
        ),
        name: "assign",
      },
    ],
  };
  const ActionTeam = {
    Action: [
      {
        icon: (
          <FontAwesomeIcon
            title="view"
            type="button"
            className="p-2"
            icon="fa-solid fa-eye"
          />
        ),
        name: "view",
      },

      {
        icon: (
          <FontAwesomeIcon
            title="status"
            type="button"
            className="p-2"
            icon="fa-solid fa-arrows-spin"
          />
        ),
        name: "status",
      },
    ],
  };
  const ActionCustomer = {
    Action: [
      {
        icon: (
          <FontAwesomeIcon
            title="view"
            type="button"
            className="p-2"
            icon="fa-solid fa-eye"
          />
        ),
        name: "view",
      },
      {
        icon: (
          <FontAwesomeIcon
            title="edit"
            type="button"
            className="p-2"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
    ],
  };
  let Action;
  if (role === "DISTRIBUTOR" || role === "CUSTOMER ADMIN") {
    Action = ActionCustomer;
  }
  if (role === "ADMIN" || role === "CUSTOMER SUPPORT MANAGER") {
    Action = ActionAdmin;
  }
  if (role === "CUSTOMER SUPPORT TEAM") {
    Action = ActionTeam;
  }
  //filters api
  const statustFilters = (e) => {
    setStatus_filter(e);
    if (e != "all") {
      console.log(e);
      dispatch(
        listRequest(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&status=" +
            e +
            (requestTypeFilter ? "&request_type=" + requestTypeFilter : "")
        )
      );
    } else {
      dispatch(
        listRequest(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (requestTypeFilter ? "&request_type=" + requestTypeFilter : "")
        )
      );
      setStatus_filter(null);
    }
  };
  const filtervalue = (e) => {
    setRequestTypeFilter(e);
    if (e != "all") {
      console.log(e);
      dispatch(
        listRequest(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&request_type=" +
            e +
            (status_filter ? "&status=" + status_filter : "")
        )
      );
    } else {
      setRequestTypeFilter(null);
      dispatch(
        listRequest(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (status_filter ? "&status=" + status_filter : "")
        )
      );
      setStatus_filter(null);
    }
  };
  //dateRange filter
  const dateRangeFilter = (start, end) => {
    dispatch(
      listRequest(
        "?from_date=" +
          start +
          "&toDate=" +
          end +
          (status_filter ? "&status=" + status_filter : "") +
          (requestTypeFilter ? "&request_type=" + requestTypeFilter : "")
      )
    );
  };
  const onEventService = (event, picker) => {
    // console.log(picker.startDate);
  };
  const callbackService = (start, end, lebal) => {
    setDateRangeStartDate(start._d);
    setDateRangeEndDate(end._d);
    dateRangeFilter(start._d, end._d);
  };
  //filter table data
  const filter = () => {
    if (requests.responce.data) {
      const filterarr = [];

      for (let i = 0; i < requests.responce.data.length; i++) {
        let data = { ...requests.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const requsteddate = new Date(data.requested_date);
        const assigned_datedate = new Date(data.assigned_date);
        const sheduleddate = new Date(data.shedule_date);
        const requsted_date =
          requsteddate.getDate() +
          "-" +
          monthNames[requsteddate.getMonth()] +
          "-" +
          requsteddate.getFullYear() +
          "," +
          requsteddate.getHours() +
          ":" +
          requsteddate.getMinutes();
        const assigned_date =
          assigned_datedate.getDate() +
          "-" +
          monthNames[assigned_datedate.getMonth()] +
          "-" +
          assigned_datedate.getFullYear();

        const newarr = {};
        // console.log(data.description.length);
        if (role === "DISTRIBUTOR" || role === "CUSTOMER ADMIN") {
          newarr._id = data._id;
          newarr.request_type = data.request_type;
          newarr.description =
            data.description.slice(0, 28) +
            (data.description.length >= 28 ? "..." : "");
          newarr.status = data.status;
          newarr.requested_date = requsted_date;
          newarr.assigned_to = data.assigned_to
            ? data.assigned_user
              ? data.assigned_user[0].name
              : "-"
            : "-";
          newarr.assigned_date = data.assigned_date ? assigned_date : "-";
        }
        if (role === "ADMIN" || role === "CUSTOMER SUPPORT MANAGER") {
          newarr._id = data._id;
          newarr.raised_by =
            data.raised_user.length != 0
              ? data.raised_user[0].name
              : data.machine.length != 0
              ? data.machine[0].device_id
              : "-";
          newarr.request_type = data.request_type;
          newarr.description =
            data.description.slice(0, 28) +
            (data.description.length >= 28 ? "..." : "");
          newarr.status = data.status;
          newarr.requested_date = requsted_date;
          newarr.assigned_to = data.assigned_to
            ? data.assigned_user
              ? data.assigned_user[0].name
              : "-"
            : "-";
          newarr.assigned_date = data.assigned_date ? assigned_date : "-";
        }
        if (role === "CUSTOMER SUPPORT TEAM") {
          newarr._id = data._id;
          newarr.raised_by =
            data.raised_user.length != 0
              ? data.raised_user[0].name
              : data.machine.length != 0
              ? data.machine[0].device_id
              : "-";
          newarr.request_type = data.request_type;
          newarr.description =
            data.description.slice(0, 28) +
            (data.description.length >= 28 ? "..." : "");
          newarr.status = data.status;
          newarr.requested_date = requsted_date;
          newarr.assigned_date = data.assigned_date ? assigned_date : "-";
        }
        filterarr.push(newarr);
      }

      const statusFilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={status_filter ? status_filter : ""}
                    className=""
                    options={request_status}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      statustFilters(a);
                      // console.log(value);
                    }}
                    sx={{ width: 250, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const requestTypes = () => {
        return (
          <>
            <div
              className="search-heder-table"
              style={{
                marginRight: "15px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={requestTypeFilter ? requestTypeFilter : ""}
                    className=""
                    options={tablefilter}
                    onChange={(e, value) => {
                      const a = value ? value : "all";

                      filtervalue(value);
                      // console.log(value);
                    }}
                    sx={{ width: 250, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Request Type"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const tablefilterDropDown = [statusFilters(), requestTypes()];

      return (
        <MainTable
          tablefilterDropDown={tablefilterDropDown}
          tabledata={filterarr}
          // filter={tablefilter}
          filtervalue={filtervalue}
          listOfMachine={"LIST SERVICE REQUEST"}
          getData={getData}
          Action={Action}
        />
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      const statusFilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={status_filter ? status_filter : ""}
                    className=""
                    options={request_status}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      statustFilters(a);
                      // console.log(value);
                    }}
                    sx={{ width: 250, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const requestTypes = () => {
        return (
          <>
            <div
              className="search-heder-table"
              style={{
                marginRight: "15px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={requestTypeFilter ? requestTypeFilter : ""}
                    className=""
                    options={tablefilter}
                    onChange={(e, value) => {
                      const a = value ? value : "all";

                      filtervalue(value);
                      // console.log(value);
                    }}
                    sx={{ width: 250, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Request Type"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const tablefilterDropDown = [statusFilters(), requestTypes()];
      return (
        <MainTable
          tablefilterDropDown={tablefilterDropDown}
          tabledata={filterarr}
          listOfMachine={"LIST SERVICE REQUEST"}
          Action={""}
        />
      );
    }
  };

  return (
    <div className="comp-change">
      {requests.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex">
        <Stack className="mx-4 pt-4 w-100">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
        </Stack>
        <div className="w-100 px-2 pt-1 d-flex justify-content-end dateRange align-items-center">
          <CalendarMonthIcon className="d-flex  align-items-center " />
          <p className="d-flex px-1 m-0 align-items-center" type="button">
            <DateRangePicker
              initialSettings={{
                startDate: oneMonthPreviosDate,
                endDate: new Date(),
                locale: {
                  format: "DD/MM/YYYY",
                },
              }}
              type="button"
              className="date"
              onEvent={onEventService}
              onCallback={callbackService}
            >
              <input type="button" className=" date " />
            </DateRangePicker>
          </p>
        </div>
      </div>
      <div className=" pt-2 position-sticky start-0 end-0 row w-100">
        <div className="w-100 h-100">
          <Grid spacing={2} container className="align-items-center   px-3">
            <Grid
              item
              className="align-items-center py-3 col-sm-6 col-md-6 col-lg-3 grid h-100"
            >
              <Paper className="border-radias-14">
                <div className=" font-size-24 fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                  OPEN
                </div>
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon
                    icon="fa-solid fa-book-open"
                    className=" border-right border-dark text-red py-3 px-2 "
                    style={{
                      fontSize: 42,
                      marginLeft: "20px",
                      paddingBottom: "10px",
                    }}
                  />
                  <div className="d-flex mx-2 flex-column w-100">
                    <div className="d-flex px-4 w-100 align-items-center">
                      <h1 className="fw-bolder cart-value text-red px-3 d-flex justify-content-center ">
                        {requests.responce
                          ? requests.responce.data.filter((e) => {
                              return e.status === "Open";
                            }).length
                          : 0}
                      </h1>
                    </div>
                    <p className=" mx-3 today-request company-text">
                      * Today Raised Request{" "}
                      {requests.responce
                        ? requests.responce.data.filter((e) => {
                            const date =
                              new Date(e.requested_date).getDate() ===
                              new Date().getDate();
                            const month =
                              new Date(e.requested_date).getMonth() ===
                              new Date().getMonth();
                            const year =
                              new Date(e.requested_date).getFullYear() ===
                              new Date().getFullYear();
                            if (date && month && year) {
                              return e.status === "Open";
                            }
                          }).length
                        : 0}
                    </p>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              className="align-items-center py-3 col-sm-6 col-md-6 col-lg-3 grid h-100"
            >
              <Paper className="border-radias-14">
                <div className=" font-size-24 fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                  ASSIGNED
                </div>
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon
                    icon="fa-solid fa-book-atlas"
                    className=" border-right  border-dark py-3 px-2 textblue"
                    style={{
                      fontSize: 42,
                      marginLeft: "20px",
                      paddingBottom: "10px",
                    }}
                  />
                  <div className="d-flex mx-2 flex-column w-100">
                    <div className="d-flex px-4 w-100 align-items-center">
                      <h1 className="fw-bolder textblue cart-value px-3 d-flex justify-content-center ">
                        {requests.responce
                          ? requests.responce.data.filter((e) => {
                              return e.status === "Service Engineer Assigned";
                            }).length
                          : 0}
                      </h1>
                    </div>
                    <p className=" mx-3 today-request company-text">
                      * Today Assigned Request{" "}
                      {requests.responce
                        ? requests.responce.data.filter((e) => {
                            const date =
                              new Date(e.updated_at).getDate() ===
                              new Date().getDate();
                            const month =
                              new Date(e.updated_at).getMonth() ===
                              new Date().getMonth();
                            const year =
                              new Date(e.updated_at).getFullYear() ===
                              new Date().getFullYear();
                            if (date && month && year) {
                              return e.status === "Service Engineer Assigned";
                            }
                          }).length
                        : 0}
                    </p>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              className="align-items-center py-3 col-sm-6 col-md-6 col-lg-3 grid h-100"
            >
              <Paper className="border-radias-14">
                <div className=" font-size-24 fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                  ISSUE FIXED
                </div>
                <div className="d-flex align-items-start">
                  <NoteAltIcon
                    // icon="fa-solid fa-blanket"
                    className=" border-right textorange border-dark py-3 px-2 "
                    style={{
                      fontSize: "80px",
                      marginLeft: "20px",
                      paddingBottom: "10px",
                    }}
                  />
                  <div className="d-flex mx-2 flex-column w-100">
                    <div className="d-flex px-4 w-100 align-items-center">
                      <h1 className="fw-bolder cart-value textorange px-3 d-flex justify-content-center ">
                        {requests.responce
                          ? requests.responce.data.filter((e) => {
                              return e.status === "Issue fixed";
                            }).length
                          : 0}
                      </h1>
                    </div>
                    <p className=" mx-3 today-request company-text">
                      * Today Issue fixed{" "}
                      {requests.responce
                        ? requests.responce.data.filter((e) => {
                            const date =
                              new Date(e.updated_at).getDate() ===
                              new Date().getDate();
                            const month =
                              new Date(e.updated_at).getMonth() ===
                              new Date().getMonth();
                            const year =
                              new Date(e.updated_at).getFullYear() ===
                              new Date().getFullYear();
                            if (date && month && year) {
                              return e.status === "Issue fixed";
                            }
                          }).length
                        : 0}
                    </p>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              className="align-items-center py-3 col-sm-6 col-md-6 col-lg-3 grid h-100"
            >
              <Paper className="border-radias-14">
                <div className=" font-size-24 fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                  CLOSED
                </div>
                <div className="d-flex align-items-start">
                  <FontAwesomeIcon
                    icon="fa-solid fa-book-bookmark"
                    className=" border-right textgreen border-dark py-3 px-2 "
                    style={{
                      fontSize: 42,
                      marginLeft: "20px",
                      paddingBottom: "10px",
                    }}
                  />
                  <div className="d-flex mx-2 flex-column pb-2 w-100">
                    <div className="d-flex px-4 w-100 align-items-center">
                      <h1 className="fw-bolder cart-value textgreen px-3 d-flex justify-content-center ">
                        {requests.responce
                          ? requests.responce.data.filter((e) => {
                              return e.status === "Closed";
                            }).length
                          : 0}
                      </h1>
                    </div>
                    <p className=" mx-3 today-request company-text">
                      * Today Closed Request{" "}
                      {requests.responce
                        ? requests.responce.data.filter((e) => {
                            const date =
                              new Date(e.updated_at).getDate() ===
                              new Date().getDate();
                            const month =
                              new Date(e.updated_at).getMonth() ===
                              new Date().getMonth();
                            const year =
                              new Date(e.updated_at).getFullYear() ===
                              new Date().getFullYear();
                            if (date && month && year) {
                              return e.status === "Closed";
                            }
                          }).length
                        : 0}
                    </p>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
      {requests.isLoading ? null : filter()}
      <div className="d-flex w-100 justify-content-center">
        {/* assign user diolough */}
        <Dialog
          open={open}
          // maxWidth="xs"
          onClose={handleClose}
        >
          <div>
            <DialogTitle>{"Assigned User"}</DialogTitle>
            <DialogContent>
              <div className=" ">
                <Autocomplete
                  options={AssignUseroption}
                  value={values.assigned_to}
                  className=""
                  renderInput={(params) => (
                    <TextField
                      error={
                        errors.assigned_to && touched.assigned_to ? true : false
                      }
                      style={{
                        width: "100%",
                      }}
                      helperText={
                        errors.assigned_to && touched.assigned_to
                          ? errors.assigned_to.label
                          : null
                      }
                      {...params}
                      name="assigned_to"
                      label="Assigned To"
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) => {
                    const a = value ? value.value : "";
                    console.log(value);
                    setFieldValue("assigned_to", value);
                  }}
                  onBlur={handleBlur}
                />
              </div>
            </DialogContent>
            <DialogActions className="p-3">
              <Button onClick={handleClose} variant="outlined" color="error">
                Close
              </Button>

              <LoadingButton
                variant="outlined"
                // loading={
                //   editUserApi.isLoading || addUserApi.isLoading ? true : false
                // }
                onClick={handleSubmit}
              >
                save
                {/* {edit ? "Submit" : "Save"} */}
              </LoadingButton>
            </DialogActions>
          </div>
        </Dialog>
      </div>
      <div className="d-flex w-100 justify-content-center">
        {/* change status */}
        <Dialog
          open={status}
          // maxWidth="xs"
          onClose={handleClose}
        >
          <div>
            <DialogTitle>{"Change status"}</DialogTitle>
            <DialogContent>
              <div className=" ">
                <Autocomplete
                  options={request_status}
                  value={values.status}
                  className=""
                  renderInput={(params) => (
                    <TextField
                      error={errors.status && touched.status ? true : false}
                      style={{
                        width: "100%",
                      }}
                      helperText={
                        errors.status && touched.status ? errors.status : null
                      }
                      {...params}
                      name="status"
                      label="Status"
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) => {
                    const a = value ? value.value : "";
                    console.log(value);
                    setFieldValue("status", a);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className="pt-3">
                <TextInput
                  // defaultvalue={is_edit.edit ? is_edit.data.description : ""}
                  style={{ width: "100%" }}
                  handleChange={handleChange}
                  placeholder="remarks"
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="remarks"
                />
              </div>
            </DialogContent>
            <DialogActions className="p-3">
              <Button
                onClick={handleCloseStatus}
                variant="outlined"
                color="error"
              >
                Close
              </Button>

              <LoadingButton
                variant="outlined"
                // loading={
                //   editUserApi.isLoading || addUserApi.isLoading ? true : false
                // }
                onClick={handleSubmit}
              >
                save
                {/* {edit ? "Submit" : "Save"} */}
              </LoadingButton>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default ViewRequest;
