import React from "react";
import "../../assets/css/form.scss";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
const TextInput = (props) => {
  const textInput = React.useRef(null);
  const {
    id,
    errors,
    style,
    name = "",
    values,
    touched,
    readonly,
    handleChange,
    underline,
    disabled,
    defaultvalue,
    handleBlur,
    placeholder,
    mapval,
    label = "",
  } = props;

  // const styles = (theme) => ({
  //   fontFamily: "Arial",
  // });

  let path = window.location.pathname;
  if (path != "/user_profile") {
    if (values[name] != undefined) {
      if (values[name].length === 0) {
        if (textInput.current) {
          textInput.current.value = "";
        }
      }
    }
  }
  if (mapval) {
    if (mapval.length === 1) {
      if (textInput.current) {
        textInput.current.value = "";
      }
    }
    // console.log(mapval.length);
  }

  return (
    <div className="d-flex flex-column ">
      {/* <label className="inputName">{placeholder}</label> */}

      <TextField
        error={errors[name] && touched[name] ? true : false}
        id={id}
        className="bg-white grey formTextInput"
        defaultValue={defaultvalue}
        disabled={disabled ? true : false}
        style={style}
        name={name}
        inputRef={textInput}
        InputProps={{
          style: { color: "black" },
        }}
        InputProps={{
          disableUnderline: readonly ? (underline ? false : true) : false,
        }}
        inputProps={{ readOnly: readonly ? true : false }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        type="text"
        values={values[name]}
        onChange={handleChange}
        label={placeholder}
        variant="standard"
        onBlur={handleBlur}
        helperText={errors[name] && touched[name] ? errors[name] : null}
      />

      <p className="text-danger">
        {/* {errors[name] && touched[name] ? errors[name] : null} */}
      </p>
    </div>
  );
};

export default TextInput;
