import React from "react";

function viewSpices(props) {
  return (
    <div className="comp-change">
      <h1>view Spices</h1>
    </div>
  );
}

export default viewSpices;
