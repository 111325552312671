import GroupsIcon from "@mui/icons-material/Groups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";

// card page data for dashboard

export const card = [
  {
    carsName: "dashboard.totalCompany",
    startIcon: "fa-solid fa-users",
    bg_color: "lightblue",
    NumberOfMachine: "120",
    content: "dashboard.newCompanyAdded",
  },
  {
    carsName: "dashboard.totalTea",
    startIcon: "fa-solid fa-mug-hot",
    bg_color: "blue",
    NumberOfMachine: "120",
    content: "dashboard.teaSalesIncresed",
  },
  {
    carsName: "dashboard.TotalTeacCost",
    startIcon: "fa-solid fa-sack-dollar",
    bg_color: "orange",
    NumberOfMachine: "5,000",
    content: "dashboard.totalCostIncresed",
  },
  {
    carsName: "dashboard.RevenueForDurection",
    startIcon: "fa-solid fa-wallet",
    bg_color: "yellow",
    NumberOfMachine: "53,700",
    content: "dashboard.revenueIncreder",
  },
];

//issuse for dashbord compnent data

export const Issues = [
  {
    icon: <ElectricalServicesIcon className="textblue" sx={{ fontSize: 47 }} />,
    issue: "dashboard.issuePowerFailure",
    value: "121",
  },
  {
    icon: (
      <FontAwesomeIcon
        icon="fa-solid fa-gears"
        className="textblue "
        style={{ fontSize: "47px" }}
      />
    ),
    issue: "dashboard.issueMotorFailure",
    value: "11",
  },
  // {
  //   icon: (
  //     <img
  //       src={require("../../assets/image/tap.png")}
  //       className="pointer-ico"
  //     />
  //   ),
  //   issue: "dashboard.issueProgram",
  //   value: "12",
  // },
  {
    icon: (
      <FontAwesomeIcon
        icon="fa-solid fa-triangle-exclamation"
        className="textblue"
        style={{ fontSize: "47px" }}
      />
    ),
    issue: "dashboard.otherIssue",
    value: "121",
  },
];

//table data for dashboard
export const tabledata = [
  {
    id: "9873479348",
    issue: "dashboard.tableData.issue",
    date: "12 Feb 22",
    name: "Antony maria",
  },
  {
    id: "9873479348",
    issue: "dashboard.tableData.issue",
    date: "12 Feb 22",
    name: "Anbu",
  },
  {
    id: "9873479348",
    issue: "dashboard.tableData.issue",
    date: "12 Feb 22",
    name: "Antony ",
  },
  {
    id: "9873479348",
    issue: "dashboard.tableData.issue",
    date: "12 Feb 22",
    name: "Antony ",
  },
];
