import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const LeadsDetailView = createSlice({
  name: "LeadsDetailView",
  initialState: {
    edit: false,
    data: {},
  },
  reducers: {
    edit: (state, payload) => {
      state.edit = payload.payload;
    },
    editLeadsDetail: (state, payload) => {
      state.edit = payload.payload;
    },
    leadsData: (state, payload) => {
      //   console.log(payload.payload.action);
      state.data = payload.payload;
    },
  },
});

export const { edit, editLeadsDetail, leadsData } = LeadsDetailView.actions;
// export const selectUser = (state) => state.user.login;
export default LeadsDetailView.reducer;
