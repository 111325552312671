import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const editLeads = createSlice({
  name: "editLeads",
  initialState: {
    edit: false,
    data: [],
  },
  reducers: {
    Leadsdata: (state, payload) => {
      state.data = payload.payload;
    },
    clearLeadsData: (state, payload) => {
      state.data = "";
    },
    isEditLeads: (state, payload) => {
      state.edit = payload.payload;
    },
  },
});

export const { isEditLeads, clearLeadsData, Leadsdata } = editLeads.actions;
// export const selectCompany = (state) => statetCompany.login;
export default editLeads.reducer;
