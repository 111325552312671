import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { Paper, Link } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropdownSelecter from "../forms/dropdownSelecter";
import { Autocomplete, TextField } from "@mui/material";
import TextInput from "../forms/textInput";
import EmailInput from "../forms/emailInput";
import { useSelector, useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import {
  addLeads,
  editLeadsapi,
} from "../../redux/reducer/crm/leadsApiService";
import { isSuccessRequest } from "../../redux/reducer/serviceRequest/addRequest";
import { clearLeadsData, isEditLeads } from "../../redux/reducer/crm/editLeads";
import { listMachine } from "../../redux/reducer/machineMaster/addMachineApiService";

import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckboxComp from "../forms/checkbox";
import {
  addRequest,
  editRequestApi,
  listAssignedUser,
} from "../../redux/reducer/serviceRequest/servise_requestApiService";
import { cleareditRequestData } from "../../redux/reducer/serviceRequest/editRequest";
import { isSuccesseditRequest } from "../../redux/reducer/serviceRequest/editRequestApi";
function RaiseRequest(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assignedUser = useSelector((state) => state.AssignedUsers);
  const machineList = useSelector((state) => state.ListMachine);
  const addRequestApi = useSelector((state) => state.addRequest);
  const isEditRequest = useSelector((state) => state.editRequest);
  const editApi = useSelector((state) => state.editRequestApi);
  // const [edit, setEdit] = useState(false);
  // console.log(addleadsApi);
  //form validationos
  const validationSchema = Yup.object().shape({
    request_type: Yup.string().required("Request Type is required"),
    description: Yup.string().required("description is id required"),
    // assigned_to: Yup.string().required("Assigned to is required"),
  });
  const formik = useFormik({
    initialValues: {
      request_type: isEditRequest.edit ? isEditRequest.data.request_type : "",
      description: isEditRequest.edit ? isEditRequest.data.description : "",
      // assigned_to: editLeads.edit ? editLeads.data[0].mobile_no : "",
      machine_id: {
        _id: isEditRequest.edit ? isEditRequest.data.machine_id : "",
        label: isEditRequest.edit
          ? isEditRequest.data.machine.length != 0
            ? isEditRequest.data.machine[0].device_id
            : ""
          : "",
        value: isEditRequest.edit
          ? isEditRequest.data.machine != 0
            ? isEditRequest.data.machine[0].device_id
            : ""
          : "",
      },

      stay_here: false,
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
    onReset: (data) => {
      console.log(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleReset,
    setTouched,
  } = formik;
  console.log(errors);
  //submit data
  const submit = (val) => {
    console.log(val);
    if (!isEditRequest.edit) {
      const submit = {
        request_type: val.request_type,
        description: val.description,
        machine_id: val.machine_id._id,
        // assigned_to: assigneduser[0]._id,
      };
      dispatch(addRequest(submit));
      console.log(submit);
    }
    if (isEditRequest.edit) {
      const submit = {
        _id: isEditRequest.data._id,
        request_type: val.request_type,
        description: val.description,
        machine_id: val.machine_id._id,
        // assigned_to: assigneduser[0]._id,
      };
      dispatch(editRequestApi(submit));
      console.log(submit);
    }
  };
  //useEffect
  useEffect(() => {
    dispatch(listAssignedUser(""));
    dispatch(listMachine(""));

    if (addRequestApi.isSuccess) {
      resetForm();
      dispatch(cleareditRequestData());
      if (!values.stay_here) {
        navigate("/servicerequest/viewRequest");
      }
    }
    if (editApi.isSuccess) {
      resetForm();
      dispatch(cleareditRequestData());

      dispatch(isSuccesseditRequest());

      navigate("/servicerequest/viewRequest");
    }
  }, [addRequestApi, editApi]);

  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      SERVICE REQUEST
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      {isEditRequest.edit ? "EDIT REQUEST" : "RAISE REQUEST"}
    </Link>,
  ];
  const request_type = [
    { value: "Complaint", label: "Complaint" },
    { value: "Feedback", label: "Feedback" },
    { value: "Machine Related", label: "Machine Related" },
  ];
  // const Status = assignedUser.responce
  //   ? assignedUser.responce.data.map((e) => {
  //       const option = { id: e._id, value: e.name, label: e.name };
  //       return option;
  //     })
  //   : [{ value: "No user found", label: "No user found" }];
  const machineOption = machineList.responce
    ? machineList.responce.data.map((e) => {
        const value = {
          _id: e._id,
          label: e.device_id,
          value: e.device_id,
        };
        return value;
      })
    : [
        {
          _id: "",
          label: "No Machine Found",
          value: "",
        },
      ];
  console.log(machineOption);
  return (
    <div className="comp-change">
      <Stack className="mx-4 pt-4 ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form p-4 " style={{ marginBottom: "100px" }}>
        <Paper className="bg-white machineForm w-40">
          <div className="row pt-1 px-4">
            <div className=" ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Reques Type"
                onBlur={() => {
                  handleBlur({ target: { name: "request_type" } });
                }}
                setFieldValue={setFieldValue}
                options={request_type}
                errors={errors}
                values={values}
                touched={touched}
                vari={() => {
                  console.log();
                }}
                name="request_type"
              />
            </div>
          </div>
          <div className="row pt-1 px-4">
            <div className="pt-3 ">
              <TextInput
                defaultvalue={
                  isEditRequest.edit ? isEditRequest.data.description : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Description"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="description"
              />
            </div>
          </div>

          {values.request_type === "Machine Related" ? (
            <div className="row pt-1 px-4">
              <div className=" ">
                <Autocomplete
                  options={machineOption}
                  value={values.machine_id}
                  className=""
                  renderInput={(params) => (
                    <TextField
                      error={
                        errors.machine_id && touched.machine_id ? true : false
                      }
                      style={{
                        width: "90%",
                      }}
                      helperText={
                        errors.machine_id && touched.machine_id
                          ? errors.machine_id
                          : null
                      }
                      {...params}
                      name="machine_id"
                      label="Machine Id"
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) => {
                    const a = value ? value.value : "";
                    console.log(value);
                    setFieldValue("machine_id", value);
                  }}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          ) : null}
          <div className="row pt-1 px-4">
            <div className=" ">
              <CheckboxComp
                style={{ width: "90%" }}
                label={"Stay here"}
                defaultChecked={false}
                // handleChange={(e)=>{
                //   handleChange({target:{name:"is_ads_allowed",}})
                // }}
                // onBlur={() => {
                //   handleBlur({ target: { name: "is_ads_allowed" } });
                // }}

                setFieldValue={setFieldValue}
                name="stay_here"
              />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end pt-4 px-2 ">
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  resetForm();
                  dispatch(cleareditRequestData());
                  isEditRequest.edit
                    ? navigate("/servicerequest/viewRequest")
                    : resetForm();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="outlined"
                // loading={
                //   editUserApi.isLoading || addUserApi.isLoading ? true : false
                // }
                onClick={handleSubmit}
              >
                save
                {/* {edit ? "Submit" : "Save"} */}
              </LoadingButton>
            </Stack>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default RaiseRequest;
