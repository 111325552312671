import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { readoneNotification } from "./notificationServiceApi";

import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const SUCCESE = async (data) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const ReadoneNotification = createSlice({
  name: "ReadoneNotification",
  initialState: {
    isLoading: false,
    responce: "",
    isSuccess: false,
    Message: "",
  },
  reducers: {
    isSuccessreadoneNotification: (state, payload) => {
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [readoneNotification.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [readoneNotification.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.responce = payload.data;
      state.Message = payload.data.message;
      state.isSuccess = true;
      //   SUCCESE(payload.data.message);
    },

    [readoneNotification.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.Message = payload;
      FAILED(payload);
      if (payload.message.name === "TokenExpiredError") {
        localStorage.clear();
        window.location.pathname = "/login";
        // Navigateto();
      }
    },
  },
});

export const { isSuccessreadoneNotification, errorMessage } =
  ReadoneNotification.actions;
export default ReadoneNotification.reducer;
