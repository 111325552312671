import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { selectUser } from "../LoginReducer";
const API_URL = "https://dtv29cy9i3.execute-api.ap-south-1.amazonaws.com/dev";
// const API_URL = "http://localhost:8080";
let token = localStorage.getItem("Token");
const entity_id = 1;
// console.log(token);

export const addRequest = createAsyncThunk(
  "AddRequest/addRequest",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/service/rise_request", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//listAssignedUser
export const listAssignedUser = createAsyncThunk(
  "ListAssignedUser/listAssignedUser",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/user/list_csm_cst" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list service Request
export const listRequest = createAsyncThunk(
  "ListRequest/listRequest",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(
        API_URL + "/service/list_rise_request" + value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//edit Service Request
export const editRequestApi = createAsyncThunk(
  "EditRequestApi/editRequestApi",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(
        API_URL + "/service/update_rise_request",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//edit leads
export const editLeadsStatus = createAsyncThunk(
  "EditLeadsStatus/editLeadsStatus",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(API_URL + "/leads/update_status", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//assign Request
export const assigneRequest = createAsyncThunk(
  "AssigneRequest/assigneRequest",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        API_URL + "/service/add_assign_request",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//update Status
export const changeRequestStatusApi = createAsyncThunk(
  "ChangeRequestStatusApi/changeRequestStatusApi",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(
        API_URL + "/service/request_change_status",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list service Request history
export const listRequestHistory = createAsyncThunk(
  "ListRequestHistory/listRequestHistory",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/service/list_history" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//list machine request

export const listMachineRequest = createAsyncThunk(
  "ListMachineRequest/listMachineRequest",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(
        API_URL + "/service/list_matched_machine_request" + value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
