import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { Paper, Link, CircularProgress } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "../components/forms/textInput";
import { changePassword } from "../redux/reducer/UserMaster/userMasterApiService";
import { isSuccess1 } from "../redux/reducer/UserMaster/changePassword";
const validationSchema = Yup.object().shape({
  old_password: Yup.string()
    .required("old Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  new_password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),

  Conform_password: Yup.string()
    .required("Conform Password is Required")
    .oneOf([Yup.ref("new_password")], "password must be match"),
});
const ChangePassword = (props) => {
  const [edtuser, setEditUser] = useState(false);
  const changePasswordApi = useSelector((state) => state.changePassword);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      Conform_password: "",
    },
    validationSchema,

    onSubmit: (data) => {
      submit(data);
    },
  });
  const submit = (val) => {
    dispatch(changePassword(val));
  };
  useEffect(() => {
    if (changePasswordApi.isSuccess) {
      resetForm();
      setEditUser(false);
      dispatch(isSuccess1());
    }
  }, [changePasswordApi]);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;
  return (
    <>
      <div className="col-6 p-4">
        <Paper className="bg-white machineForm">
          <div className="row  px-1 d-flex mx-1 ">
            <div className="font-weight-bold w-75 ">CHANGE PASSWORD</div>
            <div
              className="font-weight-bold d-flex justify-content-end w-25 "
              onClick={() => {
                setEditUser(!edtuser);
                console.log(edtuser);
              }}
            >
              Edit
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  className="px-2 pb-1"
                  icon="fa-solid fa-user-pen"
                />
              </div>
            </div>
          </div>
          <div className="row pt-3 px-4">
            <div className="col-12 ">
              <TextInput
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Old Password"
                readonly={!edtuser}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="old_password"
              />
            </div>
            <div className="col-12 ">
              <TextInput
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="New Password"
                readonly={!edtuser}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="new_password"
              />
            </div>
            <div className="col-12 ">
              <TextInput
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Conform Password"
                readonly={!edtuser}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="Conform_password"
              />
            </div>
          </div>
          {edtuser ? (
            <div className="w-100 d-flex justify-content-end p-4 px-5 ">
              <Stack spacing={2} direction="row">
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    setEditUser(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="outlined"
                  loadingPosition="center"
                  //   loading={editUserApi.isLoading ? true : false}
                  onClick={() => {
                    //   setIsCompany(false);
                    handleSubmit();
                  }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </div>
          ) : (
            ""
          )}
        </Paper>
      </div>
    </>
  );
};
export default ChangePassword;
