import React from "react";

function addSpices(props) {
  return (
    <div className="comp-change">
      <h1>ADD Spices</h1>
    </div>
  );
}

export default addSpices;
