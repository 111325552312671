import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { selectUser } from "../LoginReducer";
const API_URL = "https://dtv29cy9i3.execute-api.ap-south-1.amazonaws.com/dev";
// const API_URL = "http://localhost:8080";
let token = localStorage.getItem("Token");
const entity_id = 1;
console.log(token);

export const addDrink = createAsyncThunk(
  "AddDrink/addDrink",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/dirnks/create_drink", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//listDrink
export const listDrink = createAsyncThunk(
  "ListDrink/listDrink",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/dirnks/list_drink" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//edit Drink
export const editDrink = createAsyncThunk(
  "EditDrink/editDrink",
  async (value, { rejectWithValue }) => {
    try {
      console.log(value);
      const data = await axios.put(API_URL + "/dirnks/update_drink", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//get catogory details

export const listCategory = createAsyncThunk(
  "DrinkCategory/listCategory",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(
        API_URL + "/dirnks/list_drink_default?" + value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete drinks

export const deleteCategory = createAsyncThunk(
  "DeleteDrink/deleteCategory",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.delete(API_URL + "/dirnks/delete/" + value._id, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//create drink xref of maped drinks

export const createMapedDrinks = createAsyncThunk(
  "MapedDrink/createMapedDrinks",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        API_URL + "/dirnks/create_drink_xref",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get maped drinks
export const listMapedDrinks = createAsyncThunk(
  "ListMapedDrink/listMapedDrinks",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(
        API_URL + "/dirnks/list_drink_xref" + value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//updated maped drinks
export const updatedMapedDrinks = createAsyncThunk(
  "UpdateMapedDrink/updatedMapedDrinks",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(
        API_URL + "/dirnks/update_drink_xref",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
