import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const editProduct = createSlice({
  name: "editProduct",
  initialState: {
    edit: false,
    data: [],
  },
  reducers: {
    Productdata: (state, payload) => {
      state.data = payload.payload;
      state.edit = true;
    },
    clearProductData: (state, payload) => {
      state.data = "";
      state.edit = false;
    },
    iseditProduct: (state, payload) => {
      state.edit = payload.payload;
    },
  },
});

export const { iseditProduct, clearProductData, Productdata } =
  editProduct.actions;
// export const selectCompany = (state) => statetCompany.login;
export default editProduct.reducer;
