import React from "react";
import "../../assets/css/form.scss";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "&&:hover::before": {
      borderColor: "red",
    },
  },
});

const EmailInput = (props) => {
  const classes = useStyles();
  const textInput = React.useRef(null);
  const {
    id,
    errors,
    style,
    name = "",
    values,
    touched,
    handleChange,
    defaultvalue,
    handleBlur,
    readonly,
    placeholder,
    label = "",
  } = props;
  console.log(defaultvalue);
  let path = window.location.pathname;
  if (path != "/user_profile") {
    if (values[name] != undefined) {
      if (values[name].length === 0) {
        if (textInput.current) {
          textInput.current.value = "";
        }
      }
    }
  }
  return (
    <div className="d-flex flex-column">
      {/* <label className="inputName">{placeholder}</label> */}

      <TextField
        error={errors[name] && touched[name] ? true : false}
        id={id}
        className="bg-white grey formTextInput "
        defaultValue={defaultvalue}
        inputRef={textInput}
        InputProps={{
          style: { color: "black" },
        }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        InputProps={{ disableUnderline: readonly ? true : false }}
        inputProps={{ readOnly: readonly ? true : false }}
        style={style}
        name={name}
        type="email"
        values={values.name}
        onChange={handleChange}
        label={placeholder}
        variant="standard"
        onBlur={handleBlur}
        helperText={errors[name] && touched[name] ? errors[name] : null}
      />

      <p className="text-danger">
        {/* {errors[name] && touched[name] ? errors[name] : null} */}
      </p>
    </div>
  );
};

export default EmailInput;
