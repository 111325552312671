import React, { useEffect, useState } from "react";
import "../assets/css/mainheder.css";
import { Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { IconButton, Avatar, Badge } from "@mui/material";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import TabPanel from "@mui/lab/TabPanel";
import Stack from "@mui/material/Stack";
import { deepOrange, deepPurple, green } from "@mui/material/colors";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAllNotification,
  deleteoneNotification,
  listNotification,
  readAllNotification,
  readoneNotification,
} from "../redux/reducer/notification/notificationServiceApi";
import { isSuccessreadoneNotification } from "../redux/reducer/notification/readone";
import { isSuccessreadAllNotification } from "../redux/reducer/notification/readAllNotification";
import { isSuccessdeleteoneNotification } from "../redux/reducer/notification/deleteone";
import { isSuccessdeleteAllNotification } from "../redux/reducer/notification/deleteall";
function MainHeder(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notificationData = useSelector((state) => state.listNotification);
  const readeone = useSelector((state) => state.readoneNotification);
  const readeall = useSelector((state) => state.readallNotification);
  const deleteone = useSelector((state) => state.deleteoneNotification);
  const deleteall = useSelector((state) => state.deleteallNotification);

  const [openNotification, setOpenNotification] = useState(false);
  i18next.hasLoadedNamespace("mainHeder", (err, t) => {
    console.log("err", err);
    console.log("t", t);
  });
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //useEffect
  useEffect(() => {
    dispatch(listNotification(""));
    if (readeone.isSuccess) {
      dispatch(isSuccessreadoneNotification());
      setOpenNotification(true);
    }
    if (readeall.isSuccess) {
      dispatch(isSuccessreadAllNotification());
      setOpenNotification(true);
    }
    if (deleteone.isSuccess) {
      dispatch(isSuccessdeleteoneNotification());
      setOpenNotification(true);
    }
    if (deleteall.isSuccess) {
      dispatch(isSuccessdeleteAllNotification());
      setOpenNotification(false);
      dispatch(listNotification(""));
    }
  }, [readeone, readeall, deleteone, deleteall]);

  const logout = () => {
    window.location.pathname = "/login";
    // navigate("/login");
    localStorage.clear();
  };
  // i18next.init({
  //   ns: ["mainHeder"],
  // });

  //read notifications
  const singlereadNotification = (e) => {
    console.log(e);
    dispatch(readoneNotification("?_id=" + e._id));
  };
  const singledeleteNotification = (e) => {
    console.log(e);
    dispatch(deleteoneNotification("?_id=" + e._id));
  };
  const allReadNotification = (val) => {
    dispatch(readAllNotification("?readable=" + val));
  };
  const allDeleteNotification = (val) => {
    dispatch(deleteAllNotification("?readable=" + val));
  };

  // all request notification
  // console.log(notificationData);
  const allRequest = () => {
    return (
      <>
        <div className="d-flex align-items-center  px-3 pb-2 ">
          <span className="w-100 font-14 ">Notifications</span>
          <div className="d-flex">
            <Tooltip title="Read all">
              <FontAwesomeIcon
                onClick={() => {
                  allReadNotification("all");
                }}
                className="d-flex text-success w-100 px-3"
                icon="fa-solid fa-check-double"
              />
            </Tooltip>
            <Tooltip title="Delete all">
              <FontAwesomeIcon
                onClick={() => {
                  allDeleteNotification("all");
                }}
                className="w-100 d-flex text-danger justify-content-end"
                icon="fa-solid fa-trash-can"
              />
            </Tooltip>
          </div>
        </div>

        {notificationData.responce ? (
          notificationData.responce.data ? (
            notificationData.responce.data.map((e, key) => {
              return (
                <>
                  <div
                    key={key}
                    className={
                      e.readable
                        ? "py-2 px-3 border-top readablenotification"
                        : "py-2 px-3 border-top"
                    }
                  >
                    <div className="d-flex ">
                      <Avatar clas sx={{ bgcolor: green[500] }}>
                        {e.name.charAt(0).toUpperCase()}
                      </Avatar>
                      <div className="d-flex px-3 flex-column w-100">
                        <span className="font-13">{e.name}</span>
                        <span className="font-11 px-1">{e.Message}</span>
                      </div>
                      <div className="d-flex w-15  align-items-center">
                        <Tooltip title="Read">
                          <FontAwesomeIcon
                            onClick={() => {
                              singlereadNotification(e);
                            }}
                            className="d-flex text-success w-100 px-3"
                            icon="fa-solid fa-check"
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <FontAwesomeIcon
                            onClick={() => {
                              singledeleteNotification(e);
                            }}
                            className="w-100 text-danger d-flex text-danger justify-content-end"
                            icon="fa-solid fa-trash-can"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="py-2 px-3 border-top">
              <div className="d-flex ">
                <Avatar clas sx={{ bgcolor: green[500] }}>
                  S
                </Avatar>
                <div className="d-flex px-3 flex-column w-100">
                  <span className="font-13">No Notification</span>
                  <span className="font-11 px-1"></span>
                </div>
                <div className="d-flex w-15 align-items-center">
                  <Tooltip title="Read">
                    <FontAwesomeIcon
                      className="d-flex text-success w-100 px-3"
                      icon="fa-solid fa-check"
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <FontAwesomeIcon
                      className="w-100 text-danger d-flex justify-content-end"
                      icon="fa-solid fa-trash-can"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="py-2 px-3 border-top">
            <div className="d-flex ">
              <Avatar clas sx={{ bgcolor: green[500] }}>
                S
              </Avatar>
              <div className="d-flex px-3 flex-column w-100">
                <span className="font-13">No Notification</span>
                <span className="font-11 px-1"></span>
              </div>
              <div className="d-flex w-15 align-items-center">
                <Tooltip title="Read">
                  <FontAwesomeIcon
                    className="d-flex text-success w-100 px-3"
                    icon="fa-solid fa-check"
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <FontAwesomeIcon
                    className="w-100 text-danger d-flex justify-content-end"
                    icon="fa-solid fa-trash-can"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  // leads notification
  const leads = () => {
    return (
      <>
        <div className="d-flex  align-items-center px-3 pb-2 ">
          <span className="w-100 font-14 ">Notifications</span>
          <div className="d-flex">
            <Tooltip title="Read all">
              <FontAwesomeIcon
                onClick={() => {
                  allReadNotification("leads");
                }}
                className="d-flex w-100 px-3"
                icon="fa-solid fa-check-double"
              />
            </Tooltip>
            <Tooltip title="Delete all">
              <FontAwesomeIcon
                onClick={() => {
                  allDeleteNotification("leads");
                }}
                className="w-100 text-danger d-flex justify-content-end"
                icon="fa-solid fa-trash-can"
              />
            </Tooltip>
          </div>
        </div>
        {notificationData.responce ? (
          notificationData.responce.data
            .filter((e) => {
              return e.Type === "Leads";
            })
            .map((e, key) => {
              return (
                <>
                  <div
                    key={key}
                    className={
                      e.readable
                        ? "py-2 px-3 border-top readablenotification"
                        : "py-2 px-3 border-top"
                    }
                  >
                    <div className="d-flex ">
                      <Avatar clas sx={{ bgcolor: green[500] }}>
                        {e.name.charAt(0).toUpperCase()}
                      </Avatar>
                      <div className="d-flex px-3 flex-column w-100">
                        <span className="font-13">{e.name}</span>
                        <span className="font-11 px-1">{e.Message}</span>
                      </div>
                      <div className="d-flex w-15 align-items-center">
                        <Tooltip title="Read">
                          <FontAwesomeIcon
                            onClick={() => {
                              singlereadNotification(e);
                            }}
                            className="d-flex text-success w-100 px-3"
                            icon="fa-solid fa-check"
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <FontAwesomeIcon
                            onClick={() => {
                              singledeleteNotification(e);
                            }}
                            className="w-100 text-danger d-flex justify-content-end"
                            icon="fa-solid fa-trash-can"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
        ) : (
          <div className="py-2 px-3 border-top">
            <div className="d-flex ">
              <Avatar clas sx={{ bgcolor: green[500] }}>
                S
              </Avatar>
              <div className="d-flex px-3 flex-column w-100">
                <span className="font-13">No Notification</span>
                <span className="font-11 px-1"></span>
              </div>
              <div className="d-flex w-15 align-items-center">
                <Tooltip title="Read">
                  <FontAwesomeIcon
                    className="d-flex text-success w-100 px-3"
                    icon="fa-solid fa-check"
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <FontAwesomeIcon
                    className="w-100 text-danger d-flex justify-content-end"
                    icon="fa-solid fa-trash-can"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  // service Request notification
  const SeriveRequest = () => {
    return (
      <>
        <div className="d-flex px-3  align-items-center pb-2 ">
          <span className="w-100 font-14 ">Notifications</span>
          <div className="d-flex">
            <Tooltip title="Read all">
              <FontAwesomeIcon
                onClick={() => {
                  allReadNotification("Service");
                }}
                className="d-flex text-success w-100 px-3"
                icon="fa-solid fa-check-double"
              />
            </Tooltip>
            <Tooltip title="Delete all">
              <FontAwesomeIcon
                onClick={() => {
                  allDeleteNotification("Service");
                }}
                className="w-100 text-danger d-flex justify-content-end"
                icon="fa-solid fa-trash-can"
              />
            </Tooltip>
          </div>
        </div>
        {notificationData.responce ? (
          notificationData.responce.data
            .filter((e) => {
              return e.Type === "Service Request";
            })
            .map((e, key) => {
              return (
                <>
                  <div
                    key={key}
                    className={
                      e.readable
                        ? "py-2 px-3 border-top readablenotification"
                        : "py-2 px-3 border-top"
                    }
                  >
                    <div className="d-flex ">
                      <Avatar clas sx={{ bgcolor: green[500] }}>
                        {e.name.charAt(0).toUpperCase()}
                      </Avatar>
                      <div className="d-flex px-3 flex-column w-100">
                        <span className="font-13">{e.name}</span>
                        <span className="font-11 px-1">{e.Message}</span>
                      </div>
                      <div className="d-flex w-15 align-items-center">
                        <Tooltip title="Read">
                          <FontAwesomeIcon
                            onClick={() => {
                              singlereadNotification(e);
                            }}
                            className="d-flex text-success w-100 px-3"
                            icon="fa-solid fa-check"
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <FontAwesomeIcon
                            onClick={() => {
                              singledeleteNotification(e);
                            }}
                            className="w-100 text-danger d-flex justify-content-end"
                            icon="fa-solid fa-trash-can"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
        ) : (
          <div className="py-2 px-3 border-top">
            <div className="d-flex ">
              <Avatar clas sx={{ bgcolor: green[500] }}>
                S
              </Avatar>
              <div className="d-flex px-3 flex-column w-100">
                <span className="font-13">No Notification</span>
                <span className="font-11 px-1"></span>
              </div>
              <div className="d-flex w-15 align-items-center">
                <Tooltip title="Read">
                  <FontAwesomeIcon
                    className="d-flex text-success w-100 px-3"
                    icon="fa-solid fa-check"
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <FontAwesomeIcon
                    className="w-100 text-danger d-flex justify-content-end"
                    icon="fa-solid fa-trash-can"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  //machine related
  const MachineRequest = () => {
    return (
      <>
        <div className="d-flex px-3  align-items-center pb-2 ">
          <span className="w-100 font-14 ">Notifications</span>
          <div className="d-flex">
            <Tooltip title="Read all">
              <FontAwesomeIcon
                onClick={() => {
                  allReadNotification("machine");
                }}
                className="d-flex text-success w-100 px-3"
                icon="fa-solid fa-check-double"
              />
            </Tooltip>
            <Tooltip title="Delete all">
              <FontAwesomeIcon
                onClick={() => {
                  allDeleteNotification("machine");
                }}
                className="w-100 text-danger d-flex justify-content-end"
                icon="fa-solid fa-trash-can"
              />
            </Tooltip>
          </div>
        </div>
        {notificationData.responce ? (
          notificationData.responce.data
            .filter((e) => {
              return e.Type === "Machine Related";
            })
            .map((e, key) => {
              return (
                <>
                  <div
                    key={key}
                    className={
                      e.readable
                        ? "py-2 px-3 border-top readablenotification"
                        : "py-2 px-3 border-top"
                    }
                  >
                    <div className="d-flex ">
                      <Avatar clas sx={{ bgcolor: green[500] }}>
                        {e.name.charAt(0).toUpperCase()}
                      </Avatar>
                      <div className="d-flex px-3 flex-column w-100">
                        <span className="font-13">{e.name}</span>
                        <span className="font-11 px-1">{e.Message}</span>
                      </div>
                      <div className="d-flex w-15 align-items-center">
                        <Tooltip title="Read">
                          <FontAwesomeIcon
                            onClick={() => {
                              singlereadNotification(e);
                            }}
                            className="d-flex text-success w-100 px-3"
                            icon="fa-solid fa-check"
                          />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <FontAwesomeIcon
                            onClick={() => {
                              singledeleteNotification(e);
                            }}
                            className="w-100 text-danger d-flex justify-content-end"
                            icon="fa-solid fa-trash-can"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
        ) : (
          <div className="py-2 px-3 border-top">
            <div className="d-flex ">
              <Avatar clas sx={{ bgcolor: green[500] }}>
                S
              </Avatar>
              <div className="d-flex px-3 flex-column w-100">
                <span className="font-13">No Notification</span>
                <span className="font-11 px-1"></span>
              </div>
              <div className="d-flex w-15 align-items-center">
                <Tooltip title="Read">
                  <FontAwesomeIcon
                    className="d-flex text-success w-100 px-3"
                    icon="fa-solid fa-check"
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <FontAwesomeIcon
                    className="w-100 text-danger d-flex justify-content-end"
                    icon="fa-solid fa-trash-can"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  //notifications
  const notification = () => {
    return (
      <>
        <Paper elevation={5} className="notification">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="All" value="1" />
                  <Tab label="Leads" value="2" />
                  <Tab label="Service Request" value="3" />
                  <Tab label="Machine Related" value="4" />
                </TabList>
              </Box>
              <TabPanel
                className="p-0 py-3 notification-scroll my-1 "
                value="1"
              >
                {allRequest()}
              </TabPanel>
              <TabPanel
                className="p-0 py-3 notification-scroll my-1 "
                value="2"
              >
                {leads()}
              </TabPanel>
              <TabPanel
                className="p-0 py-3 notification-scroll my-1 "
                value="3"
              >
                {SeriveRequest()}
              </TabPanel>
              <TabPanel
                className="p-0 py-3 notification-scroll my-1 "
                value="4"
              >
                {MachineRequest()}
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </>
    );
  };
  const viewProdile = () => {
    return (
      <>
        <Paper elevation={5} className="profile-menu">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <div className="w-100 d-flex border-bottom justify-content-center p-2">
                view profile
              </div>
              <div className="w-100 d-flex justify-content-center p-2">
                Log out
              </div>
            </TabContext>
          </Box>
        </Paper>
      </>
    );
  };
  const { t } = useTranslation();
  return (
    <>
      <Paper
        className=" mainheder d-flex align-items-center"
        variant="outlined"
        square
      >
        <div className="search-hed ">
          {/* <div className="p-1  ligh w-100 rounded rounded-pill ">
            <div className="input-group">
              <input
                type="search"
                placeholder={t("mainHeader.search")}
                // aria-describedby="button-addon1"
                className="form-control  border-0 rounded rounded-pill "
              />
              <div className="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  className="btn btn-link  search-icon"
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>*/}
        </div>
        <div className="d-flex w-50 justify-content-end px-3 align-items-center">
          <IconButton
            className="shadow-none"
            onClick={() => {
              setOpenNotification(!openNotification);
            }}
            sx={{ width: 30, height: 48 }}
          >
            <Badge
              color="error"
              overlap="circular"
              badgeContent={
                notificationData.responce
                  ? notificationData.responce.data.filter((e) => {
                      return e.readable != true;
                    }).length
                  : 0
              }
              fontSize="small"
            >
              <CircleNotificationsIcon
                sx={{ fontSize: 50, color: "#8f8f8f" }}
              />
            </Badge>
          </IconButton>
          <div className="btn-group px-2">
            <div
              className="  d-flex align-items-center dropdown-toggle px-4"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Avatar sx={{ fontSize: 25, backgroundColor: "#8f8f8f" }}>
                {localStorage.getItem("name").charAt(0)}
              </Avatar>
              <div
                className="d-flex px-3 pt-2 mb-2 flex-column align-items-center justify-content-center"
                Type="button"
              >
                <p className="p-0 m-0 w-100 grey">{t("mainHeader.Welcome")}</p>
                <h6 className="p-0 m-0 w-100 d-flex justify-content-center ">
                  {localStorage.getItem("name")}
                </h6>
              </div>
            </div>

            <ul className="dropdown-menu heder-logout">
              <Paper elevation={5} className="profile-menu ">
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <div
                      className="w-100  d-flex border-bottom justify-content-center p-2 dropdown-item"
                      onClick={() => {
                        navigate("/user_profile");
                      }}
                      type="button"
                    >
                      view profile
                    </div>
                    <div
                      type="button"
                      onClick={() => {
                        logout();
                      }}
                      className="w-100 d-flex justify-content-center p-2 dropdown-item"
                    >
                      Log out
                    </div>
                  </TabContext>
                </Box>
              </Paper>
              {/* </li> */}
            </ul>
          </div>
        </div>

        {/* profile dropdown */}

        {/* langavage translation */}
        {/* <div className="btn-group px-2">
          <a
            className=" text-info dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <LanguageIcon />
          </a>
          <ul className="dropdown-menu">
            <li
              onClick={() => {
                i18next.changeLanguage("en");
              }}
            >
              <a className="dropdown-item" href="#">
                English
              </a>
            </li>
            <li
              onClick={() => {
                i18next.changeLanguage("tn");
              }}
            >
              <a className="dropdown-item" href="#">
                தமிழ்
              </a>
            </li>
          </ul>
        </div> */}
      </Paper>
      {openNotification ? notification() : ""}
      {/* {viewProdile()} */}
    </>
  );
}

export default MainHeder;
