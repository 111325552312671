import React from "react";
import { Autocomplete, TextField } from "@mui/material";
const skill = ["sdfds", "sadfds", "asdgsdf"];

const DropdownSelecter = (props) => {
  const {
    style,
    onBlur,
    options,
    touched,
    onchange,
    setFieldValue,
    disable,
    placeholder,
    readOnly,
    handchange,
    errors,
    values,
    vari,
    name,
  } = props;
  // console.log(values[name]);
  const error = errors;
  return (
    <div style={style}>
      <Autocomplete
        value={values[name]}
        readOnly={readOnly ? true : false}
        options={options}
        underlineShow={false}
        // InputProps={{ disableUnderline: readOnly ? true : false }}
        // defaultValue={"hello"}
        renderInput={(params) => (
          <TextField
            error={errors[name] && touched[name] ? true : false}
            value={values.name}
            name={name}
            {...params}
            label={placeholder}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              disableUnderline: readOnly ? true : false,
            }}
            helperText={errors[name] && touched[name] ? errors[name] : null}
          />
        )}
        onChange={(e, value) => {
          const a = value ? value.value : "";
          vari(a);

          setFieldValue(name, a);
        }}
        onBlur={onBlur}
      />
    </div>
  );
};

export default DropdownSelecter;
