import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { Paper, Link } from "@material-ui/core";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { getMachineUsage } from "../../redux/reducer/machine_usage/machineUsageApiService";
import BarChart from "../../insideComponents/dashbord/charts/barchart";
import MainTable from "../../insideComponents/mainTable";
import { useNavigate } from "react-router";
import { clearUsagePageData } from "../../redux/reducer/machine_usage/machine_usage_page_data";
import { clearMachineUsage } from "../../redux/reducer/machine_usage/viewMachineUsage";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
function ViewRawMeterial(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const company_details = useSelector((state) => state.MachineUsageDetails);
  console.log(company_details);
  const machineUsage = useSelector((state) => state.MachineUsage);
  let oneMonthPreviosDate = new Date();
  oneMonthPreviosDate.setMonth(oneMonthPreviosDate.getMonth() - 1);

  const [DateRangeStartDate, setDateRangeStartDate] =
    useState(oneMonthPreviosDate);
  const [DateRangeEndDate, setDateRangeEndDate] = useState(new Date());
  const [activeChanrtData, setActiveChartData] = useState("month");
  const [activechemicalChanrtData, setAchemicalctiveChartData] =
    useState("month");

  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      USER MASTER
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        dispatch(clearMachineUsage());
        dispatch(clearUsagePageData());
        navigate("/usermaster/viewcompany");
      }}
    >
      VIEW COMPANY
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        dispatch(clearUsagePageData());
        dispatch(clearMachineUsage());
        navigate("/usermaster/viewcompany/company_mapped_machine");
      }}
    >
      {company_details.pagePurpose.purpose == "companyView"
        ? company_details.pagePurpose.company_name
        : company_details.data[0].comapny.company_name}
    </Link>,

    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      Raw Meterial Usage
    </Link>,
  ];

  //useEffect to api calls
  useEffect(() => {
    if (company_details.pagePurpose.purpose == "companyView") {
      dispatch(
        getMachineUsage(
          "/company?company_id=" +
            company_details.pagePurpose._id +
            "&from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate
        )
      );
    } else {
      dispatch(
        getMachineUsage(
          "?machine_id=" +
            company_details.data[0]._id +
            "&from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate
        )
      );
    }
  }, []);
  const dateRangeFilter = (start, end) => {
    if (company_details.pagePurpose.purpose == "companyView") {
      dispatch(
        getMachineUsage(
          "/company?company_id=" +
            company_details.pagePurpose._id +
            "&from_date=" +
            start +
            "&toDate=" +
            end
        )
      );
    } else {
      dispatch(
        getMachineUsage(
          "?machine_id=" +
            company_details.data[0]._id +
            "&from_date=" +
            start +
            "&toDate=" +
            end
        )
      );
    }
  };
  //get date  range filter
  const onEventService = (event, picker) => {
    // console.log(picker.startDate);
  };
  const callbackService = (start, end, lebal) => {
    setDateRangeStartDate(start._d);
    setDateRangeEndDate(end._d);
    dateRangeFilter(start._d, end._d);
  };
  //get data from action
  const getData = (val) => {};

  //Actions
  const Action = {};

  //filter

  //machineusage chart data mik flavour tea
  //get months
  const months = [];

  var monthName = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  var d = new Date();
  d.setDate(1);
  for (let i = 0; i <= 8; i++) {
    months.push(monthName[d.getMonth()].substring(0, 3));
    d.setMonth(d.getMonth() - 1);
  }
  months.reverse();

  const teaPowderChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const sugarChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const spiceChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const waterChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const milkChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const baseChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const acidChartData = [0, 0, 0, 0, 0, 0, 0, 0];

  //chart heders
  const rawMeterialChartvalue = [
    "Tea Powder",
    "Sugar",
    "Spice",
    "Water",
    "Milk",
  ];
  const chmicalChartvalue = ["Base", "Acid"];

  //filter table data
  const filterMachine = (data) => {
    if (machineUsage.responce) {
      const filterarr = [];
      for (let i = 0; i < machineUsage.responce.data.length; i++) {
        let data = { ...machineUsage.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const date = new Date(data.usage_date);
        delete data.usage_date;
        const usage_date =
          date.getDate() +
          "-" +
          monthNames[date.getMonth()] +
          "-" +
          date.getFullYear();

        data.company_name = data.machine_xref.companyDetails[0].company_name;
        data.machine_name = data.machine_xref.machine_details[0].name;
        data.machine_model = data.machine_xref.machine_details[0].model;
        const milk = data.raw_material_usage.filter((a) => {
          if ("Milk" === a.name) {
            const value = a.name;
            // console.log(value);
            return value;
          }
        });
        data.milk = milk[0].value;
        const Water = data.raw_material_usage.filter((a) => {
          if ("Water" === a.name) {
            const value = a.name;
            // console.log(value);
            return value;
          }
        });
        data.Water = Water[0].value;
        const tea_powder = data.raw_material_usage.filter((a) => {
          if ("Tea Powder" === a.name) {
            console.log(a.name);
            const value = a.name;
            // console.log(value);
            return value;
          }
        });
        data.tea_powder = tea_powder[0].value;

        const sugar = data.raw_material_usage.filter((a) => {
          if ("Sugar" === a.name) {
            const value = a.name;
            // console.log(value);
            return value;
          }
        });
        data.sugar = sugar[0].value;
        console.log(data);
        const spice = data.raw_material_usage.filter((a) => {
          if ("Spice" === a.name) {
            const value = a.name;
            // console.log(value);
            return value;
          }
        });
        data.spice = spice[0].value;
        const Base = data.chemical_usage.filter((a) => {
          console.log(a);
          if ("Base" === a.name) {
            const value = a.name;
            return value;
          }
        });
        data.base = Base[0].value;
        const acid = data.chemical_usage.filter((a) => {
          if ("Acid" === a.name) {
            const value = a.name;
            return value;
          }
        });
        data.acid = acid[0].value;
        const Brewing_cyle_count = data.other_details.filter((a) => {
          if ("Brewing Cycle Count" === a.name) {
            const value = a.name;
            return value;
          }
        });
        data.Brewing_cyle_count = Brewing_cyle_count[0].value;
        const power_failure_time = data.other_details.filter((a) => {
          // console.log("hello", data);
          if ("Power Failure time" === a.name) {
            const value = a.name;
            return value;
          }
        });
        // console.log("hello vijay", power_failure_time);
        // data.power_failure_time = power_failure_time[0].value;
        var currentOffset = date.getTimezoneOffset();

        var ISTOffset = 330;

        var ISTTime = new Date(
          date.getTime() + (ISTOffset + currentOffset) * 60000
        );
        data.usage_date =
          usage_date + "," + ISTTime.getHours() + ":" + ISTTime.getMinutes();
        // console.log(tea);

        // data.is_ads_allowed = "jsd";
        filterarr.push(data);
        // console.log(data);
      }
      filterarr.filter((a) => delete a.chemical_usage);
      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.cup_count);
      filterarr.filter((a) => delete a.machine_id);
      filterarr.filter((a) => delete a.machine_xref);
      filterarr.filter((a) => delete a.other_details);
      filterarr.filter((a) => delete a.raw_material_usage);
      // filterarr.filter((a) => delete a.usage_date);
      filterarr.filter((a) => delete a.current_values);
      // filterarr.filter((a) => delete a.delivery_address_2);
      // filterarr.filter((a) => delete a.delivery_city);
      // filterarr.filter((a) => delete a.delivery_pincode);
      // filterarr.filter((a) => delete a.delivery_state);
      console.log(filterarr);
      return (
        <>
          <MainTable
            tabledata={filterarr}
            // filter={filter1}
            listOfMachine={"RAW METERIAL USAGE "}
            getData={getData}
            Action={Action}
          />
        </>
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tabledata={filterarr}
          // tablefilterDropDown={tablefilterDropDown}
          listOfMachine={" RAW METERIAL USAGE"}
          Action={""}
        />
      );
    }
  };
  const filtercompany = (data) => {
    if (machineUsage.responce) {
      const filterarr = [];
      for (let i = 0; i < machineUsage.responce.data.length; i++) {
        let data = { ...machineUsage.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const date = new Date(data.usage_date);
        // delete data.usage_date;
        // const usage_date =
        //   date.getDate() +
        //   "-" +
        //   monthNames[date.getMonth()] +
        //   "-" +
        //   date.getFullYear();

        data.company_name = company_details.pagePurpose.company_name;
        data.machine_name = data.machines[0].name;
        data.machine_model = data.machines[0].model;
        let milk_count_machine = 0;
        let Water_tea_count_machine = 0;
        let tea_powder_count_machine = 0;
        let sugar_count_machine = 0;
        let spice_count_machine = 0;
        let base_count_machine = 0;
        let acid_counr_machine = 0;
        let brewing_cycle_machine = 0;
        // let power_failure__machine

        console.log(data);
        for (let i = 0; i < data.maped_machine_usage.length; i++) {
          for (
            let j = 0;
            j < data.maped_machine_usage[i].raw_material_usage.length;
            j++
          ) {
            if (
              "Milk" === data.maped_machine_usage[i].raw_material_usage[j].name
            ) {
              milk_count_machine =
                milk_count_machine +
                data.maped_machine_usage[i].raw_material_usage[j].value;
            }
            if (
              " Water" ===
              data.maped_machine_usage[i].raw_material_usage[j].name
            ) {
              Water_tea_count_machine =
                Water_tea_count_machine +
                data.maped_machine_usage[i].raw_material_usage[j].value;
            }
            if (
              "Tea Powder" ===
              data.maped_machine_usage[i].raw_material_usage[j].name
            ) {
              tea_powder_count_machine =
                tea_powder_count_machine +
                data.maped_machine_usage[i].raw_material_usage[j].value;
            }
            if (
              "Sugar" === data.maped_machine_usage[i].raw_material_usage[j].name
            ) {
              sugar_count_machine =
                sugar_count_machine +
                data.maped_machine_usage[i].raw_material_usage[j].value;
            }
            if (
              "Spice" === data.maped_machine_usage[i].raw_material_usage[j].name
            ) {
              spice_count_machine =
                spice_count_machine +
                data.maped_machine_usage[i].raw_material_usage[j].value;
            }
          }
          for (
            let k = 0;
            k < data.maped_machine_usage[i].chemical_usage.length;
            k++
          ) {
            if ("Base" === data.maped_machine_usage[i].chemical_usage[k].name) {
              base_count_machine =
                base_count_machine +
                data.maped_machine_usage[i].chemical_usage[k].value;
            }
            if ("Acid" === data.maped_machine_usage[i].chemical_usage[k].name) {
              acid_counr_machine =
                acid_counr_machine +
                data.maped_machine_usage[i].chemical_usage[k].value;
            }
          }
          for (
            let l = 0;
            l < data.maped_machine_usage[i].other_details.length;
            l++
          ) {
            if (
              "Brewing Cycle Count" ===
              data.maped_machine_usage[i].other_details[l].name
            ) {
              brewing_cycle_machine =
                brewing_cycle_machine +
                data.maped_machine_usage[i].other_details[l].value;
            }
          }
        }

        data.milk = milk_count_machine;

        data.Water = Water_tea_count_machine;

        data.tea_powder = tea_powder_count_machine;

        data.sugar = sugar_count_machine;

        data.spice = spice_count_machine;

        data.base = base_count_machine;

        data.acid = acid_counr_machine;

        data.Brewing_cyle_count = brewing_cycle_machine;
        // const power_failure_time = data.other_details.filter((a) => {
        //   if ("Power Failure time" === a.name) {
        //     const value = a.name;
        //     return value;
        //   }
        // });

        // console.log(tea);

        // data.is_ads_allowed = "jsd";
        filterarr.push(data);
        // console.log(data);
      }
      filterarr.filter((a) => delete a.current_status);
      filterarr.filter((a) => delete a.company_id);
      filterarr.filter((a) => delete a.is_active);
      filterarr.filter((a) => delete a.machine_id);
      filterarr.filter((a) => delete a.machines);
      filterarr.filter((a) => delete a.maped_machine_usage);
      filterarr.filter((a) => delete a.status);
      filterarr.filter((a) => delete a.entity_id);
      // filterarr.filter((a) => delete a.delivery_address_2);
      // filterarr.filter((a) => delete a.delivery_city);
      // filterarr.filter((a) => delete a.delivery_pincode);
      // filterarr.filter((a) => delete a.delivery_state);
      console.log(filterarr);
      return (
        <>
          <MainTable
            tabledata={filterarr}
            // filter={filter1}
            listOfMachine={" CUP COUNT USAGE "}
            getData={getData}
            Action={Action}
          />
        </>
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tabledata={filterarr}
          // tablefilterDropDown={tablefilterDropDown}
          listOfMachine={" CUP COUNT USAGE"}
          Action={""}
        />
      );
    }
  };

  //flter chart datas bassed month and hourse
  let filteredData = [];
  let chemicalUsageData = [];
  if (company_details.pagePurpose.purpose != "companyView") {
    //chart data updating
    let monthlyFilters = !machineUsage.isLoading
      ? monthlyFiltersdata(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";
    let dailyFilters = !machineUsage.isLoading
      ? dailyFilter(machineUsage.responce, DateRangeStartDate, DateRangeEndDate)
      : "";
    let hourlyFilters = !machineUsage.isLoading
      ? hourlyFilter(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";
    console.log(monthlyFilters);
    if (activeChanrtData === "hour") {
      filteredData = hourlyFilters;
    }
    if (activeChanrtData === "daily") {
      filteredData = dailyFilters;
    }
    if (activeChanrtData === "month") {
      filteredData = monthlyFilters;
    }
    if (activechemicalChanrtData === "hour") {
      chemicalUsageData = hourlyFilters;
    }
    if (activechemicalChanrtData === "daily") {
      chemicalUsageData = dailyFilters;
    }
    if (activechemicalChanrtData === "month") {
      chemicalUsageData = monthlyFilters;
    }
  } else {
    let CompanymonthlyFilters = !machineUsage.isLoading
      ? CompanymonthlyFiltersdata(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";
    let CompanydailyFilters = !machineUsage.isLoading
      ? CompanydailyFilter(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";
    let CompanyhourlyFilters = !machineUsage.isLoading
      ? CompanyhourlyFilter(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";

    if (activeChanrtData === "hour") {
      filteredData = CompanyhourlyFilters;
    }
    if (activeChanrtData === "daily") {
      filteredData = CompanydailyFilters;
    }
    if (activeChanrtData === "month") {
      filteredData = CompanymonthlyFilters;
    }
    if (activechemicalChanrtData === "hour") {
      chemicalUsageData = CompanyhourlyFilters;
    }
    if (activechemicalChanrtData === "daily") {
      chemicalUsageData = CompanydailyFilters;
    }
    if (activechemicalChanrtData === "month") {
      chemicalUsageData = CompanymonthlyFilters;
    }
  }
  console.log(filteredData);

  return (
    <div className="comp-change">
      {machineUsage.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}{" "}
      <div className="d-flex">
        <Stack className="mx-4 pt-4 w-100 ">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
        </Stack>{" "}
        <div className="w-100 px-2 pt-1 d-flex justify-content-end dateRange align-items-center">
          <CalendarMonthIcon className="d-flex  align-items-center " />
          <p className="d-flex px-1 m-0 align-items-center">
            <DateRangePicker
              initialSettings={{
                startDate: oneMonthPreviosDate,
                endDate: new Date(),
                locale: {
                  format: "DD/MM/YYYY",
                },
              }}
              className="date"
              onEvent={onEventService}
              onCallback={callbackService}
            >
              <input type="text" className=" date " />
            </DateRangePicker>
          </p>
        </div>
      </div>
      <div className="p-4  d-flex" style={{ height: "390px" }}>
        <Paper
          style={{
            borderRadius: "15px",
            marginRight: "10px",
          }}
          className="h-100 w-50 p-4"
        >
          <div className="d-flex w-100">
            <div className="w-100 text-uppercase font-weight-bold">
              Raw Meterial Usage
            </div>
            <div className="d-flex w-100 justify-content-end">
              <div
                onClick={() => {
                  setActiveChartData("month");
                }}
                type="button"
                className={
                  activeChanrtData === "month"
                    ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                    : "mx-2 text-uppercase text-smaller"
                }
              >
                Monthly
              </div>
              <div
                onClick={() => {
                  setActiveChartData("daily");
                }}
                type="button"
                className={
                  activeChanrtData === "daily"
                    ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                    : "mx-2 text-uppercase text-smaller"
                }
              >
                Daily
              </div>
              <div
                onClick={() => {
                  setActiveChartData("hour");
                }}
                type="button"
                className={
                  activeChanrtData === "hour"
                    ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                    : "mx-2 text-uppercase text-smaller"
                }
              >
                Hourly
              </div>
            </div>
          </div>
          <BarChart
            // chartData={[
            //   teaPowderChartData,
            //   sugarChartData,
            //   spiceChartData,
            //   waterChartData,
            //   milkChartData,
            // ]}
            chartData={
              filteredData === undefined
                ? [
                    teaPowderChartData,
                    sugarChartData,
                    spiceChartData,
                    waterChartData,
                    milkChartData,
                  ]
                : [
                    filteredData.teaPowderUsage,
                    filteredData.sugarUsage,
                    filteredData.spiceUsage,
                    filteredData.waterUsage,
                    filteredData.milkUsage,
                  ]
            }
            displaymonth={filteredData === undefined ? "" : filteredData.month}
            chartvalue={rawMeterialChartvalue}
            color={["#fbd125", "#4fa6ee", "#00cec2"]}
          />
        </Paper>
        <Paper
          style={{
            borderRadius: "15px",
            marginLeft: "10px",
          }}
          className="h-100 w-50 p-4"
        >
          <div className="d-flex w-100">
            <div className="w-100 text-uppercase font-weight-bold">
              Chemical Usage
            </div>
            <div className="d-flex w-100 justify-content-end">
              <div
                onClick={() => {
                  setAchemicalctiveChartData("month");
                }}
                type="button"
                className={
                  activechemicalChanrtData === "month"
                    ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                    : "mx-2 text-uppercase text-smaller"
                }
              >
                Monthly
              </div>
              <div
                onClick={() => {
                  setAchemicalctiveChartData("daily");
                }}
                type="button"
                className={
                  activechemicalChanrtData === "daily"
                    ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                    : "mx-2 text-uppercase text-smaller"
                }
              >
                Daily
              </div>
              <div
                onClick={() => {
                  setAchemicalctiveChartData("hour");
                }}
                type="button"
                className={
                  activechemicalChanrtData === "hour"
                    ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                    : "mx-2 text-uppercase text-smaller"
                }
              >
                Hourly
              </div>
            </div>
          </div>
          <BarChart
            // chartData={[baseChartData, acidChartData]}
            chartData={
              chemicalUsageData === undefined
                ? [baseChartData, acidChartData]
                : [chemicalUsageData.baseUsage, chemicalUsageData.acidUsage]
            }
            displaymonth={
              chemicalUsageData === undefined ? "" : chemicalUsageData.month
            }
            color={["#fbd125", "#4fa6ee"]}
            chartvalue={chmicalChartvalue}
          />
        </Paper>
      </div>
      {company_details.pagePurpose.purpose == "companyView"
        ? filtercompany()
        : filterMachine()}
    </div>
  );
}

const monthlyFiltersdata = (usage, startDate, endDate) => {
  if (usage) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // get month diffrance
    function diff(from, to) {
      var arr = [];
      var datFrom = new Date("1 " + from);
      var datTo = new Date("1 " + to);

      var fromYear = datFrom.getFullYear();
      var toYear = datTo.getFullYear();
      var diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

      for (var i = datFrom.getMonth(); i <= diffYear; i++) {
        arr.push(monthNames[i % 12] + " " + Math.floor(fromYear + i / 12));
      }

      return arr;
    }

    let start = `${
      monthNames[startDate.getMonth()]
    } ${startDate.getFullYear()}`;
    let end = `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}`;

    const all_month = diff(start, end);

    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let SugarArray = [];
    let teaPowderArray = [];
    let milkArray = [];
    let spiceArray = [];
    let waterArray = [];
    let BaseArray = [];
    let AcidArray = [];
    usage.data.forEach((e, key) => {
      all_month.forEach((month, monthkey) => {
        // console.log(monthkey);
        if (
          `${new Date(e.usage_date).getMonth()}${new Date(
            e.usage_date
          ).getFullYear()}` ===
          `${new Date("1 " + all_month[monthkey]).getMonth()}${new Date(
            "1 " + all_month[monthkey]
          ).getFullYear()}`
        ) {
          e.raw_material_usage.forEach((raw_meterial, raw_meterial_key) => {
            // console.log(raw_meterial);
            if (raw_meterial.name == "Sugar") {
              let indexofmonth = SugarArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                SugarArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + SugarArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                SugarArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (raw_meterial.name == "Tea Powder") {
              let indexofmonth = teaPowderArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                teaPowderArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value:
                    raw_meterial.value + teaPowderArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                teaPowderArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (raw_meterial.name == "Milk") {
              let indexofmonth = milkArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                milkArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + milkArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                milkArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (raw_meterial.name == "Water") {
              let indexofmonth = waterArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                waterArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + waterArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                waterArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (raw_meterial.name == "Spice") {
              let indexofmonth = spiceArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                spiceArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + spiceArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                spiceArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
          });
          e.chemical_usage.forEach((chemical_Usage, chemical_usage_key) => {
            if (chemical_Usage.name == "Base") {
              let indexofmonth = BaseArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                BaseArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + BaseArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                BaseArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (chemical_Usage.name == "Acid") {
              let indexofmonth = AcidArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                AcidArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + AcidArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                AcidArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: all_month[monthkey],
                });
              }
            }
          });
        }
      });
    });
    let sugarUsage = [];
    let teaPowderUsage = [];
    let milkUsage = [];
    let spiceUsage = [];
    let waterUsage = [];
    let baseUsage = [];
    let acidUsage = [];
    for (let i = 0; i < all_month.length; i++) {
      sugarUsage.push(0);
      teaPowderUsage.push(0);
      milkUsage.push(0);
      spiceUsage.push(0);
      waterUsage.push(0);
      acidUsage.push(0);
      baseUsage.push(0);
    }
    SugarArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      sugarUsage[index] = e.value;
    });
    teaPowderArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      teaPowderUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    spiceArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      spiceUsage[index] = e.value;
    });
    waterArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      waterUsage[index] = e.value;
    });
    BaseArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      baseUsage[index] = e.value;
    });
    AcidArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      acidUsage[index] = e.value;
    });
    const months = [];
    all_month.forEach((a) => {
      months.push(`${a.substring(0, 3)}-${a.slice(-2)}`);
    });

    let monthData = [];
    let acidMonth = [];
    let sugarData = [];
    let teaPowderData = [];
    let milkData = [];
    let spiceData = [];
    let waterData = [];
    let acidData = [];
    let basedata = [];
    months.forEach((e, key) => {
      if (
        sugarUsage[key] +
          milkUsage[key] +
          spiceUsage[key] +
          waterUsage[key] +
          teaPowderUsage[key] !=
        0
      ) {
        monthData.push(e);
        teaPowderData.push(teaPowderUsage[key]);
        sugarData.push(sugarUsage[key]);
        milkData.push(milkUsage[key]);
        spiceData.push(spiceUsage[key]);
        waterData.push(waterUsage[key]);
      }
      if (baseUsage[key] + acidUsage[key] != 0) {
        acidMonth.push(e);
        acidData.push(acidUsage[key]);
        basedata.push(baseUsage[key]);
      }
    });

    return {
      month: monthData,
      acidMonth: acidMonth,
      sugarUsage: sugarData,
      teaPowderUsage: teaPowderData,
      milkUsage: milkData,
      spiceUsage: spiceData,
      waterUsage: waterData,
      baseUsage: basedata,
      acidUsage: acidData,
    };
  }
};
const dailyFilter = (usage, startDate, endDate) => {
  if (usage) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    function getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    }

    // Usage
    const dates = getDates(startDate, endDate);
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let SugarArray = [];
    let teaPowderArray = [];
    let milkArray = [];
    let spiceArray = [];
    let waterArray = [];
    let BaseArray = [];
    let AcidArray = [];
    usage.data.forEach((e, key) => {
      dates.forEach((day, dayKey) => {
        const check =
          `${dates[dayKey].getDate()}${dates[dayKey].getMonth()}${dates[
            dayKey
          ].getFullYear()}` ===
          `${new Date(e.usage_date).getDate()}${new Date(
            e.usage_date
          ).getMonth()}${new Date(e.usage_date).getFullYear()}`;
        if (check) {
          e.raw_material_usage.forEach((raw_meterial, raw_meterial_key) => {
            if (raw_meterial.name == "Sugar") {
              let indexOfDay = SugarArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                SugarArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + SugarArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                SugarArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
            if (raw_meterial.name == "Tea Powder") {
              let indexOfDay = teaPowderArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                teaPowderArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + teaPowderArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                teaPowderArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
            if (raw_meterial.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + milkArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                milkArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
            if (raw_meterial.name == "Spice") {
              let indexOfDay = spiceArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                spiceArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + spiceArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                spiceArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
            if (raw_meterial.name == "Water") {
              let indexOfDay = waterArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                waterArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + waterArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                waterArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
          });
          e.chemical_usage.forEach((chemical_Usage, chemical_usage_key) => {
            if (chemical_Usage.name == "Base") {
              let indexofmonth = BaseArray.findIndex(
                (x) => x.month === dates[dayKey]
              );

              if (indexofmonth >= 0) {
                BaseArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + BaseArray[indexofmonth].value,
                  month: dates[dayKey],
                };
              } else {
                BaseArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: dates[dayKey],
                });
              }
            }
            if (chemical_Usage.name == "Acid") {
              let indexofmonth = AcidArray.findIndex(
                (x) => x.month === dates[dayKey]
              );

              if (indexofmonth >= 0) {
                AcidArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + AcidArray[indexofmonth].value,
                  month: dates[dayKey],
                };
              } else {
                AcidArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: dates[dayKey],
                });
              }
            }
          });
        }
      });
    });
    let sugarUsage = [];
    let teaPowderUsage = [];
    let milkUsage = [];
    let spiceUsage = [];
    let waterUsage = [];
    let baseUsage = [];
    let acidUsage = [];
    for (let i = 0; i < dates.length; i++) {
      sugarUsage.push(0);
      teaPowderUsage.push(0);
      milkUsage.push(0);
      spiceUsage.push(0);
      waterUsage.push(0);
      acidUsage.push(0);
      baseUsage.push(0);
    }
    SugarArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      sugarUsage[index] = e.value;
    });
    teaPowderArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      teaPowderUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    spiceArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      spiceUsage[index] = e.value;
    });
    waterArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      waterUsage[index] = e.value;
    });
    BaseArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      baseUsage[index] = e.value;
    });
    AcidArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      acidUsage[index] = e.value;
    });
    const months = [];
    dates.forEach((a) => {
      const da = `${a.getDate()}-${monthNames[a.getMonth()].substring(
        0,
        3
      )}-${a.getFullYear()}`;
      months.push(da);
    });

    let monthData = [];
    let acidMonth = [];
    let sugarData = [];
    let teaPowderData = [];
    let milkData = [];
    let spiceData = [];
    let waterData = [];
    let acidData = [];
    let basedata = [];
    months.forEach((e, key) => {
      if (
        sugarUsage[key] +
          milkUsage[key] +
          spiceUsage[key] +
          waterUsage[key] +
          teaPowderUsage[key] !=
        0
      ) {
        monthData.push(e);
        teaPowderData.push(teaPowderUsage[key]);
        sugarData.push(sugarUsage[key]);
        milkData.push(milkUsage[key]);
        spiceData.push(spiceUsage[key]);
        waterData.push(waterUsage[key]);
      }
      if (baseUsage[key] + acidUsage[key] != 0) {
        acidMonth.push(e);
        acidData.push(acidUsage[key]);
        basedata.push(baseUsage[key]);
      }
    });

    return {
      month: monthData,
      acidMonth: acidMonth,
      sugarUsage: sugarData,
      teaPowderUsage: teaPowderData,
      milkUsage: milkData,
      spiceUsage: spiceData,
      waterUsage: waterData,
      baseUsage: basedata,
      acidUsage: acidData,
    };
  }
};
const hourlyFilter = (usage, startDate, endDate) => {
  if (usage) {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let SugarArray = [];
    let teaPowderArray = [];
    let milkArray = [];
    let spiceArray = [];
    let waterArray = [];
    let BaseArray = [];
    let AcidArray = [];
    usage.data.forEach((e, key) => {
      const date = new Date(e.usage_date);
      var currentOffset = date.getTimezoneOffset();

      var ISTOffset = 330;

      var ISTTime = new Date(
        date.getTime() + (ISTOffset + currentOffset) * 60000
      );
      const hourse = ISTTime.getHours();

      hours.forEach((hour, hourkey) => {
        if (hourse === hours[hourkey]) {
          e.raw_material_usage.forEach((raw_meterial, raw_meterial_key) => {
            if (raw_meterial.name == "Sugar") {
              let indexOfDay = SugarArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                SugarArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + SugarArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                SugarArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
            if (raw_meterial.name == "Tea Powder") {
              let indexOfDay = teaPowderArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                teaPowderArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + teaPowderArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                teaPowderArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
            if (raw_meterial.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + milkArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                milkArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
            if (raw_meterial.name == "Spice") {
              let indexOfDay = spiceArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                spiceArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + spiceArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                spiceArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
            if (raw_meterial.name == "Water") {
              let indexOfDay = waterArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                waterArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + waterArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                waterArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
          });
          e.chemical_usage.forEach((chemical_Usage, chemical_usage_key) => {
            if (chemical_Usage.name == "Base") {
              let indexofmonth = BaseArray.findIndex(
                (x) => x.month === hours[hourkey]
              );

              if (indexofmonth >= 0) {
                BaseArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + BaseArray[indexofmonth].value,
                  month: hours[hourkey],
                };
              } else {
                BaseArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: hours[hourkey],
                });
              }
            }
            if (chemical_Usage.name == "Acid") {
              let indexofmonth = AcidArray.findIndex(
                (x) => x.month === hours[hourkey]
              );

              if (indexofmonth >= 0) {
                AcidArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + AcidArray[indexofmonth].value,
                  month: hours[hourkey],
                };
              } else {
                AcidArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: hours[hourkey],
                });
              }
            }
          });
        }
      });
    });
    let sugarUsage = [];
    let teaPowderUsage = [];
    let milkUsage = [];
    let spiceUsage = [];
    let waterUsage = [];
    let baseUsage = [];
    let acidUsage = [];
    for (let i = 0; i < hours.length; i++) {
      sugarUsage.push(0);
      teaPowderUsage.push(0);
      milkUsage.push(0);
      spiceUsage.push(0);
      waterUsage.push(0);
      acidUsage.push(0);
      baseUsage.push(0);
    }
    SugarArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      sugarUsage[index] = e.value;
    });
    teaPowderArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      teaPowderUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    spiceArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      spiceUsage[index] = e.value;
    });
    waterArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      waterUsage[index] = e.value;
    });
    BaseArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      baseUsage[index] = e.value;
    });
    AcidArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      acidUsage[index] = e.value;
    });
    let filterHours = [
      "1-2AM",
      "2-3AM",
      "3-4AM",
      "4-5AM",
      "5-6AM",
      "6-7AM",
      "7-8AM",
      "8-9AM",
      "9-10AM",
      "10-11AM",
      "11-12AM",
      "12-1PM",
      "1-2PM",
      "2-3PM",
      "3-4PM",
      "4-5PM",
      "5-6PM",
      "6-7PM",
      "7-8PM",
      "8-9PM",
      "9-10PM",
      "10-11PM",
      "11-12PM",
      "12-1AM",
    ];

    let monthData = [];
    let acidMonth = [];
    let sugarData = [];
    let teaPowderData = [];
    let milkData = [];
    let spiceData = [];
    let waterData = [];
    let acidData = [];
    let basedata = [];
    filterHours.forEach((e, key) => {
      if (
        sugarUsage[key] +
          milkUsage[key] +
          spiceUsage[key] +
          waterUsage[key] +
          teaPowderUsage[key] !=
        0
      ) {
        monthData.push(e);
        teaPowderData.push(teaPowderUsage[key]);
        sugarData.push(sugarUsage[key]);
        milkData.push(milkUsage[key]);
        spiceData.push(spiceUsage[key]);
        waterData.push(waterUsage[key]);
      }
      if (baseUsage[key] + acidUsage[key] != 0) {
        acidMonth.push(e);
        acidData.push(acidUsage[key]);
        basedata.push(baseUsage[key]);
      }
    });

    return {
      month: monthData,
      acidMonth: acidMonth,
      sugarUsage: sugarData,
      teaPowderUsage: teaPowderData,
      milkUsage: milkData,
      spiceUsage: spiceData,
      waterUsage: waterData,
      baseUsage: basedata,
      acidUsage: acidData,
    };
  }
};
const CompanymonthlyFiltersdata = (usage, startDate, endDate) => {
  if (usage) {
    const machine_usage = [];
    usage.data.forEach((e) => {
      machine_usage.push(...e.maped_machine_usage);
    });
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // get month diffrance
    function diff(from, to) {
      var arr = [];
      var datFrom = new Date("1 " + from);
      var datTo = new Date("1 " + to);

      var fromYear = datFrom.getFullYear();
      var toYear = datTo.getFullYear();
      var diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

      for (var i = datFrom.getMonth(); i <= diffYear; i++) {
        arr.push(monthNames[i % 12] + " " + Math.floor(fromYear + i / 12));
      }

      return arr;
    }

    let start = `${
      monthNames[startDate.getMonth()]
    } ${startDate.getFullYear()}`;
    let end = `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}`;

    const all_month = diff(start, end);

    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let SugarArray = [];
    let teaPowderArray = [];
    let milkArray = [];
    let spiceArray = [];
    let waterArray = [];
    let BaseArray = [];
    let AcidArray = [];
    machine_usage.forEach((e, key) => {
      all_month.forEach((month, monthkey) => {
        // console.log(monthkey);
        if (
          `${new Date(e.usage_date).getMonth()}${new Date(
            e.usage_date
          ).getFullYear()}` ===
          `${new Date("1 " + all_month[monthkey]).getMonth()}${new Date(
            "1 " + all_month[monthkey]
          ).getFullYear()}`
        ) {
          e.raw_material_usage.forEach((raw_meterial, raw_meterial_key) => {
            // console.log(raw_meterial);
            if (raw_meterial.name == "Sugar") {
              let indexofmonth = SugarArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                SugarArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + SugarArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                SugarArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (raw_meterial.name == "Tea Powder") {
              let indexofmonth = teaPowderArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                teaPowderArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value:
                    raw_meterial.value + teaPowderArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                teaPowderArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (raw_meterial.name == "Milk") {
              let indexofmonth = milkArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                milkArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + milkArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                milkArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (raw_meterial.name == "Water") {
              let indexofmonth = waterArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                waterArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + waterArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                waterArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (raw_meterial.name == "Spice") {
              let indexofmonth = spiceArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                spiceArray[indexofmonth] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + spiceArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                spiceArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: all_month[monthkey],
                });
              }
            }
          });
          e.chemical_usage.forEach((chemical_Usage, chemical_usage_key) => {
            if (chemical_Usage.name == "Base") {
              let indexofmonth = BaseArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                BaseArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + BaseArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                BaseArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (chemical_Usage.name == "Acid") {
              let indexofmonth = AcidArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                AcidArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + AcidArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                AcidArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: all_month[monthkey],
                });
              }
            }
          });
        }
      });
    });
    let sugarUsage = [];
    let teaPowderUsage = [];
    let milkUsage = [];
    let spiceUsage = [];
    let waterUsage = [];
    let baseUsage = [];
    let acidUsage = [];
    for (let i = 0; i < all_month.length; i++) {
      sugarUsage.push(0);
      teaPowderUsage.push(0);
      milkUsage.push(0);
      spiceUsage.push(0);
      waterUsage.push(0);
      acidUsage.push(0);
      baseUsage.push(0);
    }
    SugarArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      sugarUsage[index] = e.value;
    });
    teaPowderArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      teaPowderUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    spiceArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      spiceUsage[index] = e.value;
    });
    waterArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      waterUsage[index] = e.value;
    });
    BaseArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      baseUsage[index] = e.value;
    });
    AcidArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      acidUsage[index] = e.value;
    });
    const months = [];
    all_month.forEach((a) => {
      months.push(`${a.substring(0, 3)}-${a.slice(-2)}`);
    });

    let monthData = [];
    let acidMonth = [];
    let sugarData = [];
    let teaPowderData = [];
    let milkData = [];
    let spiceData = [];
    let waterData = [];
    let acidData = [];
    let basedata = [];
    months.forEach((e, key) => {
      if (
        sugarUsage[key] +
          milkUsage[key] +
          spiceUsage[key] +
          waterUsage[key] +
          teaPowderUsage[key] !=
        0
      ) {
        monthData.push(e);
        teaPowderData.push(teaPowderUsage[key]);
        sugarData.push(sugarUsage[key]);
        milkData.push(milkUsage[key]);
        spiceData.push(spiceUsage[key]);
        waterData.push(waterUsage[key]);
      }
      if (baseUsage[key] + acidUsage[key] != 0) {
        acidMonth.push(e);
        acidData.push(acidUsage[key]);
        basedata.push(baseUsage[key]);
      }
    });

    return {
      month: monthData,
      acidMonth: acidMonth,
      sugarUsage: sugarData,
      teaPowderUsage: teaPowderData,
      milkUsage: milkData,
      spiceUsage: spiceData,
      waterUsage: waterData,
      baseUsage: basedata,
      acidUsage: acidData,
    };
  }
};
const CompanydailyFilter = (usage, startDate, endDate) => {
  if (usage) {
    const machine_usage = [];
    usage.data.forEach((e) => {
      machine_usage.push(...e.maped_machine_usage);
    });
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    function getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    }

    // Usage
    const dates = getDates(startDate, endDate);
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let SugarArray = [];
    let teaPowderArray = [];
    let milkArray = [];
    let spiceArray = [];
    let waterArray = [];
    let BaseArray = [];
    let AcidArray = [];
    machine_usage.forEach((e, key) => {
      dates.forEach((day, dayKey) => {
        const check =
          `${dates[dayKey].getDate()}${dates[dayKey].getMonth()}${dates[
            dayKey
          ].getFullYear()}` ===
          `${new Date(e.usage_date).getDate()}${new Date(
            e.usage_date
          ).getMonth()}${new Date(e.usage_date).getFullYear()}`;
        if (check) {
          e.raw_material_usage.forEach((raw_meterial, raw_meterial_key) => {
            if (raw_meterial.name == "Sugar") {
              let indexOfDay = SugarArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                SugarArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + SugarArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                SugarArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
            if (raw_meterial.name == "Tea Powder") {
              let indexOfDay = teaPowderArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                teaPowderArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + teaPowderArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                teaPowderArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
            if (raw_meterial.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + milkArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                milkArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
            if (raw_meterial.name == "Spice") {
              let indexOfDay = spiceArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                spiceArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + spiceArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                spiceArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
            if (raw_meterial.name == "Water") {
              let indexOfDay = waterArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                waterArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + waterArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                waterArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: dates[dayKey],
                });
              }
            }
          });
          e.chemical_usage.forEach((chemical_Usage, chemical_usage_key) => {
            if (chemical_Usage.name == "Base") {
              let indexofmonth = BaseArray.findIndex(
                (x) => x.month === dates[dayKey]
              );

              if (indexofmonth >= 0) {
                BaseArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + BaseArray[indexofmonth].value,
                  month: dates[dayKey],
                };
              } else {
                BaseArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: dates[dayKey],
                });
              }
            }
            if (chemical_Usage.name == "Acid") {
              let indexofmonth = AcidArray.findIndex(
                (x) => x.month === dates[dayKey]
              );

              if (indexofmonth >= 0) {
                AcidArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + AcidArray[indexofmonth].value,
                  month: dates[dayKey],
                };
              } else {
                AcidArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: dates[dayKey],
                });
              }
            }
          });
        }
      });
    });
    let sugarUsage = [];
    let teaPowderUsage = [];
    let milkUsage = [];
    let spiceUsage = [];
    let waterUsage = [];
    let baseUsage = [];
    let acidUsage = [];
    for (let i = 0; i < dates.length; i++) {
      sugarUsage.push(0);
      teaPowderUsage.push(0);
      milkUsage.push(0);
      spiceUsage.push(0);
      waterUsage.push(0);
      acidUsage.push(0);
      baseUsage.push(0);
    }
    SugarArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      sugarUsage[index] = e.value;
    });
    teaPowderArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      teaPowderUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    spiceArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      spiceUsage[index] = e.value;
    });
    waterArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      waterUsage[index] = e.value;
    });
    BaseArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      baseUsage[index] = e.value;
    });
    AcidArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      acidUsage[index] = e.value;
    });
    const months = [];
    dates.forEach((a) => {
      const da = `${a.getDate()}-${monthNames[a.getMonth()].substring(
        0,
        3
      )}-${a.getFullYear()}`;
      months.push(da);
    });

    let monthData = [];
    let acidMonth = [];
    let sugarData = [];
    let teaPowderData = [];
    let milkData = [];
    let spiceData = [];
    let waterData = [];
    let acidData = [];
    let basedata = [];
    months.forEach((e, key) => {
      if (
        sugarUsage[key] +
          milkUsage[key] +
          spiceUsage[key] +
          waterUsage[key] +
          teaPowderUsage[key] !=
        0
      ) {
        monthData.push(e);
        teaPowderData.push(teaPowderUsage[key]);
        sugarData.push(sugarUsage[key]);
        milkData.push(milkUsage[key]);
        spiceData.push(spiceUsage[key]);
        waterData.push(waterUsage[key]);
      }
      if (baseUsage[key] + acidUsage[key] != 0) {
        acidMonth.push(e);
        acidData.push(acidUsage[key]);
        basedata.push(baseUsage[key]);
      }
    });

    return {
      month: monthData,
      acidMonth: acidMonth,
      sugarUsage: sugarData,
      teaPowderUsage: teaPowderData,
      milkUsage: milkData,
      spiceUsage: spiceData,
      waterUsage: waterData,
      baseUsage: basedata,
      acidUsage: acidData,
    };
  }
};
const CompanyhourlyFilter = (usage, startDate, endDate) => {
  if (usage) {
    const machine_usage = [];
    usage.data.forEach((e) => {
      machine_usage.push(...e.maped_machine_usage);
    });
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let SugarArray = [];
    let teaPowderArray = [];
    let milkArray = [];
    let spiceArray = [];
    let waterArray = [];
    let BaseArray = [];
    let AcidArray = [];
    machine_usage.forEach((e, key) => {
      const date = new Date(e.usage_date);
      var currentOffset = date.getTimezoneOffset();

      var ISTOffset = 330;

      var ISTTime = new Date(
        date.getTime() + (ISTOffset + currentOffset) * 60000
      );
      const hourse = ISTTime.getHours();

      hours.forEach((hour, hourkey) => {
        if (hourse === hours[hourkey]) {
          e.raw_material_usage.forEach((raw_meterial, raw_meterial_key) => {
            if (raw_meterial.name == "Sugar") {
              let indexOfDay = SugarArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                SugarArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + SugarArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                SugarArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
            if (raw_meterial.name == "Tea Powder") {
              let indexOfDay = teaPowderArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                teaPowderArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + teaPowderArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                teaPowderArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
            if (raw_meterial.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + milkArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                milkArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
            if (raw_meterial.name == "Spice") {
              let indexOfDay = spiceArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                spiceArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + spiceArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                spiceArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
            if (raw_meterial.name == "Water") {
              let indexOfDay = waterArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                waterArray[indexOfDay] = {
                  name: raw_meterial.name,
                  value: raw_meterial.value + waterArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                waterArray.push({
                  name: raw_meterial.name,
                  value: raw_meterial.value,
                  month: hours[hourkey],
                });
              }
            }
          });
          e.chemical_usage.forEach((chemical_Usage, chemical_usage_key) => {
            if (chemical_Usage.name == "Base") {
              let indexofmonth = BaseArray.findIndex(
                (x) => x.month === hours[hourkey]
              );

              if (indexofmonth >= 0) {
                BaseArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + BaseArray[indexofmonth].value,
                  month: hours[hourkey],
                };
              } else {
                BaseArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: hours[hourkey],
                });
              }
            }
            if (chemical_Usage.name == "Acid") {
              let indexofmonth = AcidArray.findIndex(
                (x) => x.month === hours[hourkey]
              );

              if (indexofmonth >= 0) {
                AcidArray[indexofmonth] = {
                  name: chemical_Usage.name,
                  value: chemical_Usage.value + AcidArray[indexofmonth].value,
                  month: hours[hourkey],
                };
              } else {
                AcidArray.push({
                  name: chemical_Usage.name,
                  value: chemical_Usage.value,
                  month: hours[hourkey],
                });
              }
            }
          });
        }
      });
    });
    let sugarUsage = [];
    let teaPowderUsage = [];
    let milkUsage = [];
    let spiceUsage = [];
    let waterUsage = [];
    let baseUsage = [];
    let acidUsage = [];
    for (let i = 0; i < hours.length; i++) {
      sugarUsage.push(0);
      teaPowderUsage.push(0);
      milkUsage.push(0);
      spiceUsage.push(0);
      waterUsage.push(0);
      acidUsage.push(0);
      baseUsage.push(0);
    }
    SugarArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      sugarUsage[index] = e.value;
    });
    teaPowderArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      teaPowderUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    spiceArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      spiceUsage[index] = e.value;
    });
    waterArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      waterUsage[index] = e.value;
    });
    BaseArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      baseUsage[index] = e.value;
    });
    AcidArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      acidUsage[index] = e.value;
    });
    let filterHours = [
      "1-2AM",
      "2-3AM",
      "3-4AM",
      "4-5AM",
      "5-6AM",
      "6-7AM",
      "7-8AM",
      "8-9AM",
      "9-10AM",
      "10-11AM",
      "11-12AM",
      "12-1PM",
      "1-2PM",
      "2-3PM",
      "3-4PM",
      "4-5PM",
      "5-6PM",
      "6-7PM",
      "7-8PM",
      "8-9PM",
      "9-10PM",
      "10-11PM",
      "11-12PM",
      "12-1AM",
    ];
    let monthData = [];
    let acidMonth = [];
    let sugarData = [];
    let teaPowderData = [];
    let milkData = [];
    let spiceData = [];
    let waterData = [];
    let acidData = [];
    let basedata = [];
    filterHours.forEach((e, key) => {
      if (
        sugarUsage[key] +
          milkUsage[key] +
          spiceUsage[key] +
          waterUsage[key] +
          teaPowderUsage[key] !=
        0
      ) {
        monthData.push(e);
        teaPowderData.push(teaPowderUsage[key]);
        sugarData.push(sugarUsage[key]);
        milkData.push(milkUsage[key]);
        spiceData.push(spiceUsage[key]);
        waterData.push(waterUsage[key]);
      }
      if (baseUsage[key] + acidUsage[key] != 0) {
        acidMonth.push(e);
        acidData.push(acidUsage[key]);
        basedata.push(baseUsage[key]);
      }
    });

    return {
      month: monthData,
      acidMonth: acidMonth,
      sugarUsage: sugarData,
      teaPowderUsage: teaPowderData,
      milkUsage: milkData,
      spiceUsage: spiceData,
      waterUsage: waterData,
      baseUsage: basedata,
      acidUsage: acidData,
    };
  }
};

export default ViewRawMeterial;
