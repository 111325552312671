import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Stack, Link } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Autocomplete, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import {
  listMachine,
  listMapedMachine,
  updatemachineStatus,
} from "../redux/reducer/machineMaster/addMachineApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LoadingButton from "@mui/lab/LoadingButton";
import MainTable from "./mainTable";
import { useNavigate } from "react-router-dom";
import {
  listMapedDrinks,
  listDrink,
  updatedMapedDrinks,
  createMapedDrinks,
} from "../redux/reducer/drinkMastert/drinkMasterapiService";
import DropdownSelecter from "../components/forms/dropdownSelecter";
import { isSuccessupdateMaped } from "../redux/reducer/drinkMastert/updateMapedDrink";
import {
  clearmapeddrinks,
  isSuccessmapeddrink,
} from "../redux/reducer/drinkMastert/listMapedDrinksApi";
import { createmapDrink } from "../redux/reducer/drinkMastert/maped_drink_api";
const MapedDrinks = (props) => {
  const navigate = useNavigate();

  const maped_machine_xref = useSelector((state) => state.MapedDrink);
  const createMapedDrink = useSelector((state) => state.MapedDrinkApi);
  const listMapedDrink = useSelector((state) => state.ListMapedDrinks);
  const listDrinks = useSelector((state) => state.ListDrinks);
  const updateMapedDrinks = useSelector((state) => state.UpdateMapedDrink);

  const fetchedTableData = useSelector((state) => state.mapedMachines);
  const [open, setOpen] = useState(false);
  //   const [edit, setEdit] = useState(
  //     listMapedDrink.responce.data[0] ? true : false
  //   );
  const dispatch = useDispatch();
  if (listMapedDrink.responce) {
    if (listMapedDrink.isSuccess) {
      // dispatch(
      //   listDrink(
      //     `?category=${listMapedDrink.responce.data[0].drinks[0].category}`
      //   )
      // );
      dispatch(isSuccessmapeddrink());
    }
  }
  //   let edit_drink;

  //form validations

  const validationSchema = Yup.object().shape({
    // Catogryname1: Yup.string().required("Drink name is required"),
    drink_name1: Yup.object()
      .shape({
        value: Yup.string().required("Drink category required"),
      })
      .nullable(),
  });

  const drink_name1 = listMapedDrink.responce
    ? listMapedDrink.responce.data.filter((a) => {
        if (a.drinks[0].category === "Flavour Tea") {
          return a;
        }
      })
    : "";
  const drink_name2 = listMapedDrink.responce
    ? listMapedDrink.responce.data.filter((a) => {
        if (a.drinks[0].category === "Tea") {
          return a;
        }
      })
    : "";
  const drink_name3 = listMapedDrink.responce
    ? listMapedDrink.responce.data.filter((a) => {
        if (a.drinks[0].category === "Milk") {
          return a;
        }
      })
    : "";
  // console.log(drink_name1);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Catogryname1: "Flavour Tea",
      Catogryname2: "Tea",
      Catogryname3: "Milk",
      drink_name1: listMapedDrink.responce
        ? {
            id: drink_name1[0].drinks[0]._id,
            value: drink_name1[0].drinks[0].sub_category,
            label: drink_name1[0].drinks[0].sub_category,
          }
        : { value: "", label: "" },
      drink_name2: listMapedDrink.responce
        ? {
            id: drink_name2[0].drinks[0]._id,
            value: drink_name2[0].drinks[0].sub_category,
            label: drink_name2[0].drinks[0].sub_category,
          }
        : { value: "", label: "" },
      drink_name3: listMapedDrink.responce
        ? {
            id: drink_name3[0].drinks[0]._id,
            value: drink_name3[0].drinks[0].sub_category,
            label: drink_name3[0].drinks[0].sub_category,
          }
        : { value: "", label: "" },
    },

    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;

  //   console.log(listDrinks);
  console.log(errors);
  //   console.log(listMapedDrink.responce.data[0].drinks[0].category);
  //submit drink
  const submit = (val) => {
    if (listMapedDrink.responce) {
      const submit = {
        company_machine_id: [maped_machine_xref.data._id],
        drink_master_id: [
          val.drink_name1.id,
          val.drink_name2.id,
          val.drink_name3.id,
        ],
        drink_master_xref_id: [
          listMapedDrink.responce.data[0]._id,
          listMapedDrink.responce.data[1]._id,
          listMapedDrink.responce.data[2]._id,
        ],
      };
      dispatch(updatedMapedDrinks(submit));
      console.log(val);
    }
    if (!listMapedDrink.responce) {
      const submit = {
        company_machine_id: [maped_machine_xref.data._id],
        drink_master_id: [
          val.drink_name1.id,
          val.drink_name2.id,
          val.drink_name3.id,
        ],
      };
      dispatch(createMapedDrinks(submit));
      // console.log(submit);
    }
  };
  //get category list
  const getcatogoryname = (val) => {
    console.log(val);
    // dispatch(listDrink(`?category=${val}`));
  };
  //dialough action
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };
  //drink category option
  const option = [
    { value: "Tea", label: "Tea" },
    { value: "Flavour Tea", label: "Flavour Tea" },
    { value: "Milk", label: "Milk" },
  ];
  //list drinks option
  const get_FlaverTea = !listDrinks.isSuccess
    ? [{ value: "", label: "No Drinks Found" }]
    : listDrinks.responce.data.filter((data, key) => {
        if (data.category === "Flavour Tea") {
          return {
            key: key,
            id: data._id,
            value: data.sub_category,
            label: data.sub_category,
          };
        }
      });
  const FlaverTea = !listDrinks.isSuccess
    ? [{ value: "", label: "No Drinks Found" }]
    : get_FlaverTea.map((data, key) => {
        if (data.category === "Flavour Tea") {
          return {
            key: key,
            id: data._id,
            value: data.sub_category,
            label: data.sub_category,
          };
        }
      });
  const get_Tea_option = !listDrinks.isSuccess
    ? [{ value: "", label: "No Drinks Found" }]
    : listDrinks.responce.data.filter((data, key) => {
        if (data.category === "Tea") {
          return {
            key: key,
            id: data._id,
            value: data.sub_category,
            label: data.sub_category,
          };
        }
      });
  const TeaOption = !listDrinks.isSuccess
    ? [{ value: "", label: "No Drinks Found" }]
    : get_Tea_option.map((data, key) => {
        if (data.category === "Tea") {
          return {
            key: key,
            id: data._id,
            value: data.sub_category,
            label: data.sub_category,
          };
        }
      });
  const get_milk = !listDrinks.isSuccess
    ? [{ value: "", label: "No Drinks Found" }]
    : listDrinks.responce.data.filter((data) => {
        if (data.category === "Milk") {
          return data;
          // console.log(data1);
        }
      });
  const MilkOption = get_milk.map((data, key) => {
    console.log(data);
    if (listDrinks.responce) {
      return {
        key: key,
        id: data._id,
        value: data.sub_category,
        label: data.sub_category,
      };
    }
  });
  console.log(MilkOption);

  //edit machine mapping
  const editMachineMapping = () => {
    // console.log(values);
    return (
      <div>
        <Dialog open={open} onClose={handleClose} className="py-2">
          <DialogTitle className="font-weight-bold">
            {" Drinks Mapping"}
          </DialogTitle>
          <DialogContent className="py-2">
            <div className="w-100 d-flex">
              <div className="col-6">
                <DropdownSelecter
                  id={1}
                  readOnly={true}
                  defaultvalue={"Flavour Tea"}
                  className="p-2 w-100"
                  style={{ width: "90%" }}
                  onBlur={() => {
                    handleBlur({ target: { name: "Catogryname1" } });
                  }}
                  placeholder={"Catogary Name"}
                  setFieldValue={setFieldValue}
                  options={option}
                  errors={errors}
                  vari={getcatogoryname}
                  values={values}
                  touched={touched}
                  name="Catogryname1"
                />
              </div>
              <Autocomplete
                options={FlaverTea}
                value={values.drink_name1}
                // defaultValue={{ value: "vhjghjldjgfdygfjgy", label: "akdkadkj" }}
                className=" col-6"
                renderInput={(params) => (
                  <TextField
                    error={
                      errors.drink_name1 && touched.drink_name1 ? true : false
                    }
                    helperText={
                      errors.drink_name1 && touched.drink_name1
                        ? errors.drink_name1
                        : null
                    }
                    {...params}
                    name="drink_name1"
                    label="Drinks Name"
                    variant="standard"
                  />
                )}
                onChange={(e, value) => {
                  const a = value ? value : "";
                  setFieldValue("drink_name1", a);
                }}
                onBlur={handleBlur}
              />
            </div>
          </DialogContent>
          <DialogContent className="py-2">
            <div className="w-100 d-flex">
              <div className="col-6">
                <DropdownSelecter
                  readOnly={true}
                  id={1}
                  // defaultvalue={is_edit.data.category}
                  className="p-2 "
                  style={{ width: "90%" }}
                  onBlur={() => {
                    handleBlur({ target: { name: "Catogryname2" } });
                  }}
                  placeholder={"Catogary Name"}
                  setFieldValue={setFieldValue}
                  options={option}
                  errors={errors}
                  vari={getcatogoryname}
                  values={values}
                  touched={touched}
                  name="Catogryname2"
                />
              </div>
              <Autocomplete
                options={TeaOption}
                value={values.drink_name2}
                // defaultValue={{ value: "vhjghjldjgfdygfjgy", label: "akdkadkj" }}
                className=" col-6"
                renderInput={(params) => (
                  <TextField
                    error={
                      errors.drink_name2 && touched.drink_name2 ? true : false
                    }
                    helperText={
                      errors.drink_name2 && touched.drink_name2
                        ? errors.drink_name2
                        : null
                    }
                    {...params}
                    name="drink_name2"
                    label="Drinks Name"
                    variant="standard"
                  />
                )}
                onChange={(e, value) => {
                  const a = value ? value : "";
                  setFieldValue("drink_name2", a);
                }}
                onBlur={handleBlur}
              />
            </div>
          </DialogContent>
          <DialogContent className="py-2">
            <div className="w-100 d-flex">
              <div className="col-6">
                <DropdownSelecter
                  id={1}
                  readOnly={true}
                  // defaultvalue={is_edit.data.category}
                  className="p-2 "
                  style={{ width: "90%" }}
                  onBlur={() => {
                    handleBlur({ target: { name: "Catogryname3" } });
                  }}
                  placeholder={"Catogary Name"}
                  setFieldValue={setFieldValue}
                  options={option}
                  errors={errors}
                  vari={getcatogoryname}
                  values={values}
                  touched={touched}
                  name="Catogryname3"
                />
              </div>
              <Autocomplete
                options={MilkOption}
                value={values.drink_name3}
                // defaultValue={{ value: "vhjghjldjgfdygfjgy", label: "akdkadkj" }}
                className=" col-6"
                renderInput={(params) => (
                  <TextField
                    error={
                      errors.drink_name3 && touched.drink_name3 ? true : false
                    }
                    helperText={
                      errors.drink_name3 && touched.drink_name3
                        ? errors.drink_name3
                        : null
                    }
                    {...params}
                    name="drink_name"
                    label="Drinks Name"
                    variant="standard"
                  />
                )}
                onChange={(e, value) => {
                  const a = value ? value : "";
                  setFieldValue("drink_name3", a);
                }}
                onBlur={handleBlur}
              />
            </div>
          </DialogContent>
          <DialogActions className="m-2">
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              //   loading={updateMapedMachineApi.isLoading ? true : false}
              variant="outlined"
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  //api call
  const apiCall = () => {
    // dispatch();
    //   listMapedDrinks("?company_machine_id=" + maped_machine_xref.data._id)
    // dispatch(listDrink(""))
  };
  //useEffect
  useEffect(() => {
    dispatch(listDrink(``));

    if (updateMapedDrinks.isSuccess) {
      handleClose();
      // resetForm();
      dispatch(isSuccessupdateMaped());
      // dispatch(clearmapeddrinks());
      // navigate("/usermaster/Mapmachine");
    }
    if (createMapedDrink.isSuccess) {
      handleClose();
      // resetForm();
      dispatch(createmapDrink());
      // dispatch(clearmapeddrinks());
      // navigate("/usermaster/Mapmachine");
    }
  }, [updateMapedDrinks, createMapedDrink]);

  //actions
  const Action = {
    Action: [
      {
        icon: (
          <FontAwesomeIcon
            className="p-2"
            type="button"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
    ],
  };

  //get data
  const getData = (val) => {
    if (val.action == "edit") {
      handleClickOpen();
    }
  };
  //filter tables
  const fiter = () => {
    if (!listMapedDrink.isLoading) {
      const filterarr = [];

      //   console.log(fetchedTableData.responce.data[0]);
      for (let i = 0; i < 1; i++) {
        const val = {};
        // console.log(fetchedTableData.responce.data[i]);
        val.company_name = maped_machine_xref.data.company_name;
        val.device_id = maped_machine_xref.data.device_id;

        val.machine_name = maped_machine_xref.data.machine_name;

        val.machine_model = maped_machine_xref.data.machine_model;
        const milk = !listMapedDrink.responce.data
          ? "-"
          : listMapedDrink.responce.data.filter((a) => {
              if (a.drinks[0].category === "Milk") {
                return a;
              }
            })[0].drinks[0].sub_category;
        val.Milk = milk;
        const Tea = !listMapedDrink.responce.data
          ? "-"
          : listMapedDrink.responce.data.filter((a) => {
              if (a.drinks[0].category === "Tea") {
                return a;
              }
            })[0].drinks[0].sub_category;
        val.Tea = Tea;
        const Flavour_tea = !listMapedDrink.responce.data
          ? "-"
          : listMapedDrink.responce.data.filter((a) => {
              if (a.drinks[0].category === "Flavour Tea") {
                return a;
              }
            })[0].drinks[0].sub_category;
        val.Flavour_tea = Flavour_tea;

        val._id = maped_machine_xref.data._id;

        filterarr.push(val);
        // //     // console.log(data);
      }
      console.log(filterarr);
      filterarr.filter((a) => delete a.updated_at);
      filterarr.filter((a) => delete a.entity_id);
      // console.log(arr);
      if (filterarr.length != 0) {
        return (
          <MainTable
            // allacated={allacated}

            tabledata={filterarr}
            listOfMachine={" LIST OF MACHINE"}
            getData={getData}
            Action={Action}
          />
        );
      }
    }
  };
  const breadcrumbs = [
    <Link
      type="button"
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      MACHINE MASTER
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        dispatch(clearmapeddrinks());
        navigate("/usermaster/Mapmachine");
      }}
    >
      MAP COMPANY & MACHINE
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      MAP DRINKS
    </Link>,
  ];
  return (
    <div className="comp-change">
      {listMapedDrink.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex w-100  ">
        <Stack className="mx-4 pt-4 w-100 ">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
        </Stack>
        <div className="px-4 d-flex w-100 justify-content-end align-items-center">
          <div
            type="button"
            className="d-flex justify-content-end align-items-center"
            onClick={() => {
              // navigate("/drinkmaster/adddrink");
              handleClickOpen();
            }}
          >
            <div className="pb-1 ">
              {listMapedDrink.responce ? "Edit" : "map"} drinks
            </div>
            <div className="px-2">
              <FontAwesomeIcon
                style={{
                  fontSize: "22px",
                }}
                className="p-2"
                icon={
                  listMapedDrink.responce
                    ? "fa-solid fa-pen-to-square"
                    : "fa-solid fa-square-plus"
                }
              />
            </div>
          </div>
        </div>
      </div>
      {fiter()}
      {open ? editMachineMapping() : ""}
    </div>
  );
};

export default MapedDrinks;
