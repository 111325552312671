import React, { useEffect, useState } from "react";
import "../assets/css/login.css";
import { useFormik, Field, Form, ErrorMessage } from "formik";
import { Route, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, forgetPassword } from "./services/auth_api_service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, CircularProgress, Paper, TextField } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";

function Login(props) {
  const [forgetPasswords, setForgetPassword] = useState(false);
  const navigate = useNavigate();

  const validationSchema = forgetPassword
    ? Yup.object().shape({
        email_id: Yup.string()
          .required("Email is required")
          .email("Email is invalid"),
      })
    : Yup.object().shape({
        email_id: Yup.string()
          .required("Email is required")
          .email("Email is invalid"),
        Password: Yup.string()
          .required("Password is required")
          .min(6, "Password must be at least 6 characters")
          .max(40, "Password must not exceed 40 characters"),
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        //   "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        // ),
      });
  const formik = useFormik({
    initialValues: {
      email_id: "",
      Password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    errors,
  } = formik;
  const [checked, setchecked] = useState(true);

  //reducer
  const Login = useSelector((state) => state.Login);
  const forgetPasswordApi = useSelector((state) => state.ForgetPassword);
  useEffect(() => {
    document.addEventListener("keypress", keyPress, true);
    if (Login.isSuccess) {
      resetForm();
      window.location.pathname = "/";
    }
    if (forgetPasswordApi.isSuccess) {
      resetForm();
    }
  }, [Login, forgetPasswordApi]);

  const dispathc = useDispatch();
  // if (Login.isSuccess) {

  //   // navigate("/");
  // }
  const submit = (val) => {
    if (forgetPasswords) {
      dispathc(forgetPassword(val));
    }
    if (!forgetPasswords) {
      dispathc(loginUser(val));
    }

    // console.log(val);
  };
  const keyPress = (e) => {
    // console.log("value", e);
    if (e.keyCode == 13) {
      // put the login here
      handleSubmit();
    }
  };

  console.log(forgetPasswords);

  return (
    <>
      <div>
        <div className="w-100 h-100 d-flex align-items-center position-absolute justify-content-center">
          <div
            className=""
            style={{
              width: "22%",
            }}
          >
            <Paper className="d-flex flex-column p-4 justify-content-center align-items-center">
              <div className="d-flex logo justify-content-center align-items-center">
                Boatload
              </div>
              <div>
                <div className="d-flex pb-4 px-4 pt-1 justify-content-center sing">
                  {forgetPasswords ? "Forgot Password" : "SIGN-IN"}
                </div>
                <div className="font-14">
                  {forgetPasswords ? "" : "to continue view details"}{" "}
                </div>
              </div>
              <div className="w-100 form d-flex flex-column">
                <TextField
                  type="email"
                  className="textFieldinput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email_id}
                  helperText={touched.email_id && errors.email_id}
                  error={touched.email_id && errors.email_id ? true : false}
                  name="email_id"
                  label="Email Id"
                ></TextField>
                {forgetPasswords ? (
                  ""
                ) : (
                  <TextField
                    type="password"
                    className="textFieldinput"
                    value={values.Password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="Password"
                    label="Password"
                    error={touched.Password && errors.Password ? true : false}
                    helperText={touched.Password && errors.Password}
                  ></TextField>
                )}
              </div>
              <a
                className="font-14 font-weight-bold"
                onClick={() => {
                  forgetPasswords
                    ? setForgetPassword(false)
                    : setForgetPassword(true);
                }}
              >
                {forgetPasswords ? "" : "Forgot Password ?"}
              </a>
              <div className="d-flex">
                {forgetPasswords ? (
                  <div className="d-flex">
                    <div style={{ padding: "20px 4px", width: "131px" }}>
                      <LoadingButton
                        className="loginButton w-100 d-flex justify-content-center align-items-center"
                        variant="outlined"
                        // color="blue"
                        onClick={() => {
                          setForgetPassword(false);
                        }}
                      >
                        Back
                      </LoadingButton>
                      {/* <Button >
                  Submit
                </Button> */}
                    </div>
                    <div style={{ padding: "20px 4px", width: "131px" }}>
                      <LoadingButton
                        onClick={handleSubmit}
                        type="submit"
                        className="loginButton w-100 d-flex justify-content-center align-items-center"
                        loading={
                          Login.isLoading || forgetPasswordApi.isLoading
                            ? true
                            : false
                        }
                        loadingIndicator={
                          <>
                            <div className="d-flex px-2 justify-content-center w-100">
                              <div className="">
                                <CircularProgress
                                  className="d-flex align-items-center justify-content-center "
                                  size={20}
                                />
                              </div>
                              <div className="w-100 lod font-11 d-flex align-items-center justify-content-center">
                                Please Wait
                              </div>
                            </div>
                          </>
                        }
                        variant="outlined"
                      >
                        Submit
                      </LoadingButton>
                      {/* <Button >
                   Submit
                 </Button> */}
                    </div>
                  </div>
                ) : (
                  <div style={{ padding: "20px", width: "161px" }}>
                    <LoadingButton
                      onClick={handleSubmit}
                      type="submit"
                      className="loginButton w-100 d-flex justify-content-center align-items-center"
                      loading={
                        Login.isLoading || forgetPasswordApi.isLoading
                          ? true
                          : false
                      }
                      loadingIndicator={
                        <>
                          <div className="d-flex px-2 justify-content-center w-100">
                            <div className="">
                              <CircularProgress
                                className="d-flex align-items-center justify-content-center "
                                size={20}
                              />
                            </div>
                            <div className="w-100 lod font-11 d-flex align-items-center justify-content-center">
                              Please Wait
                            </div>
                          </div>
                        </>
                      }
                      variant="outlined"
                    >
                      Submit
                    </LoadingButton>
                    {/* <Button >
                  Submit
                </Button> */}
                  </div>
                )}
              </div>
            </Paper>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default Login;
