import React, { useEffect, useState } from "react";
import MainTable from "../../insideComponents/mainTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/css/service_request.css";
import { useNavigate } from "react-router-dom";
import { Paper, Link, DialogTitle, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { listUser } from "../../redux/reducer/UserMaster/userMasterApiService";
import { edit, userData } from "../../redux/reducer/UserMaster/editUser";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { listLeadsDetail } from "../../redux/reducer/crm/leadsApiService";
import { listRequestHistory } from "../../redux/reducer/serviceRequest/servise_requestApiService";
import { Button } from "@mui/material";
import { resetMachine } from "../../redux/reducer/machineMaster/addMachineApiService";

function ViewServiceRequest(props) {
  const viewRequest = useSelector((state) => state.getDetailViewData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchedRequestHistoryData = useSelector(
    (state) => state.listRequestHistory
  );
  // const styles = (theme) => ({
  //   multilineColor: {
  //     color: "red",
  //   },
  // });

  //api call
  const apiCall = () => {
    dispatch(
      listRequestHistory(
        "?service_request_id=" + viewRequest.data.detailData._id
      )
    );
  };
  // console.log(fetchedRequestHistoryData.responce.data.userData);
  useEffect(() => {
    apiCall();
  }, []);
  const getData = (val) => {
    // console.log(val);
  };
  const Action = {
    // Action: [
    //   // {
    //   //   icon: <FontAwesomeIcon className="p-2" icon="fa-solid fa-eye" />,
    //   //   name: "view",
    //   // },
    //   //   {
    //   //     icon: (
    //   //       <FontAwesomeIcon className="p-2" icon="fa-solid fa-pen-to-square" />
    //   //     ),
    //   //     name: "edit",
    //   //   },
    // ],
  };
  const ve = (e) => {
    console.log(e);
  };

  //filter table data
  const filter = () => {
    if (fetchedRequestHistoryData.responce.data) {
      const filterarr = [];
      for (let i = 0; i < fetchedRequestHistoryData.responce.data.length; i++) {
        let data = { ...fetchedRequestHistoryData.responce.data[i] };
        console.log(data);

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const date = new Date(data.updated_at);
        delete data.updated_at;
        delete data.modified_by;
        data.modified_by =
          data.modified.length != 0 ? data.modified[0].name : "-";
        const updated_at =
          date.getDate() +
          "-" +
          monthNames[date.getMonth()] +
          "-" +
          date.getFullYear();

        data.updated_at = updated_at;
        let keyValues = Object.entries(data);

        filterarr.push(data);
        // console.log(newAr);
      }

      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.request);

      filterarr.filter((a) => delete a.service_request_id);
      filterarr.filter((a) => delete a.modified);

      filterarr.filter((a) => delete a.user);

      //   console.log(newAr);
      // filterarr.filter((a) => delete a.company_id);

      //filter value
      const filter = [];
      return (
        <MainTable
          tabledata={filterarr}
          filter={filter}
          listOfMachine={" REQUEST HISTORY "}
          getData={getData}
          Action={Action}
        />
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tabledata={filterarr}
          listOfMachine={"REQUEST HISTORY"}
          Action={""}
        />
      );
    }
  };
  //brudcrumps
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      SERVICE REQUEST
    </Link>,
    <Link
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        navigate("/servicerequest/viewRequest");
      }}
    >
      VIEW REQUEST
    </Link>,
    <Link
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      {viewRequest.data.detailData.raised_by}
    </Link>,
  ];
  // console.log(JSON.parse(viewRequest.data.fullData.description));
  return (
    <div className="comp-change">
      {fetchedRequestHistoryData.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form m-4 " style={{ marginBottom: "10px" }}>
        <Paper className="bg-white machineForm " style={{ width: "100%" }}>
          <div className="d-flex">
            <div className="pb-3 p-0 w-50 font-size-20 font-weight-bold">
              {"Request Details"}
            </div>
            <div className="w-50 justify-content-end d-flex">
              <div type="button">
                {viewRequest.data.fullData.machine_request ===
                "machine Requests" ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      let conform = window.confirm("You are Reset Data?");
                      if (conform) {
                        dispatch(resetMachine(viewRequest.data.fullData));
                      }
                    }}
                  >
                    Reset Error
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="row pt-1   px-4">
            <div className="col-4 pb-4">
              <TextField
                value={viewRequest.data.detailData.raised_by}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Raised By"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="col-4 pb-4 ">
              <TextField
                value={viewRequest.data.detailData.request_type}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Request Type"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>

            <div className="col-4 pt-3">
              <TextField
                value={viewRequest.data.detailData.assigned_to}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Assigned To"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="col-4 pb-4">
              <TextField
                value={viewRequest.data.detailData.description}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Description"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="col-4 pt-3">
              <TextField
                value={viewRequest.data.detailData.requested_date}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Requested Date"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="col-4 pt-3">
              <TextField
                value={viewRequest.data.detailData.assigned_date}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Assigned Date"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
        </Paper>
        {viewRequest.data.fullData.machine_request === "machine Requests" ? (
          <div
            className="d-flex "
            style={{
              height: "400px",
            }}
          >
            <Paper className="bg-white col-5 mt-3 machineForm ">
              <div className="pb-3 p-0  font-size-20 font-weight-bold">
                {"Error Details"}
              </div>
              {JSON.parse(viewRequest.data.fullData.description).map(
                (e, key) => {
                  const data = e.split(":");
                  console.log(data);
                  return (
                    <div className="row pt-1  w-100 px-4" key={key}>
                      <div className=" pb-4 d-flex align-items-center">
                        {/* <div className="d-flex w-25">{key + 1 + " -"} </div> */}
                        <div id="error-codes-color">
                          <TextField
                            value={data[0]}
                            readonly={true}
                            className=" px-2 justify-content-center color-red d-flex"
                            name="companyName "
                            sx={{ input: { color: "red" } }}
                            style={{
                              width: "35px",
                              color: "red",
                            }}
                            color={"error"}
                            // label="Raised By"
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        </div>
                        <div>-</div>{" "}
                        <TextField
                          value={data[1]}
                          readonly={true}
                          className="w-100 px-2"
                          name="companyName "
                          // label="Raised By"
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </div>
                    </div>
                  );
                }
              )}
            </Paper>
            <Paper
              style={{
                marginLeft: "15px",
              }}
              className="bg-white w-100  mt-3 machineForm "
            >
              <div className="pb-3 p-0  font-size-20 font-weight-bold">
                {"Curent Values"}
              </div>
              <div className="">
                <div className=" pt-1 col-5  w-100 px-4">
                  <div className="row d-flex  align-items-center">
                    {viewRequest.data.fullData.curent_Data.map((e, key) => {
                      return (
                        <div className="col-4" key={key}>
                          <TextField
                            value={e.value}
                            readonly={true}
                            className=" px-2  justify-content-center d-flex"
                            name="companyName "
                            sx={{ input: { color: "red" } }}
                            color={"error"}
                            label={e.name}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        ) : (
          ""
        )}
      </div>
      {fetchedRequestHistoryData.isLoading ? null : filter()}
    </div>
  );
}

export default ViewServiceRequest;
