import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { Paper, Link, TextField } from "@material-ui/core";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { listproduct } from "../../redux/reducer/inventory/inventoryapiService";
import MainTable from "../../insideComponents/mainTable";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Checkbox from "@mui/material/Checkbox";
import { Productdata } from "../../redux/reducer/inventory/editProduct";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Autocomplete } from "@mui/material";
const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "100",
    },
  },
});

const ViewProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listProduct = useSelector((state) => state.Listproduct);
  const [filterValue, setFilterValues] = useState(null);
  const classes = useStyles();
  //useEffect
  useEffect(() => {
    dispatch(listproduct(""));
  }, []);
  console.log(listProduct);
  //table actions
  const Action = {
    Action: [
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="edit"
            className="p-2"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
    ],
  };
  //filter table value
  const tablefilter = [];
  //get data
  const getData = (val) => {
    const data = listProduct.responce.data.filter((e) => {
      return e._id === val.data._id;
    });
    if (val.action == "edit") {
      dispatch(Productdata(data[0]));
      navigate("/inventory/addproduct");
      console.log(data);
    }
  };
  //filter api
  const filterapi = (e) => {
    // console.log(e);
    if (e === "all") {
      dispatch(listproduct(""));
    }
    if (e === "Active") {
      dispatch(listproduct("?is_active=true"));
    }
    if (e === "In Active") {
      dispatch(listproduct("?is_active=false"));
    }
  };
  //filter table data
  const filter = () => {
    if (listProduct.responce.data) {
      const filterarr = [];

      for (let i = 0; i < listProduct.responce.data.length; i++) {
        let data = { ...listProduct.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const createdate = new Date(data.created_at);
        const updatedate = new Date(data.updated_at);
        const sheduleddate = new Date(data.shedule_date);

        const create_date =
          createdate.getDate() +
          "-" +
          monthNames[createdate.getMonth()] +
          "-" +
          createdate.getFullYear();
        const updated_date =
          updatedate.getDate() +
          "-" +
          monthNames[updatedate.getMonth()] +
          "-" +
          updatedate.getFullYear();
        const newobj = {};
        console.log(data);
        newobj._id = data._id;
        newobj.image = (
          <img
            className="w-25 "
            style={{ height: "80px" }}
            src={data.images}
            alt="product image"
          ></img>
        );
        newobj.name = data.name;
        newobj.description = data.description;
        newobj.quantity = data.quantity;
        newobj.unit = data.unit;
        newobj.price = data.price;
        newobj.created_at = create_date;
        newobj.updated_at = updated_date;
        newobj.is_active = (
          <FormGroup
            id={data._id}
            name={data.name}
            onChange={(e) => {
              // changeaddStatuss(e.target);
            }}
          >
            <FormControlLabel
              id={data._id}
              name={data.name}
              readOnly={true}
              className="d-flex justify-content-center w-100 m-0"
              control={
                <Checkbox
                  id={data._id}
                  inputProps={{
                    readOnly: true,
                    "aria-label": "primary checkbox",
                  }}
                  name={data.name}
                  defaultChecked={data.is_active ? true : false}
                />
                // <TextField readOnly type="checkbox"></TextField>
              }
            />
          </FormGroup>
        );
        filterarr.push(newobj);

        // console.log(data);
      }
      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.customer_id);
      filterarr.filter((a) => delete a.lead_updated_by);
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={filterValue ? filterValue.value : ""}
                    className=""
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "In Active", label: "In Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";
                      setFilterValues(value);
                      filterapi(a);
                      console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const tablefilterDropDown = [groupdrop()];
      return (
        <>
          <MainTable
            tablefilterDropDown={tablefilterDropDown}
            tabledata={filterarr}
            // filter={tablefilter}
            filtervalue={filterapi}
            listOfMachine={"Product List"}
            getData={getData}
            Action={Action}
          />
        </>
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingTop: "9px",
                    paddingBottom: "4px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={filterValue ? filterValue.value : ""}
                    className=""
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "In Active", label: "In Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";
                      setFilterValues(value);
                      filterapi(a);
                      console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Group Id"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const tablefilterDropDown = [groupdrop()];
      return (
        <MainTable
          tablefilterDropDown={tablefilterDropDown}
          tabledata={filterarr}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      INVENTORY
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      VIEW PRODUCT
    </Link>,
  ];

  return (
    <div className="comp-change">
      {listProduct.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      {listProduct.isLoading ? "" : filter()}
      {/* <h1>ADD inventory</h1> */}
    </div>
  );
};
export default ViewProduct;
