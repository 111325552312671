import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { selectUser } from "../LoginReducer";
const API_URL = "https://dtv29cy9i3.execute-api.ap-south-1.amazonaws.com/dev";
// const API_URL = "http://localhost:8080";
let token = localStorage.getItem("Token");
const entity_id = 1;
// console.log(token);

export const addLeads = createAsyncThunk(
  "AddLeads/addLeads",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/leads/add_leads", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//listDrink
export const listLeads = createAsyncThunk(
  "ListLeads/listLeads",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/leads/list_leads" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//edit leads
export const editLeadsapi = createAsyncThunk(
  "EditLeads/editLeadsapi",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(API_URL + "/leads/edit_leads", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//edit leads
export const editLeadsStatus = createAsyncThunk(
  "EditLeadsStatus/editLeadsStatus",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(API_URL + "/leads/update_status", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list leads detail view
export const listLeadsDetail = createAsyncThunk(
  "ListLeadsDetail/listLeadsDetail",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(
        API_URL + "/leads/list/leads_history" + value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
