import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const SidenavSlice = createSlice({
  name: "Login",
  initialState: {
    sidenavsubActive: false,
  },
  reducers: {
    collaps: (state, payload) => {
      state.sidenavActive = !state.sidenavActive;
    },
    activeSub: (state, payload) => {
      state.sidenavActive = true;
    },
  },
});

export const { collaps, activeSub } = SidenavSlice.actions;
// export const selectUser = (state) => state.user.login;
export default SidenavSlice.reducer;
