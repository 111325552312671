import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
const API_URL = "https://dtv29cy9i3.execute-api.ap-south-1.amazonaws.com/dev";
// const API_URL = "http://localhost:8080";
let token = localStorage.getItem("Token");
const entity_id = 1;
console.log(token);

//addUser
export const addUser = createAsyncThunk(
  "AddUser/addUser",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/user/createuser", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list user
export const listUser = createAsyncThunk(
  "ListUser/listUser",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/user/listuser" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list user
export const listProfile = createAsyncThunk(
  "ListProfile/listProfile",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/user/listprofile" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//edit User
export const editUser = createAsyncThunk(
  "EditUser/editUser",
  async (value, { rejectWithValue }) => {
    try {
      console.log(value);
      const data = await axios.put(API_URL + "/user/edit/" + value._id, value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//get company details

export const listCompany = createAsyncThunk(
  "CompanyList/listCompany",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/user/listcompany" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//get full company details

export const listFullCompany = createAsyncThunk(
  "ListFullCompany/listFullCompany",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/user/listcompany" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete drinks

export const deleteCategory = createAsyncThunk(
  "DeleteDrink/deleteCategory",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.delete(API_URL + "/dirnks/delete/" + value._id, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//machine and company mapping
export const machineMapping = createAsyncThunk(
  "MappingCompanyAndMachine/machineMapping",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        API_URL + "/machine/create/machine_xref",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//machine mapping update
export const editMappedMachine = createAsyncThunk(
  "EditMapedMachine/editMappedMachine",
  async (value, { rejectWithValue }) => {
    try {
      console.log(value);
      const data = await axios.put(
        API_URL + "/machine/update/machine_xref/",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//change password
export const changePassword = createAsyncThunk(
  "ChangePassword/changePassword",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/user/change_password", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
//change status
export const changeUserStatus = createAsyncThunk(
  "ChangeUserStatus/changeUserStatus",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(API_URL + "/user/status", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
