import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { Paper, Link } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
// import Chart from "react-apexcharts";
import BarChart from "../../insideComponents/dashbord/charts/barchart";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editLeadsStatus,
  listLeads,
} from "../../redux/reducer/crm/leadsApiService";
import MainTable from "../../insideComponents/mainTable";
import { isEditLeads, Leadsdata } from "../../redux/reducer/crm/editLeads";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import * as Yup from "yup";
import { useFormik } from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import DropdownSelecter from "../forms/dropdownSelecter";
import LoadingButton from "@mui/lab/LoadingButton";
import { isSuccessStatusLeads } from "../../redux/reducer/crm/editStatus_leads";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { edit, userData } from "../../redux/reducer/crm/convertCustomer";
import TextInput from "../forms/textInput";
import { leadsData } from "../../redux/reducer/crm/detailsView";
import { Autocomplete } from "@mui/material";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
//form validationos
const validationSchema = Yup.object().shape({
  // status: Yup.string().required("Status is id required"),
});

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "2",
    },
  },
});
function ViewLeads(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ListLeads = useSelector((state) => state.ListLeads);
  const editStatus = useSelector((state) => state.EditLeadsStatus);
  const [value, setValue] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState([]);
  const [locationFilter, setLocationFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const classes = useStyles();
  let oneMonthPreviosDate = new Date();
  oneMonthPreviosDate.setMonth(oneMonthPreviosDate.getMonth() - 1);

  const [DateRangeStartDate, setDateRangeStartDate] =
    useState(oneMonthPreviosDate);
  const [DateRangeEndDate, setDateRangeEndDate] = useState(new Date());

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: status.length != 0 ? status[0].status : "",
      remarks: "",
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
    onReset: (data) => {
      console.log(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleReset,
    setTouched,
  } = formik;
  console.log(values);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStatus([]);
    setOpen(false);
  };
  console.log();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      type="button"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      CRM
    </Link>,
    <Link
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      type="button"
      color="inherit"
    >
      VIEW LEADS
    </Link>,
  ];
  //actions
  const Action = {
    Action: [
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="view"
            className="p-2"
            icon="fa-solid fa-eye"
          />
        ),
        name: "view",
      },
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="edit"
            className="p-2"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="status"
            icon="fa-solid fa-up-right-and-down-left-from-center"
            className="p-2"
          />
        ),
        name: "status",
      },
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="convert"
            className="p-2"
            icon="fa-solid fa-arrow-up-right-from-square"
          />
        ),
        name: "convert",
      },
    ],
  };
  //submit data
  const submit = (val) => {
    if (status.length != 0) {
      val._id = status[0]._id;
      val.shedule_date = value;
      dispatch(editLeadsStatus(val));
      console.log(val);
    }
  };
  //get data from table
  const getData = (val) => {
    const lead_details = ListLeads.responce.data.filter((a) => {
      if (a._id === val.data._id) {
        return a;
      }
    });
    if (val.action == "edit") {
      dispatch(isEditLeads(true));
      dispatch(Leadsdata(lead_details));
      navigate("/crm/addleads");
    }
    if (val.action == "status") {
      setStatus(lead_details);
      setOpen(true);
    }
    if (val.action === "convert") {
      const data = {
        name: lead_details[0].customer_name,
        email_id: lead_details[0].email_id,
        leads_id: lead_details[0]._id,
      };
      dispatch(edit(true));
      dispatch(userData(data));
      navigate("/crm/viewleads/convert-customer");
    }
    if (val.action === "view") {
      dispatch(leadsData(lead_details));
      navigate("/crm/viewleads/detailView");
    }
    console.log(lead_details);
  };
  //api call
  const apiCall = () => {
    dispatch(
      listLeads(
        "?from_date=" + DateRangeStartDate + "&toDate=" + DateRangeEndDate
      )
    );
  };

  //useEffect
  useEffect(() => {
    apiCall();
    if (editStatus.isSuccess) {
      dispatch(isSuccessStatusLeads());
      handleClose();
      apiCall();
    }
  }, [editStatus]);
  //filter value
  const tablefilter = [
    {
      label: "New",
      value: "New",
    },
    {
      label: "Requirement discussed",
      value: "Requirement discussed",
    },
    {
      label: "Demo Scheduled",
      value: "Demo Scheduled",
    },
    {
      label: "Demo Completed",
      value: "Demo Completed",
    },
    {
      label: "Won",
      value: "Won",
    },
    {
      label: "Lost",
      value: "CUSTOMER ADMIN",
    },
    {
      label: "Decision Pending",
      value: "Decision Pending",
    },
  ];
  const location = [
    { value: "Namakkal", name: "Namakkal" },
    { value: "Chennai", name: "Chennai" },
    { value: "Coimbatore", name: "Coimbatore" },
    { value: "Pondicherry", name: "Pondicherry" },
    { value: "Tirichy", name: "Tirichy" },
    { value: "Selam", name: "Selam" },
    { value: "Nagarkovil", name: "Nagarkovil" },
    { value: "Thanjavur", name: "Thanjavur" },
    { value: "Madurai", name: "Madurai" },
  ];
  //filter table values
  const filtervalue = (e) => {
    console.log(e);
    if (e != "all") {
      setStatusFilter(e);
      dispatch(
        listLeads(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&status=" +
            e +
            (locationFilter ? "&location=" + locationFilter.value : "")
        )
      );
    } else {
      setStatusFilter(null);
      dispatch(
        listLeads(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (locationFilter ? "?location=" + locationFilter.value : "")
        )
      );
    }
  };
  const locationFilterTable = (e) => {
    if (e) {
      setLocationFilter(e);
      dispatch(
        listLeads(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "?location=" +
            e.value +
            (statusFilter ? "&status=" + statusFilter : "")
        )
      );
    } else {
      setLocationFilter(null);
      dispatch(
        listLeads(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (statusFilter ? "?status=" + statusFilter : "")
        )
      );
    }
  };
  const dateRangeFilter = (start, end) => {
    dispatch(
      listLeads(
        "?from_date=" +
          start +
          "&toDate=" +
          end +
          (statusFilter ? "&status=" + statusFilter : "") +
          (locationFilter ? "&location=" + locationFilter.value : "")
      )
    );
  };
  //get date  range filter
  const onEventService = (event, picker) => {
    // console.log(picker.startDate);
  };
  const callbackService = (start, end, lebal) => {
    setDateRangeStartDate(start._d);
    setDateRangeEndDate(end._d);
    dateRangeFilter(start._d, end._d);
  };
  // console.log(statusFilter);

  //filter table data
  const filter = () => {
    if (ListLeads.responce.data) {
      const filterarr = [];

      for (let i = 0; i < ListLeads.responce.data.length; i++) {
        let data = { ...ListLeads.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        filterarr.push(data);
        const createdate = new Date(data.created_at);
        const updatedate = new Date(data.updated_at);
        const sheduleddate = new Date(data.shedule_date);

        const create_date =
          createdate.getDate() +
          "-" +
          monthNames[createdate.getMonth()] +
          "-" +
          createdate.getFullYear();
        data.created_at = create_date;

        const updated_date =
          updatedate.getDate() +
          "-" +
          monthNames[updatedate.getMonth()] +
          "-" +
          updatedate.getFullYear();
        data.updated_at = updated_date;

        const shedule_date =
          sheduleddate.getDate() +
          "-" +
          monthNames[sheduleddate.getMonth()] +
          "-" +
          sheduleddate.getFullYear();

        data.remarks = data.remarks ? data.remarks : "-";
        data.customer_feedback = data.customer_feedback ? data.remarks : "-";
        data.shedule_date = data.shedule_date ? shedule_date : "-";

        // console.log(data);
      }
      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.customer_id);

      filterarr.filter((a) => delete a.lead_updated_by);
      const activefilters = () => {
        return (
          <>
            <div className="search-heder-table " id="dropdown1">
              <div
                className=" px-3 ligh   rounded rounded-pill bg-white"
                style={{
                  paddingBottom: "4px",
                  paddingTop: "9px",
                }}
              >
                <div className="btn-group ">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setLocationFilter(value);
                      locationFilterTable(value);
                    }}
                    value={locationFilter ? locationFilter.value : ""}
                    options={
                      location
                        ? location.map((e) => {
                            return {
                              value: e.value,
                              label: e.name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        classes={{ root: classes.customTextField }}
                        // label="Location"
                        placeholder="location"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const statusfilters = () => {
        console.log(statusFilter);

        return (
          <>
            <div className="search-heder-table " id="dropdown1">
              <div
                className=" px-3 ligh   rounded rounded-pill bg-white"
                style={{
                  paddingBottom: "4px",
                  paddingTop: "9px",
                }}
              >
                <div className="btn-group ">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      filtervalue(a);
                    }}
                    value={statusFilter ? statusFilter : ""}
                    options={tablefilter}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        classes={{ root: classes.customTextField }}
                        // label="Location"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const tablefilterDropDown = [activefilters(), statusfilters()];

      return (
        <MainTable
          tablefilterDropDown={tablefilterDropDown}
          tabledata={filterarr}
          // filter={tablefilter}
          listOfMachine={"LEADS LIST"}
          filtervalue={filtervalue}
          getData={getData}
          Action={Action}
        />
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      const activefilters = () => {
        return (
          <>
            <div className="search-heder-table " id="dropdown1">
              <div
                className=" px-3 ligh   rounded rounded-pill bg-white"
                style={{
                  paddingBottom: "4px",
                  paddingTop: "9px",
                }}
              >
                <div className="btn-group ">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setLocationFilter(value);
                      locationFilterTable(value);
                    }}
                    value={locationFilter ? locationFilter.value : ""}
                    options={
                      location
                        ? location.map((e) => {
                            return {
                              value: e.value,
                              label: e.name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        classes={{ root: classes.customTextField }}
                        // label="Location"
                        placeholder="location"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const statusfilters = () => {
        console.log(statusFilter);

        return (
          <>
            <div className="search-heder-table " id="dropdown1">
              <div
                className=" px-3 ligh   rounded rounded-pill bg-white"
                style={{
                  paddingBottom: "4px",
                  paddingTop: "9px",
                }}
              >
                <div className="btn-group ">
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      filtervalue(a);
                    }}
                    value={statusFilter ? statusFilter : ""}
                    options={tablefilter}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        classes={{ root: classes.customTextField }}
                        // label="Location"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const tablefilterDropDown = [activefilters(), statusfilters()];
      return (
        <MainTable
          tabledata={filterarr}
          tablefilterDropDown={tablefilterDropDown}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };
  //status option
  const Status = [
    { value: "New", label: "New" },
    { value: "Requirement discussed", label: "Requirement discussed" },
    { value: "Demo Scheduled ", label: "Demo Scheduled " },
    { value: "Demo Completed", label: "Demo Completed" },
    { value: "Won", label: "Won" },
    { value: "Lost ", label: "Lost " },
    { value: "Decision Pending", label: "Decision Pending" },
  ];

  //get convert customer
  const customers = ListLeads.responce
    ? ListLeads.responce.data.filter((e) => {
        return e.status === "Converted";
      })
    : [];

  //chart data
  const months = [];

  var monthName = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  var d = new Date();
  d.setDate(1);
  for (let i = 0; i <= 8; i++) {
    months.push(monthName[d.getMonth()].substring(0, 3));
    d.setMonth(d.getMonth() - 1);
  }
  months.reverse();

  const customerChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const leadsChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  //chart heders
  const chartvalue = ["Customers", "Leads"];

  if (ListLeads.responce) {
    //get tea count
    for (let l = 0; l < months.length; l++) {
      // let janCustomer = 0;
      // let febCustomer = 0;
      // let marCustomer = 0;
      // let aprCustomer = 0;
      // let mayCustomer = 0;
      // let junCustomer = 0;
      // let julCustomer = 0;
      // let augCustomer = 0;
      // let sepCustomer = 0;
      // let octCustomer = 0;
      // let novCustomer = 0;
      // let decCustomer = 0;
      // //flavour Tea chart data
      // let janLeads = 0;
      // let febLeads = 0;
      // let marLeads = 0;
      // let aprLeads = 0;
      // let mayLeads = 0;
      // let junLeads = 0;
      // let julLeads = 0;
      // let augLeads = 0;
      // let sepLeads = 0;
      // let octLeads = 0;
      // let novLeads = 0;
      // let decLeads = 0;
      let customer_count = 0;
      let leads_counnt = 0;
      for (let i = 0; i < ListLeads.responce.data.length; i++) {
        const teaMonth = monthName[
          new Date(ListLeads.responce.data[i].created_at).getMonth()
        ].substring(0, 3);
        if (months[l] === teaMonth) {
          leads_counnt = leads_counnt + 1;
        }
        const convertuser = monthName[
          new Date(ListLeads.responce.data[i].updated_at).getMonth()
        ].substring(0, 3);
        if (months[l] === convertuser) {
          if (ListLeads.responce.data[i].status === "Converted") {
            customer_count = customer_count + 1;
          }
        }
      }

      if (months[l] === "Jan") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Feb") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Mar") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Apr") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "May") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Jun") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Jul") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Aug") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Sep") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Oct") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Nov") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
      if (months[l] === "Dec") {
        customerChartData[l] = customer_count;
        leadsChartData[l] = leads_counnt;
      }
    }
  }

  return (
    <div className="comp-change">
      {ListLeads.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}{" "}
      <div className="d-flex">
        <Stack className="mx-4 pt-4 w-100">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
        </Stack>{" "}
        <div className="w-100 px-2 pt-1 d-flex justify-content-end dateRange align-items-center">
          <CalendarMonthIcon className="d-flex  align-items-center " />
          <p className="d-flex px-1 m-0 align-items-center">
            <DateRangePicker
              initialSettings={{
                startDate: oneMonthPreviosDate,
                endDate: new Date(),
                locale: {
                  format: "DD/MM/YYYY",
                },
              }}
              className="date"
              onEvent={onEventService}
              onCallback={callbackService}
            >
              <input type="button" className=" date " />
            </DateRangePicker>
          </p>
        </div>
      </div>
      <div className="w-100 d-flex">
        <div className="col-6 p-3 leads-cart" style={{ height: "390px" }}>
          <Paper className="d-flex border-radias-14 h-100">
            <div className="col-6 align-items-center justify-content-center d-flex flex-column">
              <div className="d-flex justify-content-center">
                <FontAwesomeIcon
                  icon="fa-solid fa-users"
                  className="p-3 font-size-130"
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                TOTAL CUSTOMER
              </div>
              <div className="d-flex justify-content-center font-size-50 align-items-center">
                {ListLeads.responce ? customers.length : 0}
              </div>
            </div>
            <div className="col-6 p-3 d-flex align-items-center justify-content-center flex-column">
              <Paper
                className="d-flex w-100 my-2 border-radias-14"
                style={{
                  backgroundColor: "#ef854b",
                  color: "white",
                }}
              >
                <div className="mx-3 p-4 font-size-30">
                  <FontAwesomeIcon icon="fa-solid fa-user-plus" />
                </div>
                <div className="d-flex justify-content-center flex-column w-100">
                  <span className="font-size-20">New Coustomer</span>
                  <div className="font-size-24 px-4">
                    {ListLeads.responce
                      ? customers.filter((e) => {
                          var date = new Date();
                          date.setDate(date.getDate() - 3);
                          return new Date(e.updated_at) > date;
                        }).length
                      : 0}
                  </div>
                </div>
              </Paper>
              <Paper
                className="d-flex w-100 my-2 border-radias-14"
                style={{
                  backgroundColor: "#a2c52d",
                  color: "white",
                }}
              >
                <div className="mx-3 p-4 font-size-30">
                  <FontAwesomeIcon icon="fa-solid fa-user-minus" />
                </div>
                <div className="d-flex justify-content-center flex-column w-100">
                  <span className="font-size-20">Old Coustomer</span>
                  <div className="font-size-24 px-4">
                    {ListLeads.responce
                      ? customers.filter((e) => {
                          var date = new Date();
                          date.setDate(date.getDate() - 3);
                          return new Date(e.updated_at) < date;
                        }).length
                      : 0}
                  </div>
                </div>
              </Paper>
              <Paper
                className="d-flex w-100 my-2 border-radias-14"
                style={{
                  backgroundColor: "#4ba9f0",
                  color: "white",
                }}
              >
                <div className="mx-3 p-4 font-size-30">
                  <FontAwesomeIcon icon="fa-solid fa-person-chalkboard" />
                </div>
                <div className="d-flex justify-content-center flex-column w-100">
                  <span className="font-size-20 px-4">Leads</span>
                  <div className="font-size-24 px-4">
                    {ListLeads.responce ? ListLeads.responce.data.length : 0}
                  </div>
                </div>
              </Paper>
            </div>
          </Paper>
        </div>
        <div className="col-6 p-3" style={{ height: "390px" }}>
          <Paper className="p-3 border-radias-14 h-100">
            <BarChart
              chartData={[customerChartData, leadsChartData]}
              chartvalue={chartvalue}
              color={["#fbd125", "#4fa6ee", "#00cec2"]}
            />
          </Paper>
        </div>
      </div>
      {ListLeads.isLoading ? null : filter()}
      <div>
        <Dialog
          open={open}
          maxWidth="xs"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Change Status"}</DialogTitle>
          <DialogContent>
            <div className="w-100 ">
              <DropdownSelecter
                style={{ width: "100%" }}
                placeholder="Status"
                onBlur={() => {
                  handleBlur({ target: { name: "status" } });
                }}
                setFieldValue={setFieldValue}
                options={Status}
                errors={errors}
                values={values}
                touched={touched}
                vari={() => {
                  console.log();
                }}
                name="status"
              />
            </div>
            <div className="w-100 py-2">
              <TextInput
                // defaultvalue={e.Parametername}
                style={{ width: "100%" }}
                handleChange={handleChange}
                // mapval={mapval}
                readonly={false}
                underline={true}
                placeholder="Remarks"
                // id={e.id}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="remarks"
              />
            </div>
            {values.status != "Demo Scheduled " ? null : (
              <div className="w-100 ">
                <LocalizationProvider
                  className="w-100"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Shedule date"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="w-100"
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
          </DialogContent>
          <DialogActions className="p-3">
            <Button onClick={handleClose} variant="outlined" color="error">
              Close
            </Button>

            <LoadingButton
              variant="outlined"
              // loading={
              //   editUserApi.isLoading || addUserApi.isLoading ? true : false
              // }
              onClick={handleSubmit}
            >
              save
              {/* {edit ? "Submit" : "Save"} */}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default ViewLeads;
