import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sampleData from "../../sampleData/viewDrinks.json";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/css/viewDrink.css";
import { edit, dataedit } from "../../redux/reducer/viewDrinkedit";
import { Paper, Link } from "@material-ui/core";
import {
  deleteCategory,
  listDrink,
} from "../../redux/reducer/drinkMastert/drinkMasterapiService";
import { isSuccess2 } from "../../redux/reducer/drinkMastert/deleteDrinks";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

function ViewDrinks(props) {
  const [tabledata, setTableData] = useState(sampleData);
  const deleteApi = useSelector((state) => state.deleteDrink);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const drinkMasterData = useSelector((state) => state.ListDrinks);
  const getData = (val) => {
    console.log(val);
  };

  const apiCall = async () => {
    await dispatch(listDrink(""));
  };

  //deleteDrink
  const deleteDrink = (data) => {
    dispatch(deleteCategory(data));
    console.log(data);
  };
  if (deleteApi.isSuccess) {
    apiCall();
    dispatch(isSuccess2());
  }

  useEffect(() => {
    apiCall();
  }, []);

  //filter data
  const filter_category = (e) => {
    if (e === "all") {
      dispatch(listDrink(""));
    } else {
      dispatch(listDrink("?category=" + e));
    }
  };

  //brudcrumbs
  const breadcrumbs = [
    <Link
      type="button"
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      DRINK MASTER
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      VIEW DRINK
    </Link>,
  ];
  // const Catagory = [
  //   {
  //     name: " Tea",
  //     catagoryImage: (
  //       <img
  //         className="w-75 h-75"
  //         src={require("../../assets/image/flawaretea.png")}
  //         alt="catogory image"
  //       />
  //     ),
  //     color: "#ef854b",
  //     data: [
  //       {
  //         Parameter: "Tea Powder",
  //         AllowedRange: 13,
  //       },
  //       {
  //         Parameter: "Sugar",
  //         AllowedRange: 60,
  //       },
  //       {
  //         Parameter: "Milk",
  //         AllowedRange: 330,
  //       },
  //       {
  //         Parameter: "Water",
  //         AllowedRange: 330,
  //       },
  //     ],
  //   },
  //   {
  //     name: "Flavour  Tea",
  //     color: "#a2c52d",
  //     catagoryImage: (
  //       <img
  //         className="w-75 h-75"
  //         src={require("../../assets/image/flawaretea.png")}
  //         alt="catogory image"
  //       />
  //     ),
  //     data: [
  //       {
  //         Parameter: "Tea Powder",
  //         AllowedRange: 13,
  //       },
  //       {
  //         Parameter: "Sugar",
  //         AllowedRange: 60,
  //       },
  //       {
  //         Parameter: "Milk",
  //         AllowedRange: 330,
  //       },
  //       {
  //         Parameter: "Water",
  //         AllowedRange: 330,
  //       },
  //       {
  //         Parameter: "Spice",
  //         AllowedRange: 2,
  //       },
  //     ],
  //   },
  //   {
  //     name: " Milk",
  //     color: "#4ba9f0",
  //     catagoryImage: (
  //       <img
  //         className=" w-75 h-75"
  //         src={require("../../assets/image/milk_PNG12751.png")}
  //         alt="catogory image"
  //       />
  //     ),
  //     data: [
  //       {
  //         Parameter: "Milk",
  //         AllowedRange: 60,
  //       },
  //       {
  //         Parameter: "Hot Water",
  //         AllowedRange: 30,
  //       },
  //     ],
  //   },
  // ];
  const emptyPage = () => {
    return (
      <div className="px-3 mb-5 pb-5">
        <div className="row">
          <div className="col-6 p-3">
            <Paper className="d-flex flex-column pb-5 h-100 border-radias-14">
              <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  onClick={() => {
                    navigate("/drinkmaster/adddrink");
                  }}
                  style={{
                    fontSize: "50px",
                    padding: "135px 0px 0 0",
                  }}
                  className=""
                  icon="fa-solid fa-plus"
                />
              </div>
              <div className="d-flex pb-5 justify-content-center" type="button">
                Add Drinks
              </div>
            </Paper>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="comp-change">
      {drinkMasterData.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <div className="mx-4 pt-4 d-flex">
        <Stack className=" w-50 d-flex">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
        </Stack>

        <div className="w-50 d-flex justify-content-end align-items-center">
          <div className="px-4">
            <div className="p-1  ligh  rounded rounded-pill bg-white">
              <div className="btn-group py-2 px-2">
                <select
                  className="form-select oopt py-0"
                  aria-label="Default select example"
                  onChange={(e) => {
                    filter_category(e.target.value);
                    // props.filtervalue(e.target.value);
                    // console.log(e.target.value);
                  }}
                >
                  <option value="all" selected>
                    Filter by
                  </option>
                  <option value="all">all</option>

                  <option className="oopt" value={"Tea"}>
                    Tea
                  </option>
                  <option className="oopt" value={"Milk"}>
                    Milk
                  </option>
                  <option className="oopt" value={"Flavour Tea"}>
                    Flavour tea
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/drinkmaster/adddrink");
            }}
            className="d-flex justify-content-end align-items-center"
            type="button"
          >
            <div className="pb-1">Add Drink</div>
            <div className="px-2">
              <FontAwesomeIcon
                style={{
                  fontSize: "22px",
                }}
                className="p-2"
                icon="fa-solid fa-square-plus"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 mb-5 pb-5">
        <div className="row">
          {drinkMasterData.isLoading
            ? null
            : !drinkMasterData.responce.data
            ? emptyPage()
            : drinkMasterData.responce.data.length < 0
            ? emptyPage()
            : drinkMasterData.responce.data.map((data) => {
                return (
                  <div className="col-6 p-3">
                    <Paper className="d-flex border-radias-14 p-2">
                      <div className="col-5">
                        <div
                          style={{ color: data.color }}
                          className="catagory-name text-start px-4"
                        >
                          {data.category}
                        </div>
                        <div
                          className="p-3 drinkImage d-flex align-items-center flex-column justify-content-center"
                          style={{ height: "265px" }}
                        >
                          <img
                            className="w-75 "
                            style={{
                              height: "79%",
                            }}
                            src={data.image}
                            alt="catogory image"
                          />
                          <div className="drinkSubCatogory font-weight-bold">
                            {data.sub_category}
                          </div>
                        </div>
                      </div>
                      <div className="col-7 d-flex flex-column">
                        <div className="drinkviewCardHead pt-2 px-2 pb-1 mt-2 align-items-center w-100 justify-content-end d-flex">
                          <div
                            className="d-flex align-items-center mx-2"
                            onClick={(e) => {
                              deleteDrink(data);
                            }}
                            type="button"
                          >
                            Remove
                            <FontAwesomeIcon
                              style={{
                                fontSize: "20px",
                              }}
                              className="px-2 d-flex "
                              icon=" fa-solid fa-square-xmark"
                            />
                          </div>
                          <div
                            className="d-flex align-items-center px-2"
                            type="button"
                          >
                            Edit
                            <FontAwesomeIcon
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                dispatch(edit(true));
                                dispatch(dataedit(data));
                                navigate("/drinkmaster/adddrink");
                              }}
                              className="px-2 d-flex align-items-center"
                              icon="fa-solid fa-pen-to-square"
                            />
                          </div>
                          {/* <div className="d-flex align-items-center px-2">
                            Add
                            <FontAwesomeIcon
                              style={{
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                navigate("/drinkmaster/addspices");
                              }}
                              className="px-2 d-flex align-items-center"
                              icon="fa-solid fa-square-plus"
                            />
                          </div> */}
                        </div>
                        <div className="viewdrinktable">
                          <table className=" w-100 mb-4 h-auto view-tb mt-2">
                            <thead className="viewDrinkHeadTable">
                              <tr>
                                <th className="text-center w-50">Parameter</th>
                                <th className="text-center">Value</th>
                              </tr>
                            </thead>
                            <tbody className="drinkbody">
                              {data.configuration.map((e, key) => {
                                return (
                                  <tr>
                                    <td className="text-center">
                                      {e.Parametername}
                                    </td>
                                    <td className="text-center">{e.value}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Paper>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}

export default ViewDrinks;
