import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const viewRequestDetailDetails = createSlice({
  name: "viewRequestDetail",
  initialState: {
    edit: false,
    data: {},
    pagePurpose: "",
  },
  reducers: {
    viewRequestDetailData: (state, payload) => {
      state.data = payload.payload;
      state.edit = true;
    },

    clearviewRequestDetailData: (state, payload) => {
      state.data = {};
      state.edit = false;
    },
  },
});

export const {
  viewRequestDetailData,
  clearviewRequestDetailData,
  pagePurpose,
} = viewRequestDetailDetails.actions;
// export const selectCompany = (state) => statetCompany.login;
export default viewRequestDetailDetails.reducer;
