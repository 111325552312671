import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listInventory } from "./inventoryapiService";

import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const SUCCESE = async (data) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const ListInventory = createSlice({
  name: "ListInventory",
  initialState: {
    isLoading: false,
    responce: "",
    isSuccess: false,
    Message: "",
  },
  reducers: {
    isSuccesslistInventory: (state, payload) => {
      state.isSuccess = false;
    },
    clearlistInventory: (state, payload) => {
      state.responce = "";
    },
  },
  extraReducers: {
    [listInventory.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [listInventory.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.responce = payload.data;
      state.Message = payload.data.message;
      state.isSuccess = true;
      //   SUCCESE(payload.data.message);
    },

    [listInventory.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.responce = "";
      state.Message = payload.message;
      FAILED(payload);
      if (payload.message.name === "TokenExpiredError") {
        localStorage.clear();
        window.location.pathname = "/login";
        // Navigateto();
      }
    },
  },
});

export const { clearlistInventory, isSuccesslistInventory } =
  ListInventory.actions;
export default ListInventory.reducer;
