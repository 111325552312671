import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { selectUser } from "../LoginReducer";
const API_URL = "https://dtv29cy9i3.execute-api.ap-south-1.amazonaws.com/dev";
// const API_URL = "http://localhost:8080";
let token = localStorage.getItem("Token");
const entity_id = 1;
// console.log(token);

export const addProduct = createAsyncThunk(
  "AddProduct/addProduct",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/product/add_product", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list product
export const listproduct = createAsyncThunk(
  "Listproduct/listproduct",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/product/list_product" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//edit product
export const editProduct = createAsyncThunk(
  "EditProduct/editProduct",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/product/edit_product", value, {
        headers: {
          Authorization: `Bearer ${token}`,
          entity_id: entity_id,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//edit product
export const productOrder = createAsyncThunk(
  "ProductOrder/productOrder",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(API_URL + "/product/create_order", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list product detail view
export const listproductDetail = createAsyncThunk(
  "ListproductDetail/listproductDetail",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(
        API_URL + "/product/list/product_history" + value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list inventory
export const listInventory = createAsyncThunk(
  "ListInventory/listInventory",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(
        API_URL + "/product/list_product_inventary" + value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list full inventory
export const listFullInventory = createAsyncThunk(
  "ListFullInventory/listFullInventory",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(
        API_URL + "/product/list_product_inventary" + value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//return product orders
export const returnProduct = createAsyncThunk(
  "ReturnProduct/returnProduct",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        API_URL + "/product/return_product",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
