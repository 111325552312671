import React, { useEffect, useState } from "react";
import "../../assets/css/addUser.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropdownSelecter from "../forms/dropdownSelecter";
import { Autocomplete } from "@mui/material";
import TextInput from "../forms/textInput";
import EmailInput from "../forms/emailInput";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Link } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckboxComp from "../forms/checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import {
  addUser,
  editUser,
} from "../../redux/reducer/UserMaster/userMasterApiService";
import { isSuccessuser } from "../../redux/reducer/UserMaster/AddUserApi";

import { isSuccess } from "../../redux/reducer/UserMaster/editUserApi";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const CompanyDetails = (props) => {
  const dispatch = useDispatch();
  const is_edit = useSelector((state) => state.editCompany);
  const editUserApi = useSelector((state) => state.editUserApi);
  const addUserApi = useSelector((state) => state.addUser);
  const [edit, setedit] = useState(is_edit.edit ? true : false);
  const [sameBillingAddress, setSameAddress] = useState(false);
  const navigate = useNavigate();

  //validations
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("User name is required"),
    companyemail: Yup.string().email().required("email is id required"),
    group_id: Yup.string().required("Group_id is required"),
    primary_contact_no: Yup.string()
      .matches(new RegExp("[0-9]{10}"), "Mobile number is not valid")
      .required("primary contact number is required"),
    type: Yup.string().required("type  is required"),
    gst_no: Yup.string().required("gst number  is required"),
    pan_no: Yup.string().required("pan number  is required"),
    billing_address_1: Yup.string().required("billing address  is required"),
    billing_city: Yup.string().required("billing city  is required"),
    billing_state: Yup.string().required("billing state  is required"),
    billing_pincode: Yup.string().required("billing Pin Code  is required"),
    delivery_address_1: Yup.string().required("delivery address is required"),
    delivery_city: Yup.string().required("deliver city  is required"),
    delivery_state: Yup.string().required("deliver state  is required"),
    delivery_pincode: Yup.string().required("deliver pin code  is required"),
    secondary_contact_no: Yup.string()
      .matches(new RegExp("[0-9]{10}"), "Mobile number is not valid")
      .max(10),
  });
  const formik = useFormik({
    initialValues: {
      companyName: is_edit.edit ? is_edit.data.company_name : "",
      group_id: is_edit.edit ? is_edit.data.group_id : "",
      type: is_edit.edit ? is_edit.data.type : "",
      gst_no: is_edit.edit ? is_edit.data.gst_no : "",
      companyemail: is_edit.edit ? is_edit.data.email_id : "",
      primary_contact_no: is_edit.edit ? is_edit.data.primary_contact_no : "",
      secondary_contact_no: is_edit.edit
        ? is_edit.data.secondary_contact_no
        : "",
      pan_no: is_edit.edit ? is_edit.data.pan_no : "",
      billing_address_1: is_edit.edit ? is_edit.data.billing_address_1 : "",
      billing_address_2: is_edit.edit ? is_edit.data.billing_address_2 : "",
      billing_city: is_edit.edit ? is_edit.data.billing_city : "",
      billing_state: is_edit.edit ? is_edit.data.billing_state : "",
      billing_pincode: is_edit.edit ? is_edit.data.billing_pincode : "",
      delivery_address_1: is_edit.edit ? is_edit.data.delivery_address_1 : "",
      delivery_address_2: is_edit.edit ? is_edit.data.delivery_address_2 : "",
      delivery_city: is_edit.edit ? is_edit.data.delivery_city : "",
      delivery_state: is_edit.edit ? is_edit.data.delivery_state : "",
      delivery_pincode: is_edit.edit ? is_edit.data.delivery_pincode : "",
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
    onReset: (data) => {
      console.log(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleReset,
    setTouched,
  } = formik;

  useEffect(() => {
    //navigate view company
    if (editUserApi.isSuccess) {
      resetForm();
      dispatch(isSuccess(false));
      setedit(false);
      if (!values.stay_here) {
        navigate("/usermaster/viewcompany");
      }
    }
    if (addUserApi.isSuccess) {
      resetForm();
      dispatch(isSuccessuser(false));
      if (!values.stay_here) {
        navigate("/usermaster/viewcompany");
      }
    }
  }, [editUserApi, addUserApi]);

  //   console.log(values);
  //submit data
  const submit = (data) => {
    if (!is_edit.edit) {
      const {
        companyName,
        group_id,
        type,
        gst_no,
        companyemail,
        primary_contact_no,
        secondary_contact_no,
        pan_no,
        billing_address_1,
        billing_address_2,
        billing_city,
        billing_state,
        billing_pincode,
        delivery_address_1,
        delivery_address_2,
        delivery_city,
        delivery_state,
        delivery_pincode,
      } = data;
      const submitdata = {
        iscompany: true,
        company_detail: {
          group_id: group_id,
          type: type,
          company_name: companyName,
          email_id: companyemail,
          primary_contact_no: primary_contact_no,
          secondary_contact_no: secondary_contact_no,
          gst_no: gst_no,
          pan_no: pan_no,
          billing_address_1: billing_address_1,
          billing_address_2: billing_address_2,
          billing_city: billing_city,
          billing_state: billing_state,
          billing_pincode: billing_pincode,
          delivery_address_1: delivery_address_1,
          delivery_address_2: delivery_address_2,
          delivery_city: delivery_city,
          delivery_state: delivery_state,
          delivery_pincode: delivery_pincode,
        },
      };
      dispatch(addUser(submitdata));
      console.log(submitdata);
    }
    if (is_edit.edit) {
      const {
        companyName,
        group_id,
        type,
        gst_no,
        companyemail,
        primary_contact_no,
        secondary_contact_no,
        pan_no,
        billing_address_1,
        billing_address_2,
        billing_city,
        billing_state,
        billing_pincode,
        delivery_address_1,
        delivery_address_2,
        delivery_city,
        delivery_state,
        delivery_pincode,
      } = data;
      const submitdata = {
        _id: is_edit.data._id,
        iscompany: true,
        company_detail: {
          group_id: group_id,
          type: type,
          company_name: companyName,
          email_id: companyemail,
          primary_contact_no: primary_contact_no,
          secondary_contact_no: secondary_contact_no,
          gst_no: gst_no,
          pan_no: pan_no,
          billing_address_1: billing_address_1,
          billing_address_2: billing_address_2,
          billing_city: billing_city,
          billing_state: billing_state,
          billing_pincode: billing_pincode,
          delivery_address_1: delivery_address_1,
          delivery_address_2: delivery_address_2,
          delivery_city: delivery_city,
          delivery_state: delivery_state,
          delivery_pincode: delivery_pincode,
        },
      };
      dispatch(editUser(submitdata));
    }
    console.log(data);
  };
  const type = [
    { value: "SALES", label: "SALES" },
    { value: "CUSTOMER", label: "CUSTOMER" },
    { value: " SEARVICE ", label: " SEARVICE " },
  ];
  const city = [
    { value: "Namakkal", label: "Namakkal" },
    { value: "Chennai", label: "Chennai" },
    { value: " Coimbatore ", label: " Coimbatore " },
    { value: "Pondicherry", label: "Pondicherry" },
    { value: "Tirichy", label: "Tirichy" },
    { value: " Selam ", label: " Selam " },
    { value: "Nagarkovil", label: "Nagarkovil" },
    { value: "Thanjavur", label: "Thanjavur" },
    { value: "Madurai", label: "Madurai" },
  ];
  const state = [
    { value: "Tamil Nadu", label: "Tamil Nadu " },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: " Maharashtra ", label: " Maharashtra " },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: " Arunachal Pradesh ", label: " Arunachal Pradesh " },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Kerala", label: "Kerala" },
    { value: " Tripura ", label: " Tripura " },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  ];
  //company details sending

  // props.submit = (editDrink) => {
  //   console.log("sbmiting");
  //   // handleSubmit();
  // };
  //delivery address same filling
  const ChangeDeliveryAddress = (e) => {
    return (
      <div className="row pt-1 px-4">
        <div className="col-4 py-3">
          <div className="d-flex flex-column">
            <TextField
              style={{ width: "90%" }}
              className="bg-white w-100 grey formTextInput p-2"
              defaultValue={values.billing_address_1}
              type="text"
              values={values.billing_address_1}
              inputProps={{ readOnly: true }}
              onBlur={handleBlur}
              onChange={handleChange}
              label={"Delivery Address 1"}
              variant="standard"
              name="delivery_address_1"
            />
          </div>
        </div>
        <div className="col-4 py-3 ">
          <div className="d-flex flex-column">
            <TextField
              style={{ width: "90%" }}
              className="bg-white w-100 grey formTextInput p-2"
              defaultValue={values.billing_address_2}
              inputProps={{ readOnly: true }}
              type="text"
              values={values.billing_address_2}
              onBlur={handleBlur}
              onChange={handleChange}
              label={"Delivery Address 2"}
              variant="standard"
              name="delivery_address_2"
            />
          </div>
        </div>
        <div className="col-4 py-3">
          <div className="" style={{ width: "90%" }}>
            <Autocomplete
              // value={values.billing_city}
              options={city}
              defaultValue={{
                value: values.billing_city,
                label: values.billing_city,
              }}
              readOnly
              renderInput={(params) => (
                <TextField
                  className="bg-white w-100 grey formTextInput p-2"
                  name={"delivery_city"}
                  {...params}
                  label={"Delivery City"}
                  variant="standard"
                />
              )}
              onChange={(e, value) => {
                const a = value ? value.value : "";
                // vari(a);

                setFieldValue("delivery_city", a);
              }}
            />
          </div>
        </div>
        <div className="col-4 py-3">
          <div className="" style={{ width: "90%" }}>
            <Autocomplete
              // value={values.billing_city}
              options={city}
              defaultValue={{
                value: values.billing_state,
                label: values.billing_state,
              }}
              readOnly
              renderInput={(params) => (
                <TextField
                  className="bg-white w-100 grey formTextInput p-2"
                  name={"delivery_state"}
                  {...params}
                  label={"Delivery State"}
                  variant="standard"
                />
              )}
              onChange={(e, value) => {
                const a = value ? value.value : "";
                // vari(a);

                setFieldValue("delivery_state", a);
              }}
            />
          </div>
        </div>
        <div className="col-4 py-3">
          <div className="d-flex flex-column">
            <TextField
              style={{ width: "90%" }}
              className="bg-white w-100 grey formTextInput p-2"
              defaultValue={values.billing_pincode}
              inputProps={{ readOnly: true }}
              type="text"
              values={values.billing_pincode}
              onBlur={handleBlur}
              onChange={handleChange}
              label={"Billing Pincode"}
              variant="standard"
              name="billing_pincode"
            />
          </div>
        </div>
      </div>
    );
  };

  //brudcrumps
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      type="button"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      CRM
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      {edit ? "EDIT" : "ADD"} COMPANY
    </Link>,
  ];

  return (
    <div className="comp-change ">
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form p-4 " style={{ marginBottom: "100px" }}>
        <Paper className="bg-white machineForm">
          <div className="font-weight-bold grey w-100 pb-2 font-20 ">
            Company Details
          </div>
          <div className="row pt-1 px-4">
            <div className="col-4 ">
              <TextInput
                defaultvalue={is_edit.edit ? is_edit.data.company_name : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Company Name"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="companyName"
              />
            </div>
            <div className="col-4 ">
              <EmailInput
                defaultvalue={is_edit.edit ? is_edit.data.email_id : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Company email id"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="companyemail"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={is_edit.edit ? is_edit.data.group_id : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Group id"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="group_id"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="User type"
                onBlur={() => {
                  handleBlur({ target: { name: "type" } });
                }}
                setFieldValue={setFieldValue}
                vari={() => {
                  console.log();
                }}
                options={type}
                errors={errors}
                values={values}
                touched={touched}
                name="type"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  is_edit.edit ? is_edit.data.primary_contact_no : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="primary contact no"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="primary_contact_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  is_edit.edit ? is_edit.data.secondary_contact_no : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="secondary contact no"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="secondary_contact_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={is_edit.edit ? is_edit.data.gst_no : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Gst No"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="gst_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={is_edit.edit ? is_edit.data.pan_no : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Pan No"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="pan_no"
              />
            </div>
          </div>
          <div className="font-weight-bold grey py-2 font-20 ">
            Billing Address
          </div>
          <div className="row pt-1 px-4">
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  is_edit.edit ? is_edit.data.billing_address_1 : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Billing Address 1"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="billing_address_1"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  is_edit.edit ? is_edit.data.billing_address_2 : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Billing Address 2"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="billing_address_2"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Biling City"
                onBlur={() => {
                  handleBlur({ target: { name: "billing_city" } });
                }}
                setFieldValue={setFieldValue}
                options={city}
                errors={errors}
                values={values}
                touched={touched}
                vari={() => {
                  console.log();
                }}
                name="billing_city"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Biling State"
                onBlur={() => {
                  handleBlur({ target: { name: "billing_state" } });
                }}
                setFieldValue={setFieldValue}
                options={state}
                errors={errors}
                vari={() => {
                  console.log();
                }}
                values={values}
                touched={touched}
                name="billing_state"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={is_edit.edit ? is_edit.data.billing_pincode : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Billing Pincode"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="billing_pincode"
              />
            </div>
          </div>
          <div className="d-flex w-100">
            <div className="font-weight-bold w-100 grey py-2 font-20 ">
              Delivery Address
            </div>
            <div className="d-flex w-100 justify-content-end font-weight-bold">
              <FormGroup
                onChange={(e) => {
                  // ChangeDeliveryAddress(e);
                  console.log(e.target.checked);
                  if (e.target.checked) {
                    const gh = {};
                    setFieldValue(
                      "delivery_address_1",
                      values.billing_address_1
                    );
                    setFieldValue(
                      "delivery_address_2",
                      values.billing_address_2
                    );
                    setFieldValue("delivery_city", values.billing_city);
                    setFieldValue("delivery_state", values.billing_state);
                    setFieldValue("delivery_pincode", values.billing_pincode);
                  }
                  if (!e.target.checked) {
                    setFieldValue("delivery_address_1", "");
                    setFieldValue("delivery_address_2", "");
                    setFieldValue("delivery_city", "");
                    setFieldValue("delivery_state", "");
                    setFieldValue("delivery_pincode", "");
                  }

                  setSameAddress(!sameBillingAddress);
                  // console.log(e.target);
                }}
              >
                <FormControlLabel
                  label="Billing address is same"
                  labelPlacement="start"
                  className="d-flex font-size-12  justify-content-center"
                  control={<Checkbox defaultChecked={false} />}
                />
              </FormGroup>
            </div>
          </div>

          {sameBillingAddress ? (
            ChangeDeliveryAddress()
          ) : (
            <div className="row pt-1 px-4">
              <div className="col-4 ">
                <TextInput
                  defaultvalue={
                    is_edit.edit ? is_edit.data.delivery_address_1 : ""
                  }
                  style={{ width: "90%" }}
                  handleChange={handleChange}
                  placeholder="Delivery Address"
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="delivery_address_1"
                />
              </div>
              <div className="col-4 ">
                <TextInput
                  defaultvalue={
                    is_edit.edit ? is_edit.data.delivery_address_2 : ""
                  }
                  style={{ width: "90%" }}
                  handleChange={handleChange}
                  placeholder="Delivery Address 2"
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="delivery_address_2"
                />
              </div>
              <div className="col-4 ">
                <DropdownSelecter
                  style={{ width: "90%" }}
                  placeholder="Delivery City"
                  onBlur={() => {
                    handleBlur({ target: { name: "delivery_city" } });
                  }}
                  setFieldValue={setFieldValue}
                  options={city}
                  errors={errors}
                  values={values}
                  touched={touched}
                  vari={() => {
                    console.log();
                  }}
                  name="delivery_city"
                />
              </div>
              <div className="col-4 ">
                <DropdownSelecter
                  style={{ width: "90%" }}
                  placeholder="Delivery State"
                  onBlur={() => {
                    handleBlur({ target: { name: "delivery_state" } });
                  }}
                  setFieldValue={setFieldValue}
                  options={state}
                  errors={errors}
                  vari={() => {
                    console.log();
                  }}
                  values={values}
                  touched={touched}
                  name="delivery_state"
                />
              </div>
              <div className="col-4 ">
                <TextInput
                  defaultvalue={
                    is_edit.edit ? is_edit.data.delivery_pincode : ""
                  }
                  style={{ width: "90%" }}
                  handleChange={handleChange}
                  placeholder="Delivery Pincode"
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="delivery_pincode"
                />
              </div>
            </div>
          )}
          <div className="row pt-1 px-4">
            <div className="col-4 ">
              <CheckboxComp
                style={{ width: "90%" }}
                label={"Stay here"}
                defaultChecked={false}
                // handleChange={(e)=>{
                //   handleChange({target:{name:"is_ads_allowed",}})
                // }}
                // onBlur={() => {
                //   handleBlur({ target: { name: "is_ads_allowed" } });
                // }}

                setFieldValue={setFieldValue}
                name="stay_here"
              />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end p-4 px-5 ">
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  is_edit.edit
                    ? navigate("/usermaster/viewcompany")
                    : resetForm();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="outlined"
                loading={
                  editUserApi.isLoading || addUserApi.isLoading ? true : false
                }
                onClick={handleSubmit}
              >
                {is_edit.edit ? "Submit" : "Save"}
              </LoadingButton>
            </Stack>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default CompanyDetails;
