import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import Router from "./route";
import { ToastContainer } from "react-toastify";
library.add(fas, faTwitter, faFontAwesome);

function App() {
  return (
    <>
      <Router />
      <ToastContainer />
    </>
  );
}

export default App;
