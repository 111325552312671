import React, { useState, useRef, useEffect } from "react";
import "../assets/css/mainTable.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../insideComponents/pagination";
import SearchIcon from "@mui/icons-material/Search";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import * as FileSaver from "file-saver";
import { Paper } from "@material-ui/core";
import * as XLSX from "xlsx";
const MainTable = (props) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [Active, setActive] = useState("all");
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [curentPage, setCurentPage] = useState(1);
  const [datasPerPage, setDataPerPage] = useState(10);

  //useEffect
  useEffect(() => {
    setTableData(props.tabledata);
    // console.log(props);
  }, []);

  const filterActive = tableData.filter((data) => {
    if (data.is_active || data.Active) {
      if (Active == "Active" || Active === "In Active") {
        return data.is_active === Active;
      }
    }
    if (
      Active == "ADMIN" ||
      Active === "CUSTOMER SUPPORT MANAGER" ||
      Active == "CUSTOMER SUPPORT TEAM" ||
      Active === "CUSTOMER ADMIN" ||
      Active == "DISTRIBUTOR"
    ) {
      console.log(data.role);
      if (data.role) {
        return data.role === Active;
      }
    }
    if (Active == "CUSTOMER" || Active === "SALES" || Active == "SEARVICE") {
      console.log(data.role);
      if (data.type) {
        return data.type === Active;
      }
    }
    if (Active == "Allowed" || Active === "notAllowed") {
      // console.log(data.role);
      if (data.is_ads_allowed) {
        return data.is_ads_allowed === Active;
      }
    }
    return data;
  });
  //get active users
  const getAciveUser = tableData.filter((data) => {
    if (data.is_active || data.Active) {
      if (data.Active) {
        return data.Active === true;
      }

      return data.is_active === "Active";
    }
    return data;
  });

  // get curent pagination

  const lastPage = curentPage * datasPerPage;
  const Pagefirst = lastPage - datasPerPage;
  const curentpage = filterActive.slice(Pagefirst, lastPage);

  //change page
  const pagePerData = (number) => setDataPerPage(number);
  const nextPage = (pageNumber) => setCurentPage(curentPage + pageNumber);
  const prevPage = (pageNumber) => setCurentPage(curentPage - pageNumber);
  const firstPage = (pageNumber) => setCurentPage(pageNumber);
  const lastPageNumber = (pageNumber) => setCurentPage(pageNumber);

  const heder =
    tableData.length != 0
      ? curentpage[0]
        ? Object.keys(curentpage[0])
        : null
      : null;

  let editheder;
  if (heder) {
    editheder = [...heder];
  }
  if (!heder) {
    editheder = [];
  }

  const heder1 = [];
  for (let j = 0; j < editheder.length; j++) {
    const words = editheder[j].split("_");
    const val1 = words
      .map((word) => {
        if (typeof word[0] !== "undefined") {
          return word[0].toUpperCase() + word.substring(1);
        }
      })
      .join(" ");

    heder1.push(val1);
  }
  // console.log(heder1);
  //final heders
  let finalHeder;
  if (heder1) {
    finalHeder = heder1;
  }
  if (!heder1) {
    finalHeder = heder;
  }
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(filterActive);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, props.listOfMachine + fileExtension);
  };

  //filter active
  // console.log(props.tablefilterDropDown);
  //view machine filter
  if (window.location.pathname === "/machinemaster/viewmachine") {
    // const indexvalue = heder.indexOf("Allacated");
    // heder.filter((a) => {
    //   console.log(a);
    //   if (a != "Allacated") {
    //     return a;
    //   }
    // });
    // console.log(heder);
    // heder.filter((a) => delete a.Allacated);
  }
  return (
    <>
      <div className=" w-100 table-ma">
        <div className="d-flex align-items-center w-100 darkgrey  px-4 ">
          {/* <div> */}
          <div className=" w-100 d-flex justify-content-start  align-items-center">
            <h5 className="overal-tea mx-0 m-0 fw-bolder py-2 ">
              {props.listOfMachine}
            </h5>
            {/* </div> */}
            {/* <div> */}
          </div>
        </div>
        <div className="d-flex align-items-center w-100 darkgrey  px-4 ">
          <div className=" w-50 d-flex justify-content-start  align-items-center">
            <div className="search-heder-table ">
              <div className="p-1  ligh  rounded rounded-pill bg-white">
                <div className="input-group-table d-flex">
                  <input
                    style={{
                      textTransform: "lowercase",
                    }}
                    type="search"
                    placeholder={"Search " + props.listOfMachine}
                    // aria-describedby="button-addon1"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    className="form-control text-black border-0 rounded rounded-pill "
                  />
                  <div className="input-group-append ">
                    <button
                      id="button-addon1"
                      type="submit"
                      className="btn btn-link  search-icon"
                      onClick={(e) => {
                        setSearchInput(search);
                      }}
                    >
                      <SearchIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className=" w-100 py-2 d-flex justify-content-end">
            {/* download table to excell */}

            <div className="d-flex px-2">
              {props.tablefilterDropDown ? props.tablefilterDropDown : ""}
            </div>

            {props.filter ? (
              <div className="mx-2">
                <div className="p-1 mx-2 ligh  rounded rounded-pill bg-white">
                  <div className="btn-group py-2 px-2">
                    <select
                      className="form-select oopt py-0"
                      aria-label="Default select example"
                      style={{
                        opacity: "0.6",
                      }}
                      onChange={(e) => {
                        // setActive(e.target.value);
                        props.filtervalue(e.target.value);
                        // console.log(e.target.value);
                      }}
                    >
                      <option value="all" selected>
                        Filter by
                      </option>
                      <option value="all">all</option>
                      {!props.filter ? (
                        <div>
                          <option className="oopt" value={true}>
                            {t("MainTables.active")}
                          </option>
                          <option className="oopt" value={false}>
                            {t("MainTables.inActive")}
                          </option>
                        </div>
                      ) : (
                        props.filter.map((data, key) => {
                          return (
                            <option
                              key={key}
                              className="oopt"
                              value={data.value}
                            >
                              {data.name}
                            </option>
                          );
                        })
                      )}
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className="d-flex px-1"
              type="button"
              onClick={() => {
                exportToCSV();
              }}
            >
              {/* <ReactHTMLTableToExcel
                // id="test-table-xls-button"
                className="export-excel  "
                // table="table-to-xls"
                // filename="Machine data"
                sheet="Boatload"
                buttonText="Export to XLS"
              /> */}
              <div className="d-flex pt-1 align-items-center px-2 export-excel">
                Export to XLS
              </div>
              <FontAwesomeIcon
                className={
                  props.tablefilterDropDown
                    ? "d-flex pt-3 align-items-center"
                    : "d-flex pt-1 align-items-center"
                }
                icon="fa-solid fa-file-excel "
              />
            </div>
          </div>
        </div>

        {/* table data listing */}

        <div className="w-100 py-3 px-4">
          {/* <div class="table-responsive  "> */}
          <Paper
            className="border-radias-14 tablesdatascroll"
            // style={{
            //   overflowX: "scroll",
            // }}
          >
            <table id="table-to-xls" class=" table1 w-100 ">
              <thead className="hedtable">
                <tr>
                  {props.tabledata[0].no_data ? "" : <th>S.NO</th>}

                  {curentpage.length === 0
                    ? null
                    : finalHeder.map((data, key) => {
                        if (data != " Id") {
                          if (data === "Company Id") {
                            if (data != "Company Id") {
                              return (
                                <>
                                  <th key={key}>{data}</th>
                                </>
                              );
                            }
                          } else {
                            return (
                              <>
                                <th key={key}>{data}</th>
                              </>
                            );
                          }
                        }
                      })}

                  {props.Action.Action ? (
                    <th> {Object.keys(props.Action)} </th>
                  ) : null}
                </tr>
              </thead>
              {props.tabledata[0].no_data ? (
                ""
              ) : (
                <tbody className="tbody">
                  {curentpage.length === 0
                    ? null
                    : curentpage

                        .filter((item) => {
                          return Object.values(item)
                            .join("")
                            .toLowerCase()
                            .includes(search.toLowerCase());
                        })
                        .map((data, key) => {
                          const keys1 = Object.keys(data);
                          // console.log(keys1);
                          const keys2 = [];
                          // console.log(keys1);
                          const key3 = keys1.filter((a) => {
                            if (a != "_id") {
                              if (a === "company_id") {
                                if (a != "company_id") {
                                  return a;
                                }
                              } else {
                                return a;
                              }
                            }
                          });
                          // console.log(key3);
                          //view machine allacted machine condtion bassed icon
                          if (
                            window.location.pathname ===
                            "/machinemaster/viewmachine"
                          ) {
                            const index = key3.indexOf("Allacated");
                            if (index > -1) {
                              key3.splice(index, 1);
                            }
                            // console.log(heder);
                            // heder.filter((a) => delete a.Allacated);
                          }

                          for (let i = 0; i < key3.length; i++) {
                            if (key3[i] != "Active") {
                              keys2.push(<td>{data[key3[i]]}</td>);
                            }
                          }
                          let active = JSON.stringify(data.Active);

                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              {keys2}

                              {!active ? null : (
                                <td className="d-flex justify-content-center">
                                  <div className="d-flex flex-row px-2 justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-circle"
                                      className={
                                        data.Active === true
                                          ? "textgreen"
                                          : "text-danger"
                                      }
                                    />
                                  </div>
                                  {data.Active ? "Active" : "Inactive"}
                                </td>
                              )}
                              {props.Action.Action ? (
                                <td className="action darkgrey">
                                  <div className="d-flex justify-content-center">
                                    {props.Action.Action
                                      ? props.Action.Action.map(
                                          (item, index) => {
                                            // console.log(data);
                                            const getData = {
                                              data: data,
                                              action: item.name,
                                            };

                                            //view machine

                                            return (
                                              <div
                                                key={index}
                                                onClick={(e) => {
                                                  props.getData(getData);
                                                }}
                                                className="d-flex"
                                              >
                                                {item.icon}
                                              </div>
                                            );
                                          }
                                        )
                                      : null}
                                  </div>
                                </td>
                              ) : null}
                            </tr>
                          );
                        })}
                </tbody>
              )}
            </table>
          </Paper>
          {/* </div> */}
          <Pagination
            curentPage={curentPage}
            totalPage={datasPerPage}
            totalData={filterActive.length}
            nextPage={nextPage}
            prevPage={prevPage}
            lastPageNumber={lastPageNumber}
            firstPage={firstPage}
            pagePerData={pagePerData}
          />
        </div>
      </div>
    </>
  );
};

export default MainTable;
