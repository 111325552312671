import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addLeads } from "./leadsApiService";

import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const SUCCESE = async (data) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const AddLeads = createSlice({
  name: "AddLeads",
  initialState: {
    isLoading: false,
    responce: "",
    isSuccess: false,
    Message: "",
  },
  reducers: {
    isSuccessAddLeads: (state, payload) => {
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [addLeads.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [addLeads.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.responce = payload.data;
      state.Message = payload.data.message;
      state.isSuccess = true;
      SUCCESE(payload.data.message);
    },

    [addLeads.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.Message = payload;
      FAILED(payload);
      if (payload.message.name === "TokenExpiredError") {
        localStorage.clear();
        window.location.pathname = "/login";
        // Navigateto();
      }
    },
  },
});

export const { isSuccessAddLeads, errorMessage } = AddLeads.actions;
export default AddLeads.reducer;
