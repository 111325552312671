import React, { useEffect, useState } from "react";
import MainTable from "../../insideComponents/mainTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserTable from "../../sampleData/sampleDataViewUser.json";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Link,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import {
  changeUserStatus,
  listUser,
} from "../../redux/reducer/UserMaster/userMasterApiService";
import { edit, userData } from "../../redux/reducer/UserMaster/editUser";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { clearUserStatus } from "../../redux/reducer/UserMaster/changeStatus";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Autocomplete } from "@mui/material";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "2",
    },
  },
});
function ViewUser(props) {
  const [tabledata, setTableData] = useState(UserTable);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchedUserData = useSelector((state) => state.listUser);
  const statusChangeApi = useSelector((state) => state.changeUserStatus);
  const classes = useStyles();
  const [tabledata1, setTableData1] = useState(
    fetchedUserData.responce.data ? fetchedUserData.responce.data : []
  );
  let oneMonthPreviosDate = new Date();
  oneMonthPreviosDate.setMonth(oneMonthPreviosDate.getMonth() - 3);

  const [DateRangeStartDate, setDateRangeStartDate] =
    useState(oneMonthPreviosDate);
  const [DateRangeEndDate, setDateRangeEndDate] = useState(new Date());
  const [rolefilter, setRole] = useState(null);
  const [activeUser, setActiveUser] = useState(null);
  const [companyNameFilter, setCompanyFilter] = useState(null);
  const [groupIdFilter, setgroupIdFilter] = useState(null);

  //api call
  const apiCall = () => {
    dispatch(
      listUser(
        "?from_date=" + DateRangeStartDate + "&toDate=" + DateRangeEndDate
      )
    );
  };
  // console.log(fetchedUserData.responce.data.userData);
  useEffect(() => {
    apiCall();
    if (statusChangeApi.isSuccess) {
      dispatch(clearUserStatus());
    }
  }, [statusChangeApi]);
  const getData = (val) => {
    console.log(val);
    if (val.action === "edit") {
      const company = fetchedUserData.responce.data.companyData.filter(
        (data) => {
          return data._id === val.data.company_id;
        }
      );
      const user = fetchedUserData.responce.data.userData.filter((data) => {
        return data._id === val.data._id;
      });
      console.log(user);
      dispatch(edit(true));
      dispatch(userData({ user: user, company: company }));
      navigate("/usermaster/adduser");
    }
    // console.log(val);
  };
  //filter table value
  const filtervalue = (val) => {
    console.log(val);
    setRole(val);
    if (val === "all") {
      setRole(null);
      dispatch(
        listUser(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (activeUser ? "&is_active=" + activeUser : "") +
            (companyNameFilter ? "&company_id=" + companyNameFilter._id : "") +
            (groupIdFilter ? "&group_id=" + groupIdFilter.value : "")
        )
      );
    } else {
      dispatch(
        listUser(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&role=" +
            val +
            (activeUser ? "&is_active=" + activeUser : "") +
            (companyNameFilter ? "&company_id=" + companyNameFilter._id : "") +
            (groupIdFilter ? "&group_id=" + groupIdFilter.value : "")
        )
      );
    }
  };
  const activeFilter = (val) => {
    if (val != "all") {
      setActiveUser(val);
      const value = val === "Active" ? true : false;
      dispatch(
        listUser(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&is_active=" +
            value +
            (companyNameFilter ? "&company_id=" + companyNameFilter._id : "") +
            (groupIdFilter ? "&group_id=" + groupIdFilter.value : "")
        )
      );
    } else {
      setActiveUser(null);
      dispatch(
        listUser(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (rolefilter ? "&role=" + rolefilter : "") +
            (companyNameFilter ? "&company_id=" + companyNameFilter._id : "") +
            (groupIdFilter ? "&group_id=" + groupIdFilter.value : "")
        )
      );
    }
  };
  const dateRangeFilter = (start, end) => {
    dispatch(
      listUser(
        "?from_date=" +
          start +
          "&toDate=" +
          end +
          (rolefilter ? "&role=" + rolefilter : "") +
          (activeUser ? "&is_active=" + activeUser : "") +
          (companyNameFilter ? "&company_id=" + companyNameFilter._id : "") +
          (groupIdFilter ? "&group_id=" + groupIdFilter.value : "")
      )
    );
  };
  const company_filter_api = (e) => {
    if (!e) {
      dispatch(
        listUser(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (rolefilter ? "&role=" + rolefilter : "") +
            (activeUser ? "&is_active=" + activeUser : "") +
            (groupIdFilter ? "&group_id=" + groupIdFilter.value : "")
        )
      );
    }
    if (e) {
      dispatch(
        listUser(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&company_id=" +
            e._id +
            (rolefilter ? "&role=" + rolefilter : "") +
            (activeUser ? "&is_active=" + activeUser : "") +
            (groupIdFilter ? "&group_id=" + groupIdFilter.value : "")
        )
      );
    }
  };
  const group_idFilter = (e) => {
    if (e) {
      dispatch(
        listUser(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            ("&group_id=" + e.value) +
            (companyNameFilter ? "&company_id=" + companyNameFilter._id : "") +
            (rolefilter ? "&role=" + rolefilter : "") +
            (activeUser ? "&is_active=" + activeUser : "")
        )
      );
    } else {
      dispatch(
        listUser(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (rolefilter ? "&role=" + rolefilter : "") +
            (activeUser ? "&is_active=" + activeUser : "") +
            (companyNameFilter ? "&company_id=" + companyNameFilter._id : "")
        )
      );
    }
  };
  //get date  range filter
  const onEventService = (event, picker) => {
    // console.log(picker.startDate);
  };
  const callbackService = (start, end, lebal) => {
    setDateRangeStartDate(start._d);
    setDateRangeEndDate(end._d);
    dateRangeFilter(start._d, end._d);
  };
  const Action = {
    Action: [
      // {
      //   icon: <FontAwesomeIcon className="p-2" icon="fa-solid fa-eye" />,
      //   name: "view",
      // },
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="edit"
            className="p-2"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
    ],
  };
  const ve = (e) => {
    console.log(e);
  };
  const changeStatus = (val) => {
    const submit = {
      _id: val.id,
      is_active: val.checked,
    };
    dispatch(changeUserStatus(submit));
    // console.log(submit);
  };

  //filter table data
  const filter = () => {
    if (fetchedUserData.responce.data) {
      const filterarr = [];
      for (let i = 0; i < fetchedUserData.responce.data.userData.length; i++) {
        let data = { ...fetchedUserData.responce.data.userData[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const date = new Date(data.create_at);
        const logindate = new Date(data.last_login_at);
        const active_user = data.is_active;
        delete data.is_active;
        const create_date =
          date.getDate() +
          "-" +
          monthNames[date.getMonth()] +
          "-" +
          date.getFullYear();
        const last_login_date =
          logindate.getDate() +
          "-" +
          monthNames[logindate.getMonth()] +
          "-" +
          logindate.getFullYear();
        data.create_at ? (data.create_at = create_date) : console.log("sdfdsf");
        data.last_login_at
          ? (data.last_login_at = last_login_date)
          : console.log("sdfdsf");
        data.is_active = (
          <FormGroup
            id={data._id}
            name={data.name}
            className="d-flex justify-content-center"
          >
            <FormControlLabel
              id={data._id}
              name={data.name}
              className="d-flex justify-content-center"
              control={
                <Checkbox
                  onChange={(e) => {
                    changeStatus(e.target);
                    // console.log(e.target.checked);
                  }}
                  color="primary"
                  id={data._id}
                  name={data._id}
                  defaultChecked={active_user}
                />
              }
            />
          </FormGroup>
        );
        // data.is_ads_allowed = "jsd";
        let keyValues = Object.entries(data);

        data.company_id
          ? keyValues.splice(5, 0, ["company_name", data.company.company_name])
          : keyValues.splice(5, 0, ["company_name", "-"]);
        keyValues.splice(6, 0, ["role", data.role]);
        let newObj = Object.fromEntries(keyValues);

        filterarr.push(newObj);
        // console.log(data);
      }
      filterarr.filter((a) => delete a.updated_at);
      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.status);
      filterarr.filter((a) => delete a.company);

      // filterarr.filter((a) => delete a.company_id);
      const companyNamefilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={companyNameFilter ? companyNameFilter.value : ""}
                    className=""
                    options={
                      fetchedUserData.responce.data.companyData.length != 0
                        ? fetchedUserData.responce.data.companyData.map((e) => {
                            return {
                              _id: e._id,
                              value: e.company_name,
                              label: e.company_name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setCompanyFilter(value);
                      company_filter_api(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="Company Name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const groupIdfilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={groupIdFilter ? groupIdFilter.value : ""}
                    className=""
                    options={
                      fetchedUserData.responce.data.companyData.length != 0
                        ? fetchedUserData.responce.data.companyData.map((e) => {
                            return {
                              _id: e._id,
                              value: e.group_id,
                              label: e.group_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setgroupIdFilter(value);
                      group_idFilter(value);
                      // console.log(value);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="Group Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const activefilters = () => {
        console.log(activeUser);
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={activeUser ? activeUser : ""}
                    className=""
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "InACtive", label: "In Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";
                      activeFilter(a);

                      console.log(a);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const rolefilters = () => {
        console.log(activeUser);
        return (
          <>
            <div
              className="search-heder-table "
              style={{
                marginRight: "48px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={rolefilter ? rolefilter : ""}
                    className=""
                    options={[
                      {
                        label: "Admin",
                        value: "ADMIN",
                      },
                      {
                        label: "Customer Suport Manager",
                        value: "CUSTOMER SUPPORT MANAGER",
                      },
                      {
                        label: "Customer Suport Team",
                        value: "CUSTOMER SUPPORT TEAM",
                      },
                      {
                        label: "Customer Admin",
                        value: "CUSTOMER ADMIN",
                      },
                      {
                        label: "Distributer",
                        value: "DISTRIBUTOR",
                      },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";
                      filtervalue(a);

                      // console.log(a);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="Role"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      const tablefilterDropDown = [
        companyNamefilters(),
        groupIdfilters(),
        activefilters(),
        rolefilters(),
      ];
      //filter value
      const filter = [];
      return (
        <MainTable
          tabledata={filterarr}
          // filter={filter}
          tablefilterDropDown={tablefilterDropDown}
          listOfMachine={" USER LIST "}
          getData={getData}
          filtervalue={filtervalue}
          Action={Action}
        />
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      const companyNamefilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={companyNameFilter ? companyNameFilter.value : ""}
                    className=""
                    options={
                      !fetchedUserData.responce
                        ? ""
                        : fetchedUserData.responce.data.companyData.length != 0
                        ? fetchedUserData.responce.data.companyData.map((e) => {
                            return {
                              _id: e._id,
                              value: e.company_name,
                              label: e.company_name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setCompanyFilter(value);
                      company_filter_api(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="Company Name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const groupIdfilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={groupIdFilter ? groupIdFilter.value : ""}
                    className=""
                    options={
                      !fetchedUserData.responce
                        ? ""
                        : fetchedUserData.responce.data.companyData.length != 0
                        ? fetchedUserData.responce.data.companyData.map((e) => {
                            return {
                              _id: e._id,
                              value: e.group_id,
                              label: e.group_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setgroupIdFilter(value);
                      group_idFilter(value);
                      // console.log(value);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="Group Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const activefilters = () => {
        console.log(activeUser);
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={activeUser ? activeUser : ""}
                    className=""
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "InACtive", label: "In Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";
                      activeFilter(a);

                      console.log(a);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const rolefilters = () => {
        console.log(activeUser);
        return (
          <>
            <div
              className="search-heder-table "
              style={{
                marginRight: "48px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={rolefilter ? rolefilter : ""}
                    className=""
                    options={[
                      {
                        label: "Admin",
                        value: "ADMIN",
                      },
                      {
                        label: "Customer Suport Manager",
                        value: "CUSTOMER SUPPORT MANAGER",
                      },
                      {
                        label: "Customer Suport Team",
                        value: "CUSTOMER SUPPORT TEAM",
                      },
                      {
                        label: "Customer Admin",
                        value: "CUSTOMER ADMIN",
                      },
                      {
                        label: "Distributer",
                        value: "DISTRIBUTOR",
                      },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";
                      filtervalue(a);

                      // console.log(a);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="Role"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      const tablefilterDropDown = [
        companyNamefilters(),
        groupIdfilters(),
        activefilters(),
        rolefilters(),
      ];
      return (
        <MainTable
          tabledata={filterarr}
          tablefilterDropDown={tablefilterDropDown}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };
  //brudcrumps
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      type="button"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      USER MASTER
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      VIEW USER
    </Link>,
  ];
  return (
    <div className="comp-change">
      {fetchedUserData.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}{" "}
      <div className="d-flex">
        <Stack className="mx-4 pt-4 w-100 ">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
        </Stack>
        <div className="w-100 px-2 pt-1 d-flex justify-content-end dateRange align-items-center">
          <CalendarMonthIcon className="d-flex  align-items-center " />
          <p className="d-flex px-1 m-0 align-items-center">
            <DateRangePicker
              initialSettings={{
                startDate: oneMonthPreviosDate,
                endDate: new Date(),
                locale: {
                  format: "DD/MM/YYYY",
                },
              }}
              className="date"
              onEvent={onEventService}
              onCallback={callbackService}
            >
              <input type="button" className=" date " />
            </DateRangePicker>
          </p>
        </div>
      </div>
      {fetchedUserData.isLoading ? null : filter()}
    </div>
  );
}

export default ViewUser;
