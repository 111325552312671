import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const Viewdrinkedit = createSlice({
  name: "Viewdrinkedit",
  initialState: {
    edit: false,
    data: {},
  },
  reducers: {
    edit: (state, payload) => {
      state.edit = payload.payload;
    },
    editDrink: (state, payload) => {
      state.edit = payload.payload;
    },
    dataedit: (state, payload) => {
      //   console.log(payload.payload.action);
      state.data = payload.payload;
    },
  },
});

export const { edit, editDrink, dataedit } = Viewdrinkedit.actions;
// export const selectUser = (state) => state.user.login;
export default Viewdrinkedit.reducer;
