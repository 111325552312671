import React, { useState } from "react";
import Dashboard from "./pages/dashbord";
import AddDrinks from "./pages/addDrinks";
import AddLeads from "./pages/addLeads";
import AddMachine from "./pages/addMachine";
import AddSpices from "./pages/addSpices";
import AddUser from "./pages/addUser";
import Inventory from "./pages/Inventory";
import MachineSetting from "./pages/MachineSetting";
import MapMachineAndUser from "./pages/MapUserAndMachine";
import Notification from "./pages/notification";
import RaiseRequest from "./pages/RaiseRequest";
import ViewDrinks from "./pages/viewDrinks";
import viewSpices from "./pages/viewSpices";
import ViewLeads from "./pages/viewLeads";
import ViewMachine from "./pages/viewMachine";
import ViewMachineUsage from "./pages/viewMachineUsage";
import ViewRequest from "./pages/viewRequest";
import ViewUser from "./pages/viewUser";
import Sidenavbar from "../layout/sidenavbar";
import MainHeder from "../layout/mainHeder";
import MachineMaster from "./pages/machineMaster";
import ViewmachineDetails from "./pages/viewMachinedetails";
import { Route, Routes, Outlet } from "react-router-dom";
import ViewRawMeterial from "./pages/viewrawMeterialUsage";
import AddCompany from "./pages/addCompany";
import ViewCompany from "./pages/viewCompany";
import FullViewCompany from "./pages/fullViewCompany";
import MappedMachineCompany from "./pages/viewMappedMachineCompany";
import CompanyMachineFullView from "./pages/viewCompanyMachineFullDetails";
import CompanyUserEdit from "./pages/companyEditUser";
import CompanyEditMachine from "./pages/companyEditMachine";
import MachineSettingInduvidual from "./pages/machine_setting_induvidulmission";
import MachineUsage from "./pages/machineUsage";
import MapedDrinks from "../insideComponents/mapedDrinks";
import ConvertCustomer from "./pages/leadsTocustomerConvert";
import LeadsDetailsView from "./pages/leadsDetailsView";
import DetailViewRequest from "./pages/detailViewRequest";
import AddProduct from "./pages/addProduct";
import ViewProduct from "./pages/viewProduct";
import ProductOrder from "./pages/productOrder";
import ViewProfile from "./pages/viewProfile";
function ComponentPage(props) {
  return (
    <>
      <div className="h-100 w-100 d-flex  p-0 whole ">
        <Sidenavbar className=" " />
        <div className="w-100  compon">
          <MainHeder className="" />

          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/user_profile" element={<ViewProfile />} />

            <Route path="/" element={<Dashboard />} />
            <Route path="/drinkmaster/adddrink" element={<AddDrinks />} />
            <Route
              path="/crm/viewleads/convert-customer"
              element={<ConvertCustomer />}
            />
            <Route
              path="/crm/viewleads/detailView"
              element={<LeadsDetailsView />}
            />

            <Route
              path="/usermaster/Mapmachine/maped_drinks"
              element={<MapedDrinks />}
            />

            <Route path="/drinkmaster/viewdrink" element={<ViewDrinks />} />
            <Route path="/drinkmaster/addspices" element={<AddSpices />} />
            <Route path="/drinkmaster/ViewSpices" element={<viewSpices />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/usermaster/adduser" element={<AddUser />} />
            <Route path="/usermaster/viewUser" element={<ViewUser />} />
            <Route path="/inventory/product_order" element={<ProductOrder />} />
            <Route path="/inventory/addproduct" element={<AddProduct />} />
            <Route path="/inventory/viewproduct" element={<ViewProduct />} />
            <Route path="/inventory/view_inventory" element={<Inventory />} />

            <Route
              path="/usermaster/Mapmachine"
              element={<MapMachineAndUser />}
            />
            <Route path="/machinemaster/addmachine" element={<AddMachine />} />
            <Route
              path="/machinemaster/viewmachine"
              element={<ViewMachine />}
            />
            <Route
              path="/machinemaster/machinesetting"
              element={<MachineSetting />}
            />
            <Route
              path="/machinemaster/machinesetting/individual"
              element={<MachineSettingInduvidual />}
            />
            <Route
              path="/machinemaster/viewmachine/viewmachinedetails"
              element={<ViewmachineDetails />}
            />
            <Route
              path="/usage/viewRawmeterialUsage"
              element={<ViewRawMeterial />}
            />
            <Route
              path="/usage/viewmachineusage"
              element={<ViewMachineUsage />}
            />
            <Route
              path="/usermaster/viewcompany/company_mapped_machine/machineUsage"
              element={<MachineUsage />}
            />
            <Route
              path="/servicerequest/raiseRequest"
              element={<RaiseRequest />}
            />
            <Route
              path="/servicerequest/viewRequest"
              element={<ViewRequest />}
            />
            <Route
              path="/servicerequest/detail_view_request"
              element={<DetailViewRequest />}
            />
            <Route path="/usermaster/addcompany" element={<AddCompany />} />
            <Route path="/usermaster/viewcompany" element={<ViewCompany />} />
            <Route
              path="/usermaster/viewcompany/fullviewcompany"
              element={<FullViewCompany />}
            />
            <Route
              path="/usermaster/viewcompany/company_mapped_machine"
              element={<MappedMachineCompany />}
            />
            <Route
              path="/usermaster/viewcompany/company_mapped_machine/fullview"
              element={<CompanyMachineFullView />}
            />
            <Route
              path="/usermaster/viewcompany/fullview/editCompanyUser"
              element={<CompanyUserEdit />}
            />
            <Route
              path="/usermaster/viewcompany/company_mapped_machine/fullview/editmachine"
              element={<CompanyEditMachine />}
            />
            <Route path="/history" element={<AddMachine />} />
            <Route path="crm/addleads" element={<AddLeads />} />
            <Route path="crm/viewleads" element={<ViewLeads />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default ComponentPage;
