import { Paper, TextField } from "@material-ui/core";
import React from "react";
import "../assets/css/addUser.css";
import TextInput from "../components/forms/textInput";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CancelIcon from "@mui/icons-material/Cancel";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
const PopUp = (props) => {
  const handleClose = () => {
    props.closePopUp(false);
  };
  console.log(props.address);
  return (
    <div className="d-flex aling-items-center  w-100 justify-content-center pop">
      <Dialog
        open={props.popup}
        keepMounted
        className="w-100"
        onClose={(handleClose) => {}}
      >
        <DialogActions className="p-0">
          <CancelIcon className="mx-4 mt-4" onClick={handleClose}></CancelIcon>
        </DialogActions>
        <DialogTitle className="pb-3 p-0 px-5 font-weight-bold">
          {"Company Address"}
        </DialogTitle>

        <DialogContent className="w-100">
          {/* <Paper className=" d-flex flex-column w-100 mt-5 py-3 w-75 justify-content-center align-items-center"> */}
          <div className=" w-100 popup">
            <DialogTitle className="pb-3 p-0 font-weight-bold">
              {"Billing Address"}
            </DialogTitle>
            <div className="row pt-1 px-4  w-100">
              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].billing_address_1}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Billing Address 1"
                  errors={""}
                  values={""}
                  touched={""}
                  handleBlur={""}
                  name="billing_address_1"
                />
              </div>
              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].billing_address_2}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Billing Address 2"
                  errors={""}
                  values={props.address[0]}
                  touched={""}
                  handleBlur={""}
                  name="billing_address_2"
                />
              </div>

              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].billing_city}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Billing City"
                  errors={""}
                  values={props.address[0]}
                  touched={""}
                  handleBlur={""}
                  name="billing_city"
                />
              </div>
              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].billing_state}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Billing State"
                  errors={""}
                  values={props.address[0]}
                  touched={""}
                  handleBlur={""}
                  name="billing_state"
                />
              </div>
              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].billing_pincode}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Billing Pincode"
                  errors={""}
                  values={props.address[0]}
                  touched={""}
                  handleBlur={""}
                  name="billing_pincode"
                />
              </div>
            </div>
            <DialogTitle className="pb-3 p-0 font-weight-bold">
              {"Delivery Address"}
            </DialogTitle>
            <div className="row pt-1 px-4  w-100">
              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].delivery_address_1}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Delivery Address 1"
                  errors={""}
                  values={props.address[0]}
                  touched={""}
                  handleBlur={""}
                  name="delivery_address_1"
                />
              </div>
              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].delivery_address_2}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Delivery Address 2"
                  errors={""}
                  values={props.address[0]}
                  touched={""}
                  handleBlur={""}
                  name="delivery_address_2"
                />
              </div>

              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].delivery_city}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Delivery City"
                  errors={""}
                  values={props.address[0]}
                  touched={""}
                  handleBlur={""}
                  name="delivery_city"
                />
              </div>
              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].delivery_state}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Delivery State"
                  errors={""}
                  values={props.address[0]}
                  touched={""}
                  handleBlur={""}
                  name="delivery_state"
                />
              </div>
              <div className="col-6 ">
                <TextInput
                  defaultvalue={props.address[0].delivery_pincode}
                  style={{ width: "100%" }}
                  readonly={true}
                  handleChange={""}
                  placeholder="Delivery Pincode"
                  errors={""}
                  values={props.address[0]}
                  touched={""}
                  handleBlur={""}
                  name="delivery_pincode"
                />
              </div>
            </div>
          </div>
          {/* </Paper> */}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" className="m-3" onClick={handleClose}>
            Close
          </Button>
          {/* <Button onClick={handleClose}>Agree</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopUp;
