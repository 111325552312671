import React, { useEffect, useState } from "react";
import "../../assets/css/dashboard.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Box from "@mui/material/Box";
import { Card, Grid, Badge } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import Charts from "../../insideComponents/dashbord/charts";
import BarChart from "../../insideComponents/dashbord/charts/barchart";
import ProgressBarChart from "../../insideComponents/dashbord/charts/progresschart";
import PieChart from "../../insideComponents/dashbord/charts/pie-chart";
import RadialBarChart from "../../insideComponents/dashbord/charts/radialBarChart";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import MachineCart from "../../insideComponents/machinemastercards";
import {
  card,
  Issues,
  tabledata,
} from "../../insideComponents/dashbord/dashbordComponents";
import { Paper } from "@material-ui/core";
function Dashbord(props) {
  const [ActiveServiceRequestDateRange, setActiveServiceRequestDateRange] =
    useState();
  const { t } = useTranslation();
  // console.log(value);

  const callbackService = (start, end, lebal) => {
    setActiveServiceRequestDateRange(start, end);
  };
  const onEventService = (event, picker) => {
    console.log(picker.startDate);
  };
  const callbackMachine = (start, end, lebal) => {
    setActiveServiceRequestDateRange(start, end);
  };
  const onEventMachine = (event, picker) => {
    console.log(picker.startDate);
  };
  const callbackOverAllTea = (start, end, lebal) => {
    setActiveServiceRequestDateRange(start, end);
  };
  const onEventOverAllTea = (event, picker) => {
    console.log(picker.startDate);
  };
  // useEffect(()=>{

  // })
  return (
    <>
      <div className="comp-change">
        {/* overall tea statistics */}
        <div>
          <div className="d-flex align-items-center darkgrey  p-3 w-100 row">
            <h5 className="overal-tea fw-bolder py-2 col d-flex justify-content-start">
              {t("dashboard.OverallTeaSatisfication")}
            </h5>

            <div className="col d-flex dateRange justify-content-end">
              <CalendarMonthIcon className="d-flex  justify-content-end " />
              <p className="d-flex px-1">
                <DateRangePicker
                  initialSettings={{
                    startDate: new Date(),
                    endDate: new Date(),
                    locale: {
                      format: "DD/MM/YYYY",
                    },
                  }}
                  className="date"
                  onEvent={onEventOverAllTea}
                  onCallback={callbackOverAllTea}
                >
                  <input type="text" className=" date " />
                </DateRangePicker>
              </p>
            </div>
          </div>
          <div className="align-items-center h-100 position-sticky start-0 end-0 container-fluid row px-1">
            {card.map((data, index) => {
              return (
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                  key={index}
                >
                  <div className="  ">
                    <div className=" align-items-center  py-2  h-100">
                      <Card
                        className={data.bg_color}
                        style={{
                          borderRadius: "15px",
                          height: "100%",
                        }}
                      >
                        <div className="text-white  fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                          {t(data.carsName)}
                        </div>
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={data.startIcon}
                            className="icon-border-right py-3 text-white"
                            style={{
                              fontSize: 40,
                              marginLeft: "20px",
                              paddingBottom: "10px",
                            }}
                          />
                          <div className="d-flex mx-2 flex-column">
                            <div className="d-flex px-3">
                              <h1 className="fw-bolder text-white">
                                {data.NumberOfMachine}
                              </h1>
                              <FontAwesomeIcon
                                icon="fa-solid fa-circle-up "
                                className="text-white  up-arrow"
                              />
                            </div>
                            <p className="text-white company-text">
                              * {t(data.content, { count: "123" })}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* machine master */}
        <div className=" pt-2 position-sticky start-0 end-0 row w-100">
          <div className="d-flex  align-items-center  darkgrey  p-3 w-100 row">
            <h5 className="overal-tea col fw-bolder  px-2 py-2 d-flex justify-content-start">
              {t("dashboard.machineMaster")}
            </h5>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 h-100">
            <Grid spacing={2} container className="align-items-center   px-1">
              <Grid
                item
                className="align-items-center py-3 col-sm-12 col-md-12 col-lg-6 grid h-100"
              >
                <Card
                  style={{ borderRadius: "15px", height: "100%" }}
                  className="px-10"
                >
                  <div className="darkgrey fw-bolder  pt-4 pb-2 d-flex align-items-center justify-content-center">
                    {t("dashboard.activeMachine")}
                  </div>
                  <div className="d-flex align-items-center active-machines">
                    <CheckCircleIcon
                      className="text-succe icon-size cancel_ico"
                      htmlColor="#a1c335"
                    />
                    <img
                      src={require("../../assets/image/coffee-machine.png")}
                      className="icon-border-cofee grey"
                    />
                    <div className="d-flex mx-2 flex-column">
                      <div className="d-flex px-3">
                        <h1 className="fw-bolder textlightblue">12,600</h1>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-up "
                          className="grey up-arrow"
                        />
                      </div>
                      <p className="grey company-text1">
                        *{t("dashboard.newlyAddedMachine", { count: "" })}{" "}
                      </p>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid
                item
                className="align-items-center py-3 col-sm-12 col-md-12 col-lg-6 grid h-100"
              >
                <Card style={{ borderRadius: "15px", height: "100%" }}>
                  <div className="darkgrey fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                    {t("dashboard.defectMachine")}
                  </div>
                  <div className="d-flex align-items-center active-machines">
                    <CancelIcon className="text-danger cancel_ico" />
                    <img
                      src={require("../../assets/image/coffee-machine.png")}
                      className="icon-border-cofee grey"
                    />
                    <div className="d-flex mx-2 flex-column">
                      <div className="d-flex px-3">
                        <h1 className="fw-bolder text-danger">120</h1>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-down "
                          className="grey up-arrow"
                        />
                      </div>
                      <p className="grey company-text1 md">
                        * {t("dashboard.newlyDefectedMachine", { count: "10" })}
                      </p>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
          <div className=" col-sm-12 col-md-12 col-lg-6 h-100">
            <Grid spacing={2} container className="align-items-center px-1">
              {Issues.map((data, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    className="align-items-center col-sm-6 col-md-3 col-lg-3 py-3 grid h-100"
                  >
                    <Card style={{ borderRadius: "15px", height: "100%" }}>
                      <div className="d-flex p-3  flex-column justify-content-center align-items-center">
                        <div className="isueIcon">{data.icon}</div>
                        <p className="fw-bolder1 grey m-0">{t(data.issue)}</p>
                        <h1 className="MasignIssues text-danger ">
                          {data.value}
                        </h1>
                      </div>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
        {/* sample checking */}
        {/* <MachineCart /> */}
        <div className="row position-sticky start-0 end-0 pt-2 activerequest w-100">
          <div className="col-md-12 col-lg-6">
            <div className="d-flex align-items-center darkgrey pb-3  pt-4 px-2  w-100 ">
              <h5 className="overal-tea fw-bolder m-0 d-flex justify-content-start">
                {t("dashboard.ActiveServiceRequest")}
              </h5>

              <div className=" px-2 pt-1 d-flex justify-content-center dateRange align-items-center">
                <CalendarMonthIcon className="d-flex  align-items-center " />
                <p className="d-flex px-1 m-0 align-items-center">
                  <DateRangePicker
                    initialSettings={{
                      startDate: "01/01/2020",
                      endDate: "01/15/2020",
                      locale: {
                        format: "DD/MM/YYYY",
                      },
                    }}
                    className="date"
                    onEvent={onEventService}
                    onCallback={callbackService}
                  >
                    <input type="text" className=" date " />
                  </DateRangePicker>
                </p>
              </div>
            </div>
            <Paper
              className="table-responsive p-3 bg-white"
              style={{
                marginLeft: "5px",
                borderRadius: "15px",
                width: "97%",
              }}
            >
              <div className="w-100 d-flex bor align-content-center  p-2">
                <div className="tableHeder">
                  <p
                    className="darkgrey pb-2 fw-bolder"
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    {t("dashboard.highPriorty")}
                  </p>
                  <div className="d-flex flex-row justify-content-center dashtbh align-items-center">
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle"
                      className="text-danger"
                    />
                    <p
                      className="fw-bolder darkgrey fs-3 px-2"
                      style={{ margin: 0, padding: 0 }}
                    >
                      2,540
                    </p>
                  </div>
                </div>
                <div className="tableHeder">
                  <p
                    className="darkgrey pb-2 fw-bolder"
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    {t("dashboard.mediumPriority")}
                  </p>
                  <div className="d-flex flex-row justify-content-center dashtbh align-items-center">
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle"
                      className="textyellow"
                    />
                    <p
                      className="fw-bolder darkgrey fs-3 px-2"
                      style={{ margin: 0, padding: 0 }}
                    >
                      2,540
                    </p>
                  </div>
                </div>
                <div className="tableHeder">
                  <p
                    className="darkgrey pb-2 fw-bolder"
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    {t("dashboard.lowPriority")}
                  </p>
                  <div className="d-flex flex-row justify-content-center dashtbh align-items-center">
                    <FontAwesomeIcon
                      icon="fa-solid  fa-circle"
                      className="textgreen"
                    />
                    <p
                      className="fw-bolder darkgrey fs-3 px-2"
                      style={{ margin: 0, padding: 0 }}
                    >
                      2,540
                    </p>
                  </div>
                </div>
              </div>

              <table className="table ">
                <tbody className="grey text-center ">
                  {tabledata.map((data, index) => {
                    return (
                      <tr key={index} className=" table-condensed">
                        <td>{data.id}</td>
                        <td>
                          <div className="table-text">{t(data.issue)}</div>
                        </td>
                        <td>{data.date}</td>
                        <td>{data.name}</td>
                        <td>
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <FontAwesomeIcon
                              icon="fa-solid fa-circle"
                              className="text-danger"
                            />
                            <p
                              className="px-2"
                              style={{ margin: 0, padding: 0 }}
                            >
                              Active
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="d-flex grey table-condensed justify-content-end align-items-center">
                <u className="p-2 ">{t("dashboard.highPriorityLowerCase")}</u>
                <FontAwesomeIcon icon="fa-solid fa-bars" className="p-2" />
                <u className="p-2">{t("dashboard.All")}</u>
                <FontAwesomeIcon
                  className="p-2"
                  icon="fa-solid fa-location-dot"
                />
              </div>
            </Paper>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="d-flex align-items-center pb-3 darkgrey pt-4 px-3 w-100 ">
              <h5 className="overal-tea fw-bolder m-0  d-flex justify-content-start">
                {t("dashboard.MachineUsageSatistics")}
              </h5>

              <div className=" px-2 pt-1 dateRange d-flex justify-content-center align-items-center">
                <CalendarMonthIcon className="d-flex  align-items-center" />
                <p className="d-flex px-1 m-0 align-items-center">
                  <DateRangePicker
                    initialSettings={{
                      startDate: "01/01/2020",
                      endDate: "01/15/2020",
                      locale: {
                        format: "DD/MM/YYYY",
                      },
                    }}
                    className="date"
                    onEvent={onEventMachine}
                    onCallback={callbackMachine}
                  >
                    <input type="text" className=" date " />
                  </DateRangePicker>
                </p>
              </div>
            </div>
            {/* charts */}
            <Paper
              className=" table-responsive chart p-3 bg-white"
              style={{
                marginLeft: "15px",
                borderRadius: "15px",
                width: "97%",
                height: "85%",
              }}
            >
              {/* <Charts /> */}
              <BarChart />
            </Paper>
          </div>
          <div className="row ">
            {/* radial bar chart */}
            <div className="col-6 w-50 p-3">
              <Paper
                className=" table-responsive mt-3 chart p-3 bg-white"
                style={{
                  marginLeft: "15px",
                  borderRadius: "15px",
                  width: "97%",
                  height: "100%",
                }}
              >
                <RadialBarChart />
              </Paper>
            </div>
            <div className="col-6 p-3">
              {/* barchart */}
              <Paper
                className=" table-responsive  mt-3 chart  bg-white"
                style={{
                  marginLeft: "  24px",
                  borderRadius: "15px",
                  width: "97%",
                  height: "100%",
                }}
              >
                <BarChart />
              </Paper>
            </div>
            <div className="col-6">
              {/* progresschart */}
              <Paper
                className=" table-responsive mt-3 chart p-3 bg-white"
                style={{
                  marginLeft: "15px",
                  borderRadius: "15px",
                  width: "97%",
                  height: "100%",
                }}
              >
                <ProgressBarChart />
              </Paper>
            </div>
            <div className="col-6">
              {/* Pie Chart */}
              <Paper
                className=" table-responsive mt-3 chart p-3 bg-white"
                style={{
                  marginLeft: "15px",
                  borderRadius: "15px",
                  width: "97%",
                  height: "100%",
                }}
              >
                <PieChart />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashbord;
