import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import {
  listFullMachine,
  listMachine,
  listMapedMachine,
  updatemachineStatus,
} from "../../redux/reducer/machineMaster/addMachineApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Autocomplete, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import { Paper, Link } from "@material-ui/core";
import MainTable from "../../insideComponents/mainTable";
import {
  listCompany,
  machineMapping,
  editMappedMachine,
  listFullCompany,
} from "../../redux/reducer/UserMaster/userMasterApiService";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { isSuccesmap } from "../../redux/reducer/UserMaster/mapMachine";
import MultiDropDown from "../forms/multiDropDown";
import CheckboxComp from "../forms/checkbox";
import { isSuccess1 } from "../../redux/reducer/UserMaster/editmapedMachine";
import {
  paths,
  gensettingdata,
} from "../../redux/reducer/machineMaster/machineSetting";
import { mapedDrinksdata } from "../../redux/reducer/drinkMastert/mapedDrinks";
import {
  listDrink,
  listMapedDrinks,
} from "../../redux/reducer/drinkMastert/drinkMasterapiService";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "2",
    },
  },
});

function MapMachineAndUser(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchedTableData = useSelector((state) => state.mapedMachines);
  const mapedMachineApi = useSelector((state) => state.machineMapping);
  const updateMapedMachineApi = useSelector((state) => state.editMapedMachine);
  const changeMachineStatus = useSelector((state) => state.changeMachineStatus);
  const listFullCompanyapi = useSelector((state) => state.listFullCompany);
  const ListFullMachine = useSelector((state) => state.listFullMachines);
  const companyList = useSelector((state) => state.listCompanyApi);
  const listMachineData = useSelector((state) => state.ListMachine);
  const [edit, setEdit] = useState(false);
  const [editdata, setEditData] = useState([]);
  const [open, setOpen] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [active_machines, setActiveMachines] = useState("all");
  const [deviceId, setDeviceId] = useState(null);
  const [curentStatus, setCurentStatus] = useState(null);
  const classes = useStyles();
  console.log(active_machines);
  // form validation

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name is required"),
    // machine_name: Yup.string().required("Machine name required"),
  });

  const formik = useFormik({
    initialValues: {
      company_name: "",
      device_id: [],
      is_active: true,
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;

  //submit data
  const submit = (data) => {
    if (!edit) {
      const Company_id = companyList.responce.data.filter((data1) => {
        let id;
        if (data1.company_name === data.company_name) {
          id = data1._id;
          return id;
        }
      });
      let id = [];
      for (let i = 0; i < data.device_id.length; i++) {
        id.push(data.device_id[i].value);
      }
      const submit = {
        company_id: Company_id[0]._id,
        machine_id: id,
        is_active: data.is_active,
      };
      console.log(submit);
      dispatch(machineMapping(submit));
    }
    if (edit) {
      console.log(data);
      const Company_id = companyList.responce.data.filter((data1) => {
        let id;
        if (data1.company_name === data.company_name) {
          id = data1._id;
          return id;
        }
      });
      let id = [];
      for (let i = 0; i < data.device_id.length; i++) {
        id.push(data.device_id[i].id);
      }
      const submit = {
        _id: editdata.data._id,
        company_id: Company_id[0]._id,
        machine_id: data.device_id.id,
        // is_active: data.is_active,
      };
      // console.log(hello);
      dispatch(editMappedMachine(submit));
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  //api call
  const apiCall = () => {
    dispatch(listMapedMachine(""));
    dispatch(listCompany(""));
    dispatch(listFullMachine(""));
    dispatch(listMachine("/non_maped"));
    // dispatch(listMachine(""));

    dispatch(listFullCompany(""));
  };

  useEffect(() => {
    apiCall();
    if (mapedMachineApi.isSuccess) {
      handleClose();
      dispatch(isSuccesmap());
      resetForm();
    }
    if (updateMapedMachineApi.isSuccess) {
      setEdit(false);
      dispatch(isSuccess1());
      resetForm();
    }
  }, [mapedMachineApi, updateMapedMachineApi, changeMachineStatus]);

  // filter maped machine tables
  const companybased_filters = (e) => {
    console.log(e);
    if (e) {
      dispatch(
        listMapedMachine(
          "?company_id=" +
            e._id +
            (deviceId ? "&machine_id=" + deviceId._id : "") +
            (active_machines === "all"
              ? ""
              : active_machines === "Active"
              ? "&is_active=true"
              : "&is_active=false") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
    if (!e) {
      dispatch(
        listMapedMachine(
          deviceId
            ? "?machine_id=" + deviceId._id
            : "" +
                (active_machines === "all"
                  ? ""
                  : active_machines === "Active"
                  ? deviceId
                    ? "&is_active=true"
                    : "?is_active=true"
                  : deviceId
                  ? "&is_active=false"
                  : "?is_active=false") +
                (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
  };
  //filter device id bassed filters
  const device_id_filters = (e) => {
    if (e) {
      dispatch(
        listMapedMachine(
          "?machine_id=" +
            e._id +
            (companyName ? "&company_id=" + companyName._id : "") +
            (active_machines === "all"
              ? ""
              : active_machines === "Active"
              ? "&is_active=true"
              : "&is_active=false") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
    if (!e) {
      dispatch(
        listMapedMachine(
          companyName
            ? "?company_id=" + companyName._id
            : "" +
                (active_machines === "all"
                  ? ""
                  : active_machines === "Active"
                  ? companyName
                    ? "&is_active=true"
                    : "?is_active=true"
                  : companyName
                  ? "&is_active=false"
                  : "?is_active=false") +
                (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
  };
  const curent_status_filters = (e) => {
    if (e) {
      setCurentStatus(e);
      dispatch(
        listMapedMachine(
          "?current_status=" +
            e +
            (companyName ? "&company_id=" + companyName._id : "") +
            (active_machines === "all"
              ? ""
              : active_machines === "Active"
              ? "&is_active=true"
              : "&is_active=false") +
            (companyName
              ? deviceId
                ? "&machine_id=" + deviceId._id
                : ""
              : deviceId
              ? "machine_id=" + deviceId._id
              : "")
        )
      );
    }
    if (!e) {
      setCurentStatus(null);
      dispatch(
        listMapedMachine(
          companyName
            ? "?company_id=" + companyName._id
            : "" +
                (active_machines === "all"
                  ? ""
                  : active_machines === "Active"
                  ? companyName
                    ? "&is_active=true"
                    : "?is_active=true"
                  : companyName
                  ? "&is_active=false"
                  : "?is_active=false") +
                (companyName
                  ? deviceId
                    ? "&machine_id=" + deviceId._id
                    : ""
                  : deviceId
                  ? "machine_id=" + deviceId._id
                  : "")
        )
      );
    }
  };
  //filter active machines
  const active_machine_filters = (e) => {
    if (e === "all") {
      dispatch(
        listMapedMachine(
          "?" +
            (companyName ? "company_id=" + companyName._id : "") +
            (companyName
              ? deviceId
                ? "&machine_id=" + deviceId._id
                : ""
              : deviceId
              ? "machine_id=" + deviceId._id
              : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
    if (e === "Active") {
      dispatch(
        listMapedMachine(
          "?is_active=true" +
            (companyName ? "&company_id=" + companyName._id : "") +
            (deviceId ? "&machine_id=" + deviceId._id : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
    if (e === "In Active") {
      dispatch(
        listMapedMachine(
          "?is_active=false" +
            (companyName ? "&company_id=" + companyName._id : "") +
            (deviceId ? "&machine_id=" + deviceId._id : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
  };
  // options
  const companyListOption = !companyList.isSuccess
    ? [{ value: "", label: "No Company Found" }]
    : companyList.responce.data.map((data, key) => {
        return {
          key: key,
          id: data._id,
          value: data.company_name,
          label: data.company_name,
        };
      });
  const listMachineoption = !listMachineData.isSuccess
    ? [{ value: "", label: "No Machine Found" }]
    : listMachineData.responce.data.map((data, key) => {
        return {
          key: key,
          id: data._id,
          value: data._id,
          label: data.device_id,
        };
      });
  const listMachineoption1 = !listMachineData.isSuccess
    ? [{ value: "", label: "No Machine Found" }]
    : listMachineData.responce.data.map((data, key) => {
        return {
          key: key,
          id: data._id,
          value: data.device_id,
          label: data.device_id,
        };
      });

  //change status
  const changeStatus = (val) => {
    const submit = {
      _id: val.id,
      is_active: val.checked,
    };
    dispatch(updatemachineStatus(submit));
    // console.log(val.checked);
  };

  //actions
  const Action = {
    Action: [
      {
        icon: (
          <FontAwesomeIcon
            title="edit"
            type="button"
            className="p-2"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
      {
        icon: (
          <FontAwesomeIcon
            title="setting"
            type="button"
            className="p-2"
            icon="fa-solid fa-gear"
          />
        ),
        name: "setting",
      },
      {
        icon: (
          <FontAwesomeIcon
            title="drink setting"
            type="button"
            className="p-2"
            icon="fa-solid fa-mug-hot"
          />
        ),
        name: "drink",
      },
      // {
      //   icon: <FontAwesomeIcon className="p-2" icon="fa-solid fa-trash" />,
      //   name: "delete",
      // },
    ],
  };
  const breadcrumbs = [
    <Link
      type="button"
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      MACHINE MASTER
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      MAP COMPANY & MACHINE
    </Link>,
  ];
  const getData = (value) => {
    console.log(value);
    // value.data.companyName = is_edit.data.company_name;

    // //edit functions
    if (value.action == "edit") {
      setEdit(true);
      setFieldValue("company_name", value.data.company_name);
      setFieldValue("device_id", value.data.device_id);
      setFieldValue();

      setEditData(value);
      console.log(value);
      // dispatch(edit(true));
      // dispatch(data(value));
      // navigate("/usermaster/company_mapped_machine/fullview/editmachine");
    }
    // if (value.action == "view") {
    //   console.log(value);
    //   dispatch(vieaData(value));
    //   navigate("/usermaster/company_mapped_machine/fullview");
    // }
    if (value.action == "setting") {
      const path = {
        path: "mapped_machine",
        second: "VIEW MACHINE",
        third: "MACHINE NAME",
      };
      // data=value.data
      // console.log(data);
      dispatch(gensettingdata(value));
      dispatch(paths(path));

      navigate("/machinemaster/machinesetting/individual");
    }
    if (value.action === "drink") {
      dispatch(listMapedDrinks("?company_machine_id=" + value.data._id));
      dispatch(listDrink(``));

      dispatch(mapedDrinksdata(value.data));
      navigate("/usermaster/Mapmachine/maped_drinks");
    }
  };
  // console.log(edit);
  const AddMachineAndCompany = () => {
    return (
      <div className="d-flex justify-content-center">
        <Dialog
          open={open}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="font-weight-bold">
            {"Company And Machine Mapping"}
          </DialogTitle>
          <DialogContent>
            <div className="w-100">
              <Autocomplete
                options={companyListOption}
                value={values.company_name}
                className="p-2"
                renderInput={(params) => (
                  <TextField
                    error={
                      errors.company_name && touched.company_name ? true : false
                    }
                    style={{
                      width: "100%",
                    }}
                    helperText={
                      errors.company_name && touched.company_name
                        ? errors.company_name
                        : null
                    }
                    {...params}
                    name="company_name"
                    label="Cmpany Name"
                    variant="standard"
                  />
                )}
                onChange={(e, value) => {
                  const a = value ? value.value : "";
                  setFieldValue("company_name", a);
                }}
                onBlur={handleBlur}
              />
            </div>
            <div className="w-100 ">
              <MultiDropDown
                style={{
                  width: "100%",
                }}
                options={listMachineoption}
                onBlur={handleBlur}
                values={values}
                placeholder={"Device Id"}
                errors={errors}
                setFieldValue={setFieldValue}
                name="device_id"
              />
            </div>
            <div className="w-100 px-1">
              <CheckboxComp
                style={{ width: "90%" }}
                label={"Is Active"}
                className="p-2"
                defaultChecked={true}
                // handleChange={(e)=>{
                //   handleChange({target:{name:"is_ads_allowed",}})
                // }}
                // onBlur={() => {
                //   handleBlur({ target: { name: "is_ads_allowed" } });
                // }}

                setFieldValue={setFieldValue}
                name="is_active"
              />
            </div>
          </DialogContent>
          <DialogActions className="m-2">
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={
                mapedMachineApi.isLoading || mapedMachineApi.isLoading
                  ? true
                  : false
              }
              variant="outlined"
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  //edit machine mapping
  const editMachineMapping = () => {
    console.log(values);
    return (
      <div>
        <Dialog
          open={edit}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="font-weight-bold">
            {"Company And Machine Mapping"}
          </DialogTitle>
          <DialogContent>
            <div className="w-100">
              <Autocomplete
                options={companyListOption}
                value={values.company_name}
                defaultValue={{
                  value: "hello",
                  lebel: "hello",
                }}
                className="p-2"
                renderInput={(params) => (
                  <TextField
                    error={
                      errors.company_name && touched.company_name ? true : false
                    }
                    style={{
                      width: "100%",
                    }}
                    helperText={
                      errors.company_name && touched.company_name
                        ? errors.company_name
                        : null
                    }
                    {...params}
                    name="company_name"
                    label="Cmpany Name"
                    variant="standard"
                  />
                )}
                onChange={(e, value) => {
                  const a = value ? value.value : "";
                  setFieldValue("company_name", a);
                }}
                onBlur={handleBlur}
              />
            </div>
            <div className="w-100 ">
              <Autocomplete
                options={listMachineoption1}
                value={values.device_id}
                className="p-2"
                renderInput={(params) => (
                  <TextField
                    error={errors.device_id && touched.device_id ? true : false}
                    style={{
                      width: "100%",
                    }}
                    helperText={
                      errors.device_id && touched.device_id
                        ? errors.device_id
                        : null
                    }
                    {...params}
                    name="device_id"
                    label="Device Id"
                    variant="standard"
                  />
                )}
                onChange={(e, value) => {
                  const a = value ? value : "";
                  setFieldValue("device_id", a);
                }}
                onBlur={handleBlur}
              />
            </div>
            {/* <div className="w-100 px-1">
              <CheckboxComp
                style={{ width: "90%" }}
                label={"Is Active"}
                className="p-2"
                defaultChecked={values.is_active}
                // handleChange={(e)=>{
                //   handleChange({target:{name:"is_ads_allowed",}})
                // }}
                // onBlur={() => {
                //   handleBlur({ target: { name: "is_ads_allowed" } });
                // }}

                setFieldValue={setFieldValue}
                name="is_active"
              />
            </div> */}
          </DialogContent>
          <DialogActions className="m-2">
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setEdit(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={updateMapedMachineApi.isLoading ? true : false}
              variant="outlined"
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  // console.log(fetchedTableData.responce.data);
  //filter table data
  const fiter = () => {
    if (fetchedTableData.responce) {
      const filterarr = [];

      //   console.log(fetchedTableData.responce.data[0]);
      for (let i = 0; i < fetchedTableData.responce.data.length; i++) {
        const val = {};
        // console.log(fetchedTableData.responce.data[i]);
        val.company_name = !fetchedTableData.responce.data[i].comapny
          ? "-"
          : fetchedTableData.responce.data[i].comapny.company_name;
        val.device_id = !fetchedTableData.responce.data[i].machine
          ? "-"
          : fetchedTableData.responce.data[i].machine.device_id;
        val.machine_name = !fetchedTableData.responce.data[i].machine
          ? "-"
          : fetchedTableData.responce.data[i].machine.name;
        val.machine_model = !fetchedTableData.responce.data[i].machine
          ? "-"
          : fetchedTableData.responce.data[i].machine.model;
        val._id = fetchedTableData.responce.data[i]._id;

        val.is_active = (
          <FormGroup
            id={fetchedTableData.responce.data[i].machine_id}
            name={fetchedTableData.responce.data[i].machine_id}
          >
            <FormControlLabel
              id={fetchedTableData.responce.data[i].machine_id}
              name={fetchedTableData.responce.data[i].machine_id}
              className="d-flex justify-content-center"
              control={
                <Checkbox
                  onChange={(e) => {
                    changeStatus(e.target);
                  }}
                  id={fetchedTableData.responce.data[i].machine_id}
                  name={fetchedTableData.responce.data[i].machine_id}
                  defaultChecked={
                    fetchedTableData.responce.data[i].is_active ? true : false
                  }
                />
              }
            />
          </FormGroup>
        );
        val.current_status =
          fetchedTableData.responce.data[i].current_status === "Connected" ? (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p
                className="px-2"
                style={{ margin: 0, padding: 0, color: "#1B8000" }}
              >
                {fetchedTableData.responce.data[i].current_status}
              </p>
            </div>
          ) : fetchedTableData.responce.data[i].current_status ===
            "Connected" ? (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p
                className="px-2"
                style={{ margin: 0, padding: 0, color: "#1B8000" }}
              >
                {fetchedTableData.responce.data[i].current_status}
              </p>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p className="px-2 text-danger" style={{ margin: 0, padding: 0 }}>
                {fetchedTableData.responce.data[i].current_status
                  ? fetchedTableData.responce.data[i].current_status
                  : " Not Connected"}
              </p>
            </div>
          );

        filterarr.push(val);
        // //     // console.log(data);
      }
      // console.log(filterarr);
      filterarr.filter((a) => delete a.updated_at);
      filterarr.filter((a) => delete a.entity_id);
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={companyName ? companyName.value : ""}
                    className=""
                    options={
                      listFullCompanyapi.responce
                        ? listFullCompanyapi.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.company_name,
                              label: e.company_name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setCompanyName(value);
                      companybased_filters(value);
                      // console.log(value);
                    }}
                    sx={{ width: 250, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Company Name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const groupdrop2 = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={deviceId ? deviceId.value : ""}
                    className=""
                    options={
                      ListFullMachine.responce
                        ? ListFullMachine.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.device_id,
                              label: e.device_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setDeviceId(value);
                      device_id_filters(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="Device Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const statusfilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={
                      active_machines
                        ? active_machines === "all"
                          ? ""
                          : active_machines
                        : ""
                    }
                    className=""
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "In Active", label: "In Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";
                      setActiveMachines(value ? value.value : null);
                      active_machine_filters(a);
                      // console.log(value);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const curentstatusfilters = () => {
        return (
          <>
            <div
              className="search-heder-table "
              style={{
                marginRight: "55px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={curentStatus ? curentStatus : ""}
                    className=""
                    options={[
                      { value: "Connected", label: "Connected" },
                      { value: "Disconnected", label: "Disconnected" },
                      { value: "Reconnected", label: "Reconnected" },
                      { value: "Not Connected", label: "Not Connected" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : null;

                      curent_status_filters(a);
                      // console.log(value);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      // console.log(arr);
      const tablefilterDropDown = [
        groupdrop(),
        groupdrop2(),
        statusfilters(),
        curentstatusfilters(),
      ];

      if (filterarr.length != 0) {
        return (
          <MainTable
            // allacated={allacated}
            // filter={filter}
            tablefilterDropDown={tablefilterDropDown}
            tabledata={filterarr}
            listOfMachine={" LIST OF MACHINE"}
            getData={getData}
            // filtervalue={(e) => {
            //   setActiveMachines(e);
            //   active_machine_filters(e);
            // }}
            Action={Action}
          />
        );
      }
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={companyName ? companyName.value : ""}
                    className=""
                    options={
                      listFullCompanyapi.responce
                        ? listFullCompanyapi.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.company_name,
                              label: e.company_name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setCompanyName(value);
                      companybased_filters(value);
                      // console.log(value);
                    }}
                    sx={{ width: 250, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Company Name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const groupdrop2 = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={deviceId ? deviceId.value : ""}
                    className=""
                    options={
                      ListFullMachine.responce
                        ? ListFullMachine.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.device_id,
                              label: e.device_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setDeviceId(value);
                      device_id_filters(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="Device Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const statusfilters = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={
                      active_machines
                        ? active_machines === "all"
                          ? ""
                          : active_machines
                        : ""
                    }
                    className=""
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "In Active", label: "In Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";
                      setActiveMachines(value ? value.value : null);
                      active_machine_filters(a);
                      // console.log(value);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const curentstatusfilters = () => {
        return (
          <>
            <div
              className="search-heder-table "
              style={{
                marginRight: "55px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={curentStatus ? curentStatus : ""}
                    className=""
                    options={[
                      { value: "Connected", label: "Connected" },
                      { value: "Disconnected", label: "Disconnected" },
                      { value: "Reconnected", label: "Reconnected" },
                      { value: "Not Connected", label: "Not Connected" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : null;

                      curent_status_filters(a);
                      // console.log(value);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Company name"
                        placeholder="Status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      // console.log(arr);
      const tablefilterDropDown = [
        groupdrop(),
        groupdrop2(),
        statusfilters(),
        curentstatusfilters(),
      ];
      return (
        <MainTable
          tabledata={filterarr}
          tablefilterDropDown={tablefilterDropDown}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };
  return (
    <div className="comp-change">
      {fetchedTableData.isLoading ||
      companyList.isLoading ||
      listMachineData.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex w-100  ">
        <Stack className="mx-4 pt-4 w-100 ">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
        </Stack>
        <div className="px-4 d-flex w-100 justify-content-end align-items-center">
          <div
            onClick={() => {
              // navigate("/drinkmaster/adddrink");
              handleClickOpen();
            }}
            className="d-flex align-items-center justify-content-end"
          >
            <div className="pb-1 ">Map Machine</div>
            <div className="px-2">
              <FontAwesomeIcon
                style={{
                  fontSize: "22px",
                }}
                className="p-2"
                icon="fa-solid fa-square-plus"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <h1>Map machine</h1> */}
      {fetchedTableData.isLoading ? null : fiter()}
      {open ? AddMachineAndCompany() : ""}
      {edit ? editMachineMapping() : ""}
    </div>
  );
}

export default MapMachineAndUser;
