import React, { useState } from "react";
import Chart from "react-apexcharts";
import "../../../assets/css/dashboard.css";
const months = [];
var monthName = new Array(
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
);
var d = new Date();
d.setDate(1);
for (let i = 0; i <= 9; i++) {
  months.push(monthName[d.getMonth()].substring(0, 3));
  d.setMonth(d.getMonth() - 1);
}
months.reverse();

const BarChart = (props) => {
  console.log(props.chartvalue);
  console.log(props.chartData);

  const series = props.chartvalue
    ? props.chartvalue.map((e, index) => {
        return {
          name: e,
          data: props.chartData
            ? props.chartData[index]
            : [44, 55, 41, 67, 135, 43, 21, 33, 45],
          color: props.color[index],
        };
      })
    : [
        {
          name: "Tea",
          data: [44, 55, 41, 67, 135, 43, 21, 33, 45],
          color: "#fbd125",
        },
        {
          name: "Flavere Tea",
          data: [44, 55, 41, 67, 135, 43, 21, 33, 45],
          color: "#4fa6ee",
        },
        {
          name: "Milk",
          data: [44, 55, 41, 67, 135, 43, 21, 33, 45],
          color: "#00cec2",
        },
      ];
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: true,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "10",
        borderRadius: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      lineCap: "round",
    },
    legend: {
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "start",
    },

    grid: {
      row: {
        colors: ["#fff", "#fff"],
      },
    },
    xaxis: {
      categories: props.displaymonth ? props.displaymonth : months,
    },
    yaxis: {
      title: false,
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => {
          return value;
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 2.5,
        opacityTo: 0.85,
        stops: [50, 70, 100],
      },
    },
  };
  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      className="apexchert scroll-barx "
      width={
        props.displaymonth
          ? props.displaymonth.length > 8
            ? props.displaymonth.length * 100
            : "100%"
          : "100%"
      }
      height="96%"
    />
  );
};
export default BarChart;
