import React from "react";
import Chart from "react-apexcharts";

const PieChart = () => {
  const series = [1020, 5225, 4221];
  const options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#fbd125", "#4fa6ee", "#00cec2"],
    labels: ["Tea", "Flavere Tea", "Milk"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    legend: {
      show: true,
      floating: true,
      fontSize: "14px",
      position: "left",
      offsetX: 500,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };
  return (
    <Chart
      options={options}
      className="apexchert"
      series={series}
      type="donut"
      width="100%"
      height="96%"
    />
  );
};

export default PieChart;
