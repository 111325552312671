import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { editMappedMachine } from "./userMasterApiService";

import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const SUCCESE = async (data) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const EditMapedMachine = createSlice({
  name: "EditMapedMachine",
  initialState: {
    isLoading: false,
    responce: "",
    isSuccess: false,
    Message: "",
  },
  reducers: {
    isSuccess: (state, payload) => {
      state.isSuccess = false;
    },
    isSuccess1: (state, payload) => {
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [editMappedMachine.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [editMappedMachine.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.responce = payload.data;
      state.Message = payload.data.message;
      state.isSuccess = true;
      SUCCESE(payload.data.message);
    },

    [editMappedMachine.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.Message = payload;
      FAILED(payload);
      if (payload.message.name === "TokenExpiredError") {
        localStorage.clear();
        window.location.pathname = "/login";
        // Navigateto();
      }
    },
  },
});

export const { isSuccess, isSuccess1, errorMessage } = EditMapedMachine.actions;
export default EditMapedMachine.reducer;
