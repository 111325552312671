import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const editCompany = createSlice({
  name: "editCompany",
  initialState: {
    edit: false,
    data: {},
  },
  reducers: {
    edit: (state, payload) => {
      state.edit = payload.payload;
    },
    editCompanys: (state, payload) => {
      state.edit = payload.payload;
    },
    CompanyData: (state, payload) => {
      //   console.log(payload.payload.action);
      state.data = payload.payload;
    },
    clearCompanyData: (state, payloads) => {
      state.edit = false;
      state.data = "";
    },
  },
});

export const { edit, editCompanys, CompanyData, clearCompanyData } =
  editCompany.actions;
// export const selectCompany = (state) => statetCompany.login;
export default editCompany.reducer;
