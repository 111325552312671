import React, { useEffect, useState, useLayoutEffect } from "react";
import "../assets/css/sidenavbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { Paper } from "@mui/material";
import { adminnavigations, parentAdminNavigations } from "../navigation";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Submenu from "./submenu";
import { collaps } from "../redux/reducer/sidenavbaractive";
function Sidenavbar(props) {
  const [adminNavigation, setAdminnavigation] = useState(adminnavigations);
  const [sidenav, setSideNav] = useState(true);
  const [subnav, setSubNav] = useState();
  i18next.loadNamespaces(["sidenav", "mainHeder"], (err, t) => {
    // console.log("err", err);
    // console.log("t", t);
  });

  //reducer

  const dispatch = useDispatch();

  //translation

  const { t } = useTranslation();
  useEffect(() => {
    setAdminnavigation(adminnavigations);
  }, []);
  const setsidenav = (e) => {
    setSideNav(!sidenav);
  };
  let Navigations;
  const role = localStorage.getItem("Role");
  if (role === "Admin") {
    Navigations = adminNavigation;
  }
  if (role === "PARENT") {
    Navigations = parentAdminNavigations;
  }
  if (!Navigations) Navigations = adminNavigation;
  // console.log(sidenav);
  // langauage
  const Language = [
    {
      code: "en",
      name: "english",
    },
    {
      code: "tn",
      name: "tamil",
    },
  ];
  //get Window size
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const windowsize = useWindowSize();
  const sidebar = windowsize[0] < 1000 ? false : true;
  // console.log(sidebar);
  return (
    <>
      <Paper
        variant="outlined"
        className={sidenav ? "sidenavbar" : "sidenavcollaps"}
        square
      >
        <div className="sidenav1">
          <div
            className="sidenavheder"
            onClick={(e) => {
              setsidenav(e);
            }}
          >
            {sidenav && sidebar ? <p>BOAT LOAD</p> : <p>B</p>}
          </div>
          <div className="sidenavlist1 ">
            {Navigations.map((item, index) => {
              return (
                <>
                  <Submenu
                    sidenav={sidenav}
                    item={item}
                    key={index}
                    sidebar={sidebar}
                  />
                </>
              );
            })}
          </div>
          {/* bottom setting */}
          {/* <div className="sidenavsetting1 ">
            <div className="sidenavsetting2 ">
              <li
                className="sidenavsetting my-1 "
                onClick={() => {
                  window.location.pathname = "/setting";
                }}
                id={window.location.pathname == "/setting" ? "active" : null}
              >
                <div style={{ marginRight: "20px" }} className="icon">
                  <FontAwesomeIcon icon="fa-solid fa-sliders" />
                </div>
                <div className="sidenavName">
                  {sidenav ? t("sidenav.sidenavsetting") : null}
                </div>
              </li>
            </div>
          </div> */}
        </div>
      </Paper>
    </>
  );
}

export default Sidenavbar;
