import { configureStore } from "@reduxjs/toolkit";
import LoginUser from "./reducer/LoginReducer";
import SidenavSlice from "./reducer/sidenavbaractive";
import viewMachineEditData from "./reducer/viewMachineEditData";
import Viewdrinkedit from "./reducer/viewDrinkedit";
import Addmachine from "./reducer/machineMaster/addmachineApi";
import ListMachine from "./reducer/machineMaster/viewMachineApi";
import EditMachine from "./reducer/machineMaster/editmachineapi";
import ListDrinks from "./reducer/drinkMastert/viewDrinks";
import ListDriksCategory from "./reducer/drinkMastert/getCatogoryDetails";
import listMachineSetting from "./reducer/machineMaster/listMachineSettingGenral";
import addDrink from "./reducer/drinkMastert/addDrinks";
import editDrink from "./reducer/drinkMastert/editDrinks";
import deleteDrink from "./reducer/drinkMastert/deleteDrinks";
import addUser from "./reducer/UserMaster/AddUserApi";
import listUser from "./reducer/UserMaster/listUserApi";
import editUser from "./reducer/UserMaster/editUser";
import editUserApi from "./reducer/UserMaster/editUserApi";
import viewProfile from "./reducer/UserMaster/viewProfile";
import listCompanyApi from "./reducer/UserMaster/listCompanyApi";
import editCompany from "./reducer/UserMaster/editCompany";
import mapedMachines from "./reducer/machineMaster/machine_xrefApi";
import ForgetPassword from "./reducer/forgetUser";
import createPassword from "./reducer/createPassword";
import machineMapping from "./reducer/UserMaster/mapMachine";
import editMapedMachine from "./reducer/UserMaster/editmapedMachine";
import updateMachineSetting from "./reducer/machineMaster/updateMachineSetting";
import changeMachineStatus from "./reducer/machineMaster/changeMachineStatus";
import addStatus from "./reducer/machineMaster/machineAddsChanging";
import machineSetting from "./reducer/machineMaster/machineSetting";
import addMachineSetting from "./reducer/machineMaster/addMachineSetting";
import MapedDrink from "./reducer/drinkMastert/mapedDrinks";
import MapedDrinkApi from "./reducer/drinkMastert/maped_drink_api";
import ListMapedDrinks from "./reducer/drinkMastert/listMapedDrinksApi";
import UpdateMapedDrink from "./reducer/drinkMastert/updateMapedDrink";
import MachineUsage from "./reducer/machine_usage/viewMachineUsage";
import MachineUsageDetails from "./reducer/machine_usage/machine_usage_page_data";
import addLeads from "./reducer/crm/addleadsapi";
import ListLeads from "./reducer/crm/listleadsApi";
import editLeads from "./reducer/crm/editLeads";
import editLeadsApi from "./reducer/crm/editLeadsApi";
import EditLeadsStatus from "./reducer/crm/editStatus_leads";
import ConvertCustomer from "./reducer/crm/convertCustomer";
import leadsdetailViewApi from "./reducer/crm/leadsdetailView";
import leadsDetailView from "./reducer/crm/detailsView";
import AssignedUsers from "./reducer/serviceRequest/getassignedUsersList";
import addRequest from "./reducer/serviceRequest/addRequest";
import listRequest from "./reducer/serviceRequest/listRequest";
import editRequest from "./reducer/serviceRequest/editRequest";
import editRequestApi from "./reducer/serviceRequest/editRequestApi";
import assignRequest from "./reducer/serviceRequest/assignRequest";
import changeRequestStatus from "./reducer/serviceRequest/updateStatusApi";
import listRequestHistory from "./reducer/serviceRequest/listrequestHistory";
import getDetailViewData from "./reducer/serviceRequest/getDetailData";
import ListMachineRequest from "./reducer/serviceRequest/getmachineBassedRequest";
import addProduct from "./reducer/inventory/addProduct";
import editProduct from "./reducer/inventory/editProduct";
import editproductApi from "./reducer/inventory/editproductApi";
import productorder from "./reducer/inventory/productorder";
import Listproduct from "./reducer/inventory/viewInventory";
import listNotification from "./reducer/notification/listNotification";
import readoneNotification from "./reducer/notification/readone";
import readallNotification from "./reducer/notification/readAllNotification";
import deleteoneNotification from "./reducer/notification/deleteone";
import deleteallNotification from "./reducer/notification/deleteall";
import listInventory from "./reducer/inventory/listInventory";
import listFullInventory from "./reducer/inventory/listFullInventory";
import ReturnProduct from "./reducer/inventory/returnProduct";
import changePassword from "./reducer/UserMaster/changePassword";
import changeUserStatus from "./reducer/UserMaster/changeStatus";
import listFullCompany from "./reducer/UserMaster/listFullCompany";
import listFullMachines from "./reducer/machineMaster/listFullMachine";
import resetError from "./reducer/machineMaster/resetErrors";
import updateSingleSetting from "./reducer/machineMaster/updateSingleSetting";
import listMapedMachineFilterApi from "./reducer/machineMaster/companyMapedMachinelist";
export const store = configureStore({
  reducer: {
    Login: LoginUser,
    ForgetPassword: ForgetPassword,
    SideNavActive: SidenavSlice,
    viewMachineEditData: viewMachineEditData,
    Viewdrinkedit: Viewdrinkedit,
    MapedDrink: MapedDrink,
    MapedDrinkApi: MapedDrinkApi,
    Addmachine: Addmachine,
    ListMachine: ListMachine,
    EditMachine: EditMachine,
    ListDrinks: ListDrinks,
    ListDriksCategory: ListDriksCategory,
    listMachineSetting: listMachineSetting,
    updateSingleSetting: updateSingleSetting,
    addDrink: addDrink,
    editDrink: editDrink,
    deleteDrink: deleteDrink,
    addUser: addUser,
    changeUserStatus: changeUserStatus,
    listUser: listUser,
    viewProfile: viewProfile,
    editUser: editUser,
    changePassword: changePassword,
    editUserApi: editUserApi,
    listCompanyApi: listCompanyApi,
    listFullCompany: listFullCompany,
    editCompany: editCompany,
    mapedMachines: mapedMachines,
    listMapedMachineFilterApi: listMapedMachineFilterApi,
    createPassword: createPassword,
    machineMapping: machineMapping,
    editMapedMachine: editMapedMachine,
    updateMachineSetting: updateMachineSetting,
    changeMachineStatus: changeMachineStatus,
    machineSetting: machineSetting,
    addMachineSetting: addMachineSetting,
    addStatus: addStatus,
    ListMapedDrinks: ListMapedDrinks,
    UpdateMapedDrink: UpdateMapedDrink,
    MachineUsage: MachineUsage,
    MachineUsageDetails: MachineUsageDetails,
    addLeads: addLeads,
    ListLeads: ListLeads,
    editLeads: editLeads,
    editLeadsApi: editLeadsApi,
    EditLeadsStatus: EditLeadsStatus,
    ConvertCustomer: ConvertCustomer,
    leadsdetailViewApi: leadsdetailViewApi,
    leadsDetailView: leadsDetailView,
    AssignedUsers: AssignedUsers,
    addRequest: addRequest,
    listRequest: listRequest,
    editRequest: editRequest,
    editRequestApi: editRequestApi,
    assignRequest: assignRequest,
    changeRequestStatus: changeRequestStatus,
    listRequestHistory: listRequestHistory,
    getDetailViewData: getDetailViewData,
    ListMachineRequest: ListMachineRequest,
    addProduct: addProduct,
    editProduct: editProduct,
    editproductApi: editproductApi,
    productorder: productorder,
    Listproduct: Listproduct,
    listNotification: listNotification,
    readoneNotification: readoneNotification,
    readallNotification: readallNotification,
    deleteoneNotification: deleteoneNotification,
    deleteallNotification: deleteallNotification,
    listInventory: listInventory,
    listFullInventory: listFullInventory,
    ReturnProduct: ReturnProduct,
    listFullMachines: listFullMachines,
    resetError: resetError,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
