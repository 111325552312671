import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { Paper, Link } from "@material-ui/core";
function Notification(props) {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      NOTIFICATION
    </Link>,
  ];
  return (
    <div className="comp-change">
      <Stack className="mx-4 pt-4 ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
    </div>
  );
}

export default Notification;

// const ChangeDeliveryAddress = (e) => {
//   return (
//     <div className="row pt-1 px-4">
//       <div className="col-4 ">
//         <TextInput
//           defaultvalue={values.billing_address_1}
//           style={{ width: "90%" }}
//           handleChange={handleChange}
//           placeholder="Delivery Address"
//           errors={errors}
//           values={values}
//           touched={touched}
//           handleBlur={handleBlur}
//           name="delivery_address_1"
//         />
//       </div>
//       <div className="col-4 ">
//         <TextInput
//           defaultvalue={values.billing_address_2}
//           style={{ width: "90%" }}
//           handleChange={handleChange}
//           placeholder="Delivery Address 2"
//           errors={errors}
//           values={values}
//           touched={touched}
//           handleBlur={handleBlur}
//           name="delivery_address_2"
//         />
//       </div>
//       <div className="col-4 ">
//         <DropdownSelecter
//           style={{ width: "90%" }}
//           placeholder="Delivery City"
//           defaultvalue={values.billing_city}
//           onBlur={() => {
//             handleBlur({ target: { name: "delivery_city" } });
//           }}
//           setFieldValue={setFieldValue}
//           options={city}
//           errors={errors}
//           values={values}
//           touched={touched}
//           vari={() => {
//             console.log();
//           }}
//           name="delivery_city"
//         />
//       </div>
//       <div className="col-4 ">
//         <DropdownSelecter
//           style={{ width: "90%" }}
//           placeholder="Delivery State"
//           onBlur={() => {
//             handleBlur({ target: { name: "delivery_state" } });
//           }}
//           setFieldValue={setFieldValue}
//           options={state}
//           errors={errors}
//           vari={() => {
//             console.log();
//           }}
//           values={values}
//           touched={touched}
//           name="delivery_state"
//         />
//       </div>
//       <div className="col-4 ">
//         <TextInput
//           defaultvalue={edit ? is_edit.data.delivery_pincode : ""}
//           style={{ width: "90%" }}
//           handleChange={handleChange}
//           placeholder="Delivery Pincode"
//           errors={errors}
//           values={values}
//           touched={touched}
//           handleBlur={handleBlur}
//           name="delivery_pincode"
//         />
//       </div>
//     </div>
//   );
// };
