import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { Paper, Link, Button, TextField } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../forms/textInput";
import LoadingButton from "@mui/lab/LoadingButton";
import TextNumberInput from "../forms/ numberInput";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  editProduct,
} from "../../redux/reducer/inventory/inventoryapiService";

import { useNavigate } from "react-router-dom";
import { isSuccessaddProduct } from "../../redux/reducer/inventory/addProduct";
import { clearProductData } from "../../redux/reducer/inventory/editProduct";
import { isSuccesseditProduct } from "../../redux/reducer/inventory/editproductApi";
const AddProduct = () => {
  const [qr_codes, setQrCodes] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addProductApi = useSelector((state) => state.addProduct);
  const editProducts = useSelector((state) => state.editProduct);
  const editProductApi = useSelector((state) => state.editproductApi);
  //file suported formet
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];
  const SUPPORTED_FORMATS_XLS = [
    "qr_codes/xlsx",
    "qr_codes/xlsm",
    "qr_codes/xlsb",
    "qr_codes/xltx",
    "qr_codes/xls",
  ];
  console.log(editProducts);
  //form validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Product name is required"),
    // description: Yup.string().email().required("Description is id required"),

    quantity: Yup.number()
      .typeError("you must specify a number")
      .min(0, "Min value 0."),

    unit: Yup.string().required("unit is id required"),
    price: Yup.string().required("Price is id required"),
    // image: Yup.mixed()
    //   .required("A file is required")
    //   // .test(
    //   //   "fileSize",
    //   //   "File too large",
    //   //   (value) => value && value.size <= FILE_SIZE
    //   // )
    //   .test(
    //     "fileFormat",
    //     "Unsupported Format",
    //     (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //   ),
    // qr_codes: Yup.mixed().required("A file is required"),
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value) => value && SUPPORTED_FORMATS_XLS.includes(value.type)
    // ),
  });
  const formik = useFormik({
    initialValues: {
      name: editProducts.edit ? editProducts.data.name : "",
      description: editProducts.edit ? editProducts.data.description : "",
      quantity: "",
      unit: editProducts.edit ? editProducts.data.unit : "",
      price: editProducts.edit ? editProducts.data.price : "",
      image: [],
      qr_codes: [],
      stay_here: false,
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
    onReset: (data) => {
      console.log(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleReset,
    setTouched,
  } = formik;
  // console.log(qr_codes);
  //file upload to get qr codes

  const getQrCodes = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    const Qr_code = [...jsonData];
    const qr_code_only = Qr_code.map((e) => {
      return e.Qr_codes;
    });

    setQrCodes(qr_code_only);
    // console.log(qr_code_only);
  };
  //useEffect
  useEffect(() => {
    if (addProductApi.isSuccess) {
      resetForm();
      dispatch(isSuccessaddProduct());
      if (!values.stay_here) {
        navigate("/inventory/viewproduct");
      }
    }
    if (editProductApi.isSuccess) {
      resetForm();
      dispatch(isSuccesseditProduct());
      navigate("/inventory/viewproduct");
    }
  }, [addProductApi, editProductApi]);
  const submit = (val) => {
    let formData = new FormData();
    if (!editProducts.edit) {
      formData.append("name", val.name);
      formData.append("description", val.description);
      formData.append("quantity", val.quantity);
      formData.append("unit", val.unit);
      formData.append("price", val.price);
      formData.append("images", val.image);
      qr_codes
        ? qr_codes.filter((e) => formData.append("qr_code[]", e))
        : formData.append("qr_code[]", "");

      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      dispatch(addProduct(formData));
      // dispatch(editProduct(formData));

      console.log(formData);
    }
    if (editProducts.edit) {
      formData.append("_id", editProducts.data._id);
      formData.append("name", val.name);
      formData.append("description", val.description);
      formData.append("quantity", val.quantity);
      formData.append("unit", val.unit);
      formData.append("price", val.price);
      formData.append("images", val.image);
      qr_codes
        ? qr_codes.filter((e) => formData.append("qr_code[]", e))
        : formData.append("qr_code[]", "");
      // formData.append("qr_code", qr_codes);

      dispatch(editProduct(formData));
      // console.log(formData);
    }
  };
  console.log(values);
  const breadcrumbs = [
    <Link
      type="button"
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      INVENTORY
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      ADD PRODUCT
    </Link>,
  ];
  return (
    <div className="comp-change">
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form p-4 " style={{ marginBottom: "100px" }}>
        <Paper className="bg-white machineForm">
          <div className="row pt-1 px-4">
            <div className="col-4 ">
              <TextInput
                defaultvalue={editProducts.edit ? editProducts.data.name : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Product Name"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="name"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  editProducts.edit ? editProducts.data.description : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Description"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="description"
              />
            </div>
            <div className="col-4 ">
              <TextNumberInput
                defaultvalue={""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Product quantity"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="quantity"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={editProducts.edit ? editProducts.data.unit : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Product Unit"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="unit"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={editProducts.edit ? editProducts.data.price : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Product Price"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="price"
              />
            </div>

            <div className="col-4  d-flex flex-column align-items-start">
              <label className="font-size-12" style={{ width: "90%" }}>
                Product Image
              </label>
              <TextField
                component="label"
                onChange={(e) => {
                  console.log(e.target.files);
                  setFieldValue("image", e.target.files[0]);
                }}
                name="image"
                error={errors.image && touched.image ? true : false}
                onBlur={handleBlur}
                helperText={errors.image && touched.image ? errors.image : null}
                type="file"
                style={{ width: "90%" }}
              />
            </div>
            <div className="col-4  d-flex flex-column align-items-start">
              <label className="font-size-12" style={{ width: "90%" }}>
                Upload Qr Code
              </label>
              <TextField
                component="label"
                onChange={(e) => {
                  // console.log(e.target.files);
                  getQrCodes(e);
                  setFieldValue("qr_codes", e.target.files[0]);
                }}
                name="qr_codes"
                error={errors.qr_codes && touched.qr_codes ? true : false}
                onBlur={handleBlur}
                helperText={
                  errors.qr_codes && touched.qr_codes ? errors.qr_codes : null
                }
                type="file"
                style={{ width: "90%" }}
              />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end p-4 px-5 ">
            <Stack spacing={2} direction="row">
              <LoadingButton
                variant="outlined"
                className="text-danger"
                color="error"
                onClick={() => {
                  resetForm();
                  // dispatch(isEditLeads(false));
                  dispatch(clearProductData());
                  editProducts.edit
                    ? navigate("/inventory/viewproduct")
                    : resetForm();
                }}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                loading={addProductApi.isLoading ? true : false}
                onClick={handleSubmit}
              >
                Submit
                {/* {edit ? "Submit" : "Save"} */}
              </LoadingButton>
            </Stack>
          </div>
        </Paper>
      </div>
    </div>
  );
};
export default AddProduct;
