import Highcharts from "highcharts";
const months = [];
var monthName = new Array(
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
);
var d = new Date();
d.setDate(1);
for (let i = 0; i <= 8; i++) {
  months.push(monthName[d.getMonth()].substring(0, 3));
  d.setMonth(d.getMonth() - 1);
}
var yAxisLabels = [100, 2000, 5000, 15000];

export const options = {
  chart: {
    type: "column",
    height: 300 + "vh",

    marginTop: 30,
  },

  title: {
    text: "",
  },
  xAxis: {
    categories: months,
  },

  yAxis: {
    className: "highcharts-color-0",
    title: {
      text: "",
    },
    borderWidth: 1,
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: "bold",
        color: "gray",
      },
    },

    tickPositioner: function () {
      return yAxisLabels;
    },
  },

  legend: {
    align: "right",
    x: 0,
    verticalAlign: "top",
    y: -18,
    floating: true,
    backgroundColor:
      Highcharts.defaultOptions.legend.backgroundColor || "white",

    shadow: false,
  },
  tooltip: {},
  plotOptions: {
    column: {
      stacking: "normal",
    },
    bar: {
      borderRadius: 10,
      columnWidth: "50%",
    },
  },
  series: [
    {
      name: "Tea",

      data: [5200, 3200, 4501, 700, 2020, 4525, 588, 1285, 2222],
      color: "#fbd125",
    },
    {
      name: "Flaver tea",
      data: [2577, 2555, 7773, 2555, 1777, 255, 677, 2770, 774],
      color: "#4fa6ee",
    },
    {
      name: "Milk",
      data: [3727, 4757, 774, 2525, 7525, 1752, 1770, 7575, 1741],
      color: "#00cec2",
    },
  ],
};
