import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ComponentPage from "./components/componentPage";
import PrivateRoute from "./productedRoute";
import Login from "./auth_component/login";
import CreatePassword from "./auth_component/createAndForgetPassword";
import AddLeads from "./components/addLeads";
function Router(props) {
  return (
    <div>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/boatload/addLeads" element={<AddLeads />} />
        <Route exact path="/create_password" element={<CreatePassword />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              {" "}
              <ComponentPage />{" "}
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default Router;
