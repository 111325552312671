import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const viewMachineEdit = createSlice({
  name: "edit",
  initialState: {
    edit: false,
    data: {},
    vieaData: {},
    settingData: {},
  },
  reducers: {
    edit: (state, payload) => {
      state.edit = payload.payload;
    },
    data: (state, payload) => {
      //   console.log(payload.payload.action);
      state.data = payload.payload.data;
    },
    vieaData: (state, payload) => {
      console.log(payload);
      state.vieaData = payload.payload.data;
    },
    settingData: (state, payload) => {
      state.settingData = payload.payload.data;
    },
  },
});

export const { edit, data, vieaData, settingData } = viewMachineEdit.actions;
// export const selectUser = (state) => state.user.login;
export default viewMachineEdit.reducer;
