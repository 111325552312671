import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { selectUser } from "../LoginReducer";
const API_URL = "https://dtv29cy9i3.execute-api.ap-south-1.amazonaws.com/dev";
// const API_URL = "http://localhost:8080";
let token = localStorage.getItem("Token");
const entity_id = 1;
console.log(token);

//get machine usage
export const listNotification = createAsyncThunk(
  "ListNotification/listNotification",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(
        API_URL + "/notification/list_notification" + value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//read all notification

export const readAllNotification = createAsyncThunk(
  "ReadAllNotification/readAllNotification",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(
        API_URL + "/notification/read_all" + value,
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const readoneNotification = createAsyncThunk(
  "ReadoneNotification/readoneNotification",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(
        API_URL + "/notification/read_one" + value,
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAllNotification = createAsyncThunk(
  "DeleteAllNotification/deleteAllNotification",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.delete(
        API_URL + "/notification/delete_all" + value,

        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteoneNotification = createAsyncThunk(
  "DeleteoneNotification/deleteoneNotification",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.delete(
        API_URL + "/notification/delete_one" + value,

        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
