import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, TextField } from "@mui/material";
import "../../assets/css/machinesetting.css";
import { Link, MenuItem, Paper, Select } from "@material-ui/core";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  listMachineSetting,
  updatemachineSetting,
} from "../../redux/reducer/machineMaster/addMachineApiService";
import MachineSettingNumberInput from "../forms/machineNumberInput";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

function MachineSetting(props) {
  const dispatch = useDispatch();
  const listSetting = useSelector((state) => state.listMachineSetting);
  const updateMachineSetting = useSelector(
    (state) => state.updateMachineSetting
  );
  const [value, setValue] = useState(null);
  const navigate = useNavigate();
  const [calibrationedit, setcalibrationEdit] = useState(false);
  const [calibrationExpand, setcalibrationExpand] = useState(true);
  const [calibrationValueback, setcalibrationValue] = useState([]);
  const [brewingedit, setBrewingEdit] = useState(false);
  const [brewingExpand, setBrewingExpand] = useState(false);
  const [brewingValueback, setBrevingValue] = useState([]);
  const [cleaningedit, setCleaningEdit] = useState(false);
  const [cleaningExpand, setCleaningExpand] = useState(false);
  const [cleaningValueback, setCleaningValue] = useState([]);
  const [cleaningedit2, setCleaningEdit2] = useState(false);
  const [cleaningExpand2, setCleaningExpand2] = useState(false);
  const [cleaningValueback2, setCleaningValue2] = useState([]);
  const [dispansingedit, setdispansingedit] = useState(false);
  const [dispansingExpand, setdispansingExpand] = useState(false);
  const [dispansingValueback, setdispansingValueback] = useState([]);
  const [deviceSettingedit, setdeviceSettingedit] = useState(false);
  const [deviceSettingeditExpand, setdeviceSettingeditExpand] = useState(false);
  const [deviceSettingeditValueback, setdeviceSettingeditValueback] = useState(
    []
  );
  const [shiftTimingdit, setshiftTimingedit] = useState(false);
  const [shiftTimingeditExpand, setshiftTimingeditExpand] = useState(false);
  const [shiftTimingeditValueback, setshiftTimingeditValueback] = useState({});
  const [startTimeOpen, setStartTimeope] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const [brewingTime, setBrewingTime] = useState(false);

  const [focus, setFocus] = useState("");

  //api call
  const apiCall = () => {
    console.log("jmgfaegf");

    dispatch(listMachineSetting("?cm_ref_id=62482ddc62c1f1daee7a3d6b"));
  };
  //useEffect to api call
  useEffect(() => {
    apiCall();
  }, []);
  let call = [];
  let brewing = [];
  let cleaning = [];
  let cleaning2 = [];
  let dispansing = [];
  let deviceSettingsa = [];
  let shiftTiming = {};
  if (!listSetting.isLoadiing) {
    if (listSetting.responce) {
      for (
        let i = 0;
        i < listSetting.responce.data[0].calibration_value.length;
        i++
      ) {
        const obj = listSetting.responce.data[0].calibration_value[i];
        call.push({ ...obj });
      }
      for (
        let i = 0;
        i < listSetting.responce.data[0].brewing_variable.length;
        i++
      ) {
        const obj = listSetting.responce.data[0].brewing_variable[i];
        brewing.push({ ...obj });
      }
      for (
        let i = 0;
        i < listSetting.responce.data[0].cleaning_variable_set_1.length;
        i++
      ) {
        const obj = listSetting.responce.data[0].cleaning_variable_set_1[i];
        cleaning.push({ ...obj });
      }
      for (
        let i = 0;
        i < listSetting.responce.data[0].cleaning_variable_set_2.length;
        i++
      ) {
        const obj = listSetting.responce.data[0].cleaning_variable_set_2[i];
        cleaning2.push({ ...obj });
      }
      for (
        let i = 0;
        i < listSetting.responce.data[0].dispensing_volume.length;
        i++
      ) {
        const obj = listSetting.responce.data[0].dispensing_volume[i];
        dispansing.push({ ...obj });
      }
      for (
        let i = 0;
        i < listSetting.responce.data[0].device_settings.length;
        i++
      ) {
        const obj = listSetting.responce.data[0].device_settings[i];
        deviceSettingsa.push({ ...obj });
      }
      shiftTiming = { ...listSetting.responce.data[0].shift_timing };

      if (
        calibrationValueback.length !=
        listSetting.responce.data[0].calibration_value.length
      ) {
        // console.log(call);
        setcalibrationValue(call);
      }
      if (
        brewingValueback.length !=
        listSetting.responce.data[0].brewing_variable.length
      ) {
        // console.log(brewing);
        setBrevingValue(brewing);
      }
      if (
        cleaningValueback.length !=
        listSetting.responce.data[0].cleaning_variable_set_1.length
      ) {
        // console.log(cleaning);
        setCleaningValue(cleaning);
      }
      if (
        cleaningValueback2.length !=
        listSetting.responce.data[0].cleaning_variable_set_2.length
      ) {
        // console.log(cleaning);
        setCleaningValue2(cleaning2);
      }
      if (
        dispansingValueback.length !=
        listSetting.responce.data[0].dispensing_volume.length
      ) {
        // console.log(cleaning);
        setdispansingValueback(dispansing);
      }
      if (
        deviceSettingeditValueback.length !=
        listSetting.responce.data[0].device_settings.length
      ) {
        // console.log(cleaning);
        setdeviceSettingeditValueback(deviceSettingsa);
      }
      if (Object.keys(shiftTimingeditValueback).length === 0) {
        setshiftTimingeditValueback(shiftTiming);
      }
    }
    // setcalibrationValue([...listSetting.responce.data[0].calibration_value])
  }
  console.log(shiftTimingeditValueback);
  //setting calibration value

  const changeCalibrationValue = (value, index) => {
    // console.log(typeof(e.target.value));

    setcalibrationValue(
      calibrationValueback,
      (calibrationValueback[index].defaultValue = value)
    );
    // console.log("calibrationValueback", calibrationValueback);
  };
  const changeBrewingVariable = (value, index) => {
    setBrevingValue(
      brewingValueback,
      (brewingValueback[index].defaultValue = value)
    );
  };
  const changeCleaningVariable = (value, index) => {
    setCleaningValue(
      cleaningValueback,
      (cleaningValueback[index].defaultValue = value)
    );
  };
  const changeCleaningVariable2 = (value, index) => {
    setCleaningValue2(
      cleaningValueback2,
      (cleaningValueback2[index].defaultValue = value)
    );
  };
  const changeDispansing = (value, index) => {
    setdispansingValueback(
      dispansingValueback,
      (dispansingValueback[index].defaultValue = value)
    );
  };
  const changeDeviceSetting = (value, index) => {
    // console.log(index);
    setdeviceSettingeditValueback(
      deviceSettingeditValueback,
      (deviceSettingeditValueback[index].defaultValue = value)
    );
  };
  const changeShiftTiming = (e, key, name) => {
    if (name === "thirdFlash") {
      const va = { ...shiftTimingeditValueback };
      const start = { ...va.ThirdFlash };
      start.defaultValue = e.target.value;

      const ThirdFlash = { ...start };
      // console.log(start);
      setshiftTimingeditValueback((shiftTimingeditValueback) => ({
        ...shiftTimingeditValueback,
        ThirdFlash,
      }));
    }
    if (name === "BrewingTime") {
      const va = { ...shiftTimingeditValueback };
      const start = { ...va.BrewingTime };
      let date = new Date(e);

      start.defaultValue = date.toISOString();

      const BrewingTime = { ...start };
      // console.log(start);
      setshiftTimingeditValueback((shiftTimingeditValueback) => ({
        ...shiftTimingeditValueback,
        BrewingTime,
      }));
    }
    if (name === "startTime") {
      const va = [];
      for (let i = 0; i < shiftTimingeditValueback.shift_timing.length; i++) {
        const l = shiftTimingeditValueback.shift_timing[i];
        va.push(l);
      }

      const start = [...va[key]];
      const update = { ...start[0] };
      let date = new Date(e);
      update.defaultValue = date.toISOString();
      start[0] = update;
      va[key] = start;
      const shift_timing = [...va];

      setshiftTimingeditValueback((shiftTimingeditValueback) => ({
        ...shiftTimingeditValueback,
        shift_timing,
      }));
    }
    if (name === "endTime") {
      const va = [];
      for (let i = 0; i < shiftTimingeditValueback.shift_timing.length; i++) {
        const l = shiftTimingeditValueback.shift_timing[i];
        va.push(l);
      }
      const start = [...va[key]];
      const update = { ...start[1] };
      // console.log(typeof e);
      let date = new Date(e);
      update.defaultValue = date.toISOString();
      start[1] = update;
      va[key] = start;
      const shift_timing = [...va];
      setshiftTimingeditValueback((shiftTimingeditValueback) => ({
        ...shiftTimingeditValueback,
        shift_timing,
      }));
    }
    if (name === "cupCounts") {
      const va = [];
      for (let i = 0; i < shiftTimingeditValueback.shift_timing.length; i++) {
        const l = shiftTimingeditValueback.shift_timing[i];
        va.push(l);
      }
      const start = [...va[key]];
      const update = { ...start[2] };
      update.defaultValue = Number(e.target.value);
      start[2] = update;
      va[key] = start;
      // const shift_timing = [...va];
      const data = { ...shiftTimingeditValueback };
      data.shift_timing = [...va];
      setshiftTimingeditValueback(data);
    }
  };
  console.log(shiftTimingeditValueback);
  //update or save the machine setting
  const submitData = () => {
    const submit = {
      cm_ref_id: ["62482ddc62c1f1daee7a3d6b"],
      calibration_value: calibrationValueback,
      brewing_variable: brewingValueback,
      cleaning_variable_set_1: cleaningValueback,
      cleaning_variable_set_2: cleaningValueback2,
      dispensing_volume: dispansingValueback,
      device_settings: deviceSettingeditValueback,
      shift_timing: shiftTimingeditValueback,
    };
    console.log(submit);
    // console.log("dispansing",dispansingValueback);

    dispatch(updatemachineSetting(submit));
  };

  // console.log("calibration", calibrationValueback);

  // console.log("brewingValueback", brewingValueback);

  // console.log("cleaningValueback", cleaningValueback);

  //text field border bottom removing
  const classes = useStyles();
  //brudcrumbs
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey brud font-weight-bold font-weight-bol"
    >
      MACHINE MASTER
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey brud font-weight-bold font-weight-bol"
      key="2"
      color="inherit"
      onClick={() => {
        navigate("/machinemaster/viewmachine");
      }}
    >
      VIEW MACHINE
    </Link>,
  ];
  //dropdowns

  const Calbration = () => {
    if (calibrationExpand) {
      return (
        <div className="calibration">
          <div>
            <div className="row calibration">
              {listSetting.isLoadiing
                ? null
                : !listSetting.responce
                ? null
                : calibrationValueback.map((data, key) => {
                    return (
                      <div key={key} className="col-4  p-2">
                        <div className="p-2 border px-4 d-flex align-items-center rounded gh">
                          <div className="d-flex text-start justify-content-start">
                            <p className="m-0 text-start font-12 w-100">
                              {data.settingName}
                            </p>
                          </div>

                          <div className="example-spacer"></div>
                          <div className="d-flex text-end  align-items-center justify-content-end">
                            <Tooltip
                              className="pb-3 "
                              title={
                                <>
                                  <div
                                    className="d-flex"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Range ({data.minValue} - {data.maxValue})
                                  </div>
                                </>
                              }
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>

                            <TextField
                              className="bg-white text-end d-flex justify-content-end formTextInput  p-2"
                              variant="standard"
                              type={calibrationedit ? "number" : null}
                              sx={
                                calibrationedit
                                  ? { width: "74px" }
                                  : { width: "51px" }
                              }
                              disabled={calibrationedit ? false : true}
                              onChange={(e) => {
                                changeCalibrationValue(
                                  Number(e.target.value),
                                  key
                                );
                              }}
                              name={data.settingName}
                              defaultValue={data.defaultValue}
                              InputProps={
                                calibrationedit
                                  ? {
                                      inputProps: {
                                        min: data.minValue,
                                        max: data.maxValue,
                                        style: { textAlign: "end" },
                                      },
                                    }
                                  : {
                                      classes,
                                      inputProps: {
                                        style: { textAlign: "end" },
                                      },
                                    }
                              }
                            />

                            <div className="pb-2 font-12">{data.unit}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      );
    }
  };
  const Brewing = () => {
    if (brewingExpand) {
      return (
        <div className="calibration">
          <div>
            <div className="row calibration">
              {listSetting.isLoadiing
                ? null
                : !listSetting.responce
                ? null
                : listSetting.responce.data[0].brewing_variable.map(
                    (data, key) => {
                      return (
                        <div
                          key={key}
                          className="col-lg-4 col-md-6  col-sm-12 p-2"
                        >
                          <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-start">
                              <p className="m-0 text-start font-12 w-100">
                                {data.settingName}
                              </p>
                            </div>
                            <div className="example-spacer"></div>
                            <div className="d-flex text-end  align-items-center justify-content-end">
                              {data.inputType === "select" ? (
                                <Select
                                  disabled={brewingedit ? false : true}
                                  defaultValue={data.defaultValue}
                                  disableUnderline={brewingedit ? false : true}
                                  onChange={(e) => {
                                    changeBrewingVariable(e.target.value, key);
                                  }}
                                >
                                  <MenuItem value={"Low"}>Low</MenuItem>
                                  <MenuItem value={"Medium"}>Medium</MenuItem>
                                  <MenuItem value={"High"}>High</MenuItem>
                                </Select>
                              ) : (
                                <>
                                  <Tooltip
                                    className="pb-3"
                                    title={
                                      <>
                                        {data.inputType === "select" ? (
                                          <div
                                            className="d-flex "
                                            style={{ fontSize: "15px" }}
                                          >
                                            Level ({data.Range})
                                          </div>
                                        ) : (
                                          <div
                                            className="d-flex font-12"
                                            style={{ fontSize: "15px" }}
                                          >
                                            Range ({data.minValue} -{" "}
                                            {data.maxValue})
                                          </div>
                                        )}
                                      </>
                                    }
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <TextField
                                    className="bg-white text-end d-flex justify-content-end formTextInput  p-2"
                                    variant="standard"
                                    type={brewingedit ? "number" : null}
                                    name={data.settingName}
                                    sx={
                                      brewingedit
                                        ? { width: "74px" }
                                        : { width: "51px" }
                                    }
                                    disabled={brewingedit ? false : true}
                                    onChange={(e) => {
                                      changeBrewingVariable(
                                        Number(e.target.value),
                                        key
                                      );
                                    }}
                                    defaultValue={data.defaultValue}
                                    InputProps={
                                      brewingedit
                                        ? {
                                            inputProps: {
                                              min: data.minValue,
                                              max: data.maxValue,
                                              style: { textAlign: "end" },
                                            },
                                          }
                                        : {
                                            classes,
                                            inputProps: {
                                              style: { textAlign: "end" },
                                            },
                                          }
                                    }
                                  />
                                </>
                              )}

                              <div className="pb-2 font-12">{data.unit}</div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
            </div>
          </div>
        </div>
      );
    }
  };
  const Cleaning = () => {
    console.log(cleaningExpand);
    if (cleaningExpand) {
      return (
        <div className="calibration">
          <div>
            <div className="row calibration">
              {listSetting.isLoadiing
                ? null
                : !listSetting.responce
                ? null
                : listSetting.responce.data[0].cleaning_variable_set_1.map(
                    (data, key) => {
                      return (
                        <div
                          key={key}
                          className="col-lg-4 col-md-6  col-sm-12 p-2"
                        >
                          <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-start">
                              <p className="m-0 text-start font-12 w-100">
                                {data.settingName}
                              </p>
                            </div>
                            <div className="example-spacer"></div>
                            <div className="d-flex text-end  align-items-center justify-content-end">
                              {data.inputType === "select" ? (
                                <Select
                                  disabled={cleaningedit ? false : true}
                                  defaultValue={data.defaultValue}
                                  disableUnderline={cleaningedit ? false : true}
                                  name={data.settingName}
                                >
                                  <MenuItem value={"Low"}>Low</MenuItem>
                                  <MenuItem value={"Medium"}>Medium</MenuItem>
                                  <MenuItem value={"High"}>High</MenuItem>
                                </Select>
                              ) : (
                                <>
                                  <Tooltip
                                    className="pb-3"
                                    title={
                                      <>
                                        {data.inputType === "select" ? (
                                          <div
                                            className="d-flex "
                                            style={{ fontSize: "15px" }}
                                          >
                                            Level ({data.Range})
                                          </div>
                                        ) : (
                                          <div
                                            className="d-flex font-12"
                                            style={{ fontSize: "15px" }}
                                          >
                                            Range ({data.minValue} -{" "}
                                            {data.maxValue})
                                          </div>
                                        )}
                                      </>
                                    }
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <TextField
                                    className="bg-white text-end d-flex justify-content-end formTextInput  p-2"
                                    variant="standard"
                                    type={cleaningedit ? "number" : null}
                                    sx={
                                      cleaningedit
                                        ? { width: "74px" }
                                        : { width: "51px" }
                                    }
                                    onChange={(e) => {
                                      changeCleaningVariable(
                                        Number(e.target.value),
                                        key
                                      );
                                    }}
                                    disabled={cleaningedit ? false : true}
                                    defaultValue={data.defaultValue}
                                    name={data.settingName}
                                    InputProps={
                                      cleaningedit
                                        ? {
                                            inputProps: {
                                              min: data.minValue,
                                              max: data.maxValue,
                                              style: { textAlign: "end" },
                                            },
                                          }
                                        : {
                                            classes,
                                            inputProps: {
                                              style: { textAlign: "end" },
                                            },
                                          }
                                    }
                                  />
                                </>
                              )}

                              <div className="pb-2 font-12">{data.unit}</div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
            </div>
          </div>
        </div>
      );
    }
  };
  const Cleaning2 = () => {
    console.log(cleaningExpand);
    if (cleaningExpand2) {
      return (
        <div className="calibration">
          <div>
            <div className="row calibration">
              {listSetting.isLoadiing
                ? null
                : !listSetting.responce
                ? null
                : listSetting.responce.data[0].cleaning_variable_set_2.map(
                    (data, key) => {
                      return (
                        <div
                          key={key}
                          className="col-lg-4 col-md-6  col-sm-12 p-2"
                        >
                          <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-start">
                              <p className="m-0 text-start font-12 w-100">
                                {data.settingName}
                              </p>
                            </div>
                            <div className="example-spacer"></div>
                            <div className="d-flex text-end  align-items-center justify-content-end">
                              {data.inputType === "select" ? (
                                <Select
                                  disabled={cleaningedit2 ? false : true}
                                  defaultValue={data.defaultValue}
                                  disableUnderline={
                                    cleaningedit2 ? false : true
                                  }
                                  name={data.settingName}
                                >
                                  <MenuItem value={"Low"}>Low</MenuItem>
                                  <MenuItem value={"Medium"}>Medium</MenuItem>
                                  <MenuItem value={"High"}>High</MenuItem>
                                </Select>
                              ) : (
                                <>
                                  <Tooltip
                                    className="pb-3"
                                    title={
                                      <>
                                        {data.inputType === "select" ? (
                                          <div
                                            className="d-flex "
                                            style={{ fontSize: "15px" }}
                                          >
                                            Level ({data.Range})
                                          </div>
                                        ) : (
                                          <div
                                            className="d-flex font-12"
                                            style={{ fontSize: "15px" }}
                                          >
                                            Range ({data.minValue} -{" "}
                                            {data.maxValue})
                                          </div>
                                        )}
                                      </>
                                    }
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <TextField
                                    className="bg-white text-end d-flex justify-content-end formTextInput  p-2"
                                    variant="standard"
                                    type={cleaningedit2 ? "number" : null}
                                    sx={
                                      cleaningedit2
                                        ? { width: "74px" }
                                        : { width: "51px" }
                                    }
                                    onChange={(e) => {
                                      changeCleaningVariable2(
                                        Number(e.target.value),
                                        key
                                      );
                                    }}
                                    disabled={cleaningedit2 ? false : true}
                                    defaultValue={data.defaultValue}
                                    name={data.settingName}
                                    InputProps={
                                      cleaningedit2
                                        ? {
                                            inputProps: {
                                              min: data.minValue,
                                              max: data.maxValue,
                                              style: { textAlign: "end" },
                                            },
                                          }
                                        : {
                                            classes,
                                            inputProps: {
                                              style: { textAlign: "end" },
                                            },
                                          }
                                    }
                                  />
                                </>
                              )}

                              <div className="pb-2 font-12">{data.unit}</div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
            </div>
          </div>
        </div>
      );
    }
  };
  const Dispansing = () => {
    console.log(dispansingExpand);
    if (dispansingExpand) {
      return (
        <div className="calibration">
          <div>
            <div className="row calibration">
              {listSetting.isLoadiing
                ? null
                : !listSetting.responce
                ? null
                : listSetting.responce.data[0].dispensing_volume.map(
                    (data, key) => {
                      return (
                        <div
                          key={key}
                          className="col-lg-4 col-md-6  col-sm-12 p-2"
                        >
                          <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-start">
                              <p className="m-0 text-start font-12 w-100">
                                {data.settingName}
                              </p>
                            </div>
                            <div className="example-spacer"></div>
                            <div className="d-flex text-end  align-items-center justify-content-end">
                              {data.inputType === "select" ? (
                                <Select
                                  disabled={dispansingedit ? false : true}
                                  defaultValue={data.defaultValue}
                                  disableUnderline={
                                    dispansingedit ? false : true
                                  }
                                  name={data.settingName}
                                >
                                  <MenuItem value={"Low"}>Low</MenuItem>
                                  <MenuItem value={"Medium"}>Medium</MenuItem>
                                  <MenuItem value={"High"}>High</MenuItem>
                                </Select>
                              ) : (
                                <>
                                  <Tooltip
                                    className="pb-3"
                                    title={
                                      <>
                                        {data.inputType === "select" ? (
                                          <div
                                            className="d-flex "
                                            style={{ fontSize: "15px" }}
                                          >
                                            Level ({data.Range})
                                          </div>
                                        ) : (
                                          <div
                                            className="d-flex font-12"
                                            style={{ fontSize: "15px" }}
                                          >
                                            Range ({data.minValue} -{" "}
                                            {data.maxValue})
                                          </div>
                                        )}
                                      </>
                                    }
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <TextField
                                    className="bg-white text-end d-flex justify-content-end formTextInput  p-2"
                                    variant="standard"
                                    type={dispansingedit ? "number" : null}
                                    sx={
                                      dispansingedit
                                        ? { width: "74px" }
                                        : { width: "51px" }
                                    }
                                    onChange={(e) => {
                                      const value = Number(e.target.value);
                                      const index = key;
                                      changeDispansing(value, key);
                                    }}
                                    disabled={dispansingedit ? false : true}
                                    defaultValue={data.defaultValue}
                                    name={data.settingName}
                                    InputProps={
                                      dispansingedit
                                        ? {
                                            inputProps: {
                                              min: data.minValue,
                                              max: data.maxValue,
                                              style: { textAlign: "end" },
                                            },
                                          }
                                        : {
                                            classes,
                                            inputProps: {
                                              style: { textAlign: "end" },
                                            },
                                          }
                                    }
                                  />
                                </>
                              )}

                              <div className="pb-2 font-12">{data.unit}</div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
            </div>
          </div>
        </div>
      );
    }
  };
  const DeviceSetting = () => {
    // console.log(dispansingExpand);
    if (deviceSettingeditExpand) {
      return (
        <div className="calibration">
          <div>
            <div className="row calibration">
              {listSetting.isLoadiing
                ? null
                : !listSetting.responce
                ? null
                : listSetting.responce.data[0].device_settings.map(
                    (data, key) => {
                      return (
                        <div
                          key={key}
                          className="col-lg-4 col-md-6  col-sm-12 p-2"
                        >
                          <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-start">
                              <p className="m-0 text-start font-12 w-100">
                                {data.settingName}
                              </p>
                            </div>
                            <div className="example-spacer"></div>
                            <div className="d-flex text-end  align-items-center justify-content-end">
                              {data.inputType === "select" ? (
                                <Select
                                  disabled={deviceSettingedit ? false : true}
                                  defaultValue={data.defaultValue}
                                  disableUnderline={
                                    deviceSettingedit ? false : true
                                  }
                                  name={data.settingName}
                                >
                                  <MenuItem value={"Low"}>Low</MenuItem>
                                  <MenuItem value={"Medium"}>Medium</MenuItem>
                                  <MenuItem value={"High"}>High</MenuItem>
                                </Select>
                              ) : (
                                <>
                                  <Tooltip
                                    className="pb-3"
                                    title={
                                      <>
                                        {data.inputType === "select" ? (
                                          <div
                                            className="d-flex "
                                            style={{ fontSize: "15px" }}
                                          >
                                            Level ({data.Range})
                                          </div>
                                        ) : (
                                          <div
                                            className="d-flex font-12"
                                            style={{ fontSize: "15px" }}
                                          >
                                            Range ({data.minValue} -{" "}
                                            {data.maxValue})
                                          </div>
                                        )}
                                      </>
                                    }
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <TextField
                                    className="bg-white text-end d-flex justify-content-end formTextInput  p-2"
                                    variant="standard"
                                    type={deviceSettingedit ? "number" : null}
                                    sx={
                                      deviceSettingedit
                                        ? { width: "74px" }
                                        : { width: "51px" }
                                    }
                                    onChange={(e) => {
                                      console.log(e.target.value, key);
                                      const value = Number(e.target.value);
                                      const index = key;
                                      changeDeviceSetting(value, index);
                                    }}
                                    disabled={deviceSettingedit ? false : true}
                                    defaultValue={data.defaultValue}
                                    name={data.settingName}
                                    InputProps={
                                      deviceSettingedit
                                        ? {
                                            inputProps: {
                                              min: data.minValue,
                                              max: data.maxValue,
                                              style: { textAlign: "end" },
                                            },
                                          }
                                        : {
                                            classes,
                                            inputProps: {
                                              style: { textAlign: "end" },
                                            },
                                          }
                                    }
                                  />
                                </>
                              )}

                              <div className="pb-2 font-12">{data.unit}</div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
            </div>
          </div>
        </div>
      );
    }
  };
  const ShiftTiming = () => {
    // console.log(dispansingExpand);
    if (shiftTimingeditExpand) {
      return (
        <div className="calibration">
          <div>
            <div className="row calibration">
              {listSetting.isLoadiing ? null : !listSetting.responce ? null : (
                <>
                  <div className="col-lg-4 col-md-6  col-sm-12 p-2">
                    <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                      <div className="d-flex text-start justify-content-start">
                        <p className="m-0 text-start font-12 w-100">
                          Third Flash
                        </p>
                      </div>
                      <div className="example-spacer"></div>
                      <div className="d-flex text-end  align-items-center justify-content-end">
                        {
                          <Select
                            disabled={shiftTimingdit ? false : true}
                            defaultValue={
                              listSetting.responce.data[0].shift_timing
                                .ThirdFlash.defaultValue
                            }
                            value={
                              shiftTimingeditValueback.ThirdFlash.defaultValue
                            }
                            disableUnderline={shiftTimingdit ? false : true}
                            onChange={(e) => {
                              changeShiftTiming(e, "", "thirdFlash");
                            }}
                            name={"ThirdFlash"}
                            className="bg-white text-end d-flex justify-content-end formTextInput  p-2"
                          >
                            <MenuItem value={true}>true</MenuItem>
                            <MenuItem value={false}>false</MenuItem>
                          </Select>
                        }

                        <div className="pb-0 px-2 font-12">option</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6  col-sm-12 p-2">
                    <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                      <div className="d-flex text-start justify-content-start">
                        <p className="m-0 text-start font-12 w-100">
                          Brewing Time
                        </p>
                      </div>
                      <div className="example-spacer"></div>
                      <div className="d-flex text-end  align-items-center justify-content-end">
                        {
                          <>
                            <Tooltip
                              className="pb-3"
                              title={"minutes and seconds"}
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                <MobileTimePicker
                                  open={brewingTime}
                                  ampm={false}
                                  onOpen={(e) => {
                                    setBrewingTime(true);
                                  }}
                                  onClose={() => {
                                    setBrewingTime(false);
                                  }}
                                  ampmInClock
                                  views={["minutes", "seconds"]}
                                  inputFormat="mm:ss"
                                  openTo="minutes"
                                  key="brewingTime"
                                  mask="__:__"
                                  InputProps={{
                                    disableUnderline: shiftTimingdit
                                      ? false
                                      : true,
                                  }}
                                  disabled={shiftTimingdit ? false : true}
                                  value={
                                    shiftTimingeditValueback.BrewingTime
                                      .defaultValue
                                  }
                                  onChange={(newValue) => {
                                    setFocus("BrewingTime");
                                    changeShiftTiming(
                                      newValue,
                                      "",
                                      "BrewingTime"
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      key="brewingTime"
                                      id="vijay7"
                                      autoFocus={
                                        focus === "BrewingTime" ? true : false
                                      }
                                      defaultValue={
                                        listSetting.responce.data[0]
                                          .shift_timing.BrewingTime.defaultValue
                                      }
                                      variant="standard"
                                      sx={
                                        shiftTimingdit
                                          ? { width: "48px" }
                                          : { width: "48px" }
                                      }
                                      {...params}
                                    />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </>
                        }

                        <div className="pb-0 px-2 font-12">time</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6  col-sm-12 p-2"></div>
                  <div className="col-lg-1 col-md-6 d-flex align-items-end  col-sm-12 p-2">
                    <div
                      type="button"
                      className="p-2 px-4 w-100 border align-items-center rounded d-flex"
                      onClick={(e) => {
                        if (shiftTimingdit) {
                          // console.log(shiftTimingdit);
                          let va = [...shiftTimingeditValueback.shift_timing];
                          va.push(shiftTimingeditValueback.shift_timing[0]);
                          const shift_timing = [...va];
                          setshiftTimingeditValueback(
                            (shiftTimingeditValueback) => ({
                              ...shiftTimingeditValueback,
                              shift_timing,
                            })
                          );
                        }
                      }}
                    >
                      <div className="d-flex text-start justify-content-center">
                        <p className="m-0 text-start font-12 w-100">Add</p>
                      </div>
                    </div>
                  </div>

                  {shiftTimingeditValueback.shift_timing.map((data, key) => {
                    return (
                      <>
                        <div className="col-lg-1 col-md-6 d-flex align-items-end  col-sm-12 p-2">
                          <div className="p-2 px-4 w-100 border h-100 align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-center">
                              <p className="m-0 text-center h-100 font-12 w-100">
                                ST.{key + 1}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-sm-12 p-2">
                          <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-start">
                              <p className="m-0 text-start font-12 w-100">
                                Start Time
                              </p>
                            </div>
                            <div className="example-spacer"></div>
                            <div className="d-flex text-end  align-items-center justify-content-end">
                              {
                                <>
                                  <Tooltip
                                    className="pb-3"
                                    title={"hours and minutes"}
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <Stack spacing={3}>
                                      <MobileTimePicker
                                        // defaultValue={data.defaultValue}
                                        open={startTimeOpen}
                                        onOpen={(e) => {
                                          setStartTimeope(true);
                                        }}
                                        ampm={false}
                                        openTo="hours"
                                        views={["hours", "minutes"]}
                                        inputFormat="HH:mm"
                                        key="startdate"
                                        onClose={() => {
                                          setStartTimeope(false);
                                        }}
                                        mask="__:__"
                                        InputProps={{
                                          disableUnderline: shiftTimingdit
                                            ? false
                                            : true,
                                        }}
                                        disabled={shiftTimingdit ? false : true}
                                        value={data[0].defaultValue}
                                        onChange={(newValue) => {
                                          setFocus("startTime");
                                          console.log(newValue);

                                          changeShiftTiming(
                                            newValue,
                                            key,
                                            "startTime"
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            id="vijay5"
                                            key="startdate"
                                            disabled
                                            // autoFocus={
                                            //   focus === "startTime"
                                            //     ? true
                                            //     : false
                                            // }
                                            variant="standard"
                                            sx={
                                              shiftTimingdit
                                                ? { width: "48px" }
                                                : { width: "48px" }
                                            }
                                            {...params}
                                          />
                                        )}
                                      />
                                    </Stack>
                                  </LocalizationProvider>
                                </>
                              }

                              <div className="pb-0 px-2 font-12">time</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6  col-sm-12 p-2">
                          <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-start">
                              <p className="m-0 text-start font-12 w-100">
                                End Time
                              </p>
                            </div>
                            <div className="example-spacer"></div>
                            <div className="d-flex text-end  align-items-center justify-content-end">
                              {
                                <>
                                  <Tooltip
                                    className="pb-3"
                                    title={"hours and minutes"}
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <Stack spacing={3}>
                                      <MobileTimePicker
                                        open={endTime}
                                        onOpen={(e) => {
                                          setEndTime(true);
                                        }}
                                        onClose={() => {
                                          setEndTime(false);
                                        }}
                                        ampmInClock
                                        ampm={false}
                                        key="EndDate"
                                        InputProps={{
                                          disableUnderline: shiftTimingdit
                                            ? false
                                            : true,
                                        }}
                                        id="vijay3"
                                        // autoFocus
                                        disabled={shiftTimingdit ? false : true}
                                        value={data[1].defaultValue}
                                        onChange={(newValue) => {
                                          setFocus("endTime");

                                          changeShiftTiming(
                                            newValue,
                                            key,
                                            "endTime"
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            key={"EndDate"}
                                            id="vijay2"
                                            autoFocus={
                                              focus === "endTime" ? true : false
                                            }
                                            defaultValue={data[1].defaultValue}
                                            variant="standard"
                                            sx={
                                              shiftTimingdit
                                                ? { width: "48px" }
                                                : { width: "48px" }
                                            }
                                            {...params}
                                          />
                                        )}
                                      />
                                    </Stack>
                                  </LocalizationProvider>
                                </>
                              }

                              <div className="pb-0 px-2 font-12">time</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-sm-12 p-2">
                          <div className="p-2 px-4 w-100 border align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-start">
                              <p className="m-0 text-start font-12 w-100">
                                Cup Counts
                              </p>
                            </div>
                            <div className="example-spacer"></div>
                            <div
                              key={"names"}
                              className="d-flex text-end  align-items-center justify-content-end"
                            >
                              {
                                <>
                                  <Tooltip className="pb-3" title={"number"}>
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {/* <MachineSettingNumberInput
                                    // changeShiftTiming={changeShiftTiming}
                                    keyval={key}
                                    name="cupCounts"
                                    sx={
                                      shiftTimingdit
                                        ? { width: "54px" }
                                        : { width: "54px" }
                                    }
                                    setshiftTimingeditValueback={
                                      setshiftTimingeditValueback
                                    }
                                    shiftTimingeditValueback={
                                      shiftTimingeditValueback
                                    }
                                    shiftTimingdit={shiftTimingdit}
                                    placeholder=""
                                    defaultvalue={data[2].defaultValue}
                                  /> */}

                                  <TextField
                                    variant="standard"
                                    type="number"
                                    id="vijay"
                                    key="cupCounts"
                                    onChange={(e) => {
                                      setFocus("cupCounts");

                                      changeShiftTiming(e, key, "cupCounts");
                                    }}
                                    value={data[2].defaultValue}
                                    sx={
                                      shiftTimingdit
                                        ? { width: "54px" }
                                        : { width: "54px" }
                                    }
                                    autoFocus={
                                      focus === "cupCounts" ? true : false
                                    }
                                    defaultValue={data[2].defaultValue}
                                    InputProps={{
                                      disableUnderline: shiftTimingdit
                                        ? false
                                        : true,
                                    }}
                                    disabled={shiftTimingdit ? false : true}
                                  />
                                </>
                              }

                              {/* <div className="pb-0 px-2 font-12">time</div> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1 col-md-6 d-flex align-items-end  col-sm-12 p-2">
                          <div className="p-2 px-4 w-100  h-100 justify-content-center align-items-center rounded d-flex">
                            <div className="d-flex text-start justify-content-center">
                              <p className="m-0 text-center h-100 font-12 w-100">
                                <FontAwesomeIcon
                                  type="button"
                                  icon="fa-solid fa-trash"
                                  onClick={() => {
                                    if (shiftTimingdit) {
                                      let va = [
                                        ...shiftTimingeditValueback.shift_timing,
                                      ];
                                      if (key != 0) {
                                        va.splice(key, 1);
                                      }
                                      const shift_timing = [...va];
                                      setshiftTimingeditValueback(
                                        (shiftTimingeditValueback) => ({
                                          ...shiftTimingeditValueback,
                                          shift_timing,
                                        })
                                      );
                                    }
                                  }}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="comp-change">
      {listSetting.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <Stack className="mx-3 px-2 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form px-4  hoel w-100  ">
        <div className=" p-2 px-0 gensetting font-weight-bold">
          GENARAL SETTING
        </div>
        {/* calibration */}
        <Paper className="bg-white  w-100 mechinesetting">
          <div
            className={
              calibrationExpand
                ? "insidehedervalue lightgrey p-3 w-100 d-flex"
                : "bor-14 lightgrey p-3 w-100 d-flex"
            }
          >
            <div className="d-flex w-50 align-items-center">
              <div className="px-2 font-15 gensetting">Calibration Setting</div>
              <div className="text-sm settingvalues text-muted">
                {listSetting.responce.data
                  ? listSetting.responce.data[0].calibration_value.length
                  : 0}{" "}
                setting
              </div>
            </div>
            <div className="d-flex w-50  align-items-center justify-content-end">
              <div>
                {calibrationedit ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    type="button"
                    icon="fa-solid fa-floppy-disk"
                    title="save"
                    onClick={() => {
                      setcalibrationEdit(false);
                      submitData();
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    type="button"
                    title="edit"
                    className="px-3 font-size-24"
                    icon="fa-solid fa-pen-to-square"
                    onClick={() => {
                      setcalibrationEdit(true);
                    }}
                  />
                )}
              </div>
              <div>
                {calibrationExpand ? (
                  <FontAwesomeIcon
                    type="button"
                    className="px-3 font-size-24"
                    icon="fa-solid fa-angle-up"
                    title="hide"
                    onClick={() => {
                      setcalibrationExpand(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    type="button"
                    className="px-3 font-size-24"
                    title="Exand"
                    icon="fa-solid  fa-chevron-down"
                    onClick={() => {
                      setcalibrationExpand(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <Calbration />
        </Paper>
        {/* Brewing Variables */}
        <Paper className="bg-white mt-4 w-100 mechinesetting">
          <div
            className={
              calibrationExpand
                ? "insidehedervalue lightgrey p-3 w-100 d-flex"
                : "bor-14 lightgrey p-3 w-100 d-flex"
            }
          >
            <div className="d-flex w-50 align-items-center">
              <div className="px-2 font-15 gensetting">Brewing Variables</div>
              <div className="text-sm font-12 settingvalues text-muted">
                {listSetting.responce.data
                  ? listSetting.responce.data[0].brewing_variable.length
                  : 0}{" "}
                setting
              </div>
            </div>
            <div className="d-flex w-50  align-items-center justify-content-end">
              <div>
                {brewingedit ? (
                  <FontAwesomeIcon
                    type="button"
                    className="px-3 font-size-24"
                    icon="fa-solid fa-floppy-disk"
                    title="save"
                    onClick={() => {
                      setBrewingEdit(false);
                      submitData();
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    type="button"
                    title="edit"
                    className="px-3 font-size-24"
                    icon="fa-solid fa-pen-to-square"
                    onClick={() => {
                      setBrewingEdit(true);
                    }}
                  />
                )}
              </div>
              <div>
                {brewingExpand ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    type="button"
                    icon="fa-solid fa-angle-up"
                    title="hide"
                    onClick={() => {
                      setBrewingExpand(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    title="expand"
                    type="button"
                    className="px-3 font-size-24"
                    icon="fa-solid  fa-chevron-down"
                    onClick={() => {
                      setBrewingExpand(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <Brewing />
        </Paper>
        {/* Cleaning Variables set 1*/}
        <Paper className="bg-white mt-4 w-100 mechinesetting">
          <div
            className={
              cleaningExpand
                ? "insidehedervalue lightgrey p-3 w-100 d-flex"
                : "bor-14 lightgrey p-3 w-100 d-flex"
            }
          >
            <div className="d-flex w-50 align-items-center">
              <div className="px-2 font-15 gensetting">
                Cleaning Variables Set 1
              </div>
              <div className="text-sm font-12 settingvalues text-muted">
                {listSetting.responce.data
                  ? listSetting.responce.data[0].cleaning_variable_set_1.length
                  : 0}{" "}
                setting
              </div>
            </div>
            <div className="d-flex w-50  align-items-center justify-content-end">
              <div>
                {cleaningedit ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    type="button"
                    icon="fa-solid fa-floppy-disk"
                    title="save"
                    onClick={() => {
                      setCleaningEdit(false);
                      submitData();
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    type="button"
                    title="edit"
                    icon="fa-solid fa-pen-to-square"
                    onClick={() => {
                      setCleaningEdit(true);
                    }}
                  />
                )}
              </div>
              <div>
                {cleaningExpand ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    icon="fa-solid fa-angle-up"
                    type="button"
                    title="hide"
                    onClick={() => {
                      setCleaningExpand(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    title="expand"
                    type="button"
                    icon="fa-solid  fa-chevron-down"
                    onClick={() => {
                      setCleaningExpand(true);
                      console.log("adsdfasf");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <Cleaning />
        </Paper>
        {/* Cleaning Variables set 2*/}
        <Paper className="bg-white mt-4 w-100 mechinesetting">
          <div
            className={
              cleaningExpand
                ? "insidehedervalue lightgrey p-3 w-100 d-flex"
                : "bor-14 lightgrey p-3 w-100 d-flex"
            }
          >
            <div className="d-flex w-50 align-items-center">
              <div className="px-2 font-15 gensetting">
                Cleaning Variables Set 2
              </div>
              <div className="text-sm font-12 settingvalues text-muted">
                {listSetting.responce.data
                  ? listSetting.responce.data[0].cleaning_variable_set_2.length
                  : 0}{" "}
                setting
              </div>
            </div>
            <div className="d-flex w-50  align-items-center justify-content-end">
              <div>
                {cleaningedit2 ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    title="save"
                    icon="fa-solid fa-floppy-disk"
                    type="button"
                    onClick={() => {
                      setCleaningEdit2(false);
                      submitData();
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    title="edit"
                    type="button"
                    icon="fa-solid fa-pen-to-square"
                    onClick={() => {
                      setCleaningEdit2(true);
                    }}
                  />
                )}
              </div>
              <div>
                {cleaningExpand2 ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    type="button"
                    title="hide"
                    icon="fa-solid fa-angle-up"
                    onClick={() => {
                      setCleaningExpand2(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    title="expand"
                    type="button"
                    className="px-3 font-size-24"
                    icon="fa-solid  fa-chevron-down"
                    onClick={() => {
                      setCleaningExpand2(true);
                      console.log("adsdfasf");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <Cleaning2 />
        </Paper>
        {/* Cleaning Variables set 2*/}
        <Paper className="bg-white mt-4 w-100 mechinesetting">
          <div
            className={
              cleaningExpand
                ? "insidehedervalue lightgrey p-3 w-100 d-flex"
                : "bor-14 lightgrey p-3 w-100 d-flex"
            }
          >
            <div className="d-flex w-50 align-items-center">
              <div className="px-2 font-15 gensetting">Dispensing Volume</div>
              <div className="text-sm font-12 settingvalues text-muted">
                {listSetting.responce.data
                  ? listSetting.responce.data[0].dispensing_volume.length
                  : 0}{" "}
                setting
              </div>
            </div>
            <div className="d-flex w-50  align-items-center justify-content-end">
              <div>
                {dispansingedit ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    title="save"
                    type="button"
                    icon="fa-solid fa-floppy-disk"
                    onClick={() => {
                      setdispansingedit(false);
                      submitData();
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    title="expand"
                    type="button"
                    className="px-3 font-size-24"
                    icon="fa-solid fa-pen-to-square"
                    onClick={() => {
                      setdispansingedit(true);
                    }}
                  />
                )}
              </div>
              <div>
                {dispansingExpand ? (
                  <FontAwesomeIcon
                    title="hide"
                    className="px-3 font-size-24"
                    type="button"
                    icon="fa-solid fa-angle-up"
                    onClick={() => {
                      setdispansingExpand(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    title="expand"
                    type="button"
                    icon="fa-solid  fa-chevron-down"
                    onClick={() => {
                      setdispansingExpand(true);
                      console.log("adsdfasf");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <Dispansing />
        </Paper>
        <Paper className="bg-white mt-4 w-100 mechinesetting">
          <div
            className={
              deviceSettingeditExpand
                ? "insidehedervalue lightgrey p-3 w-100 d-flex"
                : "bor-14 lightgrey p-3 w-100 d-flex"
            }
          >
            <div className="d-flex w-50 align-items-center">
              <div className="px-2 font-15 gensetting">Device Setting</div>
              <div className="text-sm font-12 settingvalues text-muted">
                {listSetting.responce.data
                  ? listSetting.responce.data[0].device_settings.length
                  : 0}{" "}
                setting
              </div>
            </div>
            <div className="d-flex w-50  align-items-center justify-content-end">
              <div>
                {deviceSettingedit ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    title="save"
                    type="button"
                    icon="fa-solid fa-floppy-disk"
                    onClick={() => {
                      setdeviceSettingedit(false);
                      submitData();
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    title="edit"
                    type="button"
                    icon="fa-solid fa-pen-to-square"
                    onClick={() => {
                      setdeviceSettingedit(true);
                    }}
                  />
                )}
              </div>
              <div>
                {deviceSettingeditExpand ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    type="button"
                    icon="fa-solid fa-angle-up"
                    title="hide"
                    onClick={() => {
                      setdeviceSettingeditExpand(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    type="button"
                    title="expand"
                    icon="fa-solid  fa-chevron-down"
                    onClick={() => {
                      setdeviceSettingeditExpand(true);
                      // console.log("adsdfasf");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <DeviceSetting />
        </Paper>
        {/* shift timing */}
        <Paper className="bg-white mt-4 w-100 mechinesetting">
          <div
            className={
              shiftTimingeditExpand
                ? "insidehedervalue lightgrey p-3 w-100 d-flex"
                : "bor-14 lightgrey p-3 w-100 d-flex"
            }
          >
            <div className="d-flex w-50 align-items-center">
              <div className="px-2 font-15 gensetting">
                Shift timing Setting
              </div>
              <div className="text-sm font-12 settingvalues text-muted">
                {listSetting.responce.data ? 2 : 0} setting
              </div>
            </div>
            <div className="d-flex w-50  align-items-center justify-content-end">
              <div>
                {shiftTimingdit ? (
                  <FontAwesomeIcon
                    className="px-3 font-size-24"
                    title="save"
                    type="button"
                    icon="fa-solid fa-floppy-disk"
                    onClick={() => {
                      setshiftTimingedit(false);
                      submitData();
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    title="edit"
                    className="px-3 font-size-24"
                    icon="fa-solid fa-pen-to-square"
                    type="button"
                    onClick={() => {
                      setshiftTimingedit(true);
                    }}
                  />
                )}
              </div>
              <div>
                {shiftTimingeditExpand ? (
                  <FontAwesomeIcon
                    title="hide"
                    className="px-3 font-size-24"
                    type="button"
                    icon="fa-solid fa-angle-up"
                    onClick={() => {
                      setshiftTimingeditExpand(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    title="expand"
                    type="button"
                    className="px-3 font-size-24"
                    icon="fa-solid  fa-chevron-down"
                    onClick={() => {
                      setshiftTimingeditExpand(true);
                      // console.log("adsdfasf");
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <ShiftTiming />
        </Paper>
      </div>
    </div>
  );
}

export default MachineSetting;
