import React, { useEffect, useState } from "react";
import "../../assets/css/machineMaster.css";
import { Paper, Link } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import BarChart from "../../insideComponents/dashbord/charts/barchart";
import tabledatas from "../../sampleData/viewmachinetickets.json";
import MainTable from "../../insideComponents/mainTable";
import { useNavigate } from "react-router-dom";
import DropdownSelecter from "../forms/dropdownSelecter";
import { Autocomplete, TextField, Box, LinearProgress } from "@mui/material";
import { listMachineRequest } from "../../redux/reducer/serviceRequest/servise_requestApiService";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getMachineUsage } from "../../redux/reducer/machine_usage/machineUsageApiService";
import { clearMachineUsage } from "../../redux/reducer/machine_usage/viewMachineUsage";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "100",
    },
  },
});

const ViewMachinedetails = () => {
  const viewData = useSelector((state) => state.viewMachineEditData);
  const machineRequest = useSelector((state) => state.ListMachineRequest);
  const machineUsage = useSelector((state) => state.MachineUsage);
  const dispatch = useDispatch();
  const [values, setValues] = useState();
  const classes = useStyles();
  const navigate = useNavigate();
  let oneMonthPreviosDate = new Date();
  oneMonthPreviosDate.setMonth(oneMonthPreviosDate.getMonth() - 1);

  const [DateRangeStartDate, setDateRangeStartDate] =
    useState(oneMonthPreviosDate);
  const [DateRangeEndDate, setDateRangeEndDate] = useState(new Date());
  const [activeChanrtData, setActiveChartData] = useState("month");

  const [tabledata, setTableData] = useState(tabledatas);
  //brudcrumbs
  console.log(viewData.vieaData);
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey brud font-weight-bold font-weight-bol"
    >
      USER MASTER
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey brud font-weight-bold font-weight-bol"
      key="2"
      color="inherit"
      onClick={() => {
        dispatch(clearMachineUsage());
        navigate("/usermaster/viewcompany");
      }}
    >
      VIEW COMPANY
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey brud font-weight-bold font-weight-bol"
      key="3"
      color="inherit"
      onClick={() => {
        dispatch(clearMachineUsage());
        navigate("/usermaster/viewcompany/company_mapped_machine");
      }}
    >
      {viewData.vieaData.companyName}
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey brud font-weight-bold font-weight-bol"
      key="3"
      color="inherit"
    >
      {viewData.vieaData.name}
    </Link>,
  ];

  //useEffect
  useEffect(() => {
    dispatch(
      listMachineRequest(
        "?machine_id=" +
          viewData.vieaData._id +
          "&from_date=" +
          DateRangeStartDate +
          "&toDate=" +
          DateRangeEndDate
      )
    );
    dispatch(
      getMachineUsage(
        "/view_machine?machine_id=" +
          viewData.vieaData._id +
          "&from_date=" +
          DateRangeStartDate +
          "&toDate=" +
          DateRangeEndDate
      )
    );
  }, []);

  //get date  range filter
  const onEventService = (event, picker) => {
    // console.log(picker.startDate);
  };
  const callbackService = (start, end, lebal) => {
    setDateRangeStartDate(start._d);
    setDateRangeEndDate(end._d);
    dateRangeFilter(start._d, end._d);
  };
  const dateRangeFilter = (start, end) => {
    dispatch(
      getMachineUsage(
        "/view_machine?machine_id=" +
          viewData.vieaData._id +
          "&from_date=" +
          start +
          "&toDate=" +
          end
      )
    );
    dispatch(
      listMachineRequest(
        "?machine_id=" +
          viewData.vieaData._id +
          "&from_date=" +
          start +
          "&toDate=" +
          end
      )
    );
  };
  console.log(viewData.vieaData);
  const role = [
    { value: "NLC india private Limited", label: "NLC india private Limited" },
    { value: "Agnia Technology", label: "Agnia Technology" },
    { value: "Water Surprise", label: "Water Surprise" },
  ];

  //filter table data
  const filter = () => {
    if (machineRequest.responce.data) {
      const filterarr = [];

      for (let i = 0; i < machineRequest.responce.data.length; i++) {
        let data = { ...machineRequest.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const requsteddate = new Date(data.requested_date);
        const assigned_datedate = new Date(data.assigned_date);
        const sheduleddate = new Date(data.shedule_date);
        const requsted_date =
          requsteddate.getDate() +
          "-" +
          monthNames[requsteddate.getMonth()] +
          "-" +
          requsteddate.getFullYear();
        const assigned_date =
          assigned_datedate.getDate() +
          "-" +
          monthNames[assigned_datedate.getMonth()] +
          "-" +
          assigned_datedate.getFullYear();

        const newarr = {};

        newarr._id = data._id;
        newarr.raised_by =
          data.raised_user.length != 0 ? data.raised_user[0].name : "-";

        newarr.request_type = data.request_type;
        newarr.description = data.description;
        newarr.status = data.status;
        newarr.requested_date = requsted_date;
        newarr.assigned_to = data.assigned_to
          ? data.assigned_user
            ? data.assigned_user[0].name
            : "-"
          : "-";
        newarr.assigned_date = data.assigned_date ? assigned_date : "-";

        filterarr.push(newarr);
      }

      return (
        <MainTable
          tabledata={filterarr}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tabledata={filterarr}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };
  //chart data
  const teaChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const flavourTeaChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const milkChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  //chart heders
  const chartvalue = ["Tea", "Flavour Tea", "Milk"];

  let filteredData = [];
  let monthlyFilters = !machineUsage.isLoading
    ? monthlyFiltersdata(
        machineUsage.responce,
        DateRangeStartDate,
        DateRangeEndDate
      )
    : "";
  let dailyFilters = !machineUsage.isLoading
    ? dailyFilter(machineUsage.responce, DateRangeStartDate, DateRangeEndDate)
    : "";
  let hourlyFilters = !machineUsage.isLoading
    ? hourlyFilter(machineUsage.responce, DateRangeStartDate, DateRangeEndDate)
    : "";
  if (activeChanrtData === "hour") {
    filteredData = hourlyFilters;
  }
  if (activeChanrtData === "daily") {
    filteredData = dailyFilters;
  }
  if (activeChanrtData === "month") {
    filteredData = monthlyFilters;
  }

  return (
    <>
      <div className="comp-change">
        {machineRequest.isLoading ? (
          <div className="border">
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </div>
        ) : (
          ""
        )}
        <Stack className="mx-4 flex-row pad d-flex align-items-center ">
          <Breadcrumbs
            className="w-100"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
          <div className="d-flex justify-content-end px-2 align-items-start  w-100">
            <div
              style={{ borderRadius: "26px", height: "47px" }}
              className="w-50 d-flex search px-3 justify-content-center pt-2 pb-1 radias-24 bg-white"
            >
              <Autocomplete
                value={values}
                sx={{ width: "90%" }}
                options={role}
                renderInput={(params) => (
                  <TextField
                    classes={{ root: classes.customTextField }}
                    InputLabelProps={{
                      fontSize: "20%",
                    }}
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    // label={" Machine History"}
                    placeholder="Machine History"
                    variant="standard"
                  />
                )}
              />
            </div>
          </div>
          <div className=" px-2 pt-1 d-flex justify-content-end dateRange align-items-center">
            <CalendarMonthIcon className="d-flex  align-items-center " />
            <p className="d-flex px-1 m-0 align-items-center">
              <DateRangePicker
                initialSettings={{
                  startDate: oneMonthPreviosDate,
                  endDate: new Date(),
                  locale: {
                    format: "DD/MM/YYYY",
                  },
                }}
                className="date"
                onEvent={onEventService}
                onCallback={callbackService}
              >
                <input type="button" className=" date " />
              </DateRangePicker>
            </p>
          </div>
        </Stack>
        <div className="px-4 ">
          <div className="row  ">
            <div className="col-6 d-flex radias-14 justify-content-start p-3">
              <Paper className="d-flex radias-14">
                <div className="w-100 d-flex">
                  <div className="col-5 ">
                    <div className="d-flex justify-content-center pt-4">
                      <img
                        className="w-50 pt-3"
                        src={require("../../assets/image/Cofee-machine.png")}
                      />
                    </div>
                    <div
                      style={{ paddingLeft: "10px" }}
                      className="d-flex  pt-4 justify-content-center"
                    >
                      <p className="viewtext px-2 m-0">
                        {viewData.vieaData.name}
                      </p>
                    </div>
                    <div className="d-flex pt-3 justify-content-center">
                      <p className="viewtext1 m-0">NLC india private Limited</p>
                    </div>
                    <div className="d-flex justify-content-center pb-2">
                      <p className="viewtext1 m-0">peelamedu,Coimbatore</p>
                    </div>
                  </div>
                  <div className="col-7 p-4 align-items-center d-flex flex-column">
                    <Paper className="bg-green actview d-flex  radias-14 justify-content-center p-2">
                      <div className="d-flex justify-content-center flex-column">
                        <div className="text-white font-12 text-center">
                          Machine Status
                        </div>
                        <div
                          className="text-white viewtext"
                          style={{
                            fontSize: "30px",
                          }}
                        >
                          {viewData.vieaData.Active ? "Active" : "In Active"}
                        </div>
                      </div>
                    </Paper>
                    <div className="row pt-4 ">
                      <div className="col-6  p-2">
                        <div className="border w-100  p-2 radias-14">
                          <div className="w-100 font-12 d-flex justify-content-center ">
                            Open
                          </div>
                          <div className="d-flex op font-19  justify-content-center">
                            2
                          </div>
                        </div>
                      </div>
                      <div className="col-6 p-2">
                        <div className="border p-2 radias-14">
                          <div className="w-100 font-12 d-flex justify-content-center">
                            Assigned
                          </div>
                          <div className="d-flex op font-19  justify-content-center">
                            2
                          </div>
                        </div>
                      </div>
                      <div className="col-6 p-2">
                        <div className="border p-2 radias-14">
                          <div className="w-100 font-12 d-flex justify-content-center">
                            Issue Fixed
                          </div>
                          <div className="d-flex font-19  op justify-content-center">
                            2
                          </div>
                        </div>
                      </div>
                      <div className="col-6 p-2">
                        <div className="border p-2 radias-14">
                          <div className="w-100 font-12 d-flex justify-content-center">
                            Closed
                          </div>
                          <div className="d-flex font-19  op justify-content-center">
                            122
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <div className="col-6 d-flex justify-content-center p-3">
              {/* barchart */}
              <Paper className="w-100  radias-14 p-4">
                <div className="d-flex w-100">
                  <div className="w-100 text-uppercase font-weight-bold">
                    Cup Count Usage
                  </div>
                  <div className="d-flex w-100 justify-content-end">
                    <div
                      onClick={() => {
                        setActiveChartData("month");
                      }}
                      type="button"
                      className={
                        activeChanrtData === "month"
                          ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                          : "mx-2 text-uppercase text-smaller"
                      }
                    >
                      Monthly
                    </div>
                    <div
                      onClick={() => {
                        setActiveChartData("daily");
                      }}
                      type="button"
                      className={
                        activeChanrtData === "daily"
                          ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                          : "mx-2 text-uppercase text-smaller"
                      }
                    >
                      Daily
                    </div>
                    <div
                      onClick={() => {
                        setActiveChartData("hour");
                      }}
                      type="button"
                      className={
                        activeChanrtData === "hour"
                          ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                          : "mx-2 text-uppercase text-smaller"
                      }
                    >
                      Hourly
                    </div>
                  </div>
                </div>
                {machineUsage.responce ? (
                  <BarChart
                    // className="scroll-barx"
                    chartData={
                      filteredData.length === 0
                        ? [teaChartData, flavourTeaChartData, milkChartData]
                        : [
                            filteredData.teaUsage,
                            filteredData.flavourTeaUsage,
                            filteredData.milkUsage,
                          ]
                    }
                    displaymonth={
                      filteredData.length === 0 ? "" : filteredData.month
                    }
                    chartvalue={chartvalue}
                    color={["#fbd125", "#4fa6ee", "#00cec2"]}
                  />
                ) : (
                  ""
                )}
              </Paper>
            </div>
          </div>
        </div>
        <div className="mb-4">{machineRequest.isLoading ? "" : filter()}</div>
      </div>
    </>
  );
};

const monthlyFiltersdata = (usage, startDate, endDate) => {
  if (usage) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // get month diffrance
    function diff(from, to) {
      var arr = [];
      var datFrom = new Date("1 " + from);
      var datTo = new Date("1 " + to);

      var fromYear = datFrom.getFullYear();
      var toYear = datTo.getFullYear();
      var diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

      for (var i = datFrom.getMonth(); i <= diffYear; i++) {
        arr.push(monthNames[i % 12] + " " + Math.floor(fromYear + i / 12));
      }

      return arr;
    }

    let start = `${
      monthNames[startDate.getMonth()]
    } ${startDate.getFullYear()}`;
    let end = `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}`;

    const all_month = diff(start, end);

    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    const monthArray = [];
    const flavourArray = [];
    const milkArray = [];

    usage.data.forEach((e, key) => {
      all_month.forEach((month, monthkey) => {
        // console.log(monthkey);
        if (
          `${new Date(e.usage_date).getMonth()}${new Date(
            e.usage_date
          ).getFullYear()}` ===
          `${new Date("1 " + all_month[monthkey]).getMonth()}${new Date(
            "1 " + all_month[monthkey]
          ).getFullYear()}`
        ) {
          e.cup_count.forEach((cup_count, cup_count_key) => {
            // console.log(cup_count);
            if (cup_count.name == "Tea") {
              let indexofmonth = monthArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                monthArray[indexofmonth] = {
                  name: cup_count.name,
                  value: cup_count.value + monthArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                monthArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (cup_count.name == "Flavour Tea") {
              let indexofmonth = flavourArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                flavourArray[indexofmonth] = {
                  name: cup_count.name,
                  value: cup_count.value + flavourArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                flavourArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (cup_count.name == "Milk") {
              let indexofmonth = milkArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                milkArray[indexofmonth] = {
                  name: cup_count.name,
                  value: cup_count.value + milkArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                milkArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: all_month[monthkey],
                });
              }
            }
          });
        }
      });
    });

    let teausageData = [];
    let falvourteaUsage = [];
    let milkUsage = [];
    for (let i = 0; i < all_month.length; i++) {
      teausageData.push(0);
      falvourteaUsage.push(0);
      milkUsage.push(0);
    }
    monthArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      teausageData[index] = e.value;
    });
    flavourArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      falvourteaUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    const months = [];
    all_month.forEach((a) => {
      months.push(`${a.substring(0, 3)}-${a.slice(-2)}`);
    });

    let monthData = [];
    let teaData = [];
    let flavourData = [];
    let milkData = [];
    months.forEach((e, key) => {
      if (teausageData[key] + falvourteaUsage[key] + milkUsage[key] != 0) {
        monthData.push(e);
        teaData.push(teausageData[key]);
        flavourData.push(falvourteaUsage[key]);
        milkData.push(milkUsage[key]);
      }
    });

    return {
      month: monthData,
      teaUsage: teaData,
      flavourTeaUsage: flavourData,
      milkUsage: milkData,
    };
  }
};
const dailyFilter = (usage, startDate, endDate) => {
  if (usage) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    function getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    }

    // Usage
    const dates = getDates(startDate, endDate);
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let teaArray = [];
    let flavourArray = [];
    let milkArray = [];
    usage.data.forEach((e, key) => {
      dates.forEach((day, dayKey) => {
        const check =
          `${dates[dayKey].getDate()}${dates[dayKey].getMonth()}${dates[
            dayKey
          ].getFullYear()}` ===
          `${new Date(e.usage_date).getDate()}${new Date(
            e.usage_date
          ).getMonth()}${new Date(e.usage_date).getFullYear()}`;
        if (check) {
          e.cup_count.forEach((cup_count, cup_count_key) => {
            if (cup_count.name == "Tea") {
              let indexOfDay = teaArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                teaArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + teaArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                teaArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: dates[dayKey],
                });
              }
            }
            if (cup_count.name == "Flavour Tea") {
              let indexOfDay = flavourArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                flavourArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + flavourArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                flavourArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: dates[dayKey],
                });
              }
            }
            if (cup_count.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + milkArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                milkArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: dates[dayKey],
                });
              }
            }
          });
        }
      });
    });
    let teausageData = [];
    let falvourteaUsage = [];
    let milkUsage = [];
    for (let i = 0; i < dates.length; i++) {
      teausageData.push(0);
      falvourteaUsage.push(0);
      milkUsage.push(0);
    }
    teaArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      teausageData[index] = e.value;
    });
    flavourArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      falvourteaUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    const months = [];
    dates.forEach((a) => {
      const da = `${a.getDate()}-${monthNames[a.getMonth()].substring(
        0,
        3
      )}-${a.getFullYear()}`;
      months.push(da);
    });
    let monthData = [];
    let teaData = [];
    let flavourData = [];
    let milkData = [];
    months.forEach((e, key) => {
      if (teausageData[key] + falvourteaUsage[key] + milkUsage[key] != 0) {
        monthData.push(e);
        teaData.push(teausageData[key]);
        flavourData.push(falvourteaUsage[key]);
        milkData.push(milkUsage[key]);
      }
    });

    return {
      month: monthData,
      teaUsage: teaData,
      flavourTeaUsage: flavourData,
      milkUsage: milkData,
    };
  }
};
const hourlyFilter = (usage, startDate, endDate) => {
  if (usage) {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let teaArray = [];
    let flavourArray = [];
    let milkArray = [];
    usage.data.forEach((e, key) => {
      const date = new Date(e.usage_date);
      var currentOffset = date.getTimezoneOffset();

      var ISTOffset = 330;

      var ISTTime = new Date(
        date.getTime() + (ISTOffset + currentOffset) * 60000
      );
      const hourse = ISTTime.getHours();

      hours.forEach((hour, hourkey) => {
        if (hourse === hours[hourkey]) {
          e.cup_count.forEach((cup_count, cup_count_key) => {
            if (cup_count.name == "Tea") {
              let indexOfDay = teaArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                teaArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + teaArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                teaArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: hours[hourkey],
                });
              }
            }
            if (cup_count.name == "Flavour Tea") {
              let indexOfDay = flavourArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                flavourArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + flavourArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                flavourArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: hours[hourkey],
                });
              }
            }
            if (cup_count.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + milkArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                milkArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: hours[hourkey],
                });
              }
            }
          });
        }
      });
    });
    let teausageData = [];
    let falvourteaUsage = [];
    let milkUsage = [];
    for (let i = 0; i < hours.length; i++) {
      teausageData.push(0);
      falvourteaUsage.push(0);
      milkUsage.push(0);
    }
    teaArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      teausageData[index] = e.value;
    });
    flavourArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      falvourteaUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    let filterHours = [
      "1-2AM",
      "2-3AM",
      "3-4AM",
      "4-5AM",
      "5-6AM",
      "6-7AM",
      "7-8AM",
      "8-9AM",
      "9-10AM",
      "10-11AM",
      "11-12AM",
      "12-1PM",
      "1-2PM",
      "2-3PM",
      "3-4PM",
      "4-5PM",
      "5-6PM",
      "6-7PM",
      "7-8PM",
      "8-9PM",
      "9-10PM",
      "10-11PM",
      "11-12PM",
      "12-1AM",
    ];
    let monthData = [];
    let teaData = [];
    let flavourData = [];
    let milkData = [];

    filterHours.forEach((e, key) => {
      if (teausageData[key] + falvourteaUsage[key] + milkUsage[key] != 0) {
        monthData.push(e);
        teaData.push(teausageData[key]);
        flavourData.push(falvourteaUsage[key]);
        milkData.push(milkUsage[key]);
      }
    });

    return {
      month: monthData,
      teaUsage: teaData,
      flavourTeaUsage: flavourData,
      milkUsage: milkData,
    };
  }
};

export default ViewMachinedetails;
