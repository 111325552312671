import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Paper, Link, Card } from "@material-ui/core";
import BarChart from "../../insideComponents/dashbord/charts/barchart";
import MainTable from "../../insideComponents/mainTable";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getMachineUsage } from "../../redux/reducer/machine_usage/machineUsageApiService";
import { useNavigate } from "react-router";
import { clearUsagePageData } from "../../redux/reducer/machine_usage/machine_usage_page_data";
import { clearMachineUsage } from "../../redux/reducer/machine_usage/viewMachineUsage";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
function ViewMachineUsage(props) {
  const navigate = useNavigate();
  const machineUsage = useSelector((state) => state.MachineUsage);
  const company_details = useSelector((state) => state.MachineUsageDetails);
  let oneMonthPreviosDate = new Date();
  oneMonthPreviosDate.setMonth(oneMonthPreviosDate.getMonth() - 1);

  const [DateRangeStartDate, setDateRangeStartDate] =
    useState(oneMonthPreviosDate);
  const [DateRangeEndDate, setDateRangeEndDate] = useState(new Date());
  const [activeChanrtData, setActiveChartData] = useState("month");

  const dispatch = useDispatch();

  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      USER MASTER
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        dispatch(clearMachineUsage());
        dispatch(clearUsagePageData());
        navigate("/usermaster/viewcompany");
      }}
    >
      VIEW COMPANY
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        navigate("/usermaster/viewcompany/company_mapped_machine");
        dispatch(clearMachineUsage());
        dispatch(clearUsagePageData());
      }}
    >
      {company_details.pagePurpose.purpose == "companyView"
        ? company_details.pagePurpose.company_name
        : company_details.data[0].comapny.company_name}
    </Link>,

    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      Cup Count Usage
    </Link>,
  ];

  //getApi calls
  useEffect(() => {
    if (company_details.pagePurpose.purpose == "companyView") {
      dispatch(
        getMachineUsage(
          "/company?company_id=" +
            company_details.pagePurpose._id +
            "&from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate
        )
      );
    } else {
      dispatch(
        getMachineUsage(
          "?machine_id=" +
            company_details.data[0]._id +
            "&from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate
        )
      );
    }
  }, []);
  //filter table data
  const dateRangeFilter = (start, end) => {
    if (company_details.pagePurpose.purpose == "companyView") {
      dispatch(
        getMachineUsage(
          "/company?company_id=" +
            company_details.pagePurpose._id +
            "&from_date=" +
            start +
            "&toDate=" +
            end
        )
      );
    } else {
      dispatch(
        getMachineUsage(
          "?machine_id=" +
            company_details.data[0]._id +
            "&from_date=" +
            start +
            "&toDate=" +
            end
        )
      );
    }
  };
  //get date  range filter
  const onEventService = (event, picker) => {
    // console.log(picker.startDate);
  };
  const callbackService = (start, end, lebal) => {
    setDateRangeStartDate(start._d);
    setDateRangeEndDate(end._d);
    dateRangeFilter(start._d, end._d);
  };

  //get data from actions
  const getData = (val) => {};

  //actions
  const Action = {};

  let tea_count = 0;
  let Flvour_tea_count = 0;
  let milk_count = 0;

  // get cup counts
  if (company_details.pagePurpose.purpose != "companyView") {
    if (machineUsage.responce) {
      //get tea count
      for (let i = 0; i < machineUsage.responce.data.length; i++) {
        for (
          let j = 0;
          j < machineUsage.responce.data[i].cup_count.length;
          j++
        ) {
          if ("Tea" === machineUsage.responce.data[i].cup_count[j].name) {
            tea_count =
              tea_count + machineUsage.responce.data[i].cup_count[j].value;
          }
          if (
            "Flavour Tea" === machineUsage.responce.data[i].cup_count[j].name
          ) {
            Flvour_tea_count =
              Flvour_tea_count +
              machineUsage.responce.data[i].cup_count[j].value;
          }
          if ("Milk" === machineUsage.responce.data[i].cup_count[j].name) {
            milk_count =
              milk_count + machineUsage.responce.data[i].cup_count[j].value;
          }
        }
      }
    }
  } else {
    if (machineUsage.responce) {
      for (let k = 0; k < machineUsage.responce.data.length; k++) {
        let data = { ...machineUsage.responce.data[k] };
        let tea_count_machine = 0;
        let Flvour_tea_count_machine = 0;
        let milk_count_machine = 0;

        // console.log(data);
        for (let i = 0; i < data.maped_machine_usage.length; i++) {
          for (
            let j = 0;
            j < data.maped_machine_usage[i].cup_count.length;
            j++
          ) {
            if ("Tea" === data.maped_machine_usage[i].cup_count[j].name) {
              tea_count =
                tea_count + data.maped_machine_usage[i].cup_count[j].value;
            }
            if (
              "Flavour Tea" === data.maped_machine_usage[i].cup_count[j].name
            ) {
              Flvour_tea_count =
                Flvour_tea_count +
                data.maped_machine_usage[i].cup_count[j].value;
            }
            if ("Milk" === data.maped_machine_usage[i].cup_count[j].name) {
              milk_count =
                milk_count + data.maped_machine_usage[i].cup_count[j].value;
            }
          }
        }
        tea_count = tea_count + tea_count_machine;
        Flvour_tea_count = Flvour_tea_count + Flvour_tea_count_machine;
        milk_count = milk_count + milk_count_machine;
      }
    }
  }
  // get chart data
  // get months
  const months = [];

  var monthName = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  var d = new Date();
  d.setDate(1);
  for (let i = 0; i <= 8; i++) {
    months.push(monthName[d.getMonth()].substring(0, 3));
    d.setMonth(d.getMonth() - 1);
  }
  months.reverse();

  const teaChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const flavourTeaChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  const milkChartData = [0, 0, 0, 0, 0, 0, 0, 0];
  //chart heders
  const chartvalue = ["Tea", "Flavour Tea", "Milk"];

  // console.log(teaChartData);
  // console.log(flavourTeaChartData);
  // console.log(milkChartData);

  //filter table data
  const filterMachine = (data) => {
    if (machineUsage.responce) {
      const filterarr = [];
      for (let i = 0; i < machineUsage.responce.data.length; i++) {
        let data = { ...machineUsage.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const date = new Date(data.usage_date);
        delete data.usage_date;
        const usage_date =
          date.getDate() +
          "-" +
          monthNames[date.getMonth()] +
          "-" +
          date.getFullYear();
        data.company_name = data.machine_xref.companyDetails[0].company_name;
        data.machine_name = data.machine_xref.machine_details[0].name;
        data.machine_model = data.machine_xref.machine_details[0].model;
        const tea = data.cup_count.filter((a) => {
          if ("Tea" === a.name) {
            data.value = a.name;
            // console.log(value);
            return a;
          }
        });
        data.tea = tea[0].value;
        const milk = data.cup_count.filter((a) => {
          if ("Milk" === a.name) {
            const value = a.name;
            // console.log(value);
            return a;
          }
        });
        const flavour_tea = data.cup_count.filter((a) => {
          if ("Flavour Tea" === a.name) {
            // console.log(a.name);
            const value = a.name;
            // console.log(value);
            return value;
          }
        });
        data.flavour_tea = flavour_tea[0].value;
        data.milk = milk[0].value;
        const hot_water = data.cup_count.filter((a) => {
          if ("Hot water" === a.name) {
            const value = a.name;
            // console.log(value);
            return value;
          }
        });
        data.hot_water = hot_water[0].value;

        var currentOffset = date.getTimezoneOffset();

        var ISTOffset = 330;

        var ISTTime = new Date(
          date.getTime() + (ISTOffset + currentOffset) * 60000
        );
        data.usage_date =
          usage_date + "," + ISTTime.getHours() + ":" + ISTTime.getMinutes();

        // data.time = date.toLocaleTimeString("en", {
        //   timeStyle: "short",
        //   hour12: false,
        //   timeZone: "UTC",
        // });

        // console.log(data);

        // data.is_ads_allowed = "jsd";
        filterarr.push(data);
        // console.log(data);
      }
      filterarr.filter((a) => delete a.chemical_usage);
      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.cup_count);
      filterarr.filter((a) => delete a.machine_id);
      filterarr.filter((a) => delete a.machine_xref);
      filterarr.filter((a) => delete a.other_details);
      filterarr.filter((a) => delete a.raw_material_usage);
      // filterarr.filter((a) => delete a.usage_date);
      filterarr.filter((a) => delete a.current_values);
      // filterarr.filter((a) => delete a.delivery_address_2);
      // filterarr.filter((a) => delete a.delivery_city);
      // filterarr.filter((a) => delete a.delivery_pincode);
      // filterarr.filter((a) => delete a.delivery_state);
      console.log(filterarr);
      return (
        <>
          <MainTable
            tabledata={filterarr}
            // filter={filter1}
            listOfMachine={" CUP COUNT USAGE "}
            getData={getData}
            Action={Action}
          />
        </>
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tabledata={filterarr}
          // tablefilterDropDown={tablefilterDropDown}
          listOfMachine={"CUP COUNT USAGE"}
          Action={""}
        />
      );
    }
  };
  const filtercompany = (data) => {
    if (machineUsage.responce) {
      const filterarr = [];
      for (let i = 0; i < machineUsage.responce.data.length; i++) {
        let data = { ...machineUsage.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let tea_count_machine = 0;
        let Flvour_tea_count_machine = 0;
        let milk_count_machine = 0;
        let hot_water_count_machine = 0;

        // console.log(data);
        for (let i = 0; i < data.maped_machine_usage.length; i++) {
          for (
            let j = 0;
            j < data.maped_machine_usage[i].cup_count.length;
            j++
          ) {
            if ("Tea" === data.maped_machine_usage[i].cup_count[j].name) {
              tea_count_machine =
                tea_count_machine +
                data.maped_machine_usage[i].cup_count[j].value;
            }
            if (
              "Flavour Tea" === data.maped_machine_usage[i].cup_count[j].name
            ) {
              Flvour_tea_count_machine =
                Flvour_tea_count_machine +
                data.maped_machine_usage[i].cup_count[j].value;
            }
            if ("Milk" === data.maped_machine_usage[i].cup_count[j].name) {
              milk_count_machine =
                milk_count_machine +
                data.maped_machine_usage[i].cup_count[j].value;
            }
            if ("Hot water" === data.maped_machine_usage[i].cup_count[j].name) {
              hot_water_count_machine =
                hot_water_count_machine +
                data.maped_machine_usage[i].cup_count[j].value;
            }
          }
        }

        data.company_name = company_details.pagePurpose.company_name;
        data.machine_name = data.machines[0].name;
        data.machine_model = data.machines[0].model;

        data.tea = tea_count_machine;

        data.flavour_tea = Flvour_tea_count_machine;
        data.milk = milk_count_machine;

        data.hot_water = hot_water_count_machine;

        filterarr.push(data);
        //   // console.log(data);
      }
      filterarr.filter((a) => delete a.current_status);
      filterarr.filter((a) => delete a.company_id);
      filterarr.filter((a) => delete a.is_active);
      filterarr.filter((a) => delete a.machine_id);
      filterarr.filter((a) => delete a.machines);
      filterarr.filter((a) => delete a.maped_machine_usage);
      filterarr.filter((a) => delete a.status);
      filterarr.filter((a) => delete a.entity_id);

      return (
        <>
          <MainTable
            tabledata={filterarr}
            // filter={filter1}
            listOfMachine={" CUP COUNT USAGE "}
            getData={getData}
            Action={Action}
          />
        </>
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tabledata={filterarr}
          // tablefilterDropDown={tablefilterDropDown}
          listOfMachine={" CUP COUNT USAGE"}
          Action={""}
        />
      );
    }
  };

  //flter chart datas bassed month and hourse

  let filteredData = [];

  if (company_details.pagePurpose.purpose != "companyView") {
    let monthlyFilters = !machineUsage.isLoading
      ? monthlyFiltersdata(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";
    let dailyFilters = !machineUsage.isLoading
      ? dailyFilter(machineUsage.responce, DateRangeStartDate, DateRangeEndDate)
      : "";
    let hourlyFilters = !machineUsage.isLoading
      ? hourlyFilter(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";

    if (activeChanrtData === "hour") {
      filteredData = hourlyFilters ? hourlyFilters : undefined;
    }
    if (activeChanrtData === "daily") {
      filteredData = dailyFilters ? dailyFilters : undefined;
    }
    if (activeChanrtData === "month") {
      filteredData = monthlyFilters ? monthlyFilters : undefined;
    }
  } else {
    //company usage
    let companymonthlyFilters = !machineUsage.isLoading
      ? companymonthlyFiltersdata(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";
    let companydailyFilters = !machineUsage.isLoading
      ? companydailyFilter(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";
    let companyhourlyFilters = !machineUsage.isLoading
      ? companyhourlyFilter(
          machineUsage.responce,
          DateRangeStartDate,
          DateRangeEndDate
        )
      : "";

    if (activeChanrtData === "hour") {
      filteredData = companyhourlyFilters;
    }
    if (activeChanrtData === "daily") {
      filteredData = companydailyFilters;
    }
    if (activeChanrtData === "month") {
      filteredData = companymonthlyFilters;
    }
  }

  return (
    <div className="comp-change">
      {machineUsage.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex">
        <Stack className="mx-4 pt-4  w-100">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
        </Stack>
        <div className="w-100 px-2 pt-1 d-flex justify-content-end dateRange align-items-center">
          <CalendarMonthIcon className="d-flex  align-items-center " />
          <p className="d-flex px-1 m-0 align-items-center">
            <DateRangePicker
              initialSettings={{
                startDate: oneMonthPreviosDate,
                endDate: new Date(),
                locale: {
                  format: "DD/MM/YYYY",
                },
              }}
              className="date"
              onEvent={onEventService}
              onCallback={callbackService}
            >
              <input type="button" className=" date " />
            </DateRangePicker>
          </p>
        </div>
      </div>
      <div className="px-4 pb-2 d-flex pt-4">
        <div className="col-3 ">
          <Card
            // className={data.bg_color}
            style={{
              borderRadius: "15px",
              height: "100%",
            }}
          >
            <div
              style={{
                color: "#ef854b",
              }}
              className=" fw-bolder font-size-28 pt-4 pb-2 d-flex align-items-center justify-content-center"
            >
              {"Tea"}
            </div>

            <div className="d-flex w-100 align-items-center">
              <div className="w-40 d-flex justify-content-center align-items-center">
                <img
                  className="w-75 h-50 pb-3 mb-4 border-end pt-3 px-2"
                  src="https://boatload.s3.ap-south-1.amazonaws.com/29942-4-tea-image.png"
                  alt="tea"
                />
              </div>
              <div className="d-flex mx-2 w-60 flex-column justify-content-center align-items-start">
                <div className="d-flex px-3 align-items-start">
                  <h1 className=" font-size-34 mt-2">{tea_count}</h1>
                  <FontAwesomeIcon
                    style={{
                      color: "#a2c52d",
                    }}
                    icon="fa-solid fa-circle-up "
                    className=" px-3 mb-5 font-size-24 up-arrow"
                  />
                </div>
                <p className=" company-text">* tea cup counts {tea_count}</p>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-3 mx-4">
          <Card
            // className={data.bg_color}
            style={{
              borderRadius: "15px",
              height: "100%",
            }}
          >
            <div
              style={{
                color: "#a2c52d",
              }}
              className=" fw-bolder font-size-28 pt-4 pb-2 d-flex align-items-center justify-content-center"
            >
              {"Flavour Tea"}
            </div>

            <div className="d-flex w-100 align-items-center">
              <div className="w-40 d-flex justify-content-center align-items-center">
                <img
                  className="w-75 h-50 pb-3 mb-4 border-end pt-3 px-2"
                  src="https://boatload.s3.ap-south-1.amazonaws.com/3-2-green-tea-png.png"
                  alt="Flvour tea"
                />
              </div>
              <div className="d-flex mx-2 w-60 flex-column justify-content-center align-items-start">
                <div className="d-flex px-3 align-items-start">
                  <h1 className=" font-size-34 mt-2">{Flvour_tea_count}</h1>
                  <FontAwesomeIcon
                    style={{
                      color: "#a2c52d",
                    }}
                    icon="fa-solid fa-circle-up "
                    className=" px-3 mb-5 font-size-24 up-arrow"
                  />
                </div>
                <p className=" company-text">
                  * Flavour tea cup counts {Flvour_tea_count}
                </p>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-3">
          <Card
            // className={data.bg_color}
            style={{
              borderRadius: "15px",
              height: "100%",
            }}
          >
            <div
              style={{
                color: "#4ba9f0",
              }}
              className=" fw-bolder font-size-28 pt-4 pb-2 d-flex align-items-center justify-content-center"
            >
              {"Milk"}
            </div>

            <div className="d-flex w-100 align-items-center">
              <div className="w-40 d-flex justify-content-center align-items-center">
                <img
                  className="w-75 h-50 pb-3 mb-4 border-end pt-3 px-2"
                  src="https://boatload.s3.ap-south-1.amazonaws.com/4-2-milk-png-clipart.png"
                  alt="milk"
                />
              </div>
              <div className="d-flex mx-2 w-60 flex-column justify-content-center align-items-start">
                <div className="d-flex px-3 align-items-start">
                  <h1 className=" font-size-34 mt-2">{milk_count}</h1>
                  <FontAwesomeIcon
                    style={{
                      color: "#a2c52d",
                    }}
                    icon="fa-solid fa-circle-up "
                    className=" px-3 mb-5 font-size-24 up-arrow"
                  />
                </div>
                <p className=" company-text">* Milk cup counts {milk_count}</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="p-4 " style={{ height: "400px" }}>
        <Paper
          style={{
            borderRadius: "15px",
          }}
          className="h-100 w-50 p-4"
        >
          <div className="d-flex w-100">
            <div className="w-100 text-uppercase font-weight-bold">
              Cup Count Usage
            </div>
            <div className="d-flex w-100 justify-content-end">
              <div
                onClick={() => {
                  setActiveChartData("month");
                }}
                type="button"
                className={
                  activeChanrtData === "month"
                    ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                    : "mx-2 text-uppercase text-smaller"
                }
              >
                Monthly
              </div>
              <div
                onClick={() => {
                  setActiveChartData("daily");
                }}
                type="button"
                className={
                  activeChanrtData === "daily"
                    ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                    : "mx-2 text-uppercase text-smaller"
                }
              >
                Daily
              </div>
              <div
                onClick={() => {
                  setActiveChartData("hour");
                }}
                type="button"
                className={
                  activeChanrtData === "hour"
                    ? "mx-2 text-uppercase text-smaller border-bottom border-dark"
                    : "mx-2 text-uppercase text-smaller"
                }
              >
                Hourly
              </div>
            </div>
          </div>

          <BarChart
            // className="scroll-barx"
            chartData={
              filteredData === undefined
                ? [teaChartData, flavourTeaChartData, milkChartData]
                : [
                    filteredData.teaUsage,
                    filteredData.flavourTeaUsage,
                    filteredData.milkUsage,
                  ]
            }
            displaymonth={filteredData === undefined ? "" : filteredData.month}
            chartvalue={chartvalue}
            color={["#fbd125", "#4fa6ee", "#00cec2"]}
          />
        </Paper>
      </div>

      {company_details.pagePurpose.purpose == "companyView"
        ? !machineUsage.isLoading
          ? filtercompany()
          : ""
        : !machineUsage.isLoading
        ? filterMachine()
        : ""}
    </div>
  );
}

const monthlyFiltersdata = (usage, startDate, endDate) => {
  if (usage) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // get month diffrance
    function diff(from, to) {
      var arr = [];
      var datFrom = new Date("1 " + from);
      var datTo = new Date("1 " + to);

      var fromYear = datFrom.getFullYear();
      var toYear = datTo.getFullYear();
      var diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

      for (var i = datFrom.getMonth(); i <= diffYear; i++) {
        arr.push(monthNames[i % 12] + " " + Math.floor(fromYear + i / 12));
      }

      return arr;
    }

    let start = `${
      monthNames[startDate.getMonth()]
    } ${startDate.getFullYear()}`;
    let end = `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}`;

    const all_month = diff(start, end);

    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    const monthArray = [];
    const flavourArray = [];
    const milkArray = [];

    usage.data.forEach((e, key) => {
      all_month.forEach((month, monthkey) => {
        // console.log(monthkey);
        if (
          `${new Date(e.usage_date).getMonth()}${new Date(
            e.usage_date
          ).getFullYear()}` ===
          `${new Date("1 " + all_month[monthkey]).getMonth()}${new Date(
            "1 " + all_month[monthkey]
          ).getFullYear()}`
        ) {
          e.cup_count.forEach((cup_count, cup_count_key) => {
            // console.log(cup_count);
            if (cup_count.name == "Tea") {
              let indexofmonth = monthArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                monthArray[indexofmonth] = {
                  name: cup_count.name,
                  value: cup_count.value + monthArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                monthArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (cup_count.name == "Flavour Tea") {
              let indexofmonth = flavourArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                flavourArray[indexofmonth] = {
                  name: cup_count.name,
                  value: cup_count.value + flavourArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                flavourArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (cup_count.name == "Milk") {
              let indexofmonth = milkArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                milkArray[indexofmonth] = {
                  name: cup_count.name,
                  value: cup_count.value + milkArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                milkArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: all_month[monthkey],
                });
              }
            }
          });
        }
      });
    });

    let teausageData = [];
    let falvourteaUsage = [];
    let milkUsage = [];
    for (let i = 0; i < all_month.length; i++) {
      teausageData.push(0);
      falvourteaUsage.push(0);
      milkUsage.push(0);
    }
    monthArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      teausageData[index] = e.value;
    });
    flavourArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      falvourteaUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    const months = [];
    all_month.forEach((a) => {
      months.push(`${a.substring(0, 3)}-${a.slice(-2)}`);
    });
    let monthData = [];
    let teaData = [];
    let flavourData = [];
    let milkData = [];
    months.forEach((e, key) => {
      if (teausageData[key] + falvourteaUsage[key] + milkUsage[key] != 0) {
        monthData.push(e);
        teaData.push(teausageData[key]);
        flavourData.push(falvourteaUsage[key]);
        milkData.push(milkUsage[key]);
      }
    });

    return {
      month: monthData,
      teaUsage: teaData,
      flavourTeaUsage: flavourData,
      milkUsage: milkData,
    };
  }
};
const dailyFilter = (usage, startDate, endDate) => {
  if (usage) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    function getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    }

    // Usage
    const dates = getDates(startDate, endDate);
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let teaArray = [];
    let flavourArray = [];
    let milkArray = [];
    usage.data.forEach((e, key) => {
      dates.forEach((day, dayKey) => {
        const check =
          `${dates[dayKey].getDate()}${dates[dayKey].getMonth()}${dates[
            dayKey
          ].getFullYear()}` ===
          `${new Date(e.usage_date).getDate()}${new Date(
            e.usage_date
          ).getMonth()}${new Date(e.usage_date).getFullYear()}`;
        if (check) {
          e.cup_count.forEach((cup_count, cup_count_key) => {
            if (cup_count.name == "Tea") {
              let indexOfDay = teaArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                teaArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + teaArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                teaArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: dates[dayKey],
                });
              }
            }
            if (cup_count.name == "Flavour Tea") {
              let indexOfDay = flavourArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                flavourArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + flavourArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                flavourArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: dates[dayKey],
                });
              }
            }
            if (cup_count.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + milkArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                milkArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: dates[dayKey],
                });
              }
            }
          });
        }
      });
    });
    let teausageData = [];
    let falvourteaUsage = [];
    let milkUsage = [];
    for (let i = 0; i < dates.length; i++) {
      teausageData.push(0);
      falvourteaUsage.push(0);
      milkUsage.push(0);
    }
    teaArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      teausageData[index] = e.value;
    });
    flavourArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      falvourteaUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    const months = [];
    dates.forEach((a) => {
      const da = `${a.getDate()}-${monthNames[a.getMonth()].substring(
        0,
        3
      )}-${a.getFullYear()}`;
      months.push(da);
    });
    let monthData = [];
    let teaData = [];
    let flavourData = [];
    let milkData = [];
    months.forEach((e, key) => {
      if (teausageData[key] + falvourteaUsage[key] + milkUsage[key] != 0) {
        monthData.push(e);
        teaData.push(teausageData[key]);
        flavourData.push(falvourteaUsage[key]);
        milkData.push(milkUsage[key]);
      }
    });

    return {
      month: monthData,
      teaUsage: teaData,
      flavourTeaUsage: flavourData,
      milkUsage: milkData,
    };
  }
};
const hourlyFilter = (usage, startDate, endDate) => {
  if (usage) {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let teaArray = [];
    let flavourArray = [];
    let milkArray = [];
    usage.data.forEach((e, key) => {
      const date = new Date(e.usage_date);
      var currentOffset = date.getTimezoneOffset();

      var ISTOffset = 330;

      var ISTTime = new Date(
        date.getTime() + (ISTOffset + currentOffset) * 60000
      );
      const hourse = ISTTime.getHours();

      hours.forEach((hour, hourkey) => {
        if (hourse === hours[hourkey]) {
          e.cup_count.forEach((cup_count, cup_count_key) => {
            if (cup_count.name == "Tea") {
              let indexOfDay = teaArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                teaArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + teaArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                teaArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: hours[hourkey],
                });
              }
            }
            if (cup_count.name == "Flavour Tea") {
              let indexOfDay = flavourArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                flavourArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + flavourArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                flavourArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: hours[hourkey],
                });
              }
            }
            if (cup_count.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + milkArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                milkArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: hours[hourkey],
                });
              }
            }
          });
        }
      });
    });
    let teausageData = [];
    let falvourteaUsage = [];
    let milkUsage = [];
    for (let i = 0; i < hours.length; i++) {
      teausageData.push(0);
      falvourteaUsage.push(0);
      milkUsage.push(0);
    }
    teaArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      teausageData[index] = e.value;
    });
    flavourArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      falvourteaUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    let filterHours = [
      "1-2AM",
      "2-3AM",
      "3-4AM",
      "4-5AM",
      "5-6AM",
      "6-7AM",
      "7-8AM",
      "8-9AM",
      "9-10AM",
      "10-11AM",
      "11-12AM",
      "12-1PM",
      "1-2PM",
      "2-3PM",
      "3-4PM",
      "4-5PM",
      "5-6PM",
      "6-7PM",
      "7-8PM",
      "8-9PM",
      "9-10PM",
      "10-11PM",
      "11-12PM",
      "12-1AM",
    ];
    let monthData = [];
    let teaData = [];
    let flavourData = [];
    let milkData = [];
    filterHours.forEach((e, key) => {
      if (teausageData[key] + falvourteaUsage[key] + milkUsage[key] != 0) {
        monthData.push(e);
        teaData.push(teausageData[key]);
        flavourData.push(falvourteaUsage[key]);
        milkData.push(milkUsage[key]);
      }
    });

    return {
      month: monthData,
      teaUsage: teaData,
      flavourTeaUsage: flavourData,
      milkUsage: milkData,
    };
  }
};
const companymonthlyFiltersdata = (usage, startDate, endDate) => {
  if (usage) {
    const machine_usage = [];
    usage.data.forEach((e) => {
      machine_usage.push(...e.maped_machine_usage);
    });
    console.log(machine_usage);
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // get month diffrance
    function diff(from, to) {
      var arr = [];
      var datFrom = new Date("1 " + from);
      var datTo = new Date("1 " + to);

      var fromYear = datFrom.getFullYear();
      var toYear = datTo.getFullYear();
      var diffYear = 12 * (toYear - fromYear) + datTo.getMonth();

      for (var i = datFrom.getMonth(); i <= diffYear; i++) {
        arr.push(monthNames[i % 12] + " " + Math.floor(fromYear + i / 12));
      }

      return arr;
    }

    let start = `${
      monthNames[startDate.getMonth()]
    } ${startDate.getFullYear()}`;
    let end = `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}`;

    const all_month = diff(start, end);

    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    const monthArray = [];
    const flavourArray = [];
    const milkArray = [];

    machine_usage.forEach((e, key) => {
      all_month.forEach((month, monthkey) => {
        // console.log(monthkey);
        if (
          `${new Date(e.usage_date).getMonth()}${new Date(
            e.usage_date
          ).getFullYear()}` ===
          `${new Date("1 " + all_month[monthkey]).getMonth()}${new Date(
            "1 " + all_month[monthkey]
          ).getFullYear()}`
        ) {
          e.cup_count.forEach((cup_count, cup_count_key) => {
            // console.log(cup_count);
            if (cup_count.name == "Tea") {
              let indexofmonth = monthArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                monthArray[indexofmonth] = {
                  name: cup_count.name,
                  value: cup_count.value + monthArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                monthArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (cup_count.name == "Flavour Tea") {
              let indexofmonth = flavourArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                flavourArray[indexofmonth] = {
                  name: cup_count.name,
                  value: cup_count.value + flavourArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                flavourArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: all_month[monthkey],
                });
              }
            }
            if (cup_count.name == "Milk") {
              let indexofmonth = milkArray.findIndex(
                (x) => x.month === all_month[monthkey]
              );

              if (indexofmonth >= 0) {
                milkArray[indexofmonth] = {
                  name: cup_count.name,
                  value: cup_count.value + milkArray[indexofmonth].value,
                  month: all_month[monthkey],
                };
              } else {
                milkArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: all_month[monthkey],
                });
              }
            }
          });
        }
      });
    });

    let teausageData = [];
    let falvourteaUsage = [];
    let milkUsage = [];
    for (let i = 0; i < all_month.length; i++) {
      teausageData.push(0);
      falvourteaUsage.push(0);
      milkUsage.push(0);
    }
    monthArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      teausageData[index] = e.value;
    });
    flavourArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      falvourteaUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = all_month.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    const months = [];
    all_month.forEach((a) => {
      months.push(`${a.substring(0, 3)}-${a.slice(-2)}`);
    });
    let monthData = [];
    let teaData = [];
    let flavourData = [];
    let milkData = [];
    months.forEach((e, key) => {
      if (teausageData[key] + falvourteaUsage[key] + milkUsage[key] != 0) {
        monthData.push(e);
        teaData.push(teausageData[key]);
        flavourData.push(falvourteaUsage[key]);
        milkData.push(milkUsage[key]);
      }
    });

    return {
      month: monthData,
      teaUsage: teaData,
      flavourTeaUsage: flavourData,
      milkUsage: milkData,
    };
  }
};
const companydailyFilter = (usage, startDate, endDate) => {
  if (usage) {
    const machine_usage = [];
    usage.data.forEach((e) => {
      machine_usage.push(...e.maped_machine_usage);
    });
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    function getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    }

    // Usage
    const dates = getDates(startDate, endDate);
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let teaArray = [];
    let flavourArray = [];
    let milkArray = [];
    machine_usage.forEach((e, key) => {
      dates.forEach((day, dayKey) => {
        const check =
          `${dates[dayKey].getDate()}${dates[dayKey].getMonth()}${dates[
            dayKey
          ].getFullYear()}` ===
          `${new Date(e.usage_date).getDate()}${new Date(
            e.usage_date
          ).getMonth()}${new Date(e.usage_date).getFullYear()}`;
        if (check) {
          e.cup_count.forEach((cup_count, cup_count_key) => {
            if (cup_count.name == "Tea") {
              let indexOfDay = teaArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                teaArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + teaArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                teaArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: dates[dayKey],
                });
              }
            }
            if (cup_count.name == "Flavour Tea") {
              let indexOfDay = flavourArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                flavourArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + flavourArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                flavourArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: dates[dayKey],
                });
              }
            }
            if (cup_count.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === dates[dayKey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + milkArray[indexOfDay].value,
                  month: dates[dayKey],
                };
              } else {
                milkArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: dates[dayKey],
                });
              }
            }
          });
        }
      });
    });
    let teausageData = [];
    let falvourteaUsage = [];
    let milkUsage = [];
    for (let i = 0; i < dates.length; i++) {
      teausageData.push(0);
      falvourteaUsage.push(0);
      milkUsage.push(0);
    }
    teaArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      teausageData[index] = e.value;
    });
    flavourArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      falvourteaUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = dates.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    const months = [];
    dates.forEach((a) => {
      const da = `${a.getDate()}-${monthNames[a.getMonth()].substring(
        0,
        3
      )}-${a.getFullYear()}`;
      months.push(da);
    });
    let monthData = [];
    let teaData = [];
    let flavourData = [];
    let milkData = [];
    months.forEach((e, key) => {
      if (teausageData[key] + falvourteaUsage[key] + milkUsage[key] != 0) {
        monthData.push(e);
        teaData.push(teausageData[key]);
        flavourData.push(falvourteaUsage[key]);
        milkData.push(milkUsage[key]);
      }
    });

    return {
      month: monthData,
      teaUsage: teaData,
      flavourTeaUsage: flavourData,
      milkUsage: milkData,
    };
  }
};
const companyhourlyFilter = (usage, startDate, endDate) => {
  if (usage) {
    const machine_usage = [];
    usage.data.forEach((e) => {
      machine_usage.push(...e.maped_machine_usage);
    });
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    const chartvalue = ["Tea", "Flavour Tea", "Milk"];
    let teaArray = [];
    let flavourArray = [];
    let milkArray = [];
    machine_usage.forEach((e, key) => {
      const date = new Date(e.usage_date);
      var currentOffset = date.getTimezoneOffset();

      var ISTOffset = 330;

      var ISTTime = new Date(
        date.getTime() + (ISTOffset + currentOffset) * 60000
      );
      const hourse = ISTTime.getHours();

      hours.forEach((hour, hourkey) => {
        if (hourse === hours[hourkey]) {
          e.cup_count.forEach((cup_count, cup_count_key) => {
            if (cup_count.name == "Tea") {
              let indexOfDay = teaArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                teaArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + teaArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                teaArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: hours[hourkey],
                });
              }
            }
            if (cup_count.name == "Flavour Tea") {
              let indexOfDay = flavourArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                flavourArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + flavourArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                flavourArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: hours[hourkey],
                });
              }
            }
            if (cup_count.name == "Milk") {
              let indexOfDay = milkArray.findIndex(
                (x) => x.month === hours[hourkey]
              );
              if (indexOfDay >= 0) {
                milkArray[indexOfDay] = {
                  name: cup_count.name,
                  value: cup_count.value + milkArray[indexOfDay].value,
                  month: hours[hourkey],
                };
              } else {
                milkArray.push({
                  name: cup_count.name,
                  value: cup_count.value,
                  month: hours[hourkey],
                });
              }
            }
          });
        }
      });
    });
    let teausageData = [];
    let falvourteaUsage = [];
    let milkUsage = [];
    for (let i = 0; i < hours.length; i++) {
      teausageData.push(0);
      falvourteaUsage.push(0);
      milkUsage.push(0);
    }
    teaArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      teausageData[index] = e.value;
    });
    flavourArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      falvourteaUsage[index] = e.value;
    });
    milkArray.forEach((e) => {
      const index = hours.indexOf(e.month);
      milkUsage[index] = e.value;
    });
    let filterHours = [
      "1-2AM",
      "2-3AM",
      "3-4AM",
      "4-5AM",
      "5-6AM",
      "6-7AM",
      "7-8AM",
      "8-9AM",
      "9-10AM",
      "10-11AM",
      "11-12AM",
      "12-1PM",
      "1-2PM",
      "2-3PM",
      "3-4PM",
      "4-5PM",
      "5-6PM",
      "6-7PM",
      "7-8PM",
      "8-9PM",
      "9-10PM",
      "10-11PM",
      "11-12PM",
      "12-1AM",
    ];
    let monthData = [];
    let teaData = [];
    let flavourData = [];
    let milkData = [];
    filterHours.forEach((e, key) => {
      if (teausageData[key] + falvourteaUsage[key] + milkUsage[key] != 0) {
        monthData.push(e);
        teaData.push(teausageData[key]);
        flavourData.push(falvourteaUsage[key]);
        milkData.push(milkUsage[key]);
      }
    });

    return {
      month: monthData,
      teaUsage: teaData,
      flavourTeaUsage: flavourData,
      milkUsage: milkData,
    };
  }
};
export default ViewMachineUsage;
