import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
const API_URL = "https://dtv29cy9i3.execute-api.ap-south-1.amazonaws.com/dev";
// const API_URL = "http://localhost:8080";
let token = localStorage.getItem("Token");
const entity_id = 1;

export const addMachine = createAsyncThunk(
  "AddMachine/addMachine",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        API_URL + "/machine/create/machine",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//listmachine
export const listMachine = createAsyncThunk(
  "ListMachine/listMachine",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/machine/list/machines" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//listmachine
export const listFullMachine = createAsyncThunk(
  "ListFullMachine/listFullMachine",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.get(API_URL + "/machine/list/machines" + value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//edit machine
export const editMachine = createAsyncThunk(
  "EditMachine/editMachine",
  async (value, { rejectWithValue }) => {
    try {
      console.log(value);
      const data = await axios.put(
        API_URL + "/machine/update/machine/" + value._id,
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//list machineSetting
export const listMachineSetting = createAsyncThunk(
  "ListMachineSetting/listMachineSetting",
  async (value, { rejectWithValue }) => {
    try {
      value = value ? value : "";
      console.log(value);
      const data = await axios.get(
        API_URL + "/machine/list/machine_setting/" + value,

        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

//list mapped machines
export const listMapedMachine = createAsyncThunk(
  "ListMappedMachine/listMapedMachine",
  async (value, { rejectWithValue }) => {
    try {
      value = value ? value : "";
      console.log(value);
      const data = await axios.get(
        API_URL + "/machine/list/machine_xref" + value,

        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

//list mapped machines
export const listMapedMachinefilterApi = createAsyncThunk(
  "ListMapedMachinefilterApi/listMapedMachinefilterApi",
  async (value, { rejectWithValue }) => {
    try {
      value = value ? value : "";
      console.log(value);
      const data = await axios.get(
        API_URL + "/machine/list/machine_xref" + value,

        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);
//edit machine status
export const updatemachineStatus = createAsyncThunk(
  "UpdateMachineStatus/updatemachineStatus",
  async (value, { rejectWithValue }) => {
    try {
      console.log(value);
      const data = await axios.put(
        API_URL + "/machine/status/machine_xref/" + value._id,
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//update genral machine settong
//edit machine
export const updatemachineSetting = createAsyncThunk(
  "UpdateMachineSetting/updatemachineSetting",
  async (value, { rejectWithValue }) => {
    try {
      console.log(value);
      const data = await axios.put(
        API_URL + "/machine/update/macine_setting/",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//update single machine setting
export const updateSingleMachineSetting = createAsyncThunk(
  "UpdateSingleMachineSetting/updateSingleMachineSetting",
  async (value, { rejectWithValue }) => {
    try {
      console.log(value);
      const data = await axios.put(
        API_URL + "/machine/update/single_macine_setting/",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//reset machine setting
export const resetMachine = createAsyncThunk(
  "ResetMachine/resetMachine",
  async (value, { rejectWithValue }) => {
    try {
      console.log(value);
      const data = await axios.post(API_URL + "/machine/reset/machine", value, {
        headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// adding machine settings

export const addMachineSettings = createAsyncThunk(
  "AddMachineSetting/addMachineSettings",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.post(
        API_URL + "/machine/create/macine_setting",
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//change add status
export const changeaddStatus = createAsyncThunk(
  "ChangeAddStatus/changeaddStatus",
  async (value, { rejectWithValue }) => {
    try {
      const data = await axios.put(
        API_URL + "/machine/add_status/machine_xref/" + value._id,
        value,
        {
          headers: { Authorization: `Bearer ${token}`, entity_id: entity_id },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
