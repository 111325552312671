import React from "react";
import "../../assets/css/form.scss";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
const TextNumberInput = (props) => {
  const textInput = React.useRef(null);

  const {
    id,
    errors,
    style,
    name = "",
    values,
    touched,
    min,
    max,
    handleChange,
    mapval,
    defaultvalue,
    handleBlur,
    placeholder,
    label = "",
  } = props;
  if (mapval) {
    if (mapval.length === 1) {
      if (textInput.current) {
        textInput.current.value = "";
      }
    }
    // console.log(mapval.length);
  }
  return (
    <div className="d-flex flex-column">
      {/* <label className="inputName">{placeholder}</label> */}

      <TextField
        error={errors[name] && touched[name] ? true : false}
        id={id}
        inputRef={textInput}
        className="bg-white grey formTextInput "
        defaultValue={defaultvalue}
        style={style}
        name={name}
        type="number"
        values={values.name}
        onChange={handleChange}
        InputProps={{
          inputProps: {
            min: min,
            max: max,
          },
        }}
        label={placeholder}
        variant="standard"
        onBlur={handleBlur}
        helperText={errors[name] && touched[name] ? errors[name] : null}
      />

      <p className="text-danger">
        {/* {errors[name] && touched[name] ? errors[name] : null} */}
      </p>
    </div>
  );
};

export default TextNumberInput;
