import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainTable from "../../insideComponents/mainTable";
import { Card, Grid, Badge, Autocomplete } from "@mui/material";
import tabledatas from "../../sampleData/sampleTableData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
import {
  changeaddStatus,
  listFullMachine,
  listMachine,
  updatemachineStatus,
} from "../../redux/reducer/machineMaster/addMachineApiService";
import MachineCart from "../../insideComponents/machinemastercards";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  data,
  edit,
  vieaData,
  settingData,
} from "../../redux/reducer/viewMachineEditData";
import { Link, Paper, TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  card,
  Issues,
  tabledata,
} from "../../insideComponents/dashbord/dashbordComponents";
import {
  gensettingdata,
  paths,
} from "../../redux/reducer/machineMaster/machineSetting";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "2",
    },
  },
});

function ViewMachine(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchedTableData = useSelector((state) => state.ListMachine);
  const ListFullMachine = useSelector((state) => state.listFullMachines);
  const changeMachineStatus = useSelector((state) => state.changeMachineStatus);
  const ChangeaddStatus = useSelector((state) => state.addStatus);
  const [tabledata, setTableData] = useState(
    fetchedTableData.responce.data ? fetchedTableData.responce.data : []
  );
  const [deviceId_filter, setDeviceIdFilter] = useState(null);
  const [active_filter, setActiveFilter] = useState(null);
  const [adds_filter, setAddsFilter] = useState(null);
  const [curentStatus, setCurentStatus] = useState(null);
  let oneMonthPreviosDate = new Date();
  oneMonthPreviosDate.setMonth(oneMonthPreviosDate.getMonth() - 1);

  const [DateRangeStartDate, setDateRangeStartDate] =
    useState(oneMonthPreviosDate);
  const [DateRangeEndDate, setDateRangeEndDate] = useState(new Date());
  const classes = useStyles();
  //api call functions
  const apiCall = async () => {
    await dispatch(
      listMachine(
        "?from_date=" + DateRangeStartDate + "&toDate=" + DateRangeEndDate
      )
    );
    dispatch(listFullMachine(""));
  };

  useEffect(() => {
    apiCall();
  }, [changeMachineStatus, ChangeaddStatus]);
  //lagnvage translation
  const { t } = useTranslation();

  //set data to state
  // const settingData = () => {
  //   setTableData(fetchedTableData.responce.data);
  //   console.log(fetchedTableData.responce.data);
  //   // setTableData(fetchedTableData.data);
  // };
  ///machinemaster/machinesetting/individual
  const Action = {
    Action: [
      // {
      //   icon: <FontAwesomeIcon className="p-2" icon="fa-solid fa-check" />,
      //   name: "ticmark",
      // },
      // {
      //   icon: <FontAwesomeIcon className="p-2" icon="fa-solid fa-plus" />,
      //   name: "plus",
      // },
      {
        icon: (
          <FontAwesomeIcon
            title="detail view"
            type="button"
            className="p-2"
            icon="fa-solid fa-eye"
          />
        ),
        name: "view",
      },
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="edit machine"
            className="p-2"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            title="setting"
            className="p-2"
            icon="fa-solid fa-gear"
          />
        ),
        name: "setting",
      },
      // {
      //   icon: <FontAwesomeIcon className="p-2" icon="fa-solid fa-trash" />,
      //   name: "delete",
      // },
    ],
  };
  //brudcrumbs
  const breadcrumbs = [
    <Link
      type="button"
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey brud font-weight-bol font-weight-bold"
    >
      MACHINE MASTER
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 brud grey font-weight-bol font-weight-bol font-weight-bold"
      key="2"
      color="inherit"
    >
      VIEW MACHINE
    </Link>,
  ];
  //callback functions for action buttons
  const getData = (value) => {
    console.log(value);

    //edit functions
    if (value.action == "edit") {
      const getdata = fetchedTableData.responce.data.filter((a) => {
        return a._id === value.data._id;
      });
      // console.log("hello");
      value.data.manifacture_date = getdata[0].manifacture_date;
      dispatch(edit(true));
      dispatch(data(value));
      navigate("/machinemaster/addmachine");
    }
    if (value.action == "view") {
      dispatch(vieaData(value));
      navigate("/machinemaster/viewmachine/viewmachinedetails");
    }
    if (value.action == "setting") {
      const data = fetchedTableData.responce.data.filter((a) => {
        return a._id === value.data._id;
      });
      const path = {
        path: "machine_master",
        second: "VIEW MACHINE",
        third: "MACHINE NAME",
      };
      console.log(data);
      dispatch(gensettingdata(data[0]));
      dispatch(paths(path));

      navigate("/machinemaster/machinesetting/individual");
    }
  };
  //filter
  // const filter1 = [
  //   {
  //     name: "Adds Allowed",
  //     value: "Allowed",
  //   },
  //   {
  //     name: "Not Allowed",
  //     value: "notAllowed",
  //   },
  // ];
  // console.log(fetchedTableData.responce.data);

  //change status
  const changeStatus = (val) => {
    const submit = {
      _id: val.id,
      is_active: val.checked,
    };
    dispatch(updatemachineStatus(submit));
    // console.log(val.checked);
  };
  const changeaddStatuss = (val) => {
    const submit = {
      _id: val.id,
      is_ads_allowed: val.checked,
    };
    dispatch(changeaddStatus(submit));
    // console.log(val.checked);
  };
  // filtersapi
  const deviceId_filter_api = (e) => {
    if (e) {
      if (e != "all") {
        dispatch(
          listMachine(
            "?from_date=" +
              DateRangeStartDate +
              "&toDate=" +
              DateRangeEndDate +
              "?&device_id=" +
              e.value +
              (active_filter ? "&is_active=" + active_filter : "") +
              (adds_filter
                ? "&is_ads_allowed=" + adds_filter === "Allowed"
                  ? true
                  : false
                : "")
          )
        );
      } else {
        dispatch(
          listMachine(
            "?from_date=" +
              DateRangeStartDate +
              "&toDate=" +
              DateRangeEndDate +
              (active_filter ? "&is_active=" + active_filter : "") +
              (adds_filter
                ? "&is_ads_allowed=" +
                  (adds_filter === "Allowed" ? true : false)
                : "") +
              (curentStatus ? "&current_status=" + curentStatus : "")
          )
        );
      }
    }
    if (!e) {
      dispatch(
        listMachine(
          "?from_date=" + DateRangeStartDate + "&toDate=" + DateRangeEndDate
        ) + (curentStatus ? "&current_status=" + curentStatus : "")
      );
    }
  };
  const filtervalue = (e) => {
    setAddsFilter(e);
    if (e != "all") {
      console.log(e);
      dispatch(
        listMachine(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&is_ads_allowed=" +
            (e === "Allowed" ? true : false) +
            (deviceId_filter ? "&device_id=" + deviceId_filter.value : "") +
            (active_filter ? "&is_active=" + active_filter : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    } else {
      setAddsFilter(null);
      dispatch(
        listMachine(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (deviceId_filter ? "&device_id" + deviceId_filter.value : "") +
            (active_filter
              ? (deviceId_filter ? "&is_active=" : "&is_active=") +
                active_filter
              : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
  };
  const activeFilter = (e) => {
    console.log(e);
    setActiveFilter(e);
    if (e != "all") {
      dispatch(
        listMachine(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&is_active=" +
            e +
            (deviceId_filter ? "&device_id=" + deviceId_filter.value : "") +
            (adds_filter
              ? "&is_ads_allowed=" + (adds_filter === "Allowed" ? true : false)
              : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    } else {
      setActiveFilter(null);
      dispatch(
        listMachine(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (deviceId_filter ? "&device_id" + deviceId_filter.value : "") +
            (adds_filter
              ? "&is_ads_allowed=" + adds_filter === "Allowed"
                ? true
                : false
              : "") +
            (curentStatus ? "&current_status=" + curentStatus : "")
        )
      );
    }
  };
  const dateRangeFilter = (start, end) => {
    dispatch(
      listMachine(
        "?from_date=" +
          start +
          "&toDate=" +
          end +
          (deviceId_filter ? "&device_id" + deviceId_filter.value : "") +
          (adds_filter
            ? "&is_ads_allowed=" + adds_filter === "Allowed"
              ? true
              : false
            : "") +
          (active_filter ? "&is_active=" + active_filter : "") +
          (curentStatus ? "&current_status=" + curentStatus : "")
      )
    );
  };
  const filterCurentValue = (e) => {
    setCurentStatus(e);
    if (e != "all") {
      dispatch(
        listMachine(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (active_filter ? "&is_active=" + active_filter : "") +
            (deviceId_filter ? "&device_id=" + deviceId_filter.value : "") +
            (adds_filter
              ? "&is_ads_allowed=" + (adds_filter === "Allowed" ? true : false)
              : "") +
            ("&current_status=" + e)
        )
      );
    } else {
      setCurentStatus(null);
      dispatch(
        listMachine(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (deviceId_filter ? "&device_id" + deviceId_filter.value : "") +
            (adds_filter
              ? "&is_ads_allowed=" + adds_filter === "Allowed"
                ? true
                : false
              : "")
        )
      );
    }
  };
  //get date  range filter
  const onEventService = (event, picker) => {
    // console.log(picker.startDate);
  };
  const callbackService = (start, end, lebal) => {
    setDateRangeStartDate(start._d);
    setDateRangeEndDate(end._d);
    dateRangeFilter(start._d, end._d);
  };

  //tables listing
  const fiter = () => {
    if (fetchedTableData.responce) {
      const filterarr = [];
      for (let i = 0; i < fetchedTableData.responce.data.length; i++) {
        let data = { ...fetchedTableData.responce.data[i] };
        const adds = data.is_ads_allowed;
        delete data.is_ads_allowed;

        data.is_ads_allowed = (
          <FormGroup
            id={data._id}
            name={data.name}
            onChange={(e) => {
              changeaddStatuss(e.target);
            }}
          >
            <FormControlLabel
              id={data._id}
              name={data.name}
              className="d-flex justify-content-center"
              control={
                <Checkbox
                  id={data._id}
                  name={data.name}
                  defaultChecked={adds ? true : false}
                />
              }
            />
          </FormGroup>
        );
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const createdate = new Date(data.created_at);
        const firmware = new Date(data.firmware_updated_at);
        // data.is_active
        //   ? (data.is_active = "Active")
        //   : (data.is_active = "In Active");
        const create_date =
          createdate.getDate() +
          "-" +
          monthNames[createdate.getMonth()] +
          "-" +
          createdate.getFullYear();
        const firmware_updated =
          firmware.getDate() +
          "-" +
          monthNames[firmware.getMonth()] +
          "-" +
          firmware.getFullYear();

        // data.is_ads_allowed = "jsd";

        data.created_at
          ? (data.created_at = create_date)
          : console.log("sdfdsf");
        data.firmware_updated_at
          ? (data.firmware_updated_at = firmware_updated)
          : console.log("sdfdsf");
        data.is_active = (
          <FormGroup
            id={data._id}
            name={data.name}
            onChange={(e) => {
              changeStatus(e.target);
            }}
          >
            <FormControlLabel
              id={data._id}
              name={data.name}
              className="d-flex justify-content-center"
              control={
                <Checkbox
                  id={data._id}
                  name={data.name}
                  defaultChecked={
                    data.status.length != 0 ? data.status[0].is_active : false
                  }
                />
              }
            />
          </FormGroup>
        );
        data.current_status =
          data.status[0].current_status === "Connected" ? (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p
                className="px-2"
                style={{ margin: 0, padding: 0, color: "#1B8000" }}
              >
                {data.status[0].current_status}
              </p>
            </div>
          ) : data.status[0].current_status === "Reconnected" ? (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p
                className="px-2"
                style={{ margin: 0, padding: 0, color: "#1B8000" }}
              >
                {data.status[0].current_status}
              </p>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <p className="px-2 text-danger" style={{ margin: 0, padding: 0 }}>
                {data.status[0].current_status
                  ? data.status[0].current_status
                  : " Not Connected"}
              </p>
            </div>
          );
        filterarr.push(data);
        console.log(data.status);
      }
      filterarr.filter((a) => delete a.updated_at);
      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.status);
      filterarr.filter((a) => delete a.manifacture_date);
      const groupdrop2 = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={deviceId_filter ? deviceId_filter.value : ""}
                    className=""
                    options={
                      ListFullMachine.responce
                        ? ListFullMachine.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.device_id,
                              label: e.device_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setDeviceIdFilter(value);
                      deviceId_filter_api(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="Device Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const statusFilter = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={active_filter ? active_filter : ""}
                    className=""
                    options={[
                      { value: true, label: "Active" },
                      { value: false, label: "in Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      activeFilter(a);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="Active "
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const curentStatusFilter = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={curentStatus ? curentStatus : ""}
                    className=""
                    options={[
                      { value: "Connected", label: "Connected" },
                      { value: "Disconnected", label: "Disconnected" },
                      { value: "Reconnected", label: "Reconnected" },
                      { value: "Not Connected", label: "Not Connected" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      filterCurentValue(a);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="curent status"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const addsFilter = () => {
        return (
          <>
            <div
              className="search-heder-table "
              style={{
                marginRight: "55px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={adds_filter ? adds_filter : ""}
                    className=""
                    options={[
                      { value: "Allowed", label: "Adds Allowed" },
                      { value: "notAllowed", label: "Not Allowed" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      filtervalue(a);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="Adds"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      const tablefilterDropDown = [
        groupdrop2(),
        statusFilter(),
        curentStatusFilter(),
        addsFilter(),
      ];
      // console.log(arr);
      return (
        <MainTable
          // allacated={allacated}
          tablefilterDropDown={tablefilterDropDown}
          tabledata={filterarr}
          // filter={filter1}
          listOfMachine={" LIST OF MACHINE"}
          filtervalue={filtervalue}
          getData={getData}
          Action={Action}
        />
      );
    } else {
      console.log("hedfuhdvfuyawvef");
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      const groupdrop2 = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={deviceId_filter ? deviceId_filter.value : ""}
                    className=""
                    options={
                      ListFullMachine.responce
                        ? ListFullMachine.responce.data.map((e) => {
                            return {
                              _id: e._id,
                              value: e.device_id,
                              label: e.device_id,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setDeviceIdFilter(value);
                      deviceId_filter_api(value);
                      // console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="Device Id"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const statusFilter = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={active_filter ? active_filter : ""}
                    className=""
                    options={[
                      { value: true, label: "Active" },
                      { value: false, label: "in Active" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      activeFilter(a);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="Active "
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const curentStatusFilter = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={curentStatus ? curentStatus : ""}
                    className=""
                    options={[
                      { value: "Connected", label: "Connected" },
                      { value: "Disconnected", label: "Disconnected" },
                      { value: "Reconnected", label: "Reconnected" },
                      { value: "Not Connected", label: "Not Connected" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      filterCurentValue(a);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="Adds"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const addsFilter = () => {
        return (
          <>
            <div
              className="search-heder-table "
              style={{
                marginRight: "55px",
              }}
            >
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={adds_filter ? adds_filter : ""}
                    className=""
                    options={[
                      { value: "Allowed", label: "Adds Allowed" },
                      { value: "notAllowed", label: "Not Allowed" },
                    ]}
                    onChange={(e, value) => {
                      const a = value ? value.value : "all";

                      filtervalue(a);
                    }}
                    sx={{ width: 150, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        variant="standard"
                        // label="Device Id"
                        placeholder="Adds"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      const tablefilterDropDown = [
        groupdrop2(),
        statusFilter(),
        curentStatusFilter(),
        addsFilter(),
      ];
      return (
        <MainTable
          tablefilterDropDown={tablefilterDropDown}
          tabledata={filterarr}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };

  // console.log(tabledata);

  return (
    <>
      <div className="comp-change">
        <div>
          {fetchedTableData.isLoading ? (
            <div className="border">
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            </div>
          ) : (
            ""
          )}
          <div className=" pt-3 px-2  row w-100">
            <div className="d-flex">
              <Stack className="mx-3 py-2  w-100">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                  {breadcrumbs}
                </Breadcrumbs>
                {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
              </Stack>
              <div className="w-100 px-2 pt-1 d-flex justify-content-end dateRange align-items-center">
                <CalendarMonthIcon className="d-flex  align-items-center " />
                <p className="d-flex px-1 m-0 align-items-center" type="button">
                  <DateRangePicker
                    initialSettings={{
                      startDate: oneMonthPreviosDate,
                      endDate: new Date(),
                      locale: {
                        format: "DD/MM/YYYY",
                      },
                    }}
                    className="date"
                    onEvent={onEventService}
                    onCallback={callbackService}
                  >
                    <input type="button" className=" date " />
                  </DateRangePicker>
                </p>
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-12 col-lg-6 ">
              <Grid spacing={2} container className="align-items-center   px-3">
                <Grid
                  item
                  className="align-items-center py-3 col-sm-12 col-md-12 col-lg-6 grid h-100"
                >
                  <Card
                    style={{ borderRadius: "15px", height: "100%" }}
                    className="px-10"
                  >
                    <div className="darkgrey fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                      {t("dashboard.activeMachine")}
                    </div>
                    <div className="d-flex align-items-center">
                      <CheckCircleIcon
                        className="text-succe cancel_ico"
                        htmlColor="#a1c335"
                      />
                      <img
                        src={require("../../assets/image/coffee-machine.png")}
                        className="icon-border-cofee grey"
                      />
                      <div className="d-flex mx-2 flex-column">
                        <div className="d-flex px-3">
                          <h1 className="fw-bolder textlightblue">12,600</h1>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-up "
                            className="grey up-arrow"
                          />
                        </div>
                        <p className="grey company-text">
                          *{t("dashboard.newlyAddedMachine", { count: "10" })}{" "}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid
                  item
                  className="align-items-center py-3 col-sm-12 col-md-12 col-lg-6 grid h-100"
                >
                  <Card style={{ borderRadius: "15px", height: "100%" }}>
                    <div className="darkgrey fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                      {t("dashboard.defectMachine")}
                    </div>
                    <div className="d-flex align-items-center">
                      <CancelIcon className="text-danger cancel_ico" />
                      <img
                        src={require("../../assets/image/coffee-machine.png")}
                        className="icon-border-cofee grey"
                      />
                      <div className="d-flex mx-2 flex-column">
                        <div className="d-flex px-3">
                          <h1 className="fw-bolder text-danger">120</h1>
                          <FontAwesomeIcon
                            icon="fa-solid fa-circle-down "
                            className="grey up-arrow"
                          />
                        </div>
                        <p className="grey company-text md">
                          *{" "}
                          {t("dashboard.newlyDefectedMachine", { count: "10" })}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div className=" col-sm-12 col-md-12 col-lg-6 ">
              <Grid spacing={2} container className="align-items-center px-3">
                {Issues.map((data, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      className="align-items-center col-sm-6 col-md-3 col-lg-3 py-3 grid h-100"
                    >
                      <Card
                        // className="p-2"
                        style={{ borderRadius: "15px", height: "100%" }}
                      >
                        <div className="d-flex p-3  flex-column justify-content-center align-items-center">
                          {data.icon}
                          <p className="fw-bolder grey m-0">{t(data.issue)}</p>
                          <h1 className="fw-bolder grey pt-2 ">{data.value}</h1>
                        </div>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </div> */}
          </div>
          {fetchedTableData.isLoading ? null : (
            <MachineCart
              machineData={
                fetchedTableData.responce
                  ? fetchedTableData.responce.data.map((e) => {
                      let data = { ...e };
                      data.is_active =
                        e.status.length != 0 ? e.status[0].is_active : false;
                      data.current_status =
                        e.status.length != 0 ? e.status[0].current_status : "";
                      data.is_defect =
                        e.status.length != 0 ? e.status[0].is_defect : false;
                      data.updated_at =
                        e.status.length != 0
                          ? e.status[0].updated_at
                            ? e.status[0].updated_at
                            : new Date()
                          : new Date();
                      return data;
                    })
                  : 0
              }
            />
          )}
        </div>
        {fetchedTableData.isLoading ? null : fiter()}
      </div>
    </>
  );
}

export default ViewMachine;
