import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listNotification } from "./notificationServiceApi";

import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const SUCCESE = async (data) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const ListNotification = createSlice({
  name: "ListNotification",
  initialState: {
    isLoading: false,
    responce: "",
    isSuccess: false,
    Message: "",
  },
  reducers: {
    isSuccess: (state, payload) => {
      state.isSuccess = false;
    },
    listnotificationsFalse: (state, payload) => {
      state.isSuccess = false;
    },
    clearnotification: (state, payload) => {
      state.responce = "";
    },
  },
  extraReducers: {
    [listNotification.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [listNotification.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.responce = payload.data;
      state.Message = payload.data.message;
      state.isSuccess = true;
      // SUCCESE(payload.data.message);
    },

    [listNotification.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.responce = "";

      state.Message = payload;
      // FAILED(payload);
      if (payload.message.name === "TokenExpiredError") {
        localStorage.clear();
        window.location.pathname = "/login";
        // Navigateto();
      }
    },
  },
});

export const { isSuccess, errorMessage, listDrink, clearnotification } =
  ListNotification.actions;
export default ListNotification.reducer;
