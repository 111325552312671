import React, { useEffect, useState } from "react";
import "../assets/css/login.css";
import { useFormik, Field, Form, ErrorMessage } from "formik";
import { Route, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { createPassword } from "./services/auth_api_service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Paper, TextField, CircularProgress } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation } from "react-router-dom";
function Login(props) {
  const navigate = useNavigate();
  const dispathc = useDispatch();
  const Login = useSelector((state) => state.createPassword);

  const validationSchema = Yup.object().shape({
    Password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    passwordConfirmation: Yup.string()
      .required("Conform Password is Required")
      .oneOf([Yup.ref("Password")], "password must be match"),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // ),
  });
  const formik = useFormik({
    initialValues: {
      passwordConfirmation: "",
      Password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = formik;
  useEffect(() => {
    if (Login.isSuccess) {
      resetForm();
      navigate("/login");
    }
  }, [Login]);

  //reducer

  console.log(Login);

  //get token and id
  const search = useLocation().search;
  const _id = new URLSearchParams(search).get("id");
  const token = new URLSearchParams(search).get("token");
  const submit = (val) => {
    const submit = {
      _id: _id,
      password: val.Password,
      token: token,
    };

    dispathc(createPassword(submit));
  };

  // console.log(errors);
  //   console.log(token);

  return (
    <>
      <div>
        <div className="w-100 h-100 d-flex align-items-center position-absolute justify-content-center">
          <div
            className=""
            style={{
              width: "22%",
            }}
          >
            <Paper className="d-flex flex-column p-4 justify-content-center align-items-center">
              <div className="d-flex logo justify-content-center align-items-center">
                Boatload
              </div>
              <div>
                <div className="d-flex pb-4 px-4 pt-1 justify-content-center sing">
                  Create Password
                </div>
                <div className="font-14 d-flex justify-content-center">
                  {" "}
                  to continue view details
                </div>
              </div>
              <div className="w-100 form d-flex flex-column">
                <TextField
                  type="password"
                  className="textFieldinput"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Password}
                  helperText={
                    touched.Password && errors.Password ? errors.Password : ""
                  }
                  error={touched.Password && errors.Password ? true : false}
                  name="Password"
                  label="Password"
                ></TextField>
                <TextField
                  type="password"
                  className="textFieldinput"
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="passwordConfirmation"
                  label="Confirm Password"
                  error={
                    touched.passwordConfirmation && errors.passwordConfirmation
                      ? true
                      : false
                  }
                  helperText={
                    touched.passwordConfirmation && errors.passwordConfirmation
                  }
                ></TextField>
              </div>
              {/* <div className="font-14">Forgot Password</div> */}
              <div style={{ padding: "20px", width: "161px" }}>
                <LoadingButton
                  onClick={handleSubmit}
                  className="loginButton w-100 d-flex justify-content-center align-items-center"
                  loading={Login.isLoading ? true : false}
                  loadingIndicator={
                    <>
                      <div className="d-flex px-2 justify-content-center w-100">
                        <div className="">
                          <CircularProgress
                            className="d-flex align-items-center justify-content-center "
                            size={20}
                          />
                        </div>
                        <div className="w-100 lod font-11 d-flex align-items-center justify-content-center">
                          Please Wait
                        </div>
                      </div>
                    </>
                  }
                  variant="outlined"
                >
                  Submit
                </LoadingButton>
                {/* <Button >
                  Submit
                </Button> */}
              </div>
            </Paper>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default Login;
