import React, { useState, useE } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { getMachineUsage } from "../../redux/reducer/machine_usage/machineUsageApiService";
import BarChart from "../../insideComponents/dashbord/charts/barchart";
import MainTable from "../../insideComponents/mainTable";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Link, Card } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewMachineUsage from "./viewMachineUsage";
import ViewRawMeterial from "./viewrawMeterialUsage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//machine Usage cup count

//main render
const MachineUsage = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="comp-change">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Cup Counts" {...a11yProps(0)} />
              <Tab label="Raw Meterial Usage" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ViewMachineUsage />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ViewRawMeterial />
          </TabPanel>
        </Box>
      </div>
    </>
  );
};

export default MachineUsage;
