import React from "react";
import Chart from "react-apexcharts";

const RadialBarChart = () => {
  const series = [69, 67, 61];

  const option = {
    chart: {
      height: 250,
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 3,
          size: "30%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
          },
        },
      },
    },
    colors: ["#fbd125", "#4fa6ee", "#00cec2"],
    labels: ["Tea", "Flavere Tea", "Milk"],
    legend: {
      show: true,
      floating: true,
      fontSize: "14px",
      position: "left",
      offsetX: 0,

      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 3,
      },
    },
    stroke: {
      lineCap: "round",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <Chart
      options={option}
      series={series}
      type="radialBar"
      className="apexchert"
      width="90%"
      height="97%"
    ></Chart>
  );
};
export default RadialBarChart;
