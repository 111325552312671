import React from "react";
import PropTypes from "prop-types";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckboxComp = (props) => {
  const { style, label, setFieldValue, value, name, defaultChecked } = props;
  return (
    <div style={style}>
      <FormGroup
        row
        onChange={(e) => {
          console.log(e.target.checked);

          setFieldValue(name, e.target.checked);
        }}
      >
        <FormControlLabel
          width={100}
          control={<Checkbox defaultChecked={defaultChecked} />}
          label={label}
        />
      </FormGroup>
    </div>
  );
};

export default CheckboxComp;
