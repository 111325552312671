import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { Paper, Link, Button } from "@material-ui/core";
import * as Yup from "yup";
import {
  listproduct,
  productOrder,
  returnProduct,
} from "../../redux/reducer/inventory/inventoryapiService";
import { listCompany } from "../../redux/reducer/UserMaster/userMasterApiService";
import * as XLSX from "xlsx";
import { TextField } from "@material-ui/core";
import { useFormik } from "formik";
import TextInput from "../forms/textInput";
import LoadingButton from "@mui/lab/LoadingButton";
import TextNumberInput from "../forms/ numberInput";
import DropdownSelecter from "../forms/dropdownSelecter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isSuccessproductOrder } from "../../redux/reducer/inventory/productorder";

const ProductOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listProduct = useSelector((state) => state.Listproduct);
  const companyData = useSelector((state) => state.listCompanyApi);
  const productorder = useSelector((state) => state.productorder);
  const [qr_codes, setQrCodes] = useState(null);

  //form validations
  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("Product name is required"),
    buyer_id: Yup.string().required("Buyyer is id required"),

    quantity: Yup.number().required("quvantity is required"),
    product_id: Yup.string().required("Product is id required"),
    // price: Yup.string().required("Price is id required"),
  });
  const formik = useFormik({
    initialValues: {
      product_id: "",
      buyer_id: "",
      quantity: "",
      status: "",
      price: "",
      qr_codes: [],
      stay_here: false,
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
    onReset: (data) => {
      console.log(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleReset,
    setTouched,
  } = formik;

  //file upload to get qr codes

  const getQrCodes = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    const Qr_code = [...jsonData];
    const qr_code_only = Qr_code.map((e) => {
      return e.Qr_codes;
    });

    setQrCodes(qr_code_only);
    console.log(qr_code_only);
  };

  //submit values
  const submit = (val) => {
    const product_id = listProduct.responce.data.filter((e) => {
      return e.name === val.product_id;
    });
    const company_id = companyData.responce.data.filter((e) => {
      return e.company_name === val.buyer_id;
    });
    if (val.status != "Return Product") {
      const submitData = {
        product_id: product_id[0]._id,
        buyer_id: company_id[0]._id,
        quantity: val.quantity,
        price: val.price,
        status: val.status,
      };
      console.log(submitData);
      dispatch(productOrder(submitData));
    }
    if (val.status === "Return Product") {
      const submitData = {
        product_id: product_id[0]._id,
        buyer_id: company_id[0]._id,
        quantity: val.quantity,
        price: val.price,
        status: val.status,
        qr_code: qr_codes,
      };
      console.log(submitData);
      dispatch(returnProduct(submitData));
    }
  };
  //useEffect
  useEffect(() => {
    dispatch(listproduct(""));
    dispatch(listCompany(""));
    if (productorder.isSuccess) {
      resetForm();
      navigate("/inventory/view_inventory");
      dispatch(isSuccessproductOrder());
    }
  }, [productorder]);
  console.log(errors);
  const status = [
    { value: "Sales Product", label: "Sales Product" },
    { value: "Return Product", label: "Return Product" },
  ];
  const productOption = !listProduct.responce
    ? [{ value: "No Data Found", label: "No Data Found" }]
    : listProduct.responce.data.map((e) => {
        return { id: e._id, value: e.name, label: e.name };
      });
  const companyOption = !companyData.responce
    ? [{ value: "No Data Found", label: "No Data Found" }]
    : companyData.responce.data.map((e) => {
        return { id: e._id, value: e.company_name, label: e.company_name };
      });
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      INVENTORY
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      PRODUCT ORDER (Or) RETURN
    </Link>,
  ];
  console.log(values);
  return (
    <div className="comp-change">
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      {/* <h1>ADD inventory</h1> */}
      <div className="Form p-4 " style={{ marginBottom: "100px" }}>
        <Paper className="bg-white machineForm">
          <div className="row pt-1 px-4">
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Product Name"
                onBlur={() => {
                  handleBlur({ target: { name: "product_id" } });
                }}
                setFieldValue={setFieldValue}
                options={productOption}
                errors={errors}
                values={values}
                touched={touched}
                vari={(e) => {
                  console.log(e);
                }}
                name="product_id"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Buyer Name"
                onBlur={() => {
                  handleBlur({ target: { name: "buyer_id" } });
                }}
                setFieldValue={setFieldValue}
                options={companyOption}
                errors={errors}
                values={values}
                touched={touched}
                vari={() => {
                  console.log();
                }}
                name="buyer_id"
              />
            </div>
            <div className="col-4 ">
              <TextNumberInput
                defaultvalue={""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Product quantity"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="quantity"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                // defaultvalue={
                //   editLeads.edit ? editLeads.data[0].customer_name : ""
                // }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Product price"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="price"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Status"
                onBlur={() => {
                  handleBlur({ target: { name: "status" } });
                }}
                setFieldValue={setFieldValue}
                options={status}
                errors={errors}
                values={values}
                touched={touched}
                vari={() => {
                  console.log();
                }}
                name="status"
              />
            </div>
            {values.status === "Return Product" ? (
              <div className="col-4  d-flex flex-column align-items-start">
                <label className="font-size-12" style={{ width: "90%" }}>
                  Upload Qr Code
                </label>
                <TextField
                  component="label"
                  onChange={(e) => {
                    // console.log(e.target.files);
                    getQrCodes(e);
                    setFieldValue("qr_codes", e.target.files[0]);
                  }}
                  name="qr_codes"
                  error={errors.qr_codes && touched.qr_codes ? true : false}
                  onBlur={handleBlur}
                  helperText={
                    errors.qr_codes && touched.qr_codes ? errors.qr_codes : null
                  }
                  type="file"
                  style={{ width: "90%" }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="w-100 d-flex justify-content-end p-4 px-5 ">
            <Stack spacing={2} direction="row">
              <LoadingButton
                variant="outlined"
                color="error"
                onClick={() => {
                  resetForm();
                  // dispatch(isEditLeads(false));
                  // dispatch(clearLeadsData());
                  // editLeads.edit ? navigate("/crm/viewleads") : resetForm();
                }}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                loading={productorder.isLoading ? true : false}
                onClick={handleSubmit}
              >
                save
                {/* {edit ? "Submit" : "Save"} */}
              </LoadingButton>
            </Stack>
          </div>
        </Paper>
      </div>
    </div>
  );
};
export default ProductOrder;
