import React, { useState, useEffect } from "react";
import MainTable from "../../insideComponents/mainTable";
import "../../assets/css/addUser.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropdownSelecter from "../forms/dropdownSelecter";
import TextInput from "../forms/textInput";
import EmailInput from "../forms/emailInput";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Link, Box, LinearProgress } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DialogTitle from "@mui/material/DialogTitle";

import { useNavigate } from "react-router-dom";
import {
  addUser,
  editUser,
  listUser,
} from "../../redux/reducer/UserMaster/userMasterApiService";
import { isSuccess } from "../../redux/reducer/UserMaster/editUserApi";
import { editUsers, userData } from "../../redux/reducer/UserMaster/editUser";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const CompanyFullView = (props) => {
  const dispatch = useDispatch();
  const is_edit = useSelector((state) => state.editCompany);
  const editUserApi = useSelector((state) => state.editUserApi);
  const fetchedUserData = useSelector((state) => state.listUser);
  const [edit, setedit] = useState(is_edit.edit ? true : false);
  const navigate = useNavigate();

  //validations
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("User name is required"),
    companyemail: Yup.string().email().required("email is id required"),
    group_id: Yup.string().required("Group_id is required"),
    primary_contact_no: Yup.string()
      .matches(new RegExp("[0-9]{10}"), "Mobile number is not valid")
      .required("primary contact number is required"),
    type: Yup.string().required("type  is required"),
    gst_no: Yup.string().required("gst number  is required"),
    pan_no: Yup.string().required("pan number  is required"),
    billing_address_1: Yup.string().required("billing address  is required"),
    billing_city: Yup.string().required("billing city  is required"),
    billing_state: Yup.string().required("billing state  is required"),
    billing_pincode: Yup.string().required("billing Pin Code  is required"),
    delivery_address_1: Yup.string().required("delivery address is required"),
    delivery_city: Yup.string().required("deliver city  is required"),
    delivery_state: Yup.string().required("deliver state  is required"),
    delivery_pincode: Yup.string().required("deliver pin code  is required"),
    secondary_contact_no: Yup.string()
      .matches(new RegExp("[0-9]{10}"), "Mobile number is not valid")
      .max(10),
  });
  const formik = useFormik({
    initialValues: {
      companyName: edit ? is_edit.data.company_name : "",
      group_id: edit ? is_edit.data.group_id : "",
      type: edit ? is_edit.data.type : "",
      gst_no: edit ? is_edit.data.gst_no : "",
      companyemail: edit ? is_edit.data.email_id : "",
      primary_contact_no: edit ? is_edit.data.primary_contact_no : "",
      secondary_contact_no: edit ? is_edit.data.secondary_contact_no : "",
      pan_no: edit ? is_edit.data.pan_no : "",
      billing_address_1: edit ? is_edit.data.billing_address_1 : "",
      billing_address_2: edit ? is_edit.data.billing_address_2 : "",
      billing_city: edit ? is_edit.data.billing_city : "",
      billing_state: edit ? is_edit.data.billing_state : "",
      billing_pincode: edit ? is_edit.data.billing_pincode : "",
      delivery_address_1: edit ? is_edit.data.delivery_address_1 : "",
      delivery_address_2: edit ? is_edit.data.delivery_address_2 : "",
      delivery_city: edit ? is_edit.data.delivery_city : "",
      delivery_state: edit ? is_edit.data.delivery_state : "",
      delivery_pincode: edit ? is_edit.data.delivery_pincode : "",
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
    onReset: (data) => {
      console.log(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm,
    handleReset,
    setTouched,
  } = formik;
  //api call
  const apiCall = () => {
    dispatch(listUser("?company_id=" + is_edit.data._id));
  };
  // console.log(fetchedUserData.responce.data.userData);
  useEffect(() => {
    apiCall();
  }, []);

  //navigate view company
  if (editUserApi.isSuccess) {
    dispatch(isSuccess(false));
    navigate("/usermaster/viewcompany");
  }

  //   console.log(values);
  //submit data
  const submit = (data) => {
    if (!edit) {
      const {
        companyName,
        group_id,
        type,
        gst_no,
        companyemail,
        primary_contact_no,
        secondary_contact_no,
        pan_no,
        billing_address_1,
        billing_address_2,
        billing_city,
        billing_state,
        billing_pincode,
        delivery_address_1,
        delivery_address_2,
        delivery_city,
        delivery_state,
        delivery_pincode,
      } = data;
      const submitdata = {
        iscompany: true,
        company_detail: {
          group_id: group_id,
          type: type,
          company_name: companyName,
          email_id: companyemail,
          primary_contact_no: primary_contact_no,
          secondary_contact_no: secondary_contact_no,
          gst_no: gst_no,
          pan_no: pan_no,
          billing_address_1: billing_address_1,
          billing_address_2: billing_address_2,
          billing_city: billing_city,
          billing_state: billing_state,
          billing_pincode: billing_pincode,
          delivery_address_1: delivery_address_1,
          delivery_address_2: delivery_address_2,
          delivery_city: delivery_city,
          delivery_state: delivery_state,
          delivery_pincode: delivery_pincode,
        },
      };
      dispatch(addUser(submitdata));
      resetForm();
    }
    if (edit) {
      const {
        companyName,
        group_id,
        type,
        gst_no,
        companyemail,
        primary_contact_no,
        secondary_contact_no,
        pan_no,
        billing_address_1,
        billing_address_2,
        billing_city,
        billing_state,
        billing_pincode,
        delivery_address_1,
        delivery_address_2,
        delivery_city,
        delivery_state,
        delivery_pincode,
      } = data;
      const submitdata = {
        _id: is_edit.data._id,
        iscompany: true,
        company_detail: {
          group_id: group_id,
          type: type,
          company_name: companyName,
          email_id: companyemail,
          primary_contact_no: primary_contact_no,
          secondary_contact_no: secondary_contact_no,
          gst_no: gst_no,
          pan_no: pan_no,
          billing_address_1: billing_address_1,
          billing_address_2: billing_address_2,
          billing_city: billing_city,
          billing_state: billing_state,
          billing_pincode: billing_pincode,
          delivery_address_1: delivery_address_1,
          delivery_address_2: delivery_address_2,
          delivery_city: delivery_city,
          delivery_state: delivery_state,
          delivery_pincode: delivery_pincode,
        },
      };
      dispatch(editUser(submitdata));
    }
    console.log(data);
  };
  const type = [
    { value: "SALES", label: "SALES" },
    { value: "CUSTOMER", label: "CUSTOMER" },
    { value: " SEARVICE ", label: " SEARVICE " },
  ];
  const city = [
    { value: "Namakkal", label: "Namakkal" },
    { value: "Chennai", label: "Chennai" },
    { value: " Coimbatore ", label: " Coimbatore " },
    { value: "Pondicherry", label: "Pondicherry" },
    { value: "Tirichy", label: "Tirichy" },
    { value: " Selam ", label: " Selam " },
    { value: "Nagarkovil", label: "Nagarkovil" },
    { value: "Thanjavur", label: "Thanjavur" },
    { value: "Madurai", label: "Madurai" },
  ];
  const state = [
    { value: "Tamil Nadu", label: "Tamil Nadu " },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: " Maharashtra ", label: " Maharashtra " },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: " Arunachal Pradesh ", label: " Arunachal Pradesh " },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Kerala", label: "Kerala" },
    { value: " Tripura ", label: " Tripura " },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  ];
  //company details sending

  // props.submit = (editDrink) => {
  //   console.log("sbmiting");
  //   // handleSubmit();
  // };

  //brudcrumps
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      USER MASTER
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        navigate("/usermaster/viewcompany");
      }}
    >
      VIEW COMPANY
    </Link>,
    <Link
      underline="hover"
      type="button"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      {is_edit.data.company_name}
    </Link>,
  ];
  const getData = (val) => {
    if (val.action === "edit") {
      const company = fetchedUserData.responce.data.companyData.filter(
        (data) => {
          return data._id === val.data.company_id;
        }
      );
      const user = fetchedUserData.responce.data.userData.filter((data) => {
        return data._id === val.data._id;
      });
      console.log(user);
      dispatch(editUsers(true));
      dispatch(userData({ user: user, company: company }));
      navigate("/usermaster/viewcompany/fullview/editCompanyUser");
    }
    console.log(val);
  };
  const Action = {
    Action: [
      //   {
      //     icon: <FontAwesomeIcon className="p-2" icon="fa-solid fa-eye" />,
      //     name: "view",
      //   },
      {
        icon: (
          <FontAwesomeIcon
            type="button"
            className="p-2"
            icon="fa-solid fa-pen-to-square"
          />
        ),
        name: "edit",
      },
    ],
  };

  //filter table data
  const filter = () => {
    if (fetchedUserData.responce.data) {
      const filterarr = [];
      for (let i = 0; i < fetchedUserData.responce.data.userData.length; i++) {
        let data = { ...fetchedUserData.responce.data.userData[i] };
        data.is_active
          ? (data.is_active = "Active")
          : (data.is_active = "In Active");
        // data.is_ads_allowed = "jsd";
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const date = new Date(data.create_at);
        data.is_active
          ? (data.is_active = "Active")
          : (data.is_active = "In Active");
        const create_date =
          date.getDate() +
          "-" +
          monthNames[date.getMonth()] +
          "-" +
          date.getFullYear();
        const last_login_date =
          date.getDate() +
          "-" +
          monthNames[date.getMonth()] +
          "-" +
          date.getFullYear();
        data.create_at ? (data.create_at = create_date) : console.log("sdfdsf");
        data.last_login_at
          ? (data.last_login_at = create_date)
          : console.log("sdfdsf");

        filterarr.push(data);
        // console.log(data);
      }
      filterarr.filter((a) => delete a.updated_at);
      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.status);
      filterarr.filter((a) => delete a.company);

      if (filterarr.length != 0) {
        return (
          <MainTable
            tabledata={filterarr}
            listOfMachine={" USER LIST "}
            getData={getData}
            Action={Action}
          />
        );
      }
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tabledata={filterarr}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };

  return (
    <div className="comp-change ">
      {fetchedUserData.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form p-4 " style={{ marginBottom: "10px" }}>
        <Paper className="bg-white machineForm " style={{ width: "90%" }}>
          <DialogTitle className="pb-3 p-0  font-weight-bold">
            {"Company Details"}
          </DialogTitle>
          <div className="row pt-1 px-4">
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.company_name : ""}
                style={{ width: "100%" }}
                readonly={true}
                handleChange={handleChange}
                placeholder="Company Name"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="companyName"
              />
            </div>
            <div className="col-4 ">
              <EmailInput
                readonly={true}
                defaultvalue={edit ? is_edit.data.email_id : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Company email id"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="companyemail"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                readonly={true}
                defaultvalue={edit ? is_edit.data.group_id : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Group id"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="group_id"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                readonly={true}
                defaultvalue={edit ? is_edit.data.type : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="User Type"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="type"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.primary_contact_no : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="primary contact no"
                readonly={true}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="primary_contact_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.secondary_contact_no : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="secondary contact no"
                readonly={true}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="secondary_contact_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.gst_no : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                readonly={true}
                placeholder="Gst No"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="gst_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.pan_no : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Pan No"
                errors={errors}
                values={values}
                readonly={true}
                touched={touched}
                handleBlur={handleBlur}
                name="pan_no"
              />
            </div>
          </div>
          <DialogTitle className="pb-3 p-0  font-weight-bold">
            {"Billing Address"}
          </DialogTitle>
          <div className="row pt-1 px-4  w-100">
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.billing_address_1 : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Billing Adress 1"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                readonly={true}
                name="billing_address_1"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.billing_address_2 : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Billing Adress 2"
                errors={errors}
                values={values}
                touched={touched}
                readonly={true}
                handleBlur={handleBlur}
                name="billing_address_2"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.billing_city : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Billing City"
                errors={errors}
                values={values}
                touched={touched}
                readonly={true}
                handleBlur={handleBlur}
                name="billing_city"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.billing_state : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Billing State"
                errors={errors}
                values={values}
                touched={touched}
                readonly={true}
                handleBlur={handleBlur}
                name="billing_state"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.billing_pincode : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Billing Pincode"
                errors={errors}
                values={values}
                readonly={true}
                touched={touched}
                handleBlur={handleBlur}
                name="billing_pincode"
              />
            </div>
          </div>
          <DialogTitle className="pb-3 p-0  font-weight-bold">
            {"Delivery Address"}
          </DialogTitle>
          <div className="row pt-1 px-4  w-100">
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.delivery_address_1 : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Delivery Address"
                readonly={true}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="delivery_address_1"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.delivery_address_2 : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Delivery Address 2"
                errors={errors}
                values={values}
                readonly={true}
                touched={touched}
                handleBlur={handleBlur}
                name="delivery_address_2"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.delivery_city : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Delivery City"
                errors={errors}
                values={values}
                readonly={true}
                touched={touched}
                handleBlur={handleBlur}
                name="delivery_city"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.delivery_state : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Delivery State"
                errors={errors}
                values={values}
                readonly={true}
                touched={touched}
                handleBlur={handleBlur}
                name="delivery_state"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.delivery_pincode : ""}
                style={{ width: "100%" }}
                handleChange={handleChange}
                placeholder="Delivery Pincode"
                errors={errors}
                readonly={true}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="delivery_pincode"
              />
            </div>
          </div>
        </Paper>
      </div>
      {fetchedUserData.isLoading ? null : filter()}
    </div>
  );
};

export default CompanyFullView;
