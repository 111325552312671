import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const editRequestDetails = createSlice({
  name: "EditRequest",
  initialState: {
    edit: false,
    data: {},
    pagePurpose: "",
  },
  reducers: {
    editRequestData: (state, payload) => {
      state.data = payload.payload;
      state.edit = true;
    },

    cleareditRequestData: (state, payload) => {
      state.data = {};
      state.edit = false;
    },
  },
});

export const { editRequestData, cleareditRequestData, pagePurpose } =
  editRequestDetails.actions;
// export const selectCompany = (state) => statetCompany.login;
export default editRequestDetails.reducer;
