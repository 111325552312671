import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listMapedDrinks } from "./drinkMasterapiService";

import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const SUCCESE = async (data) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const ListMapedDrink = createSlice({
  name: "ListMapedDrink",
  initialState: {
    isLoading: false,
    responce: "",
    isSuccess: false,
    Message: "",
  },
  reducers: {
    isSuccessmapeddrink: (state, payload) => {
      state.isSuccess = false;
    },
    listDrink: (state, payload) => {
      state.isSuccess = false;
    },
    clearmapeddrinks: (state, payload) => {
      state.responce = "";
    },
  },
  extraReducers: {
    [listMapedDrinks.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [listMapedDrinks.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.responce = payload.data;
      state.Message = payload.data.message;
      state.isSuccess = true;
      // SUCCESE(payload.data.message);
    },

    [listMapedDrinks.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.Message = payload;
      //   FAILED(payload);
      if (payload.message.name === "TokenExpiredError") {
        localStorage.clear();
        window.location.pathname = "/login";
        // Navigateto();
      }
    },
  },
});

export const {
  isSuccessmapeddrink,
  errorMessage,
  listDrink,
  clearmapeddrinks,
} = ListMapedDrink.actions;
export default ListMapedDrink.reducer;
