import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listAssignedUser } from "./servise_requestApiService";

import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const SUCCESE = async (data) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const ListAssignedUser = createSlice({
  name: "ListAssignedUser",
  initialState: {
    isLoading: false,
    responce: "",
    isSuccess: false,
    Message: "",
  },
  reducers: {
    isSuccess: (state, payload) => {
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [listAssignedUser.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [listAssignedUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.responce = payload.data;
      state.Message = payload.data.message;
      state.isSuccess = true;
      // SUCCESE(payload.data.message);
    },

    [listAssignedUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.responce = "";
      state.Message = payload;
      // FAILED(payload);
      if (payload.message.name === "TokenExpiredError") {
        localStorage.clear();
        window.location.pathname = "/login";
        // Navigateto();
      }
    },
  },
});

export const { isSuccess, errorMessage } = ListAssignedUser.actions;
export default ListAssignedUser.reducer;
