import React, { useEffect, useState, useLayoutEffect } from "react";
import "../assets/css/sidenavbar.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { data, edit } from "../redux/reducer/viewMachineEditData";
import { clearCategory } from "../redux/reducer/drinkMastert/getCatogoryDetails";
import { editDrink, dataedit } from "../redux/reducer/viewDrinkedit";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  userData,
  editUsers,
  clearUserData,
} from "../redux/reducer/UserMaster/editUser";
import {
  editCompanys,
  CompanyData,
  clearCompanyData,
} from "../redux/reducer/UserMaster/editCompany";
import { clearmachine } from "../redux/reducer/machineMaster/machine_xrefApi";
import {
  clearMachineList,
  clearmachineFalse,
} from "../redux/reducer/machineMaster/viewMachineApi";
import {
  clearCompany,
  companylistFalse,
} from "../redux/reducer/UserMaster/listCompanyApi";
import { clearmapeddrinks } from "../redux/reducer/drinkMastert/listMapedDrinksApi";
import { clearMachineUsage } from "../redux/reducer/machine_usage/viewMachineUsage";
import { clearUsagePageData } from "../redux/reducer/machine_usage/machine_usage_page_data";
import { cleareditRequestData } from "../redux/reducer/serviceRequest/editRequest";
import { clearProductData } from "../redux/reducer/inventory/editProduct";
function Submenu({ item, key, sidenav, sidebar }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [subnav, setSubnav] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("subNaveActiveKey") === item.name) {
      setSubnav(true);
    }
  }, []);
  //clear local storage path is /
  if (window.location.pathname == "/") {
    localStorage.removeItem("subNaveActiveKey");
  }
  console.log("submenu", subnav);
  return (
    <div key={key}>
      {/* <Link to={item.path} style={{ textDecoration: "none" }}> */}

      <li
        onClick={() => {
          setSubnav(!subnav);
          // console.log(subnav);
          localStorage.setItem("subNaveActiveKey", item.name);
          if (!item.subNav) {
            navigate(item.path);
          }
        }}
        id={
          window.location.pathname == item.path
            ? "active"
            : null && localStorage.getItem("subNaveActiveKey") === item.name
            ? "active"
            : null || window.location.pathname == "/"
            ? item.name == "sidenav.sidenavDashboard"
              ? "active"
              : null
            : null
        }
        className={
          sidenav && sidebar
            ? "sidenavlist my-1"
            : "sidenavlist my-1 collapsIcon"
        }
      >
        {/* {console.log(window.location.pathname == item.path ? "active" : null)} */}
        <div style={{ marginRight: "20px" }} className="icon">
          {item.icon}
        </div>
        <div className="sidenavName py-2">{t(item.name)}</div>
      </li>
      {/* </Link> */}
      {subnav &&
        item.subNav &&
        item.subNav.map((item, index) => {
          // console.log(sub);
          if (!sidenav) {
            setSubnav(false);
          }
          const path = item.path.split("/");
          const locationPath = window.location.pathname.split("/");

          // if (!sidebar) {
          //   setSubnav(false);
          // }
          // !sidenav ? setSubnav(false) : setSubnav(true);

          return (
            <li
              key={index}
              onClick={async () => {
                // window.location.pathname = item.path;
                // setSubnav(!subnav);
                dispatch(edit(false));
                dispatch(data(""));
                dispatch(editDrink(false));
                dispatch(clearUserData());
                dispatch(clearCompanyData());
                dispatch(dataedit(""));
                dispatch(clearCategory(""));
                dispatch(editUsers(false));
                dispatch(userData(""));
                dispatch(editCompanys(false));
                dispatch(clearmachine(""));
                dispatch(CompanyData(""));
                dispatch(clearMachineList(""));
                dispatch(clearCompany(""));
                dispatch(clearmachineFalse());
                dispatch(companylistFalse(false));
                dispatch(clearmapeddrinks());
                dispatch(clearMachineUsage());
                dispatch(clearUsagePageData());
                dispatch(cleareditRequestData());
                dispatch(clearProductData());
                navigate(item.path);
                localStorage.setItem("subNaveActive", true);

                // dispatch(activeSub());
              }}
              id={
                "/" + locationPath[1] + "/" + locationPath[2] ==
                "/" + path[1] + "/" + path[2]
                  ? "active"
                  : null
              }
              className={
                sidenav ? "sidenavlist my-1" : "sidenavlist my-1 collapsIcon"
              }
            >
              {/* {console.log(window.location.pathname == item.path ? "active" : null)} */}
              <div style={{ paddingLeft: "8%" }} className="icon">
                {item.icon}
              </div>
              <div className="sidenavName  py-1">{t(item.name)}</div>
            </li>
          );
        })}
    </div>
  );
}

export default Submenu;
