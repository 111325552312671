import React, { useEffect, useState } from "react";
import TextInput from "../forms/textInput";
import NumberInput from "../forms/ numberInput";
import FileUpload from "../forms/singleFile";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Link, CircularProgress } from "@material-ui/core";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropdownSelecter from "../forms/dropdownSelecter";
import { data } from "../../redux/reducer/viewMachineEditData";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { isSuccess1 } from "../../redux/reducer/drinkMastert/editDrinks";
import { isSuccess } from "../../redux/reducer/drinkMastert/addDrinks";
import LoadingButton from "@mui/lab/LoadingButton";
import { edit, dataedit } from "../../redux/reducer/viewDrinkedit";
import CheckboxComp from "../forms/checkbox";

import {
  listCategory,
  addDrink,
  editDrink,
} from "../../redux/reducer/drinkMastert/drinkMasterapiService";
import { useNavigate } from "react-router-dom";
import { clearCategory } from "../../redux/reducer/drinkMastert/getCatogoryDetails";
import { Box } from "@mui/system";

const inputArr = [
  {
    id: 1,
    value: "",
  },
];
function AddDrinks(props) {
  const editApi = useSelector((state) => state.editDrink);
  const addApi = useSelector((state) => state.addDrink);
  const is_edit = useSelector((state) => state.Viewdrinkedit);
  const categoryData = useSelector((state) => state.ListDriksCategory);
  const dispatch = useDispatch();
  const [drinkedit, setDrinkedit] = useState(is_edit.edit ? true : false);
  const [files, setFiles] = useState();
  const [updateValue, setupdateValue] = useState(null);
  const [parameter, setParameter] = useState(
    drinkedit ? is_edit.data.configuration : inputArr
  );
  var paramet = drinkedit
    ? is_edit.data.configuration
    : categoryData.responce
    ? categoryData.responce.data[0].configuration
    : parameter;

  const [value, setValue] = useState(paramet);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    Catogryname: Yup.string().required("Catogryname is required"),
    Parameters: Yup.array()
      .of(
        Yup.object().shape({
          Parametername: Yup.string().required("parameter name is required"),
          value: Yup.number().required("parameter value is required"),
        })
      )
      .required("Parameter is required")
      .nullable(),
    // value: Yup.string().required("parametor value Number is required"),
    sub_category: Yup.string().required("Drink Name is required"),
    // categoryimage: Yup.mixed().required("file is required").nullable(),
  });
  console.log(paramet);

  const formik = useFormik({
    initialValues: {
      Catogryname: drinkedit ? is_edit.data.category : "",
      categoryimage: null,
      Parameters: [
        drinkedit
          ? is_edit.data.configuration
          : categoryData.responce
          ? categoryData.responce.data[0].configuration
          : parameter,
      ],
      sub_category: drinkedit ? is_edit.data.sub_category : "",
      stay_here: false,

      // value: "",
    },
    validationSchema,
    onSubmit: (data) => {
      submit(data);
    },
    onReset: (data) => {
      console.log(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;
  console.log(values);

  const addparameter = (e) => {
    setParameter([...parameter, { id: Math.random(), value: "" }]);
  };
  const removeParameter = (item) => {
    console.log(item);
    const list = [...parameter];
    console.log("Before: ", list);
    var index = list.indexOf(item);
    console.log(index);
    list.splice(index, 1);
    console.log("After: ", list);
    setParameter(list);
  };

  const parameterOnchange = (e, index, val) => {
    var na = "Parameters";
    var arr = "Array";
    if (!values.Parameters[0].value) {
      console.log(paramet);
      setFieldValue("Parameters", paramet);
    }
    console.log(e.target.name, index, val);
    const val1 = {
      value: Number(e.target.value),
      Parametername: drinkedit
        ? is_edit.data.configuration[index].Parametername
        : val.Parameters[index].Parametername,
      units: drinkedit
        ? is_edit.data.configuration[index].units
        : val.Parameters[index].units,
      Allowed_Range: drinkedit
        ? is_edit.data.configuration[index].Allowed_Range
        : val.Parameters[index].Allowed_Range,
      minRange: drinkedit
        ? is_edit.data.configuration[index].minRange
        : val.Parameters[index].minRange,
      maxRange: drinkedit
        ? is_edit.data.configuration[index].maxRange
        : val.Parameters[index].maxRange,
      HexaValue: drinkedit
        ? is_edit.data.configuration[index].HexaValue
        : val.Parameters[index].HexaValue,
    };
    setFieldValue(`Parameters[${index}]`, val1);
  };
  //submit data
  const submit = (data) => {
    if (drinkedit) {
      const { Catogryname, Parameters, sub_category } = data;
      var submitData = {
        _id: is_edit.data["_id"],
        category: Catogryname,
        sub_category: sub_category,
        configuration: Parameters,
        color: is_edit.data.color,
        image: is_edit.data.image,
      };
      console.log(submitData);
      dispatch(editDrink(submitData));
    }
    if (!drinkedit) {
      const { Catogryname, Parameters, sub_category } = data;
      var submitData = {
        category: Catogryname,
        sub_category: sub_category,
        configuration: Parameters,
        color: categoryData.responce.data[0].color,
        image: categoryData.responce.data[0].image,
      };

      dispatch(addDrink(submitData));
      dispatch(clearCategory());
    }
  };

  //get category details
  const getcatogoryname = (val) => {
    console.log(val);
    dispatch(listCategory(`category=${val}`));
  };
  useEffect(() => {
    if (editApi.isSuccess) {
      dispatch(isSuccess1());
      if (!values.stay_here) {
        navigate("/drinkmaster/viewdrink");
      }
    }
    if (addApi.isSuccess) {
      clearData();
      dispatch(isSuccess());
      resetForm();
      dispatch(clearCategory());
      if (!values.stay_here) {
        navigate("/drinkmaster/viewdrink");
      }
      setFiles(false);
    }
  }, [editApi, addApi]);
  //edit after redirect
  // console.log(Parameters);

  //cancel to clear data in forms
  const clearData = () => {
    resetForm();
    dispatch(clearCategory());
    if (drinkedit) {
      resetForm();
      dispatch(edit(false));
      dispatch(dataedit(""));
      navigate("/drinkmaster/viewdrink");
    }
  };

  //option
  const option = [
    { value: "Tea", label: "Tea" },
    { value: "Flavour Tea", label: "Flavour Tea" },
    { value: "Milk", label: "Milk" },
  ];
  //brudcrumps
  const breadcrumbs = [
    <Link
      type="button"
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      DRINK MASTER
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      {drinkedit ? "EDIT " : "ADD "}
      DRINK
    </Link>,
  ];
  console.log(values);
  //add more inputs
  const arrayData = (val) => {
    const mapval = drinkedit ? parameter : val;
    return mapval.map((e, key) => {
      console.log(e.Parametername);
      return (
        <>
          <div key={key} className="row d-flex flex-row px-4">
            <div className="col-4 ">
              <TextInput
                defaultvalue={e.Parametername}
                style={{ width: "90%" }}
                handleChange={(e) => {
                  parameterOnchange(e, key, values);
                }}
                mapval={mapval}
                readonly={true}
                underline={true}
                placeholder="Parameter Name"
                id={e.id}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="Parametername"
              />
            </div>
            <div className="col-4 position-relative ">
              <NumberInput
                defaultvalue={e.value}
                style={{ width: "90%" }}
                handleChange={(e) => {
                  parameterOnchange(e, key, values);
                }}
                mapval={mapval}
                placeholder="Parameter Value "
                errors={errors}
                min={e.minRange}
                max={e.maxRange}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="value"
              />
              <div
                className="position-absolute d-flex align-items-center"
                style={{ top: "32px", left: "89%" }}
              >
                <div className="pb-3 px-2">{e.units}</div>

                <Tooltip
                  className="pb-3 p-0 px-1 "
                  title={
                    <>
                      <div
                        className="d-flex font-12"
                        style={{ fontSize: "15px" }}
                      >
                        Range {e.Allowed_Range}
                      </div>
                    </>
                  }
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            {/* <div className="col-4 d-flex justify-content-end align-items-center">
              <div
                className="w-25 d-flex justify-content-end p-4"
                onClick={addparameter}
              >
                Add
                <FontAwesomeIcon
                  className="font-size-24 px-2"
                  icon="fa-solid fa-square-plus"
                />
              </div>
              Remove
              <FontAwesomeIcon
                onClick={() => {
                  parameter.length !== 1 && removeParameter(e);
                }}
                className="font-size-24 px-2"
                icon="fa-solid fa-trash"
              />
            </div> */}
          </div>
        </>
      );
    });
  };

  return (
    <div className="comp-change">
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form p-4 ">
        <div className="bg-white  machineForm">
          <div className="row pt-3 px-4 pb-4">
            <div className="col-4 ">
              <DropdownSelecter
                id={1}
                defaultvalue={is_edit.data.category}
                style={{ width: "90%" }}
                onBlur={() => {
                  handleBlur({ target: { name: "is_ads_allowed" } });
                }}
                placeholder={"Catogary Name"}
                setFieldValue={setFieldValue}
                options={option}
                errors={errors}
                vari={(e) => {
                  if (e) {
                    setupdateValue(true);
                  } else {
                    setupdateValue(null);
                  }
                  getcatogoryname(e);
                }}
                values={values}
                touched={touched}
                name="Catogryname"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={is_edit.data.sub_category}
                style={{ width: "90%" }}
                handleChange={(e) => {
                  if (!values.Parameters[0].value) {
                    console.log(paramet);
                    setFieldValue("Parameters", paramet);
                  }
                  setFieldValue("sub_category", e.target.value);
                }}
                disabled={false}
                placeholder="Drinks Name"
                id={9}
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="sub_category"
              />
            </div>
          </div>

          {/* <div className="row  px-4">
            <div className="col-4 ">
              <FileUpload
                style={{ width: "400px" }}
                // file={file}
                files={files}
                // radioButtonvalue={radioButtonvalue}
                placeholder="Category image"
                // options={option}
                errors={errors}
                touched={touched}
                onchange={(e) => {
                  console.log(e.target.files);
                  setFiles(e.target.files);
                  let event = {
                    target: {
                      name: "categoryimage",
                      value: e.target.files,
                    },
                  };
                  handleChange(event);
                }}
                handleBlur={handleBlur}
                name="categoryimage"
                values={values}
              />
            </div>
          </div> */}
          {!updateValue ? (
            ""
          ) : (
            <>
              {" "}
              <div className="row border-top px-1 d-flex mx-1">
                <div className="font-weight-bold w-75 px-0 py-4">
                  ADD DRINK VALUES
                </div>
              </div>
              {categoryData.isLoading ? (
                <Box
                  sx={{ display: "flex" }}
                  className="justify-content-center w-75"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {!parameter
                    ? null
                    : categoryData.isLoading
                    ? null
                    : arrayData(
                        categoryData.responce
                          ? categoryData.responce.data[0].configuration
                          : parameter
                      )}
                  <div className="row pt-3 px-4 pb-4">
                    <div className="col-4 ">
                      <CheckboxComp
                        style={{ width: "90%" }}
                        label={"Stay here"}
                        defaultChecked={false}
                        // handleChange={(e)=>{
                        //   handleChange({target:{name:"is_ads_allowed",}})
                        // }}
                        // onBlur={() => {
                        //   handleBlur({ target: { name: "is_ads_allowed" } });
                        // }}

                        setFieldValue={setFieldValue}
                        name="stay_here"
                      />
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-end p-4 px-4 ">
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          clearData();
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        onClick={handleSubmit}
                        loading={
                          editApi.isLoading || addApi.isLoading ? true : false
                        }
                        variant="outlined"
                      >
                        {drinkedit ? "Edit" : "Save"}
                      </LoadingButton>
                    </Stack>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddDrinks;
