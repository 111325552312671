import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "../../assets/css/addUser.css";
import TextInput from "../forms/textInput";
import DropdownSelecter from "../forms/dropdownSelecter";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import EmailInput from "../forms/emailInput";
import CompanyDetails from "../forms/companyDetails";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Link, CircularProgress } from "@material-ui/core";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckboxComp from "../forms/checkbox";

import {
  addUser,
  editUser,
  listCompany,
} from "../../redux/reducer/UserMaster/userMasterApiService";
import { useNavigate } from "react-router-dom";
import { isSuccess } from "../../redux/reducer/UserMaster/editUserApi";
import { isSuccessuser } from "../../redux/reducer/UserMaster/AddUserApi";

function AddUser(props) {
  const navigate = useNavigate();
  const companyList = useSelector((state) => state.listCompanyApi);
  const editUserApi = useSelector((state) => state.editUserApi);
  const addUserApi = useSelector((state) => state.addUser);
  const is_edit = useSelector((state) => state.editUser);
  const [edit, setEdit] = useState(is_edit.edit ? true : false);
  console.log(edit);
  const [Role, setRole] = useState(
    is_edit.edit ? is_edit.data.user[0].role : ""
  );
  const dispatch = useDispatch();
  const validationSchema =
    Role === "DISTRIBUTOR" || Role === "CUSTOMER ADMIN"
      ? Yup.object().shape({
          username: Yup.string().required("User name is required"),
          email: Yup.string().email().required("email is id required"),

          role: Yup.string().required("role  is required"),
          company_id: Yup.string().required("company  is required"),
          //   companyName: Yup.string().required("User name is required"),
          //   companyemail: Yup.string().email().required("email is id required"),
          //   group_id: Yup.string().required("Group_id is required"),
          //   primary_contact_no: Yup.string()
          //     .matches(new RegExp("[0-9]{10}"), "Mobile number is not valid")
          //     .required("primary contact number is required"),
          //   type: Yup.string().required("type  is required"),
          //   gst_no: Yup.string().required("gst number  is required"),
          //   pan_no: Yup.string().required("pan number  is required"),
          //   billing_address_1: Yup.string().required(
          //     "billing address  is required"
          //   ),
          //   billing_city: Yup.string().required("billing city  is required"),
          //   billing_state: Yup.string().required("billing state  is required"),
          //   billing_pincode: Yup.string().required(
          //     "billing Pin Code  is required"
          //   ),
          //   delivery_address_1: Yup.string().required(
          //     "delivery address is required"
          //   ),
          //   delivery_city: Yup.string().required("deliver city  is required"),
          //   delivery_state: Yup.string().required("deliver state  is required"),
          //   delivery_pincode: Yup.string().required(
          //     "deliver pin code  is required"
          //   ),
          //   secondary_contact_no: Yup.string()
          //     .matches(new RegExp("[0-9]{10}"), "Mobile number is not valid")
          //     .max(10),
        })
      : Yup.object().shape({
          username: Yup.string().required("User name is required"),
          email: Yup.string().email().required("email is id required"),

          role: Yup.string().required("role  is required"),
        });
  // console.log(is_edit.data.company.length);
  console.log();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: edit
        ? is_edit.data.user
          ? is_edit.data.user[0].name
          : ""
        : "",

      role: edit ? (is_edit.data.user ? is_edit.data.user[0].role : "") : "",
      company_id: edit
        ? is_edit.data.user
          ? is_edit.data.company.length === 0
            ? ""
            : companyList.responce
            ? companyList.responce.data.filter((data) => {
                var company;
                if (data._id === is_edit.data.user[0].company_id) {
                  company = data.company_name;

                  return company;
                }
              })[0].company_name
            : ""
          : ""
        : "",
      email: edit
        ? is_edit.data.user
          ? is_edit.data.user[0].email_id
          : ""
        : "",
      stay_here: false,

      // companyName: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].company_name
      //     : ""
      //   : "",
      // group_id: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].group_id
      //     : ""
      //   : "",
      // type: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].type
      //     : ""
      //   : "",
      // gst_no: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].gst_no
      //     : ""
      //   : "",
      // companyemail: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].email_id
      //     : ""
      //   : "",
      // primary_contact_no: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].primary_contact_no
      //     : ""
      //   : "",
      // secondary_contact_no: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].secondary_contact_no
      //     : ""
      //   : "",
      // pan_no: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].pan_no
      //     : ""
      //   : "",
      // billing_address_1: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].billing_address_1
      //     : ""
      //   : "",
      // billing_address_2: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].billing_address_2
      //     : ""
      //   : "",
      // billing_city: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].billing_city
      //     : ""
      //   : "",
      // billing_state: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].billing_state
      //     : ""
      //   : "",
      // billing_pincode: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].billing_pincode
      //     : ""
      //   : "",
      // delivery_address_1: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].delivery_address_1
      //     : ""
      //   : "",
      // delivery_address_2: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].delivery_address_2
      //     : ""
      //   : "",
      // delivery_city: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].delivery_city
      //     : ""
      //   : "",
      // delivery_state: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].delivery_state
      //     : ""
      //   : "",
      // delivery_pincode: edit
      //   ? is_edit.data.company.length > 0
      //     ? is_edit.data.company[0].delivery_pincode
      //     : ""
      //   : "",
    },
    validationSchema,

    onSubmit: (data) => {
      submit(data);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;
  console.log(values);
  //submit data
  const submit = (data) => {
    if (!edit) {
      if (Role === "CUSTOMER ADMIN" || Role === "DISTRIBUTOR") {
        const { email, role, username, company_id } = data;
        const Company_id = companyList.responce.data.filter((data) => {
          let id;
          if (data.company_name === company_id) {
            id = data._id;
            return id;
          }
        });

        const submit = {
          email_id: email,
          role: role,
          name: username,
          company_id: Company_id[0]._id,
          iscompany: false,
        };
        console.log(submit);
        dispatch(addUser(submit));
      } else {
        const { email, role, username } = data;
        const submit = {
          email_id: email,
          role: role,
          name: username,
          iscompany: false,
        };
        dispatch(addUser(submit));
      }
    }
    if (edit) {
      if (Role === "CUSTOMER ADMIN" || Role === "DISTRIBUTOR") {
        const {
          email,
          role,
          username,
          companyName,
          group_id,
          type,
          gst_no,
          companyemail,
          primary_contact_no,
          secondary_contact_no,
          pan_no,
          billing_address_1,
          billing_address_2,
          billing_city,
          billing_state,
          billing_pincode,
          delivery_address_1,
          delivery_address_2,
          delivery_city,
          delivery_state,
          delivery_pincode,
          company_id,
        } = data;
        const Company_id = companyList.responce.data.filter((data) => {
          let id;
          if (data.company_name === company_id) {
            id = data._id;
            return id;
          }
        });
        const submit = {
          _id: is_edit.data.user[0]._id,
          email_id: email,
          role: role,
          name: username,
          iscompany: false,
          company_id: Company_id[0]._id,
          // company_detail: {
          //   group_id: group_id,
          //   type: type,
          //   company_name: companyName,
          //   email_id: companyemail,
          //   primary_contact_no: primary_contact_no,
          //   secondary_contact_no: secondary_contact_no,
          //   gst_no: gst_no,
          //   pan_no: pan_no,
          //   billing_address_1: billing_address_1,
          //   billing_address_2: billing_address_2,
          //   billing_city: billing_city,
          //   billing_state: billing_state,
          //   billing_pincode: billing_pincode,
          //   delivery_address_1: delivery_address_1,
          //   delivery_address_2: delivery_address_2,
          //   delivery_city: delivery_city,
          //   delivery_state: delivery_state,
          //   delivery_pincode: delivery_pincode,
          // },
        };
        dispatch(editUser(submit));
      } else {
        const { email, role, username } = data;
        const submit = {
          _id: is_edit.data.user[0]._id,
          email_id: email,
          iscompany: false,
          role: role,
          name: username,
        };
        dispatch(editUser(submit));
      }
    }
  };
  //get company details
  useEffect(() => {
    dispatch(listCompany(""));

    if (editUserApi.isSuccess) {
      resetForm();
      dispatch(isSuccess(false));
      if (!values.stay_here) {
        navigate("/usermaster/viewUser");
      }
    }
    if (addUserApi.isSuccess) {
      resetForm();
      dispatch(isSuccessuser(false));
      if (!values.stay_here) {
        navigate("/usermaster/viewUser");
      }
    }
  }, [editUserApi, addUserApi]);

  //navigate view user

  const role = [
    { value: "ADMIN", label: "ADMIN" },
    { value: "CUSTOMER SUPPORT MANAGER", label: "CUSTOMER SUPPORT MANAGER" },
    { value: "CUSTOMER SUPPORT TEAM", label: "CUSTOMER SUPPORT TEAM" },
    { value: "CUSTOMER ADMIN", label: "CUSTOMER ADMIN" },
    { value: "DISTRIBUTOR", label: "DISTRIBUTOR" },
  ];
  const type = !companyList.isSuccess
    ? [{ value: "", label: "No Company Found" }]
    : companyList.responce.data.map((data, key) => {
        return {
          key: key,
          value: data.company_name,
          label: data.company_name,
        };
      });

  // [
  //   { value: "SALES", label: "SALES" },
  //   { value: "CUSTOMER", label: "CUSTOMER" },
  //   { value: " SEARVICE ", label: " SEARVICE " },

  // ];
  const city = [
    { value: "Namakkal", label: "Namakkal" },
    { value: "Chennai", label: "Chennai" },
    { value: " Coimbatore ", label: " Coimbatore " },
    { value: "Pondicherry", label: "Pondicherry" },
    { value: "Tirichy", label: "Tirichy" },
    { value: " Selam ", label: " Selam " },
    { value: "Nagarkovil", label: "Nagarkovil" },
    { value: "Thanjavur", label: "Thanjavur" },
    { value: "Madurai", label: "Madurai" },
  ];
  const state = [
    { value: "Tamil Nadu", label: "Tamil Nadu " },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: " Maharashtra ", label: " Maharashtra " },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: " Arunachal Pradesh ", label: " Arunachal Pradesh " },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Kerala", label: "Kerala" },
    { value: " Tripura ", label: " Tripura " },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  ];
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      USER MASTER
    </Link>,
    <Link
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      {edit ? "EDIT" : "ADD"} USER
    </Link>,
  ];
  console.log(errors);
  //get company details
  // const companyDetails = (data) => {
  //   console.log(data);
  // };

  //clear form
  const clearForm = () => {
    resetForm();
  };

  //company details
  const companyDetail = () => {
    if (
      Role === "CUSTOMER ADMIN" ||
      Role === "DISTRIBUTOR" ||
      Role === "PARENT"
    ) {
      console.log("type");
      // return <CompanyDetails companyDetails={companyDetails} />;
      return (
        <div>
          <div className="row border-top px-1 d-flex mt-3 mx-1 ">
            <div className="font-weight-bold w-75 p-4">
              ADD COMPANY DEATAILS
            </div>
          </div>
          <div className="row pt-1 px-4">
            {/* <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.company[0].company_name : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Company Name"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="companyName"
              />
            </div>
            <div className="col-4 ">
              <EmailInput
                defaultvalue={edit ? is_edit.data.company[0].email_id : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Company email id"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="companyemail"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.company[0].group_id : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Group id"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="group_id"
              />
            </div> */}
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Select Company"
                defaultvalue={"dffjbhvhjg"}
                onBlur={() => {
                  handleBlur({ target: { name: "company_id" } });
                }}
                setFieldValue={setFieldValue}
                vari={() => {
                  console.log();
                }}
                options={type}
                errors={errors}
                values={values}
                touched={touched}
                name="company_id"
              />
            </div>
            {/* <div className="col-4 ">
              <TextInput
                defaultvalue={
                  edit ? is_edit.data.company[0].primary_contact_no : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="primary contact no"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="primary_contact_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  edit ? is_edit.data.company[0].secondary_contact_no : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="secondary contact no"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="secondary_contact_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.company[0].gst_no : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Gst No"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="gst_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={edit ? is_edit.data.company[0].pan_no : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Pan No"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="pan_no"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  edit ? is_edit.data.company[0].billing_address_1 : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Billing Adress 1"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="billing_address_1"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  edit ? is_edit.data.company[0].billing_address_2 : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Billing Adress 2"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="billing_address_2"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Biling City"
                onBlur={() => {
                  handleBlur({ target: { name: "billing_city" } });
                }}
                setFieldValue={setFieldValue}
                options={city}
                errors={errors}
                values={values}
                touched={touched}
                vari={() => {
                  console.log();
                }}
                name="billing_city"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Biling State"
                onBlur={() => {
                  handleBlur({ target: { name: "billing_state" } });
                }}
                setFieldValue={setFieldValue}
                options={state}
                errors={errors}
                vari={() => {
                  console.log();
                }}
                values={values}
                touched={touched}
                name="billing_state"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  edit ? is_edit.data.company[0].billing_pincode : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Billing Pincode"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="billing_pincode"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  edit ? is_edit.data.company[0].delivery_address_1 : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Delivery Address"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="delivery_address_1"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  edit ? is_edit.data.company[0].delivery_address_2 : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Delivery Address 2"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="delivery_address_2"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Delivery City"
                onBlur={() => {
                  handleBlur({ target: { name: "delivery_city" } });
                }}
                setFieldValue={setFieldValue}
                options={city}
                errors={errors}
                values={values}
                touched={touched}
                vari={() => {
                  console.log();
                }}
                name="delivery_city"
              />
            </div>
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="Delivery State"
                onBlur={() => {
                  handleBlur({ target: { name: "delivery_state" } });
                }}
                setFieldValue={setFieldValue}
                options={state}
                errors={errors}
                vari={() => {
                  console.log();
                }}
                values={values}
                touched={touched}
                name="delivery_state"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={
                  edit ? is_edit.data.company[0].delivery_pincode : ""
                }
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="Delivery Pincode"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="delivery_pincode"
              />
            </div> */}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="comp-change ">
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form p-4 " style={{ marginBottom: "100px" }}>
        <Paper className="bg-white machineForm">
          <div className="row pt-3 px-4">
            <div className="col-4 ">
              <DropdownSelecter
                style={{ width: "90%" }}
                placeholder="User role"
                onBlur={() => {
                  handleBlur({ target: { name: "role" } });
                }}
                setFieldValue={setFieldValue}
                vari={(e) => {
                  setRole(e);

                  companyDetail();
                }}
                options={role}
                errors={errors}
                values={values}
                touched={touched}
                name="role"
              />
            </div>
            <div className="col-4 ">
              <TextInput
                defaultvalue={is_edit.edit ? is_edit.data.user[0].name : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="User Name"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="username"
              />
            </div>
            <div className="col-4 ">
              <EmailInput
                defaultvalue={is_edit.edit ? is_edit.data.user[0].email_id : ""}
                style={{ width: "90%" }}
                handleChange={handleChange}
                placeholder="User email id"
                errors={errors}
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                name="email"
              />
            </div>
            <div className="col-4 ">
              <CheckboxComp
                style={{ width: "90%" }}
                label={"Stay here"}
                defaultChecked={false}
                // handleChange={(e)=>{
                //   handleChange({target:{name:"is_ads_allowed",}})
                // }}
                // onBlur={() => {
                //   handleBlur({ target: { name: "is_ads_allowed" } });
                // }}

                setFieldValue={setFieldValue}
                name="stay_here"
              />
            </div>
          </div>
          {companyDetail()}
          <div className="w-100 d-flex justify-content-end p-4 px-5 ">
            <Stack spacing={2} direction="row">
              <Button
                color="error"
                variant="outlined"
                onClick={() => {
                  is_edit.edit ? navigate("/usermaster/viewUser") : clearForm();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="outlined"
                loadingPosition="center"
                loading={
                  editUserApi.isLoading || addUserApi.isLoading ? true : false
                }
                // loadingIndicator={
                //   <>
                //     <div className="d-flex px-2 justify-content-center w-100">
                //       <div className="">
                //         <CircularProgress
                //           className="d-flex align-items-center justify-content-center "
                //           size={20}
                //         />
                //       </div>
                //       <div className="w-100 lod font-11 d-flex align-items-center justify-content-center">
                //         Please Wait
                //       </div>
                //     </div>
                //   </>
                // }
                onClick={handleSubmit}
              >
                {is_edit.edit ? "Submit" : "Save"}
              </LoadingButton>
            </Stack>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default AddUser;
