import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const MapedDrinks = createSlice({
  name: "MapedDrinks",
  initialState: {
    edit: false,
    data: {},
    settings: {},
    paths: {},
  },
  reducers: {
    mapedDrinksdata: (state, payload) => {
      state.data = payload.payload;
    },
  },
});

export const { mapedDrinksdata,  } = MapedDrinks.actions;
// export const selectCompany = (state) => statetCompany.login;
export default MapedDrinks.reducer;
