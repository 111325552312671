import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "../forms/textInput";
import PasswordInput from "../forms/PasswordInput";
import "../../assets/css/form.scss";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import DatePicker from "../forms/datepicker";
import DateRangePicker from "../forms/dateRangePicker";
import RadioButton from "../forms/radioButton";
import FileUpload from "../forms/fileUpload";
import Slider from "../forms/slider";
import DiscrideSlider from "../forms/DiscrideSlider";
import Groupcheckbox from "../forms/groupcheckbox";
import CheckboxComp from "../forms/checkbox";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { useFormik, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import DropdownSelecter from "../forms/dropdownSelecter";
import MultiDropDown from "../forms/multiDropDown";
import { Link, Paper } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isSuccess } from "../../redux/reducer/machineMaster/addmachineApi";
import { isSuccess1 } from "../../redux/reducer/machineMaster/editmachineapi";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  addMachine,
  editMachine,
} from "../../redux/reducer/machineMaster/addMachineApiService";

function AddMachine(props) {
  const is_edit = useSelector((state) => state.viewMachineEditData);
  const addapi = useSelector((state) => state.Addmachine);
  const editApi = useSelector((state) => state.EditMachine);
  const [value, setValue] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const edit_data = useSelector((state) => state);
  const [edit, setEdit] = useState(is_edit.edit);

  const [files, setFiles] = useState();
  const validationSchema = Yup.object().shape({
    machinename: Yup.string().required("machineName is required"),
    device_id: Yup.string().required("machineId is required"),
    Model: Yup.string().required("machineModel Number is required"),
    // CompanyName: Yup.string().required("CompanyName is required"),
    is_ads_allowed: Yup.string().required("this  is required"),
    // CompanyLocation: Yup.array()
    //   .of(Yup.object().required("company location is required").nullable())
    //   .nullable(),

    // date: Yup.string().required("date is required").nullable(),
    // file: Yup.mixed().required("file is required").nullable(),
    // DateRange: Yup.array()
    //   .of(Yup.object().required("date range is required").nullable())
    //   .nullable(),
  });
  console.log(edit);
  const getMonth = is_edit.edit
    ? new Date(is_edit.data["manifacture_date"]).getMonth().toString()
        .length === 1
      ? "-0"
      : "-"
    : "";
  const manifactureDate = is_edit.edit
    ? new Date(is_edit.data["manifacture_date"]).getFullYear() +
      getMonth +
      new Date(is_edit.data["manifacture_date"]).getMonth() +
      "-" +
      new Date(is_edit.data["manifacture_date"]).getDate()
    : "";
  const formik = useFormik({
    initialValues: {
      machinename: is_edit.edit ? is_edit.data["name"] : "",
      device_id: is_edit.edit ? is_edit.data["device_id"] : "",
      Model: is_edit.edit ? is_edit.data["model"] : "",
      manifacture_date: is_edit.edit ? manifactureDate : "",
      // CompanyName: "",
      is_ads_allowed: false,

      // CompanyLocation: [],
      // date: "",
      // DateRange: [null, null],
      // file: null,
    },
    validationSchema,
    onSubmit: (values) => {
      // console.log(values);

      submit(values);
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    // handleSubmit = (values) => {
    //   console.log(values);
    // },
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;

  console.log(manifactureDate);
  // console.log(errors);
  const locationset = (e) => {
    console.log(e);
    // setLocation(e);
  };

  //onsubmit values
  // console.log(is_edit);

  //getRadio Value
  // const getRadioValue = (e) => {
  //   console.log(e.target.value);
  // };
  // const radioButtonvalue = [
  //   {
  //     option: "Option1",
  //   },
  //   {
  //     option: "Option2",
  //   },
  // ];
  //getting file
  // const file = (e) => {
  //   const data = [];
  //   for (let i = 0; i < e.target.files.length; i++) {
  //     data.push(e.target.files[i]);
  //   }
  //   setFiles(data);
  // };

  //dropdown option
  const option = [
    { value: true, label: "Adds allowed" },
    { value: false, label: "Adds not allowed" },
  ];
  //form submitions
  const submit = (val) => {
    if (is_edit.edit) {
      const {
        machinename,
        Model,
        device_id,
        is_ads_allowed,
        manifacture_date,
      } = val;
      const value = {
        _id: is_edit.data["_id"],
        name: machinename,
        model: Model,
        device_id: device_id,
        is_ads_allowed: is_ads_allowed,
        manifacture_date: manifacture_date,
        firmware_version: "v8iue",
        firmware_updated_at: new Date(),
      };
      dispatch(editMachine(value));
    }
    if (!is_edit.edit) {
      const {
        machinename,
        Model,
        device_id,
        is_ads_allowed,
        manifacture_date,
      } = val;
      const value = {
        name: machinename,
        model: Model,
        device_id: device_id,
        manifacture_date: manifacture_date,
        is_ads_allowed: is_ads_allowed,
        firmware_version: "v8iue",
        firmware_updated_at: new Date(),
      };
      console.log(val);
      dispatch(addMachine(value));
    }
  };
  //submit after clear forms
  console.log(values);
  // console.log(addapi);
  useEffect(() => {
    if (addapi.isSuccess) {
      resetForm();
      dispatch(isSuccess());
      navigate("/machinemaster/viewmachine");
    }
    if (editApi.isSuccess) {
      resetForm();
      dispatch(isSuccess1());
      navigate("/machinemaster/viewmachine");
    }
  }, [addapi, editApi]);

  //brudcrumbs
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey brud font-weight-bol font-weight-bold"
    >
      MACHINE MASTER
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey brud font-weight-bol font-weight-bold"
      key="2"
      color="inherit"
    >
      {is_edit.edit ? "EDIT MACHINE" : "ADD MACHINE"}
    </Link>,
  ];
  return (
    <div className="comp-change p-3">
      <Stack className="mx-2 py-2  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div>
        <div className="grey">
          <div className="px-1   py-1 font-weight-bold"></div>
        </div>
        <div className="Form p-2  h-100 ">
          <Paper className="bg-white addmachineform  machineForm1">
            <div className="row  ">
              <div className="col-12 ">
                <TextInput
                  defaultvalue={
                    is_edit.edit ? is_edit.data["device_id"] : values.device_id
                  }
                  style={{ width: "90%" }}
                  handleChange={handleChange}
                  placeholder="Device Id"
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="device_id"
                />
              </div>
            </div>
            <div className="row  ">
              <div className="col-12">
                <TextInput
                  defaultvalue={is_edit.edit ? is_edit.data["name"] : ""}
                  style={{ width: "90%" }}
                  handleChange={handleChange}
                  placeholder="Machine name"
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="machinename"
                />
              </div>
            </div>
            <div className="row  ">
              <div className="col-12 ">
                <TextInput
                  defaultvalue={is_edit.edit ? is_edit.data.model : ""}
                  style={{ width: "90%" }}
                  handleChange={handleChange}
                  placeholder="Machine Model "
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleBlur={handleBlur}
                  name="Model"
                />
              </div>
            </div>
            <div className="row ">
              <div className="col-12 ">
                <LocalizationProvider
                  className="w-100"
                  dateAdapter={AdapterDateFns}
                >
                  <TextField
                    defaultValue={is_edit.edit ? manifactureDate : ""}
                    type="date"
                    style={{ width: "90%" }}
                    value={values.manifacture_date}
                    variant="standard"
                    className="py-3"
                    // errors={errors.manifacture_date}
                    // touched={touched.manifacture_date}
                    handleBlur={handleBlur}
                    name="manifacture_date"
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="row mt-2 ">
              <div className="col-12 ">
                <div>
                  <CheckboxComp
                    style={{ width: "90%" }}
                    label={"Ads Allowed"}
                    defaultChecked={false}
                    // handleChange={(e)=>{
                    //   handleChange({target:{name:"is_ads_allowed",}})
                    // }}
                    // onBlur={() => {
                    //   handleBlur({ target: { name: "is_ads_allowed" } });
                    // }}
                    setFieldValue={setFieldValue}
                    name="is_ads_allowed"
                  />
                </div>
              </div>

              <div className="col-12 ">
                <div>
                  <CheckboxComp
                    style={{ width: "90%" }}
                    label={"Stay Here"}
                    defaultChecked={false}
                    // handleChange={(e)=>{
                    //   handleChange({target:{name:"is_ads_allowed",}})
                    // }}
                    // onBlur={() => {
                    //   handleBlur({ target: { name: "is_ads_allowed" } });
                    // }}
                    setFieldValue={setFieldValue}
                    name="stay_here"
                  />
                </div>
              </div>

              <div className="w-100 d-flex justify-content-end p-4 px-1 ">
                <Stack spacing={2} direction="row">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      if (is_edit.edit) {
                        navigate("/machinemaster/viewmachine");
                      }
                      if (!is_edit.edit) {
                        resetForm();
                      }
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={
                      addapi.isLoading || editApi.isLoading ? true : false
                    }
                    variant="outlined"
                    onClick={handleSubmit}
                  >
                    {is_edit.edit ? "Submit" : "Save"}
                  </LoadingButton>
                </Stack>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}

export default AddMachine;
