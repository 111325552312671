import React, { useEffect, useState } from "react";
import MainTable from "../../insideComponents/mainTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserTable from "../../sampleData/sampleDataViewUser.json";
import { useNavigate } from "react-router-dom";
import { Paper, Link, DialogTitle, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import { listUser } from "../../redux/reducer/UserMaster/userMasterApiService";
import { edit, userData } from "../../redux/reducer/UserMaster/editUser";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { listLeadsDetail } from "../../redux/reducer/crm/leadsApiService";

function ViewUser(props) {
  const [tabledata, setTableData] = useState(UserTable);
  const viewLeads = useSelector((state) => state.leadsDetailView);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchedLeadsHistoryData = useSelector(
    (state) => state.leadsdetailViewApi
  );

  //api call
  const apiCall = () => {
    dispatch(listLeadsDetail("?leads_id=" + viewLeads.data[0]._id));
  };
  // console.log(fetchedLeadsHistoryData.responce.data.userData);
  useEffect(() => {
    apiCall();
  }, []);
  const getData = (val) => {
    // console.log(val);
  };
  const Action = {
    // Action: [
    //   // {
    //   //   icon: <FontAwesomeIcon className="p-2" icon="fa-solid fa-eye" />,
    //   //   name: "view",
    //   // },
    //   //   {
    //   //     icon: (
    //   //       <FontAwesomeIcon className="p-2" icon="fa-solid fa-pen-to-square" />
    //   //     ),
    //   //     name: "edit",
    //   //   },
    // ],
  };
  const ve = (e) => {
    console.log(e);
  };

  //filter table data
  const filter = () => {
    if (fetchedLeadsHistoryData.responce.data) {
      const filterarr = [];
      for (let i = 0; i < fetchedLeadsHistoryData.responce.data.length; i++) {
        let data = { ...fetchedLeadsHistoryData.responce.data[i] };
        console.log(data);

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const date = new Date(data.created_at);
        const lead_updated = data.lead_updated_by;
        delete data.lead_updated_by;
        data.lead_updated_by = data.user[0].name;
        const create_date =
          date.getDate() +
          "-" +
          monthNames[date.getMonth()] +
          "-" +
          date.getFullYear();

        data.created_at
          ? (data.created_at = create_date)
          : console.log("sdfdsf");

        let keyValues = Object.entries(data);

        filterarr.push(data);
        // console.log(newAr);
      }
      filterarr.filter((a) => delete a.updated_at);
      filterarr.filter((a) => delete a.entity_id);
      filterarr.filter((a) => delete a.leads_id);
      filterarr.filter((a) => delete a.contact_person);
      filterarr.filter((a) => delete a.customer_name);
      filterarr.filter((a) => delete a.email_id);
      filterarr.filter((a) => delete a.location);
      filterarr.filter((a) => delete a.mobile_no);
      filterarr.filter((a) => delete a.source);

      filterarr.filter((a) => delete a.customer_id);

      filterarr.filter((a) => delete a.user);

      //   console.log(newAr);
      // filterarr.filter((a) => delete a.company_id);

      //filter value
      const filter = [];
      return (
        <MainTable
          tabledata={filterarr}
          filter={filter}
          listOfMachine={" LEADS ACTIONS "}
          getData={getData}
          Action={Action}
        />
      );
    } else {
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tabledata={filterarr}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };
  //brudcrumps
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      CRM
    </Link>,
    <Link
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
      onClick={() => {
        navigate("/crm/viewleads");
      }}
    >
      VIEW LEADS
    </Link>,
    <Link
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      {viewLeads.data[0].customer_name}
    </Link>,
  ];
  return (
    <div className="comp-change">
      {fetchedLeadsHistoryData.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <Stack className="mx-4 pt-4  ">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {breadcrumbs}
        </Breadcrumbs>
        {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
      </Stack>
      <div className="Form p-4 " style={{ marginBottom: "10px" }}>
        <Paper className="bg-white machineForm " style={{ width: "90%" }}>
          <div className="pb-3 p-0 font-size-20 font-weight-bold">
            {"Leads Details"}
          </div>
          <div className="row pt-1 px-4">
            <div className="col-4 ">
              <TextField
                value={viewLeads.data[0].customer_name}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Customer Name"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="col-4 ">
              <TextField
                value={viewLeads.data[0].contact_person}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Contact Person"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="col-4 ">
              <TextField
                value={viewLeads.data[0].email_id}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Email Id"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="col-4 pt-3">
              <TextField
                value={viewLeads.data[0].mobile_no}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Mobile No"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="col-4 pt-3">
              <TextField
                value={viewLeads.data[0].location}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Location"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="col-4 pt-3">
              <TextField
                value={viewLeads.data[0].source}
                readonly={true}
                className="w-100 px-2"
                name="companyName "
                label="Source"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
        </Paper>
      </div>
      {fetchedLeadsHistoryData.isLoading ? null : filter()}
    </div>
  );
}

export default ViewUser;
