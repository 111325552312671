import React from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const MultiDropDown = (props) => {
  const {
    style,
    options,
    placeholder,
    onBlur,
    name,
    errors,
    touched,
    values,
    setFieldValue,
  } = props;

  return (
    <div style={style}>
      <Autocomplete
        multiple
        className="p-2"
        name={name}
        onBlur={onBlur}
        value={values.name}
        onChange={(event, newValue) => {
          //   console.log(newValue);
          setFieldValue(name, newValue);
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={errors[name] && touched[name] ? errors[name] : null}
            error={errors[name] && touched[name] ? true : false}
            label={placeholder}
            variant="standard"
          />
        )}
      />
    </div>
  );
};

export default MultiDropDown;
