import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPassword } from "../../auth_component/services/auth_api_service";

import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";

const SUCCESE = async (data) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data) => {
  console.log(data);
  toast.error(data.message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const CreatePassword = createSlice({
  name: "CreatePassword",
  initialState: {
    isLoading: false,
    responce: "",
    isSuccess: false,
    Message: "",
  },
  reducers: {},
  extraReducers: {
    [createPassword.pending]: (state, { payload }) => {
      state.isLoading = true;
    },
    [createPassword.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.responce = payload;
      //   localStorage.setItem("Token", payload.data.token);
      //   localStorage.setItem("Role", payload.data.role);
      state.Message = payload.data.message;
      state.isSuccess = true;
      SUCCESE(payload.data.message);
    },
    [createPassword.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.Message = payload;
      FAILED(payload);
    },
  },
});

export const { login, errorMessage } = CreatePassword.actions;
export const selectUser = (state) => state.user.login;
export default CreatePassword.reducer;
