import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";
import {
  Paper,
  Link,
  Grid,
  TextField,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { useDispatch, useSelector } from "react-redux";
import MainTable from "../../insideComponents/mainTable";
import {
  listFullInventory,
  listproduct,
  listInventory,
} from "../../redux/reducer/inventory/inventoryapiService";
import { Autocomplete } from "@mui/material";
import DateRangePicker from "react-bootstrap-daterangepicker";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      color: "black",
      opacity: "2",
    },
  },
});

function Inventory(props) {
  const listInventoryApi = useSelector((state) => state.listInventory);
  const ListFullInventory = useSelector((state) => state.listFullInventory);
  const ListProduct = useSelector((state) => state.Listproduct);
  const dispatch = useDispatch();
  const [company_name_filter, setCompany_filter] = useState(null);
  const [product_name_filter, setProduct_name_filter] = useState(null);
  const classes = useStyles();
  let oneMonthPreviosDate = new Date();
  oneMonthPreviosDate.setMonth(oneMonthPreviosDate.getMonth() - 1);

  const [DateRangeStartDate, setDateRangeStartDate] =
    useState(oneMonthPreviosDate);
  const [DateRangeEndDate, setDateRangeEndDate] = useState(new Date());

  //useEffect
  useEffect(() => {
    dispatch(
      listInventory(
        "?from_date=" + DateRangeStartDate + "&toDate=" + DateRangeEndDate
      )
    );
    dispatch(listproduct(""));
    dispatch(listFullInventory(""));
  }, []);

  //table actions
  const Action = {};
  //filter table value
  const tablefilter = [];
  //get data
  const getData = (val) => {};
  //filter apis
  const companynameFilterApi = (e) => {
    // console.log(e);
    if (!e) {
      dispatch(
        listInventory(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (product_name_filter
              ? "&product_id=" + product_name_filter._id
              : "")
        )
      );
    }
    if (e) {
      dispatch(
        listInventory(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&buyer_id=" +
            e._id +
            (product_name_filter
              ? "?product_id=" + product_name_filter._id
              : "")
        )
      );
    }
  };
  const product_name_filterApi = (e) => {
    if (!e) {
      dispatch(
        listInventory(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            (company_name_filter ? "&buyer_id=" + company_name_filter._id : "")
        )
      );
    }
    if (e) {
      dispatch(
        listInventory(
          "?from_date=" +
            DateRangeStartDate +
            "&toDate=" +
            DateRangeEndDate +
            "&product_id=" +
            e._id +
            (company_name_filter ? "?buyer_id=" + company_name_filter._id : "")
        )
      );
    }
  };
  const dateRangeFilter = (start, end) => {
    console.log(start._d, end._d);
    dispatch(
      listInventory(
        "?toDate=" +
          end._d +
          (company_name_filter ? "&buyer_id=" + company_name_filter._id : "") +
          "&from_date=" +
          start._d +
          (product_name_filter ? "?product_id=" + product_name_filter._id : "")
      )
    );
  };
  const onEventService = (event, picker) => {
    // console.log(picker.startDate);
  };
  const callbackService = (start, end, lebal) => {
    setDateRangeStartDate(start._d);
    setDateRangeEndDate(end._d);
    dateRangeFilter(start, end);
  };

  const Role = localStorage.getItem("Role");
  //table data filter
  const filter = () => {
    if (listInventoryApi.responce.data) {
      const filterarr = [];
      const role = localStorage.getItem("Role");

      for (let i = 0; i < listInventoryApi.responce.data.length; i++) {
        let data = { ...listInventoryApi.responce.data[i] };
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        // console.log(data);
        const createdate = new Date(data.created_at);
        const last_stock_updated_at = new Date(data.last_stock_updated_at);
        const sheduleddate = new Date(data.shedule_date);
        const updated_date =
          last_stock_updated_at.getDate() +
          "-" +
          monthNames[last_stock_updated_at.getMonth()] +
          "-" +
          last_stock_updated_at.getFullYear();
        const newObj = {};
        // newObj._id = data._id;
        // newObj.product_name = data.Product[0].name;
        // newObj.buyyer_name = data.Buyyer[0].company_name;
        // newObj.stack = data.stock;
        // newObj.last_stack_updated_at = updated_date;
        // newObj.return_stack = data.return_stack ? data.return_stack : 0;
        let adminReturnProduct;
        let distributeReturn;
        if (role === "CUSTOMER ADMIN") {
          adminReturnProduct =
            data.ReturnProduct.length <= 0 ? 0 : data.ReturnProduct[0].quantity;
          distributeReturn =
            data.ReturnProductDistributer.length <= 0
              ? 0
              : data.ReturnProductDistributer[0].stack;
          newObj._id = data._id;
          newObj.product_name = data.Product[0].name;
          newObj.company_name = data.Buyyer[0].company_name;
          newObj.seller_name =
            data.seller.length != 0 ? data.seller[0].company_name : "Boatload";
          newObj.total_order =
            data.ProductOrders.length != 0 ? data.ProductOrders[0].quantity : 0;
          newObj.stack = data.stock;
          newObj.returnProduct = adminReturnProduct + distributeReturn;
        }
        if (role === "DISTRIBUTOR") {
          newObj._id = data._id;
          newObj.product_name = data.Product[0].name;
          newObj.stack = data.stock;
          newObj.returnProduct = adminReturnProduct =
            data.ReturnProduct.length <= 0 ? 0 : data.ReturnProduct[0].quantity;
          newObj.customer_name =
            data.CustonmerDetails.length != 0
              ? data.CustonmerDetails[0].company_name
              : "-";
          newObj.sales_product = data.salceProduct
            ? data.salceProduct.quantity
            : 0;
          newObj.cutomer_return_products =
            data.CustomerReturnProducts.length != 0
              ? data.CustomerReturnProducts[0].quantity
              : 0;
        }
        if (role === "ADMIN") {
          newObj._id = data._id;
          newObj.product_name = data.Product[0].name;
          newObj.customer_name =
            data.Buyyer.length != 0 ? data.Buyyer[0].company_name : "-";
          newObj.sales_product =
            data.ProductOrders.length != 0 ? data.ProductOrders[0].quantity : 0;
          newObj.custumer_return_product =
            data.ReturnProduct.length != 0 ? data.ReturnProduct[0].quantity : 0;
        }
        filterarr.push(newObj);

        // console.log(newObj);
      }
      filterarr.filter((a) => delete a.Buyyer);
      filterarr.filter((a) => delete a.Product);
      filterarr.filter((a) => delete a.entity_id);
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={product_name_filter ? product_name_filter.value : ""}
                    className=""
                    options={
                      ListProduct.responce
                        ? ListProduct.responce.data.map((e, key) => {
                            return {
                              key: key,
                              _id: e._id,
                              value: e.name,
                              label: e.name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setProduct_name_filter(value);
                      product_name_filterApi(value);
                      console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Product name"
                        placeholder="Product name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const groupdrop2 = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group "
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setCompany_filter(value);
                      companynameFilterApi(value);
                      // console.log(value);
                    }}
                    value={company_name_filter ? company_name_filter.value : ""}
                    options={
                      ListFullInventory.responce
                        ? ListFullInventory.responce.data.map((e) => {
                            return {
                              _id: e.buyer_id,
                              value: e.Buyyer[0].company_name,
                              label: e.Buyyer[0].company_name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Customer name"
                        placeholder="Customer name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      const tablefilterDropDown =
        Role === "CUSTOMER ADMIN" ? [groupdrop()] : [groupdrop(), groupdrop2()];

      return (
        <MainTable
          tabledata={filterarr}
          tablefilterDropDown={tablefilterDropDown}
          // filter={tablefilter}
          listOfMachine={"INVENTORY LIST"}
          getData={getData}
          Action={Action}
        />
      );
    } else {
      const groupdrop = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group p"
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={product_name_filter ? product_name_filter.value : ""}
                    className=""
                    options={
                      ListProduct.responce
                        ? ListProduct.responce.data.map((e, key) => {
                            return {
                              key: key,
                              _id: e._id,
                              value: e.name,
                              label: e.name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setProduct_name_filter(value);
                      product_name_filterApi(value);
                      console.log(value);
                    }}
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Product name"
                        placeholder="Product name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };
      const groupdrop2 = () => {
        return (
          <>
            <div className="search-heder-table ">
              <div className=" px-3 ligh  rounded rounded-pill bg-white">
                <div
                  className="btn-group "
                  style={{
                    paddingBottom: "4px",
                    paddingTop: "9px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    onChange={(e, value) => {
                      const a = value ? value : "all";
                      setCompany_filter(value);
                      companynameFilterApi(value);
                      // console.log(value);
                    }}
                    value={company_name_filter ? company_name_filter.value : ""}
                    options={
                      ListFullInventory.responce
                        ? ListFullInventory.responce.data.map((e) => {
                            return {
                              _id: e.buyer_id,
                              value: e.Buyyer[0].company_name,
                              label: e.Buyyer[0].company_name,
                            };
                          })
                        : [{ value: "no data", label: "no data" }]
                    }
                    sx={{ width: 200, height: 35 }}
                    renderInput={(params) => (
                      <TextField
                        classes={{ root: classes.customTextField }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        // label="Customer name"
                        placeholder="Customer name"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      const tablefilterDropDown =
        Role === "CUSTOMER ADMIN" ? [groupdrop()] : [groupdrop(), groupdrop2()];
      const filterarr = [
        {
          no_data: "no_data",
        },
      ];
      return (
        <MainTable
          tablefilterDropDown={tablefilterDropDown}
          tabledata={filterarr}
          listOfMachine={" LIST OF TICKETS"}
          Action={""}
        />
      );
    }
  };
  //brudcumps
  const breadcrumbs = [
    <Link
      underline="hover"
      type="button"
      key="1"
      color="inherit"
      className="m-0 grey font-weight-bold"
    >
      USER MASTER
    </Link>,
    <Link
      type="button"
      underline="hover"
      className="m-0 grey font-weight-bold"
      key="2"
      color="inherit"
    >
      INVENTORY
    </Link>,
  ];

  return (
    <div className="comp-change">
      {listInventoryApi.isLoading ? (
        <div className="border">
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex">
        <Stack className="mx-4 pt-4  w-100">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {breadcrumbs}
          </Breadcrumbs>
          {/* <p>MACHINE MASTER {">"} ADD MACHINE</p> */}
        </Stack>
        <div className="w-100 px-2 pt-1 d-flex justify-content-end dateRange align-items-center">
          <CalendarMonthIcon
            className="d-flex  align-items-center"
            type="button"
          />
          <p className="d-flex px-1 m-0 align-items-center">
            <DateRangePicker
              initialSettings={{
                startDate: oneMonthPreviosDate,
                endDate: new Date(),
                locale: {
                  format: "DD/MM/YYYY",
                },
              }}
              className="date"
              onEvent={onEventService}
              onCallback={callbackService}
            >
              <input type="button" className=" date " />
            </DateRangePicker>
          </p>
        </div>
      </div>
      {/* <h1>ADD inventory</h1> */}
      {listInventoryApi.isLoading ? "" : filter()}
    </div>
  );
}

export default Inventory;

const inventpryCart = () => {
  return (
    <div className=" pt-2 position-sticky start-0 end-0 row w-100">
      <div className="w-100 h-100">
        <Grid spacing={2} container className="align-items-center   px-3">
          <Grid
            item
            className="align-items-center py-3 col-sm-6 col-md-6 col-lg-3 grid h-100"
          >
            <Paper className="border-radias-14">
              <div className=" font-size-24 fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                TOTAL STOCK
              </div>
              <div className="d-flex align-items-start">
                {/* <FontAwesomeIcon icon="fa-solid fa-house-circle-check" /> */}

                <FontAwesomeIcon
                  icon="fa-solid fa-house-circle-check"
                  className=" border-right border-dark text-red py-3 px-2 "
                  style={{
                    fontSize: 42,
                    marginLeft: "20px",
                    paddingBottom: "10px",
                  }}
                />
                <div className="d-flex mx-2 flex-column w-100">
                  <div className="d-flex px-4 w-100 align-items-center">
                    <h1 className="fw-bolder cart-value text-red px-3 d-flex justify-content-center ">
                      001
                    </h1>
                  </div>
                  <p className=" mx-3 today-request company-text">
                    * Today Raised Request 90
                  </p>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            className="align-items-center py-3 col-sm-6 col-md-6 col-lg-3 grid h-100"
          >
            <Paper className="border-radias-14">
              <div className=" font-size-24 fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                UNUTILIZED STOCK
              </div>
              <div className="d-flex align-items-start">
                <FontAwesomeIcon
                  icon="fa-solid fa-box-open"
                  className=" border-right  border-dark py-3 px-2 textblue"
                  style={{
                    fontSize: 42,
                    marginLeft: "20px",
                    paddingBottom: "10px",
                  }}
                />
                <div className="d-flex mx-2 flex-column w-100">
                  <div className="d-flex px-4 w-100 align-items-center">
                    <h1 className="fw-bolder textblue cart-value px-3 d-flex justify-content-center ">
                      200
                    </h1>
                  </div>
                  <p className=" mx-3 today-request company-text">
                    * Today Assigned Request 90
                  </p>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            className="align-items-center py-3 col-sm-6 col-md-6 col-lg-3 grid h-100"
          >
            <Paper className="border-radias-14">
              <div className=" font-size-24 fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                UTILIZED STOCK
              </div>
              <div className="d-flex align-items-start">
                <FontAwesomeIcon
                  icon="fa-solid fa-cart-flatbed"
                  className=" border-right  border-dark py-3 px-2 textorange"
                  style={{
                    fontSize: 42,
                    marginLeft: "20px",
                    paddingBottom: "10px",
                  }}
                />
                <div className="d-flex mx-2 flex-column w-100">
                  <div className="d-flex px-4 w-100 align-items-center">
                    <h1 className="fw-bolder cart-value textorange px-3 d-flex justify-content-center ">
                      200
                    </h1>
                  </div>
                  <p className=" mx-3 today-request company-text">
                    * Today Issue fixed 90
                  </p>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            className="align-items-center py-3 col-sm-6 col-md-6 col-lg-3 grid h-100"
          >
            <Paper className="border-radias-14">
              <div className=" font-size-24 fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                DAMAGED STOCK
              </div>
              <div className="d-flex align-items-start">
                <FontAwesomeIcon
                  icon="fa-solid fa-person-walking-luggage"
                  className=" border-right textgreen border-dark py-3 px-2 "
                  style={{
                    fontSize: 42,
                    marginLeft: "20px",
                    paddingBottom: "10px",
                  }}
                />
                <div className="d-flex mx-2 flex-column pb-2 w-100">
                  <div className="d-flex px-4 w-100 align-items-center">
                    <h1 className="fw-bolder cart-value textgreen px-3 d-flex justify-content-center ">
                      0
                    </h1>
                  </div>
                  <p className=" mx-3 today-request company-text">
                    * Today Closed Request 09
                  </p>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
