import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const MachineSetting = createSlice({
  name: "editCompany",
  initialState: {
    edit: false,
    data: {},
    settings: {},
    paths: {},
  },
  reducers: {
    gensettingdata: (state, payload) => {
      state.data = payload.payload;
    },
    paths: (state, payload) => {
      state.paths = payload.payload;
    },
    settings: (state, payload) => {
      //   console.log(payload.payload.action);
      state.settings = payload.payload;
    },
  },
});

export const { gensettingdata, paths, settings } = MachineSetting.actions;
// export const selectCompany = (state) => statetCompany.login;
export default MachineSetting.reducer;
