import React, { useState } from "react";
import "../assets/css/dashboard.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Box from "@mui/material/Box";
import { Card, Grid, Badge } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import Charts from "../insideComponents/dashbord/charts";
import BarChart from "../insideComponents/dashbord/charts/barchart";

import PieChart from "../insideComponents/dashbord/charts/pie-chart";
import RadialBarChart from "../insideComponents/dashbord/charts/radialBarChart";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  card,
  Issues,
  tabledata,
} from "../insideComponents/dashbord/dashbordComponents";

const Machinemastercards = (props) => {
  const [ActiveServiceRequestDateRange, setActiveServiceRequestDateRange] =
    useState();
  const { t } = useTranslation();
  var todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - 7);
  const path = window.location.pathname;
  console.log(path);
  // console.log(props.machineData);
  return (
    <div>
      <div className=" pt-2 position-sticky start-0 end-0 row w-100">
        <div className="col-sm-12 col-md-12 col-lg-6 h-100">
          <Grid spacing={2} container className="align-items-center   px-1">
            <Grid
              item
              className="align-items-center py-3 col-sm-12 col-md-12 col-lg-6 grid h-100"
            >
              <Card
                style={{ borderRadius: "15px", height: "100%" }}
                className="px-10"
              >
                <div className="darkgrey fw-bolder  pt-4 pb-2 d-flex align-items-center justify-content-center">
                  {t("dashboard.activeMachine")}
                </div>
                <div className="d-flex align-items-center active-machines">
                  <CheckCircleIcon
                    className="text-succe cancel_ico"
                    htmlColor="#a1c335"
                  />
                  <img
                    src={require("../assets/image/coffee-machine.png")}
                    className="icon-border-cofee grey"
                  />
                  <div className="d-flex mx-2 flex-column">
                    <div className="d-flex px-3">
                      <h1 className="fw-bolder textlightblue">
                        {props.machineData
                          ? props.machineData != 0
                            ? props.machineData.filter((e) => {
                                if (e.status.length != 0) {
                                  return e.is_active === true;
                                }
                              }).length
                            : 0
                          : 0}
                      </h1>
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-up "
                        className="grey up-arrow"
                      />
                    </div>
                    <p className="grey company-text1">
                      *
                      {props.machineData
                        ? props.machineData != 0
                          ? props.machineData.filter((e) => {
                              console.log(e);
                              if (e.status.length != 0) {
                                return e.current_status === "Connected";
                              }
                            }).length
                          : 0
                        : 0}{" "}
                      conected machine
                    </p>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid
              item
              className="align-items-center py-3 col-sm-12 col-md-12 col-lg-6 grid h-100"
            >
              <Card style={{ borderRadius: "15px", height: "100%" }}>
                <div className="darkgrey fw-bolder pt-4 pb-2 d-flex align-items-center justify-content-center">
                  {t("dashboard.defectMachine")}
                </div>
                <div className="d-flex align-items-center active-machines">
                  <CancelIcon className="text-danger cancel_ico" />
                  <img
                    src={require("../assets/image/coffee-machine.png")}
                    className="icon-border-cofee grey"
                  />
                  <div className="d-flex mx-2 flex-column">
                    <div className="d-flex px-3">
                      <h1 className="fw-bolder text-danger">
                        {" "}
                        {props.machineData
                          ? props.machineData != 0
                            ? props.machineData.filter((e) => {
                                console.log(e);
                                if (e.status.length != 0) {
                                  return e.is_defect === true;
                                }
                              }).length
                            : 0
                          : 0}{" "}
                      </h1>
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-down "
                        className="grey up-arrow"
                      />
                    </div>
                    <p className="grey company-text1 md">
                      * recently defected{" "}
                      {props.machineData
                        ? props.machineData != 0
                          ? props.machineData.filter((e) => {
                              console.log(e);
                              if (e.status.length != 0) {
                                if (e.is_defect === true) {
                                  const date = new Date();
                                  date.setHours(date.getHours() - 2);
                                  return e.updated_at >= date;
                                }
                              }
                            }).length
                          : 0
                        : 0}
                    </p>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div className=" col-sm-12 col-md-12 col-lg-6 h-100">
          <Grid spacing={2} container className="align-items-center px-1">
            {Issues.map((data, index) => {
              return (
                <Grid
                  key={index}
                  item
                  className="align-items-center col-sm-6 col-md-3 col-lg-3 py-3 grid h-100"
                >
                  <Card style={{ borderRadius: "15px", height: "100%" }}>
                    <div className="d-flex p-3  flex-column justify-content-center align-items-center">
                      {data.icon}
                      <p className="fw-bolder1  grey m-0">{t(data.issue)}</p>
                      <h1 className="MasignIssues text-danger grey ">
                        {data.value}
                      </h1>
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Machinemastercards;
