import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const ConvertUser = createSlice({
  name: "convertCustomerUser",
  initialState: {
    edit: false,
    data: {},
  },
  reducers: {
    edit: (state, payload) => {
      state.edit = payload.payload;
    },
    editUsers: (state, payload) => {
      state.edit = payload.payload;
    },
    userData: (state, payload) => {
      //   console.log(payload.payload.action);
      state.data = payload.payload;
    },
  },
});

export const { edit, editUsers, userData } = ConvertUser.actions;
// export const selectUser = (state) => state.user.login;
export default ConvertUser.reducer;
